import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import normalize from "json-api-normalizer"

import DisplayHomeTile from "components/common/DisplayHomeTile"

export default function DisplayHomeRoot() {
  const {
    config: {
      display_location_name: locationName,
      records,
      record_ids: displayHomeIds
    }
  } = useSelector(state => state)

  const displayHomes = normalize(records).displayHome
  const displayHomesCount = Object.values(displayHomes).filter(displayHome => displayHome.attributes.status === "Open to Public").length

  if (displayHomesCount === 0) {
    return ""
  }

  const pluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

  return (
    <Fragment>
      <div className="text-center">
        <h2 className="mb-4">Display Homes</h2>
        <p className="mb-5">
          {`${locationName} has ${pluralize(displayHomesCount, 'home')} on display`}
        </p>
      </div>
      <div className="row">
        {displayHomeIds.map(displayHomeId => (
          <div className="col-lg-4 col-md-6">
            <DisplayHomeTile
              hideAddr
              key={`display-home-${displayHomeId}`}
              displayHome={displayHomes[displayHomeId]} />
          </div>
        ))}
      </div>
    </Fragment>
  )
}