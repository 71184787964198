import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"

import HomeDesignName from "components/common/HomeDesignName"
import { IconFacade, IconDisplayPin } from "images/icons"
import { NewBadge, AwardBadge, OnPromotionBadge } from "components/buttons/badges"
import { setShowFacadeModal } from "../../actions"

const OnDisplayBadge = ({ onDisplay }) => {
  if (onDisplay) {
    return (
      <li className="list-inline-item mr-2">
        <a href="#on_display">
          <div className="btn btn-black-opac btn-md">
            <IconDisplayPin style={{ marginTop: "-2px" }} />
          </div>
        </a>
      </li>
    )
  }
  return ""
}

// OnDisplayBadge.propTypes = {
//   virtualTour: PropTypes.bool
// }

function VirtualTourBadge({ virtualTour }) {
  if (virtualTour) {
    return (
      <li className="list-inline-item">
        <a
          className="btn btn-white btn-md mr-2"
          href={virtualTour}
          target="_blank"
          rel="noopener noreferrer">
          <i className="material-icons">remove_red_eye</i>
          <span className="ml-1 hide-1">
            3D Virtual Tour
          </span>
        </a>
      </li>
    )
  }
  return ""
}

// VirtualTourBadge.propTypes = {
//   virtualTour: PropTypes.string
// }

export default function CarouselOverlay({ index, facadeName, facadeCount }) {
  const dispatch = useDispatch()
  const {
    attributes: {
      name, onDisplay, virtualTour,
      newDesign, awardWinner, onPromotion
    }
  } = useSelector(state => state.homeDesign)
  return (
    <Fragment>
      <div className="card-badges container-fluid">
        <NewBadge display={newDesign} />
        <AwardBadge display={awardWinner} />
        <OnPromotionBadge display={onPromotion} />
      </div>
      <div className="summary container-fluid">
        <div className="row justify-content-around">
          <div className="col-md-6 order-2 order-md-1">
            <h1 className="h2 text-left home-name">
              <HomeDesignName name={name} />
            </h1>
          </div>
          <div className="col-md-6 text-right align-self-end order-1 order-md-2">
            <ul className="list-inline">
              <li className="list-inline-item mr-2">
                <button
                  type="button"
                  className="btn btn-black-opac btn-md"
                  onClick={() => dispatch(setShowFacadeModal(true))}
                  style={{ boxShadow: "none" }}>
                  <IconFacade className="mr-2" style={{ marginTop: "-2px" }} />
                  {`${index}/${facadeCount}`}
                  <span className="hide-2">
                    {` ${facadeName}`}
                  </span>
                </button>
              </li>
              <OnDisplayBadge onDisplay={onDisplay} />
              <VirtualTourBadge virtualTour={virtualTour} />
            </ul>
            {/* <div className="row justify-content-end">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-black-opac btn-md"
                  onClick={() => dispatch(setShowFacadeModal(true))}
                  style={{ boxShadow: "none", overflow: "hidden", textOverflow: "ellipsis" }}>
                  <IconFacade className="mr-2" style={{ marginTop: "-2px" }} />
                  {`${index}/${facadeCount} ${facadeName}`}
                </button>
              </div>
              <div className="col-3">
                <OnDisplayBadge onDisplay={onDisplay} />
              </div>
              <div className="col-3">
                <VirtualTourBadge virtualTour={virtualTour} />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

CarouselOverlay.propTypes = {
  index: PropTypes.number.isRequired,
  facadeName: PropTypes.string.isRequired,
  facadeCount: PropTypes.number.isRequired
}
