/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from "react"
import Select from "react-select"
import { Row, Col } from "react-bootstrap"
import Util from "../../Util/configurator-util"
import { ACTIONS, DESIGN_SORT_OPTIONS, useDispatch } from "../../configurator-context-provider"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.PriceFilters = false

const PriceFilters = () => {
  // if (window.configurator.debugPoints.PriceFilters) {
  //   debugger
  // }

  const dispatch = useDispatch()
  const [priceFilters, setPriceFilters] = useState(["any", "any"])

  const designSortOptions = Object.values(DESIGN_SORT_OPTIONS)
  const [designSort, setDesignSort] = useState(designSortOptions[0].value)

  const handleSortOptionChange = newValue => {
    if (newValue.value === designSort) return
    setDesignSort(newValue.value)
    dispatch({ type: ACTIONS.SORT_DESIGNS, payload: { option: newValue.value } })
  }

  const handlePriceFilterChange = (isLower, newValue) => {
    const newPriceFilters = priceFilters.slice()

    if (isLower) {
      if (newPriceFilters[0] === newValue.value) return
      newPriceFilters[0] = newValue.value
    } else {
      if (newPriceFilters[1] === newValue.value) return
      newPriceFilters[1] = newValue.value
    }

    setPriceFilters(newPriceFilters)
    dispatch({ type: ACTIONS.SET_PRICE_FILTERS, payload: { priceFilters: newPriceFilters } })
  }

  const priceFilterOptions = [
    { label: "Any", value: "any" },
    { label: "$ 90,000", value: 90000 },
    { label: "$ 175,000", value: 175000 },
    { label: "$ 200,000", value: 200000 },
    { label: "$ 225,000", value: 225000 },
    { label: "$ 250,000", value: 250000 },
    { label: "$ 275,000", value: 275000 },
    { label: "$ 300,000", value: 300000 },
    { label: "$ 325,000", value: 325000 },
    { label: "$ 350,000", value: 350000 },
    { label: "$ 375,000", value: 375000 },
    { label: "$ 400,000", value: 400000 },
    { label: "$ 450,000", value: 450000 },
    { label: "$ 500,000", value: 500000 },
    { label: "$ 550,000", value: 550000 },
    { label: "$ 600,000", value: 600000 }
  ]

  const displayOptions = [
    { label: "Facade", value: "facade" },
    { label: "Floorplan", value: "floorplan" }
  ]

  const minOptions = []
  const maxOptions = []
  Util.each(priceFilterOptions, option => {
    if (option.value === "any") {
      minOptions.push({ ...option })
      maxOptions.push({ ...option })
      return
    }

    if (priceFilters[0] === "any" || priceFilters[0] < option.value) {
      maxOptions.push({ ...option })
    } else {
      maxOptions.push({ ...option, isDisabled: true })
    }
    if (priceFilters[1] === "any" || priceFilters[1] > option.value) {
      minOptions.push({ ...option })
    } else {
      minOptions.push({ ...option, isDisabled: true })
    }
  })

  return (
    <Col lg="4">
      <Row>
        <div className="title">Price Range</div>
        <Col>
          <div className="form-group">
            <label>Min</label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              defaultValue={priceFilterOptions[0]}
              isClearable={false}
              isSearchable={false}
              name="minPrice"
              options={minOptions}
              onChange={newValue => handlePriceFilterChange(true, newValue)} />
          </div>
        </Col>
        <Col>
          <div className="form-group">
            <label>Max</label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              defaultValue={priceFilterOptions[0]}
              isClearable={false}
              isSearchable={false}
              name="maxPrice"
              options={maxOptions}
              onChange={newValue => handlePriceFilterChange(false, newValue)} />
          </div>
        </Col>
      </Row>
      <Row className="price-second-row">
        <Col>
          <div className="form-group">
            <div className="title">Display By</div>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              defaultValue={displayOptions[0]}
              isClearable={false}
              isSearchable={false}
              name="displayBy"
              options={displayOptions}
              onChange={newValue => dispatch({ type: ACTIONS.SET_DISPLAY_BY, payload: { displayBy: newValue.value } })} />
          </div>
        </Col>
        <Col>
          <div className="form-group">
            <div className="title">Sort By</div>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              defaultValue={designSortOptions[0]}
              isClearable={false}
              isSearchable={false}
              name="designSort"
              options={designSortOptions}
              onChange={newValue => handleSortOptionChange(newValue)} />
          </div>
        </Col>
      </Row>
    </Col>
  )
}

export default PriceFilters
