import React from "react"
import PropTypes from "prop-types"

import {
  IconCar, IconShower, IconTheatre, IconBed
} from "images/icons"

export default function HomeRoomSmall({
  homeDesign: {
    attributes: {
      bedrooms, bathrooms, homeTheatre, garages
    }
  }, size = 13,
}) {
  const roomCountsDisplay = [
    {
      name: "Bedroom",
      count: bedrooms,
      icon: <IconBed className="mr-1" style={{ marginTop: "-2px" }} height={size} width={size} />
    },
    {
      name: "Bathroom",
      count: bathrooms,
      icon: <IconShower className="mr-1" style={{ marginTop: "-2px" }} height={size} width={size} />
    },
    {
      name: "Home Theatre",
      count: homeTheatre,
      icon: <IconTheatre className="mr-1" style={{ marginTop: "-2px" }} height={size} width={size} />
    },
    {
      name: "Garage",
      count: garages,
      icon: <IconCar className="mr-1" style={{ marginTop: "-2px" }} height={size} width={size} />
    }
  ]

  return (
    <ul className="list-inline design-spec small">
      {roomCountsDisplay.map(room => {
        if (room.count > 0) {
          return (
            <li className="list-inline-item" key={`icon-${room.name}`} style={{ marginTop: "-2px" }}>
              {room.icon}
              {room.count}
            </li>
          )
        }
        return ""
      })}
    </ul>
  )
}

HomeRoomSmall.propTypes = {
  homeDesign: PropTypes.shape({
    attributes: PropTypes.shape({
      bedrooms: PropTypes.number.isRequired,
      bathrooms: PropTypes.number.isRequired,
      homeTheatre: PropTypes.number.isRequired,
      garages: PropTypes.number.isRequired,
    }).isRequired
  }).isRequired,
  // eslint-disable-next-line react/require-default-props
  size: PropTypes.number
}