import React, { useRef, useEffect, useState } from "react"
import { useSelector } from "react-redux"

import HomeRooms from "components/common/homeRooms"
import HomeMeasurements from "components/common/HomeMeasurements"
import HomeDesignFloorPlan from "components/common/HomeDesignFloorPlan"
import priceFormatter from "helpers/priceFormatter"

export default function HomeLandDetails() {
  const homeLand = useSelector(state => state.homeLand)
  const {
    attributes: {
      price, id,
      bedrooms, bathrooms, garages, homeTheatre,
      designLength, designWidth,
      sizeM2, livingArea,
      terrazzaArea, garageArea, patioArea, balconyArea,
      floorplansLeft, floorplansRight,
    }
  } = homeLand

  const priceString = priceFormatter({ price })

  const homeMeasurements = {
    id,
    designLength,
    designWidth,
    sizeM2,
    livingArea,
    terrazzaArea,
    garageArea,
    patioArea,
    balconyArea,
  }

  const detailColRef = useRef()
  const [detailColHeight, setDetailColHeight] = useState(0)
  useEffect(() => {
    if (detailColRef.current) {
      if (detailColRef.current.clientHeight >= 600) {
        setDetailColHeight(detailColRef.current.clientHeight)
      } else {
        setDetailColHeight(600)
      }
    }
  }, [detailColRef])

  return (
    <div className="container pb-5">
      <div className="row mt-lg-5">
        <div className="col" ref={detailColRef}>
          <HomeRooms
            bedrooms={bedrooms}
            bathrooms={bathrooms}
            garages={garages}
            homeTheatre={homeTheatre}
            className="d-none d-lg-block" />

          {price && <div className="h2 my-4 pt-3 pb-2 d-none d-lg-block">{`${priceString}*`}</div>}

          <div className="home-info">
            <HomeMeasurements homeLand measurements={homeMeasurements} />
          </div>
        </div>
        <div className="col-xl-9 col-md-8 mt-md-0">
          <HomeDesignFloorPlan floorPlansLeft={floorplansLeft} floorPlansRight={floorplansRight} bigImage picHeight={detailColHeight} />
        </div>
      </div>
    </div>
  )
}