import normalize from "json-api-normalizer"

const normalizedLandForSale = normalize(gon.record).landForSale
const normalizedObject = Object.values(normalizedLandForSale)[0]

const landForSale = (state = normalizedObject, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default landForSale