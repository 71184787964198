import React from "react"

import Filter from "./Filter"
import Gallery from "./Gallery"

export default function Body() {
  return (
    <div className="container-fluid">
      <div className="row">
        <Filter />
        <Gallery />
      </div>
    </div>
  )
}