import {
  all, put, fork, call, takeLatest, select, debounce
} from "redux-saga/effects"
import { getData } from "./requesters"
import * as actions from "./actions"
import * as constants from "./constants"

function* fetchData() {
  const apiUrl = yield select(state => state.homeLands.api_url)
  const filters = yield select(state => state.homeLands.filters)
  const { response, error } = yield call(getData, apiUrl, filters)
  if (response) {
    yield put(actions.fetchDataSuccess(response, filters))
  } else {
    yield put(actions.fetchDataFailed(error))
  }
}

// watchers
function* watchFetchDataRequest() {
  yield takeLatest(constants.FETCH_REQUESTED, fetchData)
}

function* watchUpdateFilters() {
  // Need to confirm what has changed here to proceed
  yield debounce(300, constants.FILTERS_UPDATED, fetchData)
}

export default function* RootSaga() {
  yield all([
    fork(watchFetchDataRequest),
    fork(watchUpdateFilters)
  ])
}
