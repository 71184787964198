import { combineReducers } from "redux"

import byId, * as fromById from "./byId"
import allIds, * as fromAllIds from "./allIds"
import count from "./count"
import apiRequest, * as fromApiRequest from "./apiRequest"
import filters, * as fromFilters from "./filters"
import options, * as fromOptions from "./options"

// fromById
export const getHomeDesign = (state, HomeDesignId) => fromById.getHomeDesign(state, HomeDesignId)
export const getHomeDesignObj = state => fromById.getHomeDesignObj(state)
export const getHomeDesigns = state => state.allIds.map(HomeDesignId => fromById.getHomeDesign(state, HomeDesignId))

// fromAllIds
export const getHomeDesignIds = state => fromAllIds.getHomeDesignIds(state)

// fromApiRequest
export const isFetchingHomeDesigns = state => fromApiRequest.isFetchingHomeDesigns(state)

// fromFilters
export const getFilters = state => fromFilters.getFilters(state)

// fromOptions
export const getOptions = state => fromOptions.getOptions(state)

export default combineReducers({
  byId,
  allIds,
  count,
  apiRequest,
  filters,
  options
})
