import * as constants from "../../constants"

const byId = (state = {}, action) => {
  switch (action.type) {
    case constants.FETCH_SUCCESS: {
      return action.response.data.plan || {}
    }

    default:
      return state
  }
}

export const getHomeDesign = (state, homeDesignId) => state.byId[homeDesignId]
export const getHomeDesignObj = state => state.byId

export default byId
