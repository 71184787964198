/* eslint-disable no-undef */
import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"
import createReducer from "components/utils/createReducer"

import favouriteList from "components/common/favouriteIcon/redux/reducers"
import comparePlanList from "components/common/CompareButton/redux/reducers"
import popularDesigns from "./popularDesigns"

// building config reducer with gon excluding project has its own.
const config = createReducer(
  function () {
    const { ...initialState } = gon
    return initialState
  }()
)({})

export default history => combineReducers({
  router: connectRouter(history),
  config,
  popularDesigns,
  favouriteList,
  comparePlanList,
})