import React from "react"
import { useSelector, useDispatch } from "react-redux"

import Carousel from "react-bootstrap/Carousel"
import CarouselOverlay from "./carouselOverlay"
import FacadeModal from "./facadeModal"
import { setFacadeCarouselIdx } from "../../actions"

export default function HomeDesignCarousel() {
  const dispatch = useDispatch()
  const currentIdx = useSelector(state => state.facadeCarouselIndex)
  const {
    attributes: {
      name, facades, newDesign, awardWinner, onPromotion
    }
  } = useSelector(state => state.homeDesign)

  const facadesSafe = facades.data.length > 0 ? facades.data : [{
    id: 0,
    attributes: {
      id: 0,
      imageJson: {
        md: null
      },
      name: "Placeholder"
    }
  }]
  const facadesLength = facadesSafe.length

  return (
    <div className="home-design-hero container-fluid mb-md-4">

      <Carousel
        className="carousel-inner"
        interval={null}
        activeIndex={currentIdx}
        onSelect={selectedIndex => { dispatch(setFacadeCarouselIdx(selectedIndex)) }}
        indicators={false}>
        {facadesSafe.map(facade => {
          const { attributes: { id: facadeId, imageJson: { md: imageUrl }, name: facadeName } } = facade
          return (
            <Carousel.Item key={`detail-facade-${facadeId}`}>
              <div
                title={facadeName}
                style={{
                  backgroundImage: `url(${imageUrl || "/fallback/placeholder-house.svg"})`
                }}>
              </div>
            </Carousel.Item>
          )
        })}
      </Carousel>

      <CarouselOverlay
        index={currentIdx + 1}
        facadeName={facadesSafe[currentIdx].attributes.name}
        facadeCount={facadesLength} />

      <FacadeModal
        name={name}
        facades={facadesSafe}
        newDesign={newDesign}
        awardWinner={awardWinner}
        onPromotion={onPromotion} />
    </div>
  )
}