import axios from "axios"
import normalize from "json-api-normalizer"

// Get Popular Designs
const getPopularDesigns = (apiUrl, body) => axios.get(apiUrl, body)
  .then(({ data }) => {
    const return_data = {
      response: {
        data: {}
      }
    }
    Object.keys(data.records).forEach(key => {
      return_data.response.data[key] = normalize(data.records[key])
    })
    return return_data
  })
  .catch(error => ({ error: error.message }))

export { getPopularDesigns }