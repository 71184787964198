import React from "react"
import { useSelector } from "react-redux"

import HomeDesignName from "components/common/HomeDesignName"
import HomeRooms from "components/common/homeRooms"
import { IconFacade } from "images/icons"
import priceFormatter from "helpers/priceFormatter"
import HomeLandIntro from "./HomeLandIntro"


export default function HomeLandHero() {
  const homeLand = useSelector(state => state.homeLand)
  const {
    attributes: {
      planName, price, suburb, address1, pdf,
      bedrooms, bathrooms, garages, homeTheatre,
      facadeImage: { md: imageUrl }, facadeName,
      blockWidth, blockDepth, landarea,
      status,
      developmentLogo,
      landPrice, homePrice
    }
  } = homeLand
  const priceString = priceFormatter({ price })
  let devLogoRendered = false

  const enoughRoom = () => [blockDepth, blockWidth, landarea, landPrice, homePrice].filter(el => ![null, undefined].includes(el)).length < 2

  const renderLogoInHero = () => {
    if (!developmentLogo) { return false }
    const room = enoughRoom()
    if (room) {
      devLogoRendered = true
    }
    return room
  }

  return (
    <>
      <div className="home-design-hero container-fluid mb-md-4 mb-2">
        <div className="row">
          <div className="col-lg-8">
            <div className="carousel" id="home-design-hero">
              <div className="carousel-inner">
                <div
                  className="carousel-item active home-land"
                  style={{
                    backgroundImage: `url(${imageUrl || "/fallback/placeholder-house.svg"})`,
                  }}>
                </div>
              </div>
              <div className="card-badges container-fluid d-flex justify-content-end p-0">
                <div className="display-home-for-sale-overlay">
                  <span className="for-sale">{status}</span>
                </div>
              </div>
              <div className="summary">
                <div className="row justify-content-around">
                  <div className="col-md-8 order-2 order-md-1">
                    <h1 className="h2 text-left home-name">
                      <HomeDesignName name={planName} />
                    </h1>
                  </div>
                  <div className="col-md-4 text-right align-self-end order-1 order-md-2">
                    {facadeName && (
                      <ul className="list-inline mr-2">
                        <li className="list-inline-item">
                          <div className="btn btn-black-opac">
                            <IconFacade className="mr-2" style={{ marginTop: "-2px" }} />
                            {facadeName}
                          </div>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 pl-lg-0">
            <div className="bg-light home-land-summary d-flex">
              <div className="text-center align-self-center mx-auto">
                {price && (
                  <div className="price">
                    {`*${priceString}`}
                  </div>
                )}
                <h3 className="mb-4">
                  {address1}
                  <br />
                  {suburb}
                </h3>
                <HomeRooms
                  bedrooms={bedrooms}
                  bathrooms={bathrooms}
                  garages={garages}
                  homeTheatre={homeTheatre} />

                <div className="home-info mt-5 text-left">
                  <table className="table table-sm table-borderless small-text table-list">
                    <tbody>
                      { landarea
                        ? (
                          <tr>
                            <td>Land Area</td>
                            <td>
                              {`${landarea} m`}
                              <sup>2</sup>
                            </td>
                          </tr>
                        ) : null
                      }
                      { blockWidth
                        ? (
                          <tr>
                            <td>Block Width</td>
                            <td>{`${blockWidth} m`}</td>
                          </tr>
                        ) : null }
                      { blockDepth
                        ? (
                          <tr>
                            <td>Block Depth</td>
                            <td>{`${blockDepth} m`}</td>
                          </tr>
                        ) : null }
                      { landPrice
                        ? (
                          <tr>
                            <td>Land Price</td>
                            <td>{landPrice}</td>
                          </tr>
                        ) : null }
                      { homePrice
                        ? (
                          <tr>
                            <td>Home Price</td>
                            <td>{homePrice}</td>
                          </tr>
                        ) : null }

                    </tbody>
                  </table>
                </div>
                {pdf && (
                  <a
                    className="btn btn-dark mb-4 mt-lg-5 btn-lg"
                    href={pdf}
                    target="_blank"
                    rel="noopener noreferrer">
                    Download Brochure
                  </a>
                )}
                {renderLogoInHero() ? (
                  <div className="row justify-content-center">
                    <div className="col-5 col-md-6">
                      <img className="my-md-4 my-2 img-fluid" src={developmentLogo.url} />
                    </div>
                  </div>
                ) : <></>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomeLandIntro devLogoRendered={devLogoRendered} />
    </>
  )
}