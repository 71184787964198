// Can put common controller stuff in here :D

import * as Sentry from '@sentry/browser';
import * as initializers0 from '../initializers/alerts.js'; import * as initializers1 from '../initializers/input_components/datepicker.js'; import * as initializers2 from '../initializers/input_components/tooltips.js'; import * as initializers3 from '../initializers/sweetalert.js'; import * as initializers4 from '../initializers/tooltips.js'; let initializers = [initializers0, initializers1, initializers2, initializers3, initializers4];

let runInitializers = function () {
  if (initializers == undefined) { return };

  initializers.forEach((initializer) => {
    return initializer.default(this);
  });
};

let Controller = function () {
  this.init = () => {
    try {
      runInitializers.call(this);
      this.call();
    } catch(e) {
      if (gon.env == "development") {
        console.error(e.stack)
      } else {
        Sentry.captureException(e);
      }
    }
  };
};

export default Controller;
