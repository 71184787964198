import React from "react"
import { Row, Col } from "react-bootstrap"
import classNames from "classnames"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.StandardInclusion = false

const StandardInclusion = ({ groupMember, index }) => {
  // if (window.configurator.debugPoints.StandardInclusion) {
  //   debugger
  // }

  return (
    <Row
      className={classNames("group-member-row", {
        "bg-light": index % 2 === 0
      })}>
      <Col xs={10} className="d-flex align-items-center">
        <div>
          &#9642;
          {groupMember.getName()}
        </div>
      </Col>
      <Col xs={2} className="d-flex align-items-center justify-content-end">
        <div>
          <input
            id={groupMember.getInternalID()}
            checked
            type="checkbox"
            className="inclusions-check"
            disabled />
        </div>
      </Col>
    </Row>
  )
}

export default StandardInclusion
