import React, { useState } from "react"
import {
  Container, Row, Col, Button, Modal
} from "react-bootstrap"
import classNames from "classnames"
import Util from "../Util/configurator-util"
import {
  ACTIONS, STEPS, useStep, useTotal, useSelectedDesign, useSelectedFacade, useContactFormValid, useDispatch, useSelectedRoof, useSelectedPriceLevel, useContactFormik
} from "../configurator-context-provider"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.Header = false

const MissingSelectionModal = ({ show, onHide, message }) => (
  <Modal
    show={show}
    onHide={onHide}
    centered
    size="sm">
    <Modal.Header closeButton>
      <Modal.Title>
        Error
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{message}</p>
    </Modal.Body>
  </Modal>
)

const Header = () => {
  // if (window.configurator.debugPoints.Header) {
  //   debugger
  // }

  const [modalStatus, setModalStatus] = useState({
    show: false,
    message: ""
  })
  const step = useStep()
  const total = useTotal()
  const selectedDesign = useSelectedDesign()
  const selectedPriceLevel = useSelectedPriceLevel()
  const selectedRoof = useSelectedRoof()
  const selectedFacade = useSelectedFacade()
  const contactFormValid = useContactFormValid()
  const dispatch = useDispatch()
  const {
    touched,
    validateForm,
    values
  } = useContactFormik()

  const nextDisabled = step === STEPS.CONTACT ? !contactFormValid : false
  const nextLabel = step === STEPS.CONTACT ? "Submit" : "Next"
  const backLabel = "Back"

  const handleClickNext = () => {
    switch (step) {
      case STEPS.DESIGN:
        if (selectedDesign.length === 0 && selectedPriceLevel.length === 0) {
          setModalStatus({
            show: true,
            message: "Please select a Build Location and Design before proceeding"
          })
          return
        }
        if (selectedPriceLevel.length === 0) {
          setModalStatus({
            show: true,
            message: "Please select a Build Location before proceeding"
          })
          return
        }
        if (selectedDesign.length === 0) {
          setModalStatus({
            show: true,
            message: "Please select a Design before proceeding"
          })
          return
        }
        break

      case STEPS.FACADE:
        if (selectedRoof.length === 0 && selectedFacade.length === 0) {
          setModalStatus({
            show: true,
            message: "Please select a Roof Type and Facade before proceeding"
          })
          return
        } if (selectedRoof.length === 0) {
          setModalStatus({
            show: true,
            message: "Please select a Roof Type before proceeding"
          })
          return
        } if (selectedFacade.length === 0) {
          setModalStatus({
            show: true,
            message: "Please select a Facade before proceeding"
          })
          return
        }
        break

      case STEPS.CONTACT:
        if (!values.subscribe) {
          setModalStatus({
            show: true,
            message: 'Please accept terms and conditions before proceeding'
          })
          return
        }
        if (!contactFormValid) {
          touched.firstName = true
          touched.surname = true
          touched.email = true
          touched.mobile = true
          touched.currentPostcode = true
          touched.intendedBuildLocation = true
          touched.isFirstHomeBuyer = true
          touched.lookingToBuild = true
          touched.liveInvestBoth = true
          validateForm()
          return
        }
        break

      default:
        break
    }

    dispatch({ type: ACTIONS.SET_STEP_NEXT })
  }

  return (
    <div className="configurator-header sticky">
      <Container fluid="sm">
        <Row>
          <Col>
            {step !== STEPS.INITIALISATION && step !== STEPS.DESIGN && step !== STEPS.FINAL && (
              <Button
                id="back"
                className="mb-3"
                onClick={() => dispatch({ type: ACTIONS.SET_STEP_BACK })}>
                {backLabel}
              </Button>
            )}
          </Col>
          <Col>
            <div className="running-total">{Util.formatAmount(total)}</div>
          </Col>
          <Col>
            {step !== STEPS.FINAL && (
              <Button
                id="next"
                className={classNames("mb-3", {
                  disabled: nextDisabled
                })}
                onClick={() => handleClickNext()}>
                {nextLabel}
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ span: 6, offset: 3 }}>
            <div className="configurator-progress"></div>
          </Col>
        </Row>
      </Container>
      <MissingSelectionModal
        show={modalStatus.show}
        onHide={() => setModalStatus({
          show: false,
          message: ""
        })}
        message={modalStatus.message} />
    </div>
  )
}

export default Header
