import BaseController from "controllers/pages/base"
// import { tns } from "tiny-slider/src/tiny-slider"
import startPopularDesignsApp from "apps/PopularDesigns"

class Controller extends BaseController {
  call() {
    super.call()

    startPopularDesignsApp(
      document.getElementById("popular-designs"),
      {}
    )

    // tinySlider.call(this)
  }
}

// function tinySlider() {
//   const slider = tns({
//     container: ".popular-slider",
//     items: 1.2,
//     loop: false,
//     gutter: 10,
//     disable: false,
//     controls: false,
//     navPosition: "bottom",
//     mouseDrag: true,
//     responsive: {
//       575: {
//         items: 2.1
//       },
//       767: {
//         disable: true,
//         items: 3
//       }
//     }
//   })
// }

export default Controller
