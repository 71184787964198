import { combineReducers } from "redux"

import * as constants from "../../constants"

const apiRequest = (state = null, action) => {
  switch (action.type) {
    case constants.FETCH_START:
    case constants.FETCH_SUCCESS:
    case constants.FETCH_FAILED:
      return action.type
    default:
      return state
  }
}

const designs = (state = {}, action) => {
  switch (action.type) {
    case constants.FETCH_SUCCESS: {
      return action.response.data
    }
    default:
      return state
  }
}

export default combineReducers({
  apiRequest,
  designs
})