/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import cx from "classnames"

import { updateFilter } from "../../redux/actions/galleryData"

export default function MediaTypeFilter() {
  const { by_media_type } = useSelector(state => state.filters)
  const dispatch = useDispatch()
  function mediaTypeButton({ type_name, displayAs }) {
    return (
      <li className="list-inline-item">
        <div
          className={cx("btn", "btn-media-type", { active: by_media_type.includes(type_name) })}
          onClick={() => dispatch(updateFilter({ by_media_type: _.xor(by_media_type, [type_name]) }))}>
          {displayAs}
        </div>
      </li>
    )
  }
  return (
    <div className="col-xl-12 col-lg-4 col-md-6 filter-col">
      <div className="title">Media Type</div>
      <ul className="list-inline mb-1">
        {mediaTypeButton({ type_name: "photos", displayAs: "Photos" })}
        {mediaTypeButton({ type_name: "videos", displayAs: "Videos" })}
      </ul>
      <ul className="list-inline mb-1" style={{ width: "50%" }}>
        {mediaTypeButton({ type_name: "virtual_tour", displayAs: "3D Virtual Tours" })}
      </ul>
    </div>
  )
}