/* eslint-disable no-undef */
import { combineReducers } from "redux"
import * as constants from "../constants"

const options = (state = {
  sort_by: [["Name A-Z", "name__asc"],
    ["Name Z-A", "name__desc"],
    ["Bedroom High", "bedrooms__desc"],
    ["Bedroom Low", "bedrooms__asc"],
    ["Bathroom High", "bathrooms__desc"],
    ["Bathroom Low", "bathrooms__asc"],
    ["Garage High", "garages__desc"],
    ["Garage Low", "garages__asc"]]
}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const filters = (state = { sort_by: "name__asc" }, action) => {
  switch (action.type) {
    case constants.FILTER_UPDATE_NO_FETCH: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}


const objects = (state = {}, action) => {
  switch (action.type) {
    case constants.FETCH_SUCCESS: {
      return action.payload.response
    }
    default: {
      return state
    }
  }
}

const sortedObjects = (state = [], action) => {
  switch (action.type) {
    case constants.OBJECT_SORTED: {
      return action.payload
    }
    default: {
      return state
    }
  }
}

const ids = (state = [], action) => {
  switch (action.type) {
    case constants.FETCH_SUCCESS: {
      return action.payload.meta.ordering
    }
    default: {
      return state
    }
  }
}

const dataFetching = (state = true, action) => {
  switch (action.type) {
    case constants.FETCH_REQUESTED: {
      return true
    }
    case constants.FETCH_SUCCESS:
    case constants.FETCH_FAILED: {
      return false
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  objects,
  sortedObjects,
  ids,
  dataFetching,
  options,
  filters
})