import $ from 'jquery';

function ajaxLoadForm(bindAfterAction){
  $.ajax({
    method: "get",
    url: gon.edit_form_url,
    success: function(response){
      $("#edit-form").html(response.html);
      bindAfterAction.call(this);
    },
    error: function(error) {
      $("#edit-form").text(error)
    }
  });
};

export default ajaxLoadForm;