import React, { Suspense } from "react"
import PropTypes from "prop-types"
import { TransitionGroup, CSSTransition } from "react-transition-group"

import LoadingBar from "components/loadingBar"

const HomeDesignTile = React.lazy(() => import("components/common/HomeDesignTile"))
const HomeDesignFloorPlanTile = React.lazy(() => import("components/common/HomeDesignFloorPlanTile"))
const HomeLandTile = React.lazy(() => import("components/common/HomeLandTile"))
const LandForSaleTile = React.lazy(() => import("components/common/LandForSaleTile"))
const DisplayHomeTile = React.lazy(() => import("components/common/DisplayHomeTile"))

function HomeCard({ type, homeDesignId, homeDesign }) {
  switch (type) {
    case "facade": {
      return (
        <HomeDesignTile
          homeDesignId={homeDesignId}
          homeDesign={homeDesign} />
      )
    }
    case "floorplan": {
      return (
        <HomeDesignFloorPlanTile
          homeDesignId={homeDesignId}
          homeDesign={homeDesign} />
      )
    }
    case "homeLand": {
      return (
        <HomeLandTile
          homeLandId={homeDesignId}
          homeLand={homeDesign} />
      )
    }
    case "landForSale": {
      return (
        <LandForSaleTile
          id={homeDesignId}
          object={homeDesign} />
      )
    }
    case "displayHome": {
      return (
        <DisplayHomeTile
          id={homeDesignId}
          displayHome={homeDesign} />
      )
    }
    default: {
      return ""
    }
  }
}

export default function HomeDesignList({ renderList, dataObject, renderType }) {
  return (
    <Suspense fallback={<LoadingBar isLoading />}>
      <TransitionGroup appear className="row design-card-deck">
        {renderList.map(planId => {
          if (parseInt(planId, 10) in dataObject) {
            const homeDesign = dataObject[planId]
            return (
              <CSSTransition
                timeout={400}
                classNames="tile-transition"
                key={`home-design-${planId}`}>
                <div className="col-lg-4 col-md-6">
                  <HomeCard
                    type={renderType}
                    homeDesignId={planId}
                    homeDesign={homeDesign} />
                </div>
              </CSSTransition>
            )
          }
          return ""
        })}
      </TransitionGroup>
    </Suspense>
  )
}

HomeDesignList.propTypes = {
  renderList: PropTypes.arrayOf(PropTypes.number).isRequired,
  dataObject: PropTypes.objectOf(PropTypes.object).isRequired,
  renderType: PropTypes.oneOf(["facade", "floorplan", "homeLand", "landForSale", "displayHome"]).isRequired
}
