import $ from 'jquery';

function ajaxPreviewStatus(){
  if( window.self === window.top) {
    $.ajax({
      method: "get",
      url: gon.preview_status_url,
      success: function(response){
        $('body').append(response.html);
      },
      error: function(error) {
        console.log(error)
      }
    })
  };
};

export default ajaxPreviewStatus;