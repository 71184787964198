import React from "react"
import { Container, Row } from "react-bootstrap"
import { useFacades } from "../../configurator-context-provider"
import FacadeCard from "./FacadeCard"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.FacadeCardList = false

const FacadeCardList = () => {
  // if (window.configurator.debugPoints.FacadeCardList) {
  //   debugger
  // }

  const facades = useFacades()

  return (
    <Container fluid="sm">
      <Row xs={1} md={2} lg={3}>
        {facades.map(facade => (
          <FacadeCard
            key={facade.getInternalID()}
            facade={facade} />
        ))}
      </Row>
    </Container>
  )
}

export default FacadeCardList
