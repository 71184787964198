import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import HomeCount from "components/common/HomeCount"
import LoadingBar from "components/loadingBar"
import { FAVOURITE_PLANS } from "servos/localStorage/storedList"
import { homeDesignActions } from "modules/HomeDesign"
import HomeDesignList from "components/common/HomeDesignList"
import SortByFilter from "components/filters/SortByFilter"
import DisplayByFilter from "components/filters/DisplayByFilter"
import { removeFavouriteId } from "components/common/favouriteIcon/redux/actions"
import * as reducers from "../reducers"

function HomeDesigns() {
  const dispatch = useDispatch()
  const isFetchingHomeDesigns = useSelector(state => reducers.isFetchingHomeDesigns(state))
  const homeDesignObj = useSelector(state => state.homeDesigns.byId)
  const homeDesignIds = useSelector(state => state.homeDesigns.allIds)
  const favouriteList = useSelector(state => state.favouriteList)
  const favouritePlanList = favouriteList[FAVOURITE_PLANS]
  const filters = useSelector(state => state.homeDesigns.filters)
  const options = useSelector(state => state.homeDesigns.options)
  const { view_by } = filters
  const { sort_by } = options

  useEffect(() => {
    if (favouritePlanList.length > 0) {
      dispatch(homeDesignActions.filtersUpdated({ by_ids: favouritePlanList }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (!isFetchingHomeDesigns) {
      favouritePlanList.forEach(favouritePlanId => {
        if (!homeDesignIds.includes(favouritePlanId)) {
          dispatch(removeFavouriteId({ listName: FAVOURITE_PLANS, planId: favouritePlanId }))
        }
      })
    }
  }, [isFetchingHomeDesigns, favouritePlanList, homeDesignIds, dispatch])

  const renderList = homeDesignIds.filter(id => favouritePlanList.includes(id))

  return (
    <div>
      <div className="container-fluid filter-content">

        <div className="row justify-content-around my-2 small-text align-items-center">
          <div className="col-sm-5 col-md-4 col-lg-3 order-sm-1 offset-md-3 offset-lg-5 offset-xl-6 mb-2 text-sm-right text-center">
            <DisplayByFilter
              display_type={view_by}
              dataChanged={data => { dispatch(homeDesignActions.filtersUpdatedNoRefresh(data)) }}
              inlineStyle />
          </div>

          <HomeCount number={renderList.length} />

          <SortByFilter
            options={sort_by}
            onChange={value => { dispatch(homeDesignActions.filtersUpdated({ sort_by: value })) }}
            selected={filters.sort_by} />
        </div>
        {favouritePlanList.length > 0 && <LoadingBar isLoading={isFetchingHomeDesigns} />}
        {((!isFetchingHomeDesigns && renderList.length === 0) || favouritePlanList.length === 0)
          ? <div className="no-results">List is empty</div>
          : <HomeDesignList dataObject={homeDesignObj} renderList={renderList} renderType={view_by} />}
      </div>
    </div>
  )
}

export default HomeDesigns