export const TOGGLE_COMPARE = "TOGGLE_COMPARE"
export const RESET_COMPARE = "RESET_COMPARE"
export const REMOVE_COMPARE = "REMOVE_COMPARE"
export const SET_COMPARE = "SET_COMPARE"

export const toggleComparePlanList = payload => ({
  type: TOGGLE_COMPARE,
  payload
})

export const resetComparePlanList = () => ({
  type: RESET_COMPARE,
})

export const removeComparePlanItem = planId => ({
  type: REMOVE_COMPARE,
  payload: { planId }
})

export const setComparePlanList = planIdList => ({
  type: SET_COMPARE,
  payload: { planIdList }
})