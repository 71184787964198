import {
  all, put, fork, call, takeLatest, select
} from "redux-saga/effects"
import { getNormalizedData } from "helpers/requesters"

import * as actions from "./actions/galleryData"
import * as types from "./actionTypes"
import filterGalleryData from "./filterGalleryData"

function* fetchGalleryData({ payload: { apiUrl } }) {
  const { response, error } = yield call(getNormalizedData, apiUrl)
  if (response) {
    yield put(actions.fetchDataSuccess({ response }))
  } else {
    yield put(actions.fetchDataFailed(error))
  }
}

function* formatData(action) {
  const { filters } = yield select(state => state)
  const { response } = action.payload
  const filteredData = filterGalleryData({ data: Object.values(response), filter: filters })
  yield put(actions.dataUpdated(filteredData))
}

function* updateData(action) {
  const filters = action.payload
  const { data: { fullArray } } = yield select(state => state)
  const filteredData = filterGalleryData({ data: fullArray, filter: filters })
  yield put(actions.dataUpdated(filteredData))
}

// watchers
function* watchFetchGalleryData() {
  yield takeLatest(types.FETCH_REQUESTED, fetchGalleryData)
}

function* watchFetchSuccess() {
  yield takeLatest(types.FETCH_SUCCESS, formatData)
}

function* watchFilterUpdated() {
  yield takeLatest(types.FILTER_UPDATED, updateData)
}

export default function* RootSaga() {
  yield all([
    fork(watchFetchGalleryData),
    fork(watchFetchSuccess),
    fork(watchFilterUpdated)
  ])
}
