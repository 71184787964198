/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { PureComponent } from "react"
import Select from "react-select"

class SimpleSelect extends PureComponent {
  render() {
    const {
      options,
      label,
      value,
      handleSelect
    } = this.props

    const selectStyles = {
      control: (styles, { isFocused }) => ({
        ...styles,
        "&:hover": { borderColor: "gray" },
        border: isFocused ? "2px solid #272727" : "1px solid lightgray",
        boxShadow: "none",
      }),
      option: (styles, { isDisabled, isFocused, isSelected }) => ({
        ...styles,
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isSelected ? "#272727" : isFocused ? "#f2f2f2" : "white",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#272727" : "#d9d9d9"),
        },
      })
    }

    return (
      <div className="form-group">
        <label>{label}</label>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          options={options}
          value={value}
          onChange={handleSelect}
          styles={selectStyles} />
      </div>
    )
  }
}

export default SimpleSelect