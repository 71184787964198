import BaseController from "controllers/base"
import startFavouritePageApp from "apps/CompareHomes"

class Controller extends BaseController {
  call() {
    super.call()

    startFavouritePageApp(
      document.getElementById("react-body"),
      {}
    )
  }
}

export default Controller