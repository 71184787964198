import axios from "axios"
import normalize from "json-api-normalizer"

export const getData = (apiUrl, body) => axios.post(apiUrl, body)
  .then(({ data }) => {
    const normalizedData = normalize(data.records)
    return {
      response: { data: normalizedData, meta: data.meta },
    }
  })
  .catch(error => ({ error: error.message }))
