import $ from 'jquery';

const ShowPreviewLabel = function(){
  $('#news_thumbnail').change(function () {
    if (this.files && this.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(this.files[0]);
      reader.onload = function (e) {
        $(".image-preview").attr("src", e.target.result);
        $(".preview-text").show()
      };
    }
  })
}

export default ShowPreviewLabel;