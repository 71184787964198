import {
  getList, setList, resetList, COMPARE_PLANS
} from "servos/localStorage/storedList"
import { addToStored, HOME_PLANS } from "servos/localStorage/storedObj"
import {
  TOGGLE_COMPARE, RESET_COMPARE, REMOVE_COMPARE, SET_COMPARE
} from "./actions"

export default function (state = getList(COMPARE_PLANS), action) {
  switch (action.type) {
    case TOGGLE_COMPARE: {
      const { homeDesignId, extra } = action.payload
      const planId = parseInt(homeDesignId, 10)
      const favouritePlanListTemp = [...state]
      const index = favouritePlanListTemp.indexOf(planId)
      if (index > -1) {
        favouritePlanListTemp.splice(index, 1)
      } else if (favouritePlanListTemp.length < 3) {
        favouritePlanListTemp.push(planId)
        addToStored(HOME_PLANS, { [planId]: extra })
      }
      // update cookie
      setList(COMPARE_PLANS, favouritePlanListTemp)
      return [...favouritePlanListTemp]
    }
    case REMOVE_COMPARE: {
      let { planId } = action.payload
      planId = parseInt(planId, 10)
      const favouritePlanListTemp = [...state]
      const index = favouritePlanListTemp.indexOf(planId)
      if (index > -1) {
        favouritePlanListTemp.splice(index, 1)
      }
      // update localStorage
      setList(COMPARE_PLANS, favouritePlanListTemp)
      return [...favouritePlanListTemp]
    }
    case RESET_COMPARE: {
      // update localStorage
      resetList(COMPARE_PLANS)
      return []
    }
    case SET_COMPARE: {
      const { planIdList } = action.payload
      // update localStorage
      setList(COMPARE_PLANS, planIdList)
      return [...planIdList]
    }
    default:
      return state
  }
}