/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import cx from "classnames"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import ReactCardFlip from "react-card-flip"

import { BhZoomIn, BhZoomOut, BhFlip } from "images/icons"

const FLOOR = {
  GROUND: 0,
  FIRST: 1
}

function TabHeader({
  floor, floorPlansLeft, floorPlansRight, setGroundFloor, setFirstFloor, visibility
}) {
  return (
    <div className={`d-flex justify-content-center mt-2 ${visibility}`}>
      <ul className="nav bh-nav bh-nav-sm">
        <li className="nav-item">
          <a
            role="link"
            tabIndex="0"
            className={cx("nav-link", { active: floor === FLOOR.GROUND })}
            onClick={setGroundFloor}
            onKeyPress={setGroundFloor}>
            Ground Floor
          </a>
        </li>
        {(floorPlansLeft.length > 1 && floorPlansRight.length > 1) && (
          <li className="nav-item">
            <a
              role="link"
              tabIndex="0"
              className={cx("nav-link", { active: floor === FLOOR.FIRST })}
              onClick={setFirstFloor}
              onKeyPress={setFirstFloor}>
              First Floor
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}

export default function FloorPlan({
  floorPlansLeft, floorPlansRight
}) {
  const [floor, setFloor] = useState(FLOOR.GROUND)
  const [isFlipped, setIsFlipped] = useState(false)

  const showHeader = (floorPlansLeft.length > 1 && floorPlansRight.length > 1)

  const width = "100%"
  const height = "100%"
  const tabVisibility = showHeader ? null : "visibility-hidden"

  return (
    <div>
      <TabHeader
        visibility={tabVisibility}
        floor={floor}
        floorPlansLeft={floorPlansLeft}
        floorPlansRight={floorPlansRight}
        setGroundFloor={() => { setFloor(FLOOR.GROUND) }}
        setFirstFloor={() => { setFloor(FLOOR.FIRST) }} />
      <div className="home-floorplan d-flex justify-content-center m-2">
        <TransformWrapper wheel={{ disabled: true }}>
          {({ zoomIn, zoomOut }) => (
            <>
              <TransformComponent>
                <ReactCardFlip isFlipped={isFlipped}>
                  <img src={floorPlansRight[floor]} style={{ height, width, objectFit: "scale-down" }} />
                  <img src={floorPlansLeft[floor]} style={{ height, width, objectFit: "scale-down" }} />
                </ReactCardFlip>
              </TransformComponent>
              <ul className="list-unstyled" style={{ zIndex: 10 }}>
                <li>
                  <div
                    className="btn btn-light"
                    onClick={zoomIn}>
                    <BhZoomIn />
                  </div>
                </li>
                <li>
                  <div
                    className="btn btn-light"
                    onClick={zoomOut}>
                    <BhZoomOut />
                  </div>
                </li>
                <li>
                  <div
                    className="btn btn-light"
                    onClick={() => { setIsFlipped(!isFlipped) }}>
                    <BhFlip />
                  </div>
                </li>
              </ul>
            </>
          )}
        </TransformWrapper>
      </div>
    </div>
  )
}
