import BaseController from "controllers/base"
import $ from 'jquery';

import ajaxLoadForm from "widgets/cms/LoadEditForm";
import addDatePicker from "widgets/datePicker";
import ShowPreviewLabel from "widgets/showPreviewLabel"
import ajaxElementForms from "widgets/cms/ajaxUpdateForm"
import loadCKEditor from "widgets/cms/loadCKEditor"

class Controller extends BaseController {
  call() {
    super.call();
    // edit page need to load in following orders

    // 1. config Trix
    configBeforeLoadForm.call(this)

    // 2. load edit form
    loadForm.call(this)
    // bind elements in ajax success call
  };
};

const configBeforeLoadForm = function () {
}

let loadForm = function () {
  ajaxLoadForm(bindAfterLoadForm);
}

const bindAfterLoadForm = function () {
  addDatePicker.call(this);
  bindElementForms.call(this);
  ShowPreviewLabel.call(this);
  loadCKEditor.call(this);
}

const bindElementForms = function () {
  ajaxElementForms(bindAfterLoadForm)
}


export default Controller;