import BaseController from "controllers/pages/base";
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui.js';
import ConnectedSortable from "widgets/ConnectedSortable";


class Controller extends BaseController {
  call() {
    super.call();
    dragable_menu.call(this);
  }
};

const dragable_menu = function () {
  ConnectedSortable(".footer-sort-list", ".drag-handle", "footer-id");
}

export default Controller;