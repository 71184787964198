import React, { Component } from "react"

import IconButtonHorizontal from "components/buttons/iconButtonHorizontal"

class DisplayByFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  static getDerivedStateFromProps(props) {
    return props
  }

  displayTypeSelected = value => {
    const { display_type } = this.state
    const { dataChanged } = this.props

    if (display_type !== value) {
      dataChanged({ view_by: value })
    }
  }

  isSelected = value => {
    const { display_type } = this.state
    return display_type === value
  }

  rawButtons() {
    return (
      <div className="display-by btn-group btn-group-toggle">
        <IconButtonHorizontal
          icon="facade"
          text="Façade"
          active={this.isSelected("facade")}
          handleToggled={() => this.displayTypeSelected("facade")} />

        <IconButtonHorizontal
          icon="floorplan"
          text="Floorplan"
          active={this.isSelected("floorplan")}
          handleToggled={() => this.displayTypeSelected("floorplan")} />
      </div>
    )
  }

  render() {
    const { inlineStyle, showLabel = true } = this.props

    if (inlineStyle) {
      return (
        this.rawButtons()
      )
    }

    return (
      <div className="col">
        { showLabel === true ? <div className="title">Display by</div> : null }
        {this.rawButtons()}
      </div>
    )
  }
}

export default DisplayByFilter