import $ from 'jquery';
import BaseController from "controllers/pages/base";

import ConnectedSortable from "widgets/ConnectedSortable";


class Controller extends BaseController {
  call() {
    super.call();
    dragable_menu.call(this);
    preview_image.call(this);
  }
};

const dragable_menu = function () {
  $('[menu-group]').each(function () {
    const menuId = $(this).attr('menu-group');
    const connectedMenuList = `ul[menu-id=${menuId}]`;
    ConnectedSortable(connectedMenuList, ".drag-handle", "menu-col")
  });
}

const preview_image = function () {
  $('.image-input').change(function () {
    const $preview_image = $(this).siblings(".image-container").children(".image-preview");
    const $preview_text = $(this).siblings(".image-container").children(".preview-text")
    if (this.files && this.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $preview_image.attr("src", e.target.result);
        $preview_text.show()
      };
      reader.readAsDataURL(this.files[0]);
    }
  })
}

export default Controller;
