import React from "react"
import { Row, Col } from "react-bootstrap"
import { Range } from "rc-slider"
import { ACTIONS, useDispatch } from "../../configurator-context-provider"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.SpecificationFilters = false

const SpecificationFilters = () => {
  // if (window.configurator.debugPoints.SpecificationFilters) {
  //   debugger
  // }

  const dispatch = useDispatch()

  const bedroomMarks = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6+"
  }
  const bathroomMarks = {
    1: "1",
    1.5: "1.5",
    2: "2",
    2.5: "2.5",
    3: "3",
    3.5: "3.5",
    4: "4"
  }
  const garageMarks = {
    0: "0",
    1: "1",
    2: "2",
    3: "3"
  }

  return (
    <Col xs="12" md="6" lg="4">
      <div className="title">Specifications</div>
      <ul className="list-unstyled">
        <li>
          <Row>
            <Col xs="12" md="4">Bedrooms</Col>
            <Col xs="12" md="8">
              <Range
                min={1}
                max={6}
                defaultValue={[1, 6]}
                step={null}
                marks={bedroomMarks}
                onAfterChange={newValue => dispatch({ type: ACTIONS.SET_BEDROOM_FILTERS, payload: { bedroomFilters: newValue } })} />
            </Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col xs="12" md="4">Bathrooms</Col>
            <Col xs="12" md="8">
              <Range
                min={1}
                max={4}
                defaultValue={[1, 4]}
                step={null}
                marks={bathroomMarks}
                onAfterChange={newValue => dispatch({ type: ACTIONS.SET_BATHROOM_FILTERS, payload: { bathroomFilters: newValue } })} />
            </Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col xs="12" md="4">Garages</Col>
            <Col xs="12" md="8">
              <Range
                min={0}
                max={3}
                defaultValue={[0, 3]}
                step={null}
                marks={garageMarks}
                onAfterChange={newValue => dispatch({ type: ACTIONS.SET_GARAGE_FILTERS, payload: { garageFilters: newValue } })} />
            </Col>
          </Row>
        </li>
      </ul>
    </Col>
  )
}

export default SpecificationFilters
