/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import { useSelector } from "react-redux"

import GoogleMapReact from "google-map-react"

import Marker from "./Marker"
import InfoBox from "./InfoBox"


export default function HomeDisplayLocation() {
  const { google_key } = useSelector(state => state.config.env_vars)
  const { attributes: { name, location, imageUrls } } = useSelector(state => state.displayHome)
  const {
    attributes: {
      name: locationName, latitude, longitude
    }
  } = Object.values(location)[0]

  return (
    <>
      {/* Preload all images */}
      <div style={{ display: "none" }}>
        {imageUrls.map(url => <img src={url} />)}
      </div>
      <div className="container mb-md-5 pb-5" style={{ position: "relative" }}>
        <a
          name="on_display"
          style={{
            display: "block",
            position: "relative",
            top: "-100px",
            visibility: "hidden"
          }}>
        </a>
        <div className="text-center">
          <h2>On Display</h2>
          <p>{`${name} is on display at ${locationName}`}</p>
          <div className="map-container-google-displayhome z-depth-1-half map-container" style={{ height: "500px" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: google_key }}
              defaultCenter={{ lat: latitude, lng: longitude + 0.005 }}
              defaultZoom={15}
              yesIWantToUseGoogleMapApiInternals>
              <Marker
                lat={latitude}
                lng={longitude} />
            </GoogleMapReact>
            <InfoBox />
          </div>
        </div>
      </div>
    </>
  )
}