import React from "react"

import Breadcrumb from "./components/breadcrumb"
import HomeLandHero from "./components/HomeLandHero"
import HomeLandDetails from "./components/HomeLandDetails"

function HomeLandRoot() {
  return (
    <div className="home-details">
      <Breadcrumb />
      <HomeLandHero />
      <HomeLandDetails />
    </div>
  )
}

export default HomeLandRoot