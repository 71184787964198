import BaseController from "controllers/base"
import $ from 'jquery';

import bindFormPreview from "widgets/cms/bindFormPreview"

class Controller extends BaseController {
  call() {
    super.call();
    bindFormPreview.call(this);
  };
};

export default Controller;