import Util from "../Util/configurator-util"
import OptionGroup from "./configurator-optiongroup"

function Section(ctx) {
  this.name = ""
  this.sort = 999999999
  this.groups = []

  if (!ctx) {
    return
  }

  this.setName(ctx.name || "")
  this.setSort(ctx.sort || 999999999)
  if (ctx.groups) {
    Util.each(ctx.groups, group => {
      this.addGroup(new OptionGroup(group))
    })
  }
}

/**
 * Get the Name
 * @returns {String}
 */
Section.prototype.getName = function () { return this.name }
/**
 * Set the Name
 * @param {String} name The Name
 */
Section.prototype.setName = function (name) {
  if (!Util.isString(name)) {
    throw new TypeError("Invalid parameter name: must be String")
  }

  this.name = name
}

/**
 * Get the Sort
 * @returns {Number}
 */
Section.prototype.getSort = function () { return this.sort }
/**
 * Set the Sort
 * @param {Number} sort The Sort
 */
Section.prototype.setSort = function (sort) {
  if (!Util.isNumber(sort)) {
    throw new TypeError("Invalid parameter sort: must be Number")
  }

  this.sort = sort
}

/**
 * Get the Option Groups
 * @returns {OptionGroup[]}
 */
Section.prototype.getGroups = function () {
  return this.groups.slice().sort((a, b) => a.getSort() - b.getSort())
}

/**
 * Get a specific Option Group
 * @param {String} name The name of the group to return
 * @returns {OptionGroup}
 */
Section.prototype.getGroup = function (name) {
  let toReturn

  if (!Util.isString(name)) {
    throw new TypeError("Invalid parameter name: must be String")
  }

  toReturn = null
  Util.each(this.groups, group => {
    if (toReturn === null && group.getName() === name) {
      toReturn = group
    }
  })

  return toReturn
}

/**
 * Add an Option Group to this section
 * @param {OptionGroup} group The OptionGroup to add
 */
Section.prototype.addGroup = function (group) {
  let found

  if (group.toString() !== (new OptionGroup()).toString()) {
    throw new TypeError("Invalid parameter group: must be OptionGroup")
  }

  found = false
  Util.each(this.groups, existingGroup => {
    if (existingGroup.getName() === group.getName()) {
      found = true
    }
  })

  if (!found) {
    this.groups.push(group)
  }
}
/**
 * Remove an Option Group from this section
 * @param {String} name The name of the Option Group to remove
 */
Section.prototype.removeGroup = function (name) {
  if (!Util.isString(name)) {
    throw new TypeError("Invalid parameter name: must be String")
  }

  Object.keys(this.groups).forEach(key => {
    if (this.groups[key].getName() === name) {
      this.groups.splice(key, 1)
    }
  })
}

Object.defineProperty(Section.prototype, "toString", {
  value: () => "[object Section]"
})

export default Section
