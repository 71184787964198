import React, { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import Title from "components/common/PageTitle"
import SortByFilter from "components/filters/SortByFilter"
import HomeDesignList from "components/common/HomeDesignList"
import LoadingBar from "components/loadingBar"
import HomeCount from "components/common/HomeCount"
import Filters from "./components/Filters"
import { getData, updateFilter } from "./redux/actions"

export default function HomeLandSearchRoot() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getData())
  }, [dispatch])

  const {
    filters: { sort_by },
    options: { sort_by: sort_by_options },
    homeLandObjs, homeLandIds: { filtered }
  } = useSelector(state => state.homeLands)
  const homeLandIdsLendgh = filtered.length
  const dataFetching = useSelector(state => state.homeLands.dataFetching)
  return (
    <Fragment>
      <Title title="Home &amp; Land Packages" />
      <Filters />

      <div className="container-fluid filter-content pb-5">

        <div className="row justify-content-around mt-4 mb-2 small-text">
          <HomeCount number={homeLandIdsLendgh} />
          <SortByFilter
            options={sort_by_options}
            onChange={value => { dispatch(updateFilter({ sort_by: value })) }}
            selected={sort_by} />
        </div>
        <LoadingBar isLoading={dataFetching} />
        {(!dataFetching && homeLandIdsLendgh === 0)
          ? <div className="no-results">No Results Found</div>
          : <HomeDesignList renderList={filtered} dataObject={homeLandObjs} renderType="homeLand" />}
      </div>

    </Fragment>
  )
}