/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { Modal, Carousel } from "react-bootstrap"

import { NewBadge, AwardBadge } from "components/buttons/badges"
import HomeRoomsSmall from "components/common/homeRoomsSmall"
import FavouriteIcon from "components/common/favouriteIcon"
import { FAVOURITE_HOMES  } from "servos/localStorage/storedList"
import { IconFacade } from "images/icons"
import priceFormatter from "helpers/priceFormatter"
import GalleryModal from "components/common/homeDetails/onDisplay/GalleryModal"

function VideoModal(props) {
  const { youtubeurl, name } = props

  const length = youtubeurl.length !== 1

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body>
        <Carousel
          controls={length}
          indicators={length}>
          {youtubeurl.map((video, i) => (
            <Carousel.Item key={`video-${i}`}>
              <div style={{ width: "100%", paddingBottom: "56.25%", position: "relative" }}>
                <iframe
                  title={`${name}-video-iframe`}
                  src={video}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  style={{ width: "100%", height: "100%", position: "absolute" }} />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  )
}

function FacadeStatusOverlay({ status }) {
  if (["Closed to Public", "Coming Soon"].includes(status)) {
    return (
      <div style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        background: "rgba(0,0,0,0.3)",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "37px",
        fontWeight: "bold",
        fontFamily: "Montserrat"
      }}>
        {status === "Closed to Public" ? "Closed" : status}
      </div>
    )
  }
  return ""
}

function ForSaleFacadeOverlay({
  forSale, returnValue, saleStatus, detailUrl
}) {
  if (forSale) {
    return (
      <div className="for-sale-overlay">
        {returnValue && <span className="return-value mr-1">{`${returnValue} % Return`}</span>}
        <a href={detailUrl}>
          <span className="for-sale for-sale-status">{saleStatus}</span>
          <span className="for-sale overlay-href">More Info</span>
        </a>
      </div>
    )
  }
  return ""
}

function FacadeType({ facadeType }) {
  if (facadeType) {
    return (
      <div className="card-icons">
        <div className="row justify-content-between">
          <div className="col text-right">
            <ul className="list-inline">
              <li className="list-inline-item">
                <IconFacade className="icon mr-1" />
                <span className="name">{facadeType}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
  return ""
}

export default function DisplayHomeTile({ displayHome, hideAddr }) {
  const [imageModalShow, setImageModalShow] = useState(false)
  const [videoModalShow, setVideoModalShow] = useState(false)
  const {
    attributes: {
      id, name, detailUrl, facadeSmUrl, facadeType,
      virtualTour360,
      newDesign, awardWinner, youtubeUrl,
      imageUrls, status,
      forSale, salePrice, returnValue,
      suburb, saleStatus,
      plan: {
        data: {
          attributes: {
            detailUrl: planDetailUrl
          }
        }
      }
    }
  } = displayHome
  const priceString = priceFormatter({ price: salePrice })

  return (
    <div className="card design-card design-card-facade mb-4 border-0 display-home-card">
      <a className="link" href={planDetailUrl}>
        <div className="card-img rounded-0 embed-responsive embed-responsive-16by9">
          <img className="embed-responsive-item" src={facadeSmUrl} alt={name} />
          <FacadeStatusOverlay status={status} />
          <FacadeType facadeType={facadeType} />
        </div>
      </a>
      <div className="card-badges d-flex justify-content-between">
        <div>
          <NewBadge display={newDesign} />
          <AwardBadge display={awardWinner} />
        </div>
        <ForSaleFacadeOverlay forSale={forSale} returnValue={returnValue} saleStatus={saleStatus} detailUrl={detailUrl} />
      </div>

      <div className="card-body bg-light">
        <div className="card-title d-flex justify-content-between">
          <a className="link" href={detailUrl}>
            <span className="home-name">
              <span className="series-name">
                {name}
              </span>
            </span>
          </a>
          <FavouriteIcon
            homeDesignId={id}
            listName={FAVOURITE_HOMES} />
        </div>
      </div>
      <ul className="list-group list-group-flush bg-light">
        <li className="list-group-item">
          <HomeRoomsSmall homeDesign={displayHome} />
        </li>
        {forSale && (
        <>
          <li className="list-group-item">
            <div className="h3 home-name mb-3">
              <span className="series-name">{priceString}</span>
            </div>
          </li>
          {!hideAddr && (
            <li className="list-group-item">
              <div className="row justify-content-around align-items-center">
                <div className="col">
                  <div className="address">
                    <strong className="small-text">{suburb}</strong>
                  </div>
                </div>
              </div>
            </li>
          )}
        </>
        )}

        <li className="list-group-item">
          <ul className="list-inline small-text">
            {imageUrls.length > 0
                && (
                  <li className="list-inline-item">
                    <a
                      className="text-link"
                      role="button"
                      tabIndex={0}
                      onClick={() => setImageModalShow(true)}
                      style={{ textDecoration: "underline" }}>
                      Gallery
                    </a>
                    <GalleryModal
                      show={imageModalShow}
                      onHide={() => { setImageModalShow(false) }}
                      homeName={name}
                      urls={imageUrls} />
                  </li>
                )
            }
            {youtubeUrl.length > 0
                && (
                  <li className="list-inline-item">
                    <a className="text-link" role="button" tabIndex={0} onClick={() => setVideoModalShow(true)} style={{ textDecoration: "underline" }}>
                      Video
                    </a>
                    <VideoModal
                      name={name}
                      youtubeurl={youtubeUrl}
                      show={videoModalShow}
                      onHide={() => setVideoModalShow(false)} />
                  </li>
                )
            }
            {virtualTour360 && (
              <li className="list-inline-item">
                <a className="text-link" href={virtualTour360} target="_blank" rel="noopener noreferrer">
                  3D Virtual Tour
                </a>
              </li>
            )}
          </ul>
        </li>
      </ul>
    </div>
  )
}
