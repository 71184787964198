import { combineReducers }  from "redux"
import { connectRouter } from "connected-react-router"

import createReducer from "components/utils/createReducer"
import favouriteList from "components/common/favouriteIcon/redux/reducers"
import displayHomeForSale from "./displayHomeForSale"

// building config reducer with gon excluding project has its own.
const config = createReducer(
  function () {
    const { ...initialState } = gon

    return initialState
  }()
)({})

export default history => combineReducers({
  router: connectRouter(history),
  config,
  favouriteList,
  displayHomeForSale
})
