/* eslint-disable react/no-danger */
import React from "react"

import { useSelector } from "react-redux"

export default function HomeLandIntro({ devLogoRendered }) {
  const { attributes: { description, developmentLogo } } = useSelector(state => state.homeLand)
  return (
    <>
      <div className="container">
        <div className="home-land-intro mt-4">
          <div className="row justify-content-between">
            <div className="col-md-8" dangerouslySetInnerHTML={{ __html: description }} />
            {!devLogoRendered && developmentLogo
            && (
              <div className="col-md-3 text-center text-md-right">
                <img className="img-fluid" src={developmentLogo.url} />
              </div>
            )}
          </div>
        </div>
      </div>
      <br />
    </>
  )
}