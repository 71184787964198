import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools }          from "redux-devtools-extension"
import createSagaMiddleware             from "redux-saga"

import rootReducer from "./reducers"
import RootSaga from "./sagas"

const sagaMiddleware = createSagaMiddleware()

const enhancer = composeWithDevTools(
  applyMiddleware(sagaMiddleware),
)

const store = createStore(rootReducer, enhancer)

sagaMiddleware.run(RootSaga)

export default store
