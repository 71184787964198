export default function priceFormatter({ price }) {
  if (Number.isInteger(price)) {
    const formatter = new Intl.NumberFormat("en-AU", {
      style: "currency",
      currency: "AUD",
      minimumFractionDigits: 0,
    })
    return formatter.format(price)
  }
  return price
}