import React from "react"
import ReactDOM from "react-dom"

import { Provider } from "react-redux"

import { homeDesignRootSaga } from "modules/HomeDesign"
import configureStore from "./store"

import App from "./App"

const startCompareHomesPageApp = rootElem => {
  const store = configureStore()

  store.runSaga(homeDesignRootSaga)

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElem
  )
}

export default startCompareHomesPageApp