import BaseController from "controllers/base"
import startHomeLandSearchApp from "apps/HomeLandSearch"

class Controller extends BaseController {
  call() {
    super.call()

    startHomeLandSearchApp(
      document.getElementById("home-land"),
      {}
    )
  }
}

export default Controller