/* eslint-disable no-undef */
import { combineReducers }  from "redux"
import { connectRouter } from "connected-react-router"
import createReducer from "components/utils/createReducer"
import favouriteList from "components/common/favouriteIcon/redux/reducers"

const config = createReducer(
  function () {
    const { ...initialState } = gon
    return initialState
  }()
)({})

export const getUrls    = state => state.config.urls
export const getBaseUrl = state => state.config.urls.api

export default history => combineReducers({
  router: connectRouter(history),
  config,
  favouriteList
})
