import { combineReducers }  from "redux"
import { connectRouter } from "connected-react-router"

import createReducer from "components/utils/createReducer"
import favouriteList from "components/common/favouriteIcon/redux/reducers"
import landForSale from "./landForSale"

// building config reducer with gon excluding project has its own.
const config = createReducer(
  function () {
    // eslint-disable-next-line no-undef
    const { ...initialState } = gon

    return initialState
  }()
)({})

export const getUrls    = state => state.config.urls
export const getBaseUrl = state => state.config.urls.api

export default history => combineReducers({
  router: connectRouter(history),
  config,
  landForSale,
  favouriteList,
})
