/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
import BaseController from "controllers/base"
import reactShareButtonApp from "apps/ShareButton"
import { cmsFormControl, cmsFormFileDropable } from "servos/cms/ajaxForm"

class Controller extends BaseController {
  call() {
    super.call()
    bindReactShareButton.call(this)
    cmsFormControl.call(this)
    cmsFormFileDropable.call(this)
    renderGoogleMaps.call(this)
    puseYoutubeWhenClose.call(this)
    bindFAQAccordian.call(this)
  }
}

const bindReactShareButton = () => {
  if (document.getElementsByClassName("share-button").length > 0) {
    reactShareButtonApp(
      document.getElementsByClassName("share-button")[0],
      {}
    )
  }
}

const renderGoogleMaps = () => {
  $(".contact-us-business-centres-map").each((index, element) => {
    console.log(element)
    const $container = $(element)
    const lat = parseFloat($container.attr("lat"))
    const lng = parseFloat($container.attr("lng"))
    const zoom = parseFloat($container.attr("zoom")) || 14
    const map = new google.maps.Map(
      element, {
        zoom,
        center: { lat, lng }
      }
    )
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lng),
      map,
      animation: google.maps.Animation.DROP,
      icon: $container.attr("pin_icon")
    })
  })
}

const puseYoutubeWhenClose = () => {
  $("#modalPulseVideo").on("hidden.bs.modal", () => {
    $("#page-hero_video").attr("src", $("#page-hero_video").attr("src"))
  })
}

const bindFAQAccordian = () => {
  // window.location.href
  var phrase = window.location.href
  var myRegexp = /faqs#(.*)/
  var match = myRegexp.exec(phrase)

  if (match) {
    $(`#${match[1]}`).children(".collapse").collapse()
  }
}


export default Controller
