import React from "react"
import Carousel from "react-bootstrap/Carousel"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.FacadeCarousel = false

const FacadeCarousel = ({ facades }) => {
  // if (window.configurator.debugPoints.FacadeCarousel) {
  //   debugger
  // }

  return (
    <Carousel
      fade
      indicators={false}
      interval={null}>
      {facades.map(facade => (
        <Carousel.Item key={facade.getInternalID()}>
          <img className="configurator-img d-block w-100" src={facade.getImage()} alt={facade.getName()} />
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default FacadeCarousel
