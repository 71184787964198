/* eslint-disable no-undef */
import { combineReducers } from "redux"

import imageModal from "./imageModal"
import data from "./data"
import { filters, options } from "./filters"
import * as types from "../actionTypes"

const config = (state = gon) => state

const dataFetching = (state = true, action) => {
  switch (action.type) {
    case types.UPDATE_FILTER:
    case types.FETCH_REQUESTED: {
      return true
    }
    case types.DATA_UPDATED:
    case types.FETCH_FAILED: {
      return false
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  config,
  data,
  filters,
  options,
  dataFetching,
  imageModal
})
