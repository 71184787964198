import React from "react"
import PropTypes from "prop-types"

export default function HomeName(props) {
  const { name = "" } = props
  return (
    <span className="home-name" {...props}>
      <span className="series-name">{name.split(" ")[0]}</span>
      {" "}
      {name.split(" ").slice(1).join(" ")}
    </span>
  )
}

HomeName.propTypes = {
  name: PropTypes.string.isRequired
}
