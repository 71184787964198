import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"

import Breadcrumb from "./components/breadcrumb"
import HomeDesignCarousel from "./components/homeDesignCarousel"
import HomeDetails from "./components/homeDetails"
import HomeSeries from "./components/homeSeries"
import HomeDisplayLocation from "./components/homeDisplayLocation"
// import SuggestedOptions from "./components/suggestedOptions"
import QuickViewCarousel from "./components/QuickViewCarousel"
import PlanGallery from "./components/PlanGallery"

export default function HomeDesignRoot() {
  const [targetElement, setTargetElement] = useState(null)
  useEffect(() => {
    setTargetElement(document.querySelector("#home-details-body"))
    return clearAllBodyScrollLocks()
  }, [])

  const { homeDesign, showQuickViewCarousel } = useSelector(state => state)

  if (showQuickViewCarousel) {
    disableBodyScroll(targetElement)
  } else {
    enableBodyScroll(targetElement)
  }

  return (
    <div className="home-details">
      <div id="home-details-body">
        <Breadcrumb />
        <HomeDesignCarousel />
        <HomeDetails homeDesign={homeDesign} />
        <HomeSeries />
        <HomeDisplayLocation />
        <PlanGallery />
        {/*
          https://trello.com/c/GHJ5xgMD/329-home-design-detail-hide-displaynone-you-might-like-these-heading
          hide you might like these
          <SuggestedOptions homeDesign={homeDesign} />
        */}
      </div>
      {showQuickViewCarousel && <QuickViewCarousel />}
    </div>
  )
}