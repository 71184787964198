import React, { Fragment } from "react"
import { Tabs, Tab } from "react-bootstrap"

import NewsTable from "./newsTable"

export default function NewsListRoot() {
  const categories = ["All", "Company", "Home Designs", "Trends", "Advice", "Community"]
  return (
    <Fragment>
      <Tabs className="nav bh-nav mb-4" defaultActiveKey={categories[0]} id="news-list">
        {categories.map(categorie => (
          <Tab className="nav-item" key={categorie} eventKey={categorie} title={categorie}>
            <NewsTable category={categorie} />
          </Tab>
        ))}
      </Tabs>
    </Fragment>
  )
}