import BaseController from "controllers/backend/base";

class Controller extends BaseController {
  call() {
    super.call();

    BindSubmitForm.call(this);
  }
};

var BindSubmitForm = function() {
  $('form.attribute-form').on('submit', function(e) {
    e.preventDefault()
    SubmitForm.call(this, $(e.currentTarget))

    return false;
  });

  $('form.attribute-form input').on('blur', function(e) {
    e.preventDefault()
    
    SubmitForm.call(this, $(e.currentTarget).parents('form'))

    return false;
  });
}

var SubmitForm = function(form) {
  form.find('input').removeClass('text-success text-danger')

  $.ajax({
    type: form.attr('method'),
    url: form.attr('action'),
    dataType: 'json',
    data: form.serializeArray(),
    success: (response) => {
      form.find('input').addClass('text-success')
    },
    error: (xhr, text, error) => {
      form.find('input').addClass('text-danger')
    }
  });
}

export default Controller;