import normalize from "json-api-normalizer"

// eslint-disable-next-line no-undef
const normalizedHomeLand = normalize(gon.record).homeAndLand
const normalizedHomeLandObj = Object.values(normalizedHomeLand)[0]

const homeLand = (state = normalizedHomeLandObj, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default homeLand