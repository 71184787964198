import React from "react"
// import PropTypes from "prop-types"

import VirtualTourItem from "./VirtualTourItem"
import PhotoItem from "./PhotoItem"
import VideoItem from "./VideoItem"

export default function GalleryItem({
  record, record: { type }
}) {
  switch (type) {
    case "virtual_tour": {
      return <VirtualTourItem data={record} />
    }
    case "video": {
      return <VideoItem data={record} />
    }
    case "photo": {
      return <PhotoItem data={record} />
    }
    default: {
      return ""
    }
  }
}

// GalleryItem.propTypes = {
//   record: PropTypes.shape({
//     itemIdx: PropTypes.number.isRequired,
//     type: PropTypes.oneOf(["virtual_tour", "video", "photo"]).isRequired,
//     name: PropTypes.string.isRequired
//   })
// }
