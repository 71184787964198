import React, { useState, useEffect } from "react"
import { useDebounce } from "use-debounce"
import { Col, InputGroup, FormControl } from "react-bootstrap"
import { ACTIONS, useDispatch } from "../../configurator-context-provider"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.NameFilter = false

const NameFilter = () => {
  // if (window.configurator.debugPoints.NameFilter) {
  //   debugger
  // }

  const dispatch = useDispatch()
  const [nameFilterValue, setNameFilterValue] = useState("")
  const [debouncedNameFilter] = useDebounce(nameFilterValue, 1000)

  useEffect(() => {
    dispatch({ type: ACTIONS.SET_NAME_FILTER, payload: { nameFilter: debouncedNameFilter } })
  }, [debouncedNameFilter, dispatch])

  return (
    <Col>
      <InputGroup>
        <FormControl
          id="name-filter"
          type="text"
          placeholder="Search Designs"
          aria-label="Search Designs"
          aria-describedby="basic-addon2"
          onChange={e => setNameFilterValue(e.target.value)} />
      </InputGroup>
    </Col>
  )
}

export default NameFilter
