import React from "react"
import { useSelector } from "react-redux"

import FavouriteIcon from "components/common/favouriteIcon"
import ShareButton from "apps/ShareButton/ShareButton"
import { FAVOURITE_LANDS  } from "servos/localStorage/storedList"

export default function BreadCrumb() {
  const homeLand = useSelector(state => state.homeLand)
  const { attributes: { name, id } } = homeLand
  return (
    <div className="page-action-nav home-details-nav sticky-top">
      <div className="container-fluid">
        <div className="row justify-content-around">
          <div className="col d-none d-md-block">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/home-and-land">Home & Land Packages</a>
                </li>
                <li aria-current="page" className="breadcrumb-item active">
                  {name}
                </li>
              </ol>
            </nav>
          </div>

          <div className="col text-right">
            <ul className="list-inline actions">
              <li className="li list-inline-item">
                <ShareButton />
              </li>
              <li className="list-inline-item">
                <FavouriteIcon
                  wrapperClass="btn btn-sm"
                  showText
                  homeDesignId={id}
                  listName={FAVOURITE_LANDS} />
              </li>
              <li className="list-inline-item">
                <a
                  href="#submission-form"
                  className="btn btn-sm btn-red"
                  rel="noopener noreferrer">
                  Enquire Now
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}