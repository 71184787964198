/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { ACTIONS, useDispatch, useSelectedPriceLevel } from "../../configurator-context-provider"
import useClickOutside from "../../hooks/useClickOutside"
import Util from "../../Util/configurator-util"
import icon from "../../images/icon-camera.png"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.OptionCard = false

const OptionCard = ({ option }) => {
  // if (window.configurator.debugPoints.OptionCard) {
  //   debugger
  // }

  const selectedPriceLevel = useSelectedPriceLevel()
  const dispatch = useDispatch()

  if (option.isHidden()) {
    return (
      <Row className="option-row">
        <Col xs={12}>&nbsp;</Col>
      </Row>
    )
  }

  const handleClick = e => {
    const assignedID = e.target.dataset.assignedid
    const { checked } = e.target
    dispatch({ type: ACTIONS.SELECT_OPTION, payload: { assignedID, checked } })
  }

  const defaultOptionCheck = () => (
    <input
      id={`option-check-${option.getAssignedID()}`}
      data-assignedid={option.getAssignedID()}
      disabled={option.isSelected()}
      checked={option.isSelected()}
      type="checkbox"
      className="option-check"
      onChange={e => handleClick(e)} />
  )

  const otherOptionCheck = () => (
    <input
      id={`option-check-${option.getAssignedID()}`}
      data-assignedid={option.getAssignedID()}
      checked={option.isSelected()}
      type="checkbox"
      className="option-check"
      onChange={e => handleClick(e)} />
  )

  const showPrice = Number(option.getPriceWithTax(selectedPriceLevel)) !== 0
  const price = (option.getPriceWithTax(selectedPriceLevel) > 0 ? "+" : "") + Util.formatAmount(option.getPriceWithTax(selectedPriceLevel))

  return (
    <Row className="option-row">
      <Col xs={6} className="d-flex align-items-center">
        <div>
          {option.getItemName()}
          {" "}
          {
            option.getImage().length > 0 && (
              <OptionImage
                assignedID={option.getAssignedID()}
                optionItem={option.getItemName()}
                optionImage={option.getImage()} />
            )
          }
        </div>
      </Col>
      <Col xs={4} className="d-flex align-items-center justify-content-end">
        <div className="option-price">
          {showPrice && price}
        </div>
      </Col>
      <Col xs={2} className="d-flex align-items-center justify-content-end">
        <div>
          {option.isDefaultOption() ? defaultOptionCheck() : otherOptionCheck()}
        </div>
      </Col>
    </Row>
  )
}

const OptionImage = ({ assignedID, optionItem, optionImage }) => {
  const [isOpen, setIsOpen] = useState(false)
  const elementRef = useRef(null)

  const handleClick = e => {
    switch (e.target.id) {
      case `option-image-popup-${assignedID}`:
      case `option-image-${assignedID}`:
        break

      default:
        setIsOpen(!isOpen)
        break
    }
  }

  useClickOutside(elementRef, handleClick)

  return (
    <div
      id={`option-imgage-control-${assignedID}`}
      onClick={e => handleClick(e)}
      style={{
        width: "1.4rem",
        height: "1.4rem",
        display: "inline-block",
        cursor: "pointer"
      }}>
      <img src={icon} alt="" className="configurator-img" />
      {isOpen && (
        <div
          id={`option-image-popup-${assignedID}`}
          style={{
            position: "absolute", "max-width": "200px", height: "auto", zIndex: 20
          }}
          ref={elementRef}>
          <img
            id={`option-image-${assignedID}`}
            className="configurator-img-fub configurator-img image"
            src={optionImage}
            alt={optionItem} />
        </div>
      )}
    </div>
  )
}

export default OptionCard
