import React from "react"

export default function Title({ title }) {
  return (
    <div className="page-header text-center">
      <div className="container-fluid">
        <h1>{title}</h1>
      </div>
    </div>
  )
}