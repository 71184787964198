import $ from 'jquery';
import FlashMessages from "mixins/flash";

let bindFormPreview = function(){
  $('form[id="form_submission"').submit(function(event){
    event.preventDefault();
    console.log("form clicked");
    $("#flash-container").append($("<div>", { id: "flash" }));
    FlashMessages.call(this, { options: { message: "Submit Button Clicked! (Form Preview)" }, settings: { type: "info" } });
  })
}

export default bindFormPreview;