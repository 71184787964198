import React from "react"
import { STEPS, useStep } from "./configurator-context-provider"
import Header from "./components/Header"
import DesignCardListView from "./components/design/DesignCardListView"
import FacadeCardListView from "./components/facade/FacadeCardListView"
import ConfigurationListView from "./components/configuration/ConfigurationListView"
import SiteCostsListView from "./components/sitecosts/SiteCostsListView"
import InclusionsListView from "./components/inclusions/InclusionsListView"
import ContactForm from "./components/contact/ContactForm"
import Final from "./components/final/Final"
import "./styles/configurator-view.scss"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {
  ConfiguratorView: false,
  reducer: false,
}

const ConfiguratorView = () => {
  // if (window.configurator.debugPoints.ConfiguratorView) {
  //   debugger
  // }

  const step = useStep()

  switch (step) {
    case STEPS.INITIALISATION:
      break

    case STEPS.DESIGN:
      return (
        <>
          <Header />
          <DesignCardListView />
        </>
      )

    case STEPS.FACADE:
      return (
        <>
          <Header />
          <FacadeCardListView />
        </>
      )

    case STEPS.SITE_COSTS:
      return (
        <>
          <Header />
          <SiteCostsListView />
        </>
      )

    case STEPS.INCLUSIONS:
      return (
        <>
          <Header />
          <InclusionsListView />
        </>
      )

    case STEPS.CONFIGURATION:
      return (
        <>
          <Header />
          <ConfigurationListView />
        </>
      )

    case STEPS.CONTACT:
      return (
        <>
          <Header />
          <ContactForm />
        </>
      )

    case STEPS.FINAL:
      return (
        <>
          <Header />
          <Final />
        </>
      )

    default:
      break
  }
  return <div>Error!</div>
}

export default ConfiguratorView
