/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import { connect } from "react-redux"

import HomeDesignName from "components/common/HomeDesignName"
import { removeComparePlanItem } from "components/common/CompareButton/redux/actions"
import { getObj, HOME_PLANS } from "servos/localStorage/storedObj"

const ComparedHomeItem = ({ homeDesign, homeDesignId, removeCompare }) => {
  const { name } = (homeDesign && homeDesign.attributes) || getObj(HOME_PLANS)[homeDesignId] || {}
  return (
    <li className="list-inline-item">
      <div className="btn btn-compare-home btn-md">
        <HomeDesignName name={name} />
        <i
          className="material-icons ml-2"
          onClick={() => removeCompare(homeDesignId)}
          onKeyDown={() => removeCompare(homeDesignId)}>
          close
        </i>
      </div>
    </li>
  )
}

export default connect(
  null,
  { removeCompare: removeComparePlanItem }
)(ComparedHomeItem)