import React from "react"
import ReactDOM from "react-dom"

import { Provider } from "react-redux"

import { ThemeProvider } from "styled-components"
import { theme } from "themes"

import CompareBar from "components/common/CompareBar"
import HomeDesignsList from "./components/HomeDesignsList"
import Filter from "./components/Filter"

import RootSaga from "./sagas"
import configureStore from "./store"

const startHomeDesignsSearchApp = rootElem => {
  const store = configureStore()

  store.runSaga(RootSaga)

  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div className="page-header text-center">
          <h1>Home Designs</h1>
        </div>
        <Filter />
        <HomeDesignsList />
        <CompareBar />
      </ThemeProvider>
    </Provider>,
    rootElem
  )
}

export default startHomeDesignsSearchApp