import $ from "jquery"
import "jquery-ui-dist/jquery-ui"
import swal from "sweetalert2"

function noDuplicates(arr) {
  return new Set(arr).size === arr.length
}

const ConnectedSortable = function (connted_list, handleBar, col_attr) {
  $(connted_list).sortable({
    connectWith: "ul#menu-g",
    handle: handleBar,
    appendTo: "body",
    update(e, ui) {
      const menu_item_names = $(this).closest('.menu-group-wrapper').find("input.item-name").map((lii, ell) => { return $(ell).val() })
      if (noDuplicates(menu_item_names)) {
        const index = {
          showing: "published",
          not_showing: "draft"
        }
        const menu_col = $(this).attr(col_attr)
        const new_state_col = index[$(this).attr("menu-state")]

        const menu_id = $(this).attr('menu-id')

        updateList($(this).children("li"), "input.item-sort", "input.item-col", "input.state-col", menu_col, new_state_col, "input.item-name", menu_id, ui, connted_list)
      } else {
        $(ui.sender).sortable('cancel');
        swal.fire({
          type: "failed",
          title: "Error",
          text: "That URL is already in this menu.",
          confirmButtonText: "Ok"
        })
      }
    }
  })
}

const updateList = function (list, input_sort, input_col, input_state, col, state_col, input_name, menu_id, ui, connted_list) {
  list.each((idx, element) => {

    // fields applicable to both
    const $input_sort = $(element).find(input_sort).first()
    const $input_col = $(element).find(input_col).first()
    const $input_name = $(element).find(input_name).first()
    const $input_id = $(element).attr('item-id')

    // determine which model were dealing with
    const prefix = $input_sort.attr("name").split("[")[0]

    // new values for both models
    $input_sort.val(idx)
    $input_col.val(col)
    let input_sort_name
    let input_col_name
    let input_menu_id_name
    let input_state_name

    if (prefix === "menus") {
      const $input_state = $(element).find(input_state).first()
      const $input_menu_id = $(element).find(".item-menu-id").first()

      // update form input elements to match nested params
      input_sort_name    = `${prefix}[${menu_id}][page_links_attributes][${$input_id}][sort]`
      input_col_name     = `${prefix}[${menu_id}][page_links_attributes][${$input_id}][submenu_id]`
      input_menu_id_name = `${prefix}[${menu_id}][page_links_attributes][${$input_id}][menu_id]`
      input_state_name = `${prefix}[${menu_id}][page_links_attributes][${$input_id}][state]`

      $input_state.val(state_col).attr("name", input_state_name)
      $input_menu_id.val(menu_id).attr("name", input_menu_id_name)
    } else if (prefix === 'page_links') {
      input_sort_name = `${prefix}[${$input_id}][footer_sort]`
      input_col_name = `${prefix}[${$input_id}][footer_id]`
    }

    $input_sort.attr("name", input_sort_name)
    $input_col.attr("name", input_col_name)

    if (ui.item.attr('item-id') === $input_id) {
      swal.fire({
        title: "This will update the live menu. Are you sure?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes`
      }).then(confirm => {
        if (confirm.value === true) {
          $.ajax({
            method: "patch",
            url: `/backend/cms/page_links/${$input_id}`,
            data: window.location.pathname.match(/menu$/) ? {
              // update submenu_id for headers, footer_id for footers
              page_link: { menu_id, submenu_id: $input_col.val() }
            } : {
              page_link: { menu_id, footer_id: $input_col.val() }
            },
            dataType: "json",
            success: () => {
              swal.fire({
                type: "success",
                title: "Updated!",
                text: "Click Publish to update menu position and images",
                confirmButtonText: "Ok"
              })
            },
            error: (response) => {
              swal.fire({
                type: "failed",
                title: "There was an error!",
                text: response.message,
                confirmButtonText: "Ok"
              })
            }
          })
        } else {
          $(ui.sender).sortable('cancel');
        }
      })
    }
  })
}

export default ConnectedSortable
