import * as constants from "./constants"

export const getData = () => ({
  type: constants.FETCH_REQUESTED
})

export const fetchDataSuccess = (response, filters) => ({
  type: constants.FETCH_SUCCESS,
  payload: { response, filters }
})

export const fetchDataFailed = error => ({
  type: constants.FETCH_FAILED,
  payload: error
})

export const updateFilter = updatedItem => ({
  type: constants.FILTERS_UPDATED,
  payload: updatedItem
})

export const updateFilterNoFetchData = updatedItem => ({
  type: constants.FILTERS_UPDATED_NO_FETCH,
  payload: updatedItem
})

export const controlModalFilter = state => ({
  type: constants.CTRL_MODAL_FILTER,
  payload: state
})