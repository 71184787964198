/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import cx from "classnames"

import { FAVOURITE_PLANS  } from "servos/localStorage/storedList"
import { toggleFavouriteList } from "./redux/actions"

function FavouriteIcon({
  homeDesignId, wrapperClass, showText, listName = FAVOURITE_PLANS
}) {
  const dispatch = useDispatch()
  const favouriteList = useSelector(state => state.favouriteList)[listName]
  const defaultWrapperClass = wrapperClass || ""
  const favourited = favouriteList.includes(parseInt(homeDesignId, 10))
  const defaultShowText = showText || false
  return (
    <div
      className={cx(defaultWrapperClass, "click-fav", { active: favourited })}
      onClick={e => {
        e.preventDefault()
        dispatch(toggleFavouriteList({ listName, planId: homeDesignId }))
      }}
      key={homeDesignId}
      style={{ marginTop: "-2px" }}>
      <div className="material-icons">
        {favourited ? "favorite" : "favorite_border"}
      </div>
      {defaultShowText ? <span> Favourite</span> : ""}
    </div>
  )
}

export default FavouriteIcon