import React from "react"
import { Container } from "react-bootstrap"
import { useStandardInclusions } from "../../configurator-context-provider"
import StandardInclusion from "./StandardInclusion"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.InclusionsList = false

const InclusionList = () => {
  // if (window.configurator.debugPoints.InclusionsList) {
  //   debugger
  // }

  const inclusions = useStandardInclusions()

  return (
    <Container fluid="sm">
      {inclusions.map((groupMember, idx) => (
        <StandardInclusion
          key={groupMember.getInternalID()}
          groupMember={groupMember}
          index={idx} />
      ))}
    </Container>
  )
}

export default InclusionList
