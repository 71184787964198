import axios from "axios"
import normalize from "json-api-normalizer"

// Get Home Designs
const getHomeDesigns = (baseUrl, body) => axios.post(`${baseUrl}home-designs`, body)
  .then(({ data }) => {
    const normalizedData = normalize(data.records)
    return { response: { data: normalizedData, ordering: data.records.meta.ordering, count: data.records.meta.count } }
  })
  .catch(error => ({ error: error.message }))

export { getHomeDesigns }