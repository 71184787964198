import React from "react"
import { useSelector } from "react-redux"

import Breadcrumb from "components/common/detailPage/Breadcrumb"
import { FAVOURITE_HOMES } from "servos/localStorage/storedList"

import Hero from "./components/Hero"
// import Details from "./components/Details"
import HomeDisplayLocation from "./components/homeDisplayLocation"
import HomeDetails from "../HomeDesignDetail/components/homeDetails"

export default function HomeDesignRoot() {
  const { attributes: { id, name, plan: { data: homeDesign } } } = useSelector(state => state.displayHome)

  return (
    <div className="home-details">
      <div id="home-details-body">
        <Breadcrumb
          parentName="Display Homes for Sale"
          parentUrl="/home-and-land/display-homes-for-sale"
          homeId={id}
          homeName={name}
          favouriteListNam={FAVOURITE_HOMES} />
        <Hero />
        <HomeDetails homeLand={false} homeDesign={homeDesign} />
        <HomeDisplayLocation />
      </div>
    </div>
  )
}
