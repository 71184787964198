import React from "react"
import cx from "classnames"

export default function ToggleSelect({
  name, options, selected, onChange
}) {
  const handleClick =  value => {
    if (selected === value) {
      onChange("")
    } else {
      onChange(value)
    }
  }

  return (
    <div className="btn-group btn-group-toggle">
      {options.map(object => (
        <button
          key={`${name}-${object[0]}`}
          type="button"
          onClick={() => handleClick(object[1])}
          className={cx(
            "btn", "btn-secondary",
            { active: selected === object[1] },
          )}>
          {object[0]}
        </button>
      ))}
    </div>
  )
}