import homeDesigns, * as fromHomeDesigns from "./homeDesigns"

export const getUrls    = state => state.config.urls
export const getBaseUrl = state => state.config.urls.api

// HomeDesign
export const getHomeDesigns = state => fromHomeDesigns.getHomeDesigns(state.homeDesigns)
export const getHomeDesignIds = state => fromHomeDesigns.getHomeDesignIds(state.homeDesigns)
export const getHomeDesignObj = state => fromHomeDesigns.getHomeDesignObj(state.homeDesigns)
export const getHomeDesign = (state, homeDesignId) => fromHomeDesigns.getHomeDesign(state.homeDesigns, homeDesignId)
export const isFetchingHomeDesigns = state => fromHomeDesigns.isFetchingHomeDesigns(state.homeDesigns)
export const getFilters = state => fromHomeDesigns.getFilters(state.homeDesigns)
export const getOptions = state => fromHomeDesigns.getOptions(state.homeDesigns)

export default homeDesigns
