import * as constants from "../../constants"

const count = (state = [], action) => {
  switch (action.type) {
    case constants.FETCH_SUCCESS: {
      return action.response.count
    }
    default:
      return state
  }
}

export default count