const colorsJson = require("../../assets/colors.json")

const Colorful = function () {
  this.colors = colorsJson
}

const Colors = () => colorsJson

const Color = color => {
  const colors = colorsJson
  return colors[color]
}

export { Colors, Color }

export default Colorful
