import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"

import PopularDesignsRoot from "./components/popularDesignsRoot"

import RootSaga from "./sagas"
import configureStore from "./store"

const startPopularDesignsApp = rootElem => {
  const store = configureStore()

  store.runSaga(RootSaga)

  ReactDOM.render(
    <Provider store={store}>
      <PopularDesignsRoot />
    </Provider>,
    rootElem
  )
}

export default startPopularDesignsApp