import React, { useRef, useEffect, useState } from "react"

import HomeMeasurements from "components/common/HomeMeasurements"
import HomeDesignFloorPlan from "components/common/HomeDesignFloorPlan"
import HomeRooms from "components/common/homeRooms"
import Price from "apps/HomeDesignDetail/components/homeDetails/price"
import Awards from "./awards"
import FloorPlanFeatures from "./floorPlanFeatures"

export default function HomeDetails({ homeDesign, showAsCard, homeLand = false }) {
  const {
    attributes,
    attributes: {
      floorplansLeft, floorplansRight,
      floorplanFeatures, downloadFloorPlanUrl,
      detailUrl,
      awards,
      inclusionPagePath,
      bedrooms, bathrooms, garages, homeTheatre,
      price
    }
  } = homeDesign

  const isDisplayHomeForSale = !window.location.pathname.match(/display-homes-for-sale/)

  const detailColRef = useRef()
  const [detailColHeight, setDetailColHeight] = useState(0)
  useEffect(() => {
    if (detailColRef.current) {
      setDetailColHeight(detailColRef.current.clientHeight)
    }
  }, [detailColRef])
  return (
    <div className="container mb-md-5 pb-5">
      <div className="row justify-content-center">

        <div className="col" ref={detailColRef}>
          { isDisplayHomeForSale && (
            <HomeRooms
              bedrooms={bedrooms}
              bathrooms={bathrooms}
              garages={garages}
              homeTheatre={homeTheatre} />
          )}
          <div className="home-info">
            { isDisplayHomeForSale && (
              <>
                <Price price={price} />
                <br />
                <HomeMeasurements homeLand={homeLand} measurements={attributes} />
              </>
            )}
            <Awards awards={awards} />
            <FloorPlanFeatures features={floorplanFeatures} />

            { detailUrl && !window.location.pathname.match(/^\/home-designs/)
            && (
              <div className="col-xl-12 col-md-12 mt-12 mt-md-12" style={{ textAlign: "justify", textJustify: "inner-word" }}>
                <i>
                  If you are interested in purchasing a display home, please refer to and download the sale brochure for details as the home dimensions and inclusions will differ from standard designs.
                </i>
              </div>
            )
            }
            {downloadFloorPlanUrl && window.location.pathname.match(/^\/home-designs/) && (
              <a href={downloadFloorPlanUrl} className="btn btn-outline btn-block btn-md mt-3" target="_blank" rel="noopener noreferrer" download>
                Download Floor Plan
              </a>
            )}
            {inclusionPagePath && window.location.pathname.match(/^\/home-designs/) && (
              <a href={inclusionPagePath} className="btn btn-outline btn-block btn-md mt-2">
                Inclusions
              </a>
            )}
          </div>
          {showAsCard && (
            <a
              className="btn btn-dark btn-block btn-md mt-2"
              href={detailUrl}>
              View Details
            </a>
          )}
        </div>

        <div className="col-xl-9 col-md-8 mt-5 mt-md-0">
          <HomeDesignFloorPlan floorPlansLeft={floorplansLeft} floorPlansRight={floorplansRight} bigImage picHeight={detailColHeight} />
        </div>

      </div>
    </div>
  )
}
