import React from "react"
import Col from "react-bootstrap/Col"
import classNames from "classnames"
import Util from "../../Util/configurator-util"
import {
  ACTIONS, useDispatch, useSelectedFacade, useSelectedPriceLevel
} from "../../configurator-context-provider"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.FacadeCard = false

const FacadeCard = ({ facade }) => {
  // if (window.configurator.debugPoints.FacadeCard) {
  //   debugger
  // }

  const selectedFacade = useSelectedFacade()
  const selectedPriceLevel = useSelectedPriceLevel()
  const dispatch = useDispatch()

  const facadeID = facade.getInternalID()
  const facadeURL = facade.getImage()
  const facadeName = facade.getName()
  const price = Number(facade.getItem().getPriceWithTax(selectedPriceLevel)) > 0 ? Util.formatAmount(facade.getItem().getPriceWithTax(selectedPriceLevel)) : "Included"

  const handleClick = () => {
    if (selectedFacade === facadeID) return
    dispatch({ type: ACTIONS.SELECT_FACADE, payload: { selectedFacade: facadeID } })
  }

  return (
    <Col className="configurator-pointer">
      <div
        className={classNames("configurator-design-card", {
          active: facadeID === selectedFacade
        })}
        onClick={() => handleClick()}
        aria-hidden>
        <img className="configurator-img" src={facadeURL} alt={facadeName} />
        <div
          className="configurator-specifications">
          <div className="spec"><span className="configurator-first-word">{facadeName}</span></div>
          <div className="spec"><span>{price}</span></div>
        </div>
      </div>
    </Col>
  )
}

export default FacadeCard
