import React, { Component } from "react"

import { Range } from "rc-slider"

class SpecificationsFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  static getDerivedStateFromProps(props) {
    return props
  }

  setBedroomCount = value => {
    const { dataChanged } = this.props

    dataChanged({ by_bedroom_count: value })
  }

  setBathroomCount = value => {
    const { dataChanged } = this.props

    dataChanged({ by_bathroom_count: value })
  }

  setGarageCount = value => {
    const { dataChanged } = this.props

    dataChanged({ by_garage_count: value })
  }

  render() {
    const { by_bedroom_count, by_bathroom_count, by_garage_count } = this.state

    return (
      <div>
        <div className="title mt-4 mt-md-0">Specifications</div>
        <ul className="list-unstyled pr-2">
          <li>
            <div className="row">
              <div className="col col-sm-12 col-lg-4">
                Bedrooms
              </div>
              <div className="col col-sm-12 col-lg-8">
                <Range
                  min={1}
                  max={6}
                  value={by_bedroom_count}
                  marks={{
                    1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: "6+"
                  }}
                  step={null}
                  onChange={value => this.setBedroomCount(value)}
                  tipFormatter={value => `${value}%`} />
              </div>
            </div>
          </li>
          <li>
            <div className="row">
              <div className="col col-sm-12 col-lg-4">
                Bathrooms
              </div>
              <div className="col col-sm-12 col-lg-8">
                <Range
                  min={1}
                  max={4}
                  value={by_bathroom_count}
                  marks={{
                    1: 1, 1.5: 1.5, 2: 2, 2.5: 2.5, 3: 3, 3.5: 3.5, 4: "4+"
                  }}
                  step={null}
                  onChange={value => this.setBathroomCount(value)}
                  tipFormatter={value => `${value}%`} />
              </div>
            </div>
          </li>
          <li>
            <div className="row">
              <div className="col col-sm-12 col-lg-4">
                Garage
              </div>
              <div className="col col-sm-12 col-lg-8">
                <Range
                  min={0}
                  max={3}
                  value={by_garage_count}
                  marks={{
                    0: 0, 1: 1, 2: 2, 3: "3+"
                  }}
                  step={null}
                  onChange={value => this.setGarageCount(value)}
                  tipFormatter={value => `${value}%`} />
              </div>
            </div>
          </li>
          {/* <li>Bathroom</li>
          <li>Garage</li> */}
        </ul>
      </div>
    )
  }
}

export default SpecificationsFilter