const Util = (function () {
  /**
   * @memberof Util
   * @name Util.round
   *
   * @param {Number} n
   * @returns {String}
   */
  function round(n = 0, p = 0) {
    return String(+(Math.round(n + "e+" + p) + "e-" + p))
  }

  /**
   * @memberof Util
   * @name Util.each
   *
   * @param {Object|Array} obj
   * @param {Function} callback
   * @returns {Object|Array} iterable - original collection
   */
  function each(obj, callback) {
    let i = 0

    if (!obj) {
      return obj
    }

    const { length } = obj
    // is object has a length, treat it as an Array, allows for iterating array-like objects like "arguments"
    if (length === +length) {
      while (i < length) {
        callback(obj[i], i, obj)
        i += 1
      }
      // Treat as an object/map, allows plain or custom objects to be iterated
    } else {
      Object.getOwnPropertyNames(obj).forEach(k => {
        callback(obj[k], k, obj)
      })
    }

    return obj
  }

  /**
   * @memberof Util
   * @name Util.extend
   *
   * @param {Object} receiver
   * @param {Object} contributor
   * @returns {Object} receiver
   */
  function extend(receiver, contributor) {
    Object.keys(contributor).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(contributor, key)) {
        receiver[key] = contributor[key]
      }
    })

    return receiver
  }

  /**
   * @memberof Util
   * @name Util.deepExtend
   *
   * @param {Object} receiver
   * @param {Object} contributor
   * @returns {Object} receiver
   */
  function deepExtend(receiver, contributor) {
    Object.keys(contributor).forEach(key => {
      receiver[key] = typeof contributor[key] === "object" ? deepExtend({}, contributor[key]) : contributor[key]
    })

    return receiver
  }

  function deepFreeze(obj) {
    Object.freeze(obj)
    Object.keys(obj).forEach(p => {
      if (Object.prototype.hasOwnProperty.call(obj, p) || (typeof obj[p] === "object") || !Object.isFrozen(obj[p])) {
        deepFreeze(obj[p])
      }
    })
  }

  function isWindow(obj) {
    return obj != null && Object.prototype.hasOwnProperty.call(obj, "window") && obj === obj.window
  }

  /* adapted from jQuery isPlainObject */
  const plainObjectInstance = {}

  function type(obj) {
    if (obj == null) {
      return `${obj}`
    }
    // Support: Android<4.0, iOS<6 (functionish RegExp)
    return typeof obj === "object" || typeof obj === "function"
      ? plainObjectInstance[Object.prototype.toString.call(obj)] || "object"
      : typeof obj
  }

  /**
   * Determines if a variable refers to an instance of Object.prototype (aka "Plain Object" aka {})
   *
   * @memberof Util
   * @name Util.isObject
   *
   * @param {*} obj
   * @returns {boolean}
   */
  function isObject(obj) {
    // Not plain objects:
    // - Any object or value whose internal [[Class]] property is not "[object Object]"
    // - window
    if (type(obj) !== "object" || isWindow(obj)) {
      return false
    }

    if (obj.constructor && !plainObjectInstance.hasOwnProperty.call(obj.constructor.prototype, "isPrototypeOf")) {
      return false
    }

    // If the function hasn"t returned already, we"re confident that
    // |obj| is a plain object, created by {} or constructed with new Object
    return true
  }

  /**
   * Determines if a variable refers to a Function
   *
   * @memberof Util
   * @name Util.isFunction
   *
   * @param {*} obj
   * @returns {boolean}
   */
  function isFunction(obj) {
    const o = Object.prototype.toString.call(obj)
    return o === "[object Function]" || o === "[object AsyncFunction]"
  }

  /**
   * Determines if a variable refers to an Async Function
   *
   * @memberof Util
   * @name Util.isAsyncFunction
   *
   * @param {*} obj
   * @returns {boolean}
   */
  function isAsyncFunction(obj) {
    return Object.prototype.toString.call(obj) === "[object AsyncFunction]"
  }

  /**
   *  Determines if a variable refers to an Array
   *
   * @memberof Util
   * @name Util.isArray
   *
   * @param {*} obj
   * @returns {boolean}
   */
  const { isArray } = Array // native in ECMAScript 1.5

  /**
   * Determines if a variable refers to a boolean
   *
   * @memberof Util
   * @name Util.isBoolean
   *
   * @param {*} obj
   * @returns {boolean}
   */
  function isBoolean(obj) {
    return obj === true || obj === false || Object.prototype.toString.call(obj) === "[object Boolean]"
  }

  /**
   * Determines if a variable refers to a string
   *
   * @memberof Util
   * @name Util.isString
   *
   * @param {*} obj
   * @returns {boolean}
   */
  function isString(obj) {
    return Object.prototype.toString.call(obj) === "[object String]"
  }

  /**
   * Determines if a variable refers to a number
   *
   * @memberof Util
   * @name Util.isNumber
   *
   * @param obj
   * @returns {boolean}
   */
  function isNumber(obj) {
    return Object.prototype.toString.call(obj) === "[object Number]"
  }

  /**
   * Determines if a variable refers to a number or a string
   *
   * @memberof Util
   * @name Util.isNumberOrString
   *
   * @param obj
   * @returns {boolean}
   */
  function isNumberOrString(obj) {
    return Object.prototype.toString.call(obj) === "[object Number]"
      || Object.prototype.toString.call(obj) === "[object String]"
  }

  /**
   *
   * Determines if a variable refers to a Date
   *
   * @memberof Util
   * @name Util.isDate
   *
   * @param obj
   * @returns {boolean}
   */
  function isDate(obj) {
    return Object.prototype.toString.call(obj) === "[object Date]"
  }

  /**
   * Determines if a variable refers to a RegExp
   *
   * @memberof Util
   * @name Util.isRegExp
   *
   * @param obj
   * @returns {boolean}
   */
  function isRegExp(obj) {
    return Object.prototype.toString.call(obj) === "[object RegExp]"
  }

  /**
     * Determines if a variable refers to an Error
     *
     * @memberof Util
     * @name Util.isError
     *
     * @param obj
     * @returns {boolean}
     */
  function isError(obj) {
    return Object.prototype.toString.call(obj) === "[object Error]"
  }

  /**
   * Remove leading and trailing whitespace from a string
     *
     * @memberof Util
     * @name Util.trim
     *
     * @param {String} str String to have leading/trailing whitespace extracted
   */
  function trim(str) {
    return `${str}`.replace(/^\s+/, "").replace(/\s+$/, "")
  }

  function firstWord(str) {
    return str.split(" ")[0]
  }

  function rest(str) {
    return str.substr(str.indexOf(" ") + 1) || ""
  }

  function formatAmount(num = 0, showCents = false) {
    const parts = String(round(Math.abs(num), showCents ? 2 : 0)).split(".")
    let formatted = []
    let to = parts[0].length

    do {
      let from = to - 3 >= 0 ? to - 3 : 0
      formatted = [parts[0].substring(from, to)].concat(formatted)
      to = from
    } while (to > 0)

    formatted = `${num < 0 ? "-$" : "$"}${formatted.join(",")}`

    if (showCents) {
      formatted = `${formatted}.${parts.length > 1 ? parts[1] : "00"}`

      while (formatted.indexOf(".") > formatted.length - 3) formatted = `${formatted}0`
    }

    return formatted
  }

  return {
    round,
    each,
    extend,
    deepExtend,
    deepFreeze,
    isObject,
    isFunction,
    isAsyncFunction,
    isArray,
    isBoolean,
    isString,
    isNumber,
    isNumberOrString,
    isDate,
    isRegExp,
    isError,
    trim,
    firstWord,
    rest,
    formatAmount
  }
}())

export default Util
