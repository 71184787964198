/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment } from "react"
import PropTypes from "prop-types"

import "./LocationFilter.scss"

export default function LocationFilter({
  options, selected_options, dataChanged, filterType
}) {
  return (
    <Fragment>
      <div className="title">
        Location
      </div>
      <div className="row">
        <div className="col-md-11">
          <ul className="list-unstyled location-checkbox">
            {Object.keys(options).map(area => {
              const arrSum = arr => arr.reduce((a, b) => a + b, 0)
              const areaSum = arrSum(Object.values(options[area]))
              const areaLocations = Object.keys(options[area])
              const locationAllIncluded = areaLocations.every(v => selected_options.includes(v))
              return (
                <li key={`${filterType}-area-${area}`}>
                  <div className="d-flex justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        id={`${filterType}-area-${area}`}
                        type="checkbox"
                        checked={locationAllIncluded}
                        onChange={() => {
                          const preOptions = [...selected_options]
                          if (locationAllIncluded) {
                            areaLocations.forEach(v => {
                              const index = preOptions.indexOf(v)
                              if (index > -1) preOptions.splice(index, 1)
                            })
                          } else {
                            areaLocations.forEach(v => {
                              const index = preOptions.indexOf(v)
                              if (index === -1) preOptions.push(v)
                            })
                          }
                          dataChanged({ by_location: preOptions })
                        }} />
                      <label className="custom-control-label" htmlFor={`${filterType}-area-${area}`}>
                        {` ${area} (${areaSum})`}
                      </label>
                    </div>
                    <a
                      aria-controls="locationCollapse"
                      aria-expanded="false"
                      className="btn location-collapse"
                      data-toggle="collapse"
                      href={`#${filterType}-locationCollapse-${area}`}
                      role="button">
                      <i className="material-icons"></i>
                    </a>
                  </div>
                  <div className="collapse" id={`${filterType}-locationCollapse-${area}`}>
                    <ul className="list-unstyled">
                      {Object.keys(options[area]).map(location => {
                        const locationIncluded = selected_options.includes(location)
                        return (
                          <li key={`#${filterType}-location-${area}-${location}`}>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                id={`#${filterType}-location-${area}-${location}`}
                                type="checkbox"
                                checked={locationIncluded}
                                onChange={() => { dataChanged({ by_location: _.xor(selected_options, [location]) }) }} />
                              <label className="custom-control-label" htmlFor={`#${filterType}-location-${area}-${location}`}>
                                {` ${location} (${options[area][location]})`}
                              </label>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </Fragment>
  )
}

LocationFilter.propTypes = {
  options: PropTypes.objectOf(PropTypes.object).isRequired,
  selected_options: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataChanged: PropTypes.func.isRequired,
  filterType: PropTypes.oneOf(["sticky", "main", "modal"]).isRequired
}