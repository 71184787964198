import FlashMessages from "mixins/flash"
import "easy-autocomplete"
import $ from "jquery"

import {
  getList, getListLength, FAVOURITE_PLANS, FAVOURITE_LANDS, FAVOURITE_HOMES
} from "servos/localStorage/storedList"

require("easy-autocomplete/src/sass/easy-autocomplete.scss")

class Controller {
  call() {
    document.controller = this

    // EXAMPLE
    // this.flash = { options: { message: 'You have updated your account settings, well done mate.', url: 'http://www.google.com', icon: 'fa fa-check' }, settings: { type: 'info', placement: { from: 'top', align: 'center' } } };

    FlashMessages.call(this, this.flash)

    // // Display flash messages from AJAX responses
    // displaySuccessFlash.call(this);

    // // Display flash messages from AJAX responses
    // displayErrorFlash.call(this);

    // searchButton.call(this)
    focusSearchHeaderInput.call(this)
    menuActive.call(this)
    favouriteButtonActiveIndicate.call(this)
    toggleMegaMenuHover.call(this)

    // Stops dropdowns in sticky filter from closing when clicked inside
    //
    $(document).on("click", ".sticky-filter .dropdown-menu", e => {
      e.stopPropagation()
    })

    $(document).on("click", ".sticky-filter .dropdown-menu .btn-apply", e => {
      e.currentTarget.closest(".dropdown-menu").classList.remove("show")
    })
  }
}

const toggleMegaMenuHover = function () {
  $("li.nav-item.dropdown.megamenu").mouseenter(function () {
    $(this).find(".dropdown-toggle").dropdown("show")
  }).mouseleave(function () {
    $(this).find(".dropdown-toggle").dropdown("hide")
  })
}

const setFavouriteInticator = function () {
  if (getListLength(FAVOURITE_PLANS) || getListLength(FAVOURITE_LANDS) || getListLength(FAVOURITE_HOMES)) {
    $(".favourite-indicator").show()
  } else {
    $(".favourite-indicator").hide()
  }
}

const favouriteButtonActiveIndicate = function () {
  setFavouriteInticator()
  const checkFavouriteList = () => {
    let lastList = JSON.stringify(getList(FAVOURITE_PLANS)) + JSON.stringify(getList(FAVOURITE_LANDS)) + JSON.stringify(getList(FAVOURITE_HOMES))
    return function () {
      const currentList = JSON.stringify(getList(FAVOURITE_PLANS)) + JSON.stringify(getList(FAVOURITE_LANDS)) + JSON.stringify(getList(FAVOURITE_HOMES))
      if (currentList !== lastList) {
        setFavouriteInticator()
        lastList = currentList
      }
    }
  }

  window.setInterval(checkFavouriteList(), 100) // run every 300 ms
}

const focusSearchHeaderInput = function () {
  $(".dropdown.search").on("shown.bs.dropdown", () => {
    $("#search-header").focus()
  })
}

const searchButton = function () {
  const $inputHeader = $("#search-header")
  const options = {
    getValue: "name",
    url(phrase) {
      return `/search.json?q=${phrase}`
    },
    list: {
      onChooseEvent() {
        const { url } = $inputHeader.getSelectedItemData()
        window.location.replace(url)
      }
    }
  }
  $inputHeader.easyAutocomplete(options)

  const $inputPage = $("#search-input")
  const optionsPage = {
    getValue: "name",
    url(phrase) {
      return `/search.json?q=${phrase}`
    },
    list: {
      onChooseEvent() {
        $inputPage.closest("form").submit()
      }
    }
  }
  $inputPage.easyAutocomplete(optionsPage)
}

const menuActive = function () {
  const path = window.location.pathname
  const pathBase = path.split("/")[1]
  const { origin } = window.location
  let currentLinkItem
  let foundMatch = false
  $(".megamenu.menu-parent a.nav-link").each(function () {
    if (!foundMatch) {
      const linkHref = $(this).attr("href")
      if (linkHref) {
        const linkPath = linkHref.replace(origin, "")
        const linkBase = linkPath.split("/")[1]
        if (pathBase === linkBase) {
          currentLinkItem = $(this).closest(".dropdown-menu.links").siblings("a.nav-link.dropdown-toggle.menu-parent-item")
          currentLinkItem.addClass("active")
          foundMatch = true
        }
      }
    }
  })
  $("li.dropdown.menu-parent").on("show.bs.dropdown", function () {
    $(this).children("a.nav-link.dropdown-toggle.menu-parent-item").addClass("active")
  })
  $("li.dropdown.menu-parent").on("hide.bs.dropdown", function () {
    $(this).children("a.nav-link.dropdown-toggle.menu-parent-item").each(function () {
      if (!$(this).is(currentLinkItem)) {
        $(this).removeClass("active")
      }
    })
  })
}

export default Controller
