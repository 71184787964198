import * as constants from "./constants"

export const getData = () => ({
  type: constants.FETCH_REQUESTED
})

export const fetchDataSuccess = payload => ({
  type: constants.FETCH_SUCCESS,
  payload
})

export const fetchDataFailed = error => ({
  type: constants.FETCH_FAILED,
  payload: error
})

export const updateFilter = updatedItem => ({
  type: constants.FILTERS_UPDATED,
  payload: updatedItem
})