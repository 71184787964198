import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"

import store from "./redux/store"
import Root from "./GalleryRoot"

export default function startGalleryPageApp(rootElem) {
  ReactDOM.render(
    <Provider store={store}>
      <Root />
    </Provider>,
    rootElem
  )
}