/* eslint-disable react/no-array-index-key */
import React, { useState } from "react"
import Carousel from "react-bootstrap/Carousel"

import {
  DisplayPin, ThreeD, NewBadge, AwardBadge,
  OnPromotionBadge
} from "components/buttons/badges"
import { IconFacade } from "images/icons"

export default function FacadeCarousel({
  showBedges,
  homeDesign: {
    id,
    attributes: {
      detailUrl,
      onDisplay,
      virtualTour,
      newDesign,
      awardWinner,
      onPromotion,
      facades,
    }
  }
}) {
  const [currentIdx, setCurrentIdx] = useState(0)

  const facadesSafe = facades.data.length > 0 ? facades.data : [{
    id: 0,
    attributes: {
      id: 0,
      imageJson: {
        sm: null
      },
      name: "Placeholder"
    }
  }]
  const facadesLength = facadesSafe.length
  return (
    <div>
      <div className="card-img">
        <div className="card design-card design-card-facade border-0">
          <Carousel
            className="carousel-inner"
            interval={null}
            activeIndex={currentIdx}
            onSelect={selectedIndex => { setCurrentIdx(selectedIndex) }}
            indicators={false}>
            {facadesSafe.map(facade => {
              const { attributes: { id: facadeId, imageJson: { sm: imageUrl }, name } } = facade
              return (
                <Carousel.Item style={{ background: "cover" }} key={`facade-image-${id}-${facadeId}`}>
                  <a
                    href={detailUrl}
                    className="no-url-decoration">
                    <div className="carousel-item active w-100 no-repeat center cover">
                      <img
                        src={imageUrl || "/fallback/placeholder-house.svg"}
                        alt={name}
                        style={{ height: "210px", objectFit: "cover", width: "100%" }} />
                    </div>
                  </a>
                </Carousel.Item>
              )
            })}
          </Carousel>
          <div className="card-icons facade-name">
            <div className="row justify-content-between">
              {showBedges && (
                <div className="col">
                  <ul className="list-inline">
                    <DisplayPin display={onDisplay} />
                    <ThreeD display={virtualTour} />
                  </ul>
                </div>
              )}
              <div className="col text-right">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <span className="total">
                      <IconFacade className="icon mr-1" />
                      {facadesLength}
                    </span>
                    <span className="name">
                      <IconFacade className="icon mr-1" />
                      {`${currentIdx + 1}/${facadesLength} ${facadesSafe[currentIdx].attributes.name}`}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showBedges && (
        <div className="card-badges">
          <NewBadge display={newDesign} />
          <AwardBadge display={awardWinner} />
          <OnPromotionBadge display={onPromotion} />
        </div>
      )}
    </div>
  )
}
