import {
  all, put, fork, call, takeLatest, select
} from "redux-saga/effects"
import { getNormalizedData } from "helpers/requesters"
import * as actions from "./actions"
import * as constants from "./constants"

function* fetchData() {
  const apiUrl = gon.api_url
  const { response, error, meta } = yield call(getNormalizedData, apiUrl)
  if (response) {
    yield put(actions.fetchDataSuccess({ response, meta }))
  } else {
    yield put(actions.fetchDataFailed(error))
  }
}

function* updateList() {
  const { objects, filters: { sort_by } } = yield select(state => state.displayHomeForSale)
  const sortable = [...Object.values(objects)]
  const [key, order] = sort_by.split("__")
  if (key === "name") {
    sortable.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
  } else {
    sortable.sort((a, b) => a.attributes[key] - b.attributes[key])
  }
  if (order === "desc") sortable.reverse()
  yield put(actions.objectSorted(sortable))
}

// watchers
function* watchFetchDataRequest() {
  yield takeLatest(constants.FETCH_REQUESTED, fetchData)
}

function* watchFilterUpdateNoFetch() {
  yield takeLatest(constants.FILTER_UPDATE_NO_FETCH, updateList)
  yield takeLatest(constants.FETCH_SUCCESS, updateList)
}

export default function* RootSaga() {
  yield all([
    fork(watchFetchDataRequest),
    fork(watchFilterUpdateNoFetch)
  ])
}
