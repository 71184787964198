import React from "react"
import { Container, Row } from "react-bootstrap"
import { useDesigns } from "../../configurator-context-provider"
import DesignCard from "./DesignCard"
import Loading from "../Loading"
import Error from "../Error"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.DesignCardList = false

const DesignCardList = () => {
  // if (window.configurator.debugPoints.DesignCardList) {
  //   debugger
  // }

  const designs = useDesigns()

  if (designs.loading) {
    return <Loading />
  }

  if (designs.error) {
    return <Error message={designs.error} />
  }

  return (
    <Container fluid="sm">
      <Row xs={1} md={2} lg={3}>
        {designs.designs.map(design => (
          <DesignCard
            key={design.getInternalID()}
            design={design} />
        ))}
      </Row>
    </Container>
  )
}

export default DesignCardList
