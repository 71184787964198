export default function filterData({ data, filter }) {
  const {
    by_design_type,
    by_location,
    by_media_type,
    by_series
  } = filter
  const galleryArray = []
  Object.values(data).forEach((item, itemIdx) => {
    const {
      attributes: {
        location,
        name,
        imageUrls,
        series,
        designType,
        virtualTour,
        virtualTourPreview,
        youtubePreview,
        youtubeUrl
      }
    } = item
    if ([location, "Any"].includes(by_location)
            && [series, "Any"].includes(by_series)
            && (by_design_type.includes(designType) || by_design_type.length === 0 || by_design_type.length === 6)) {
      if (by_media_type.includes("virtual_tour") || by_media_type.length === 0) {
        if (virtualTour) {
          galleryArray.push({
            itemIdx,
            type: "virtual_tour",
            name,
            url: virtualTour,
            preview: virtualTourPreview
          })
        }
      }
      if (by_media_type.includes("videos") || by_media_type.length === 0) {
        if (Array.isArray(youtubeUrl) && youtubeUrl.length > 0) {
          youtubeUrl.forEach((url, idx) => {
            galleryArray.push({
              itemIdx,
              type: "video",
              name,
              url,
              preview: youtubePreview[idx]
            })
          })
        }
      }
      if (by_media_type.includes("photos") || by_media_type.length === 0) {
        if (Array.isArray(imageUrls) && imageUrls.length > 0) {
          imageUrls.forEach((image, idx) => {
            galleryArray.push({
              itemIdx,
              type: "photo",
              name,
              url: image,
              imageIndex: idx,
            })
          })
        }
      }
    }
  })
  const sortedGalleryArray = galleryArray.sort((a, b) => a.name.localeCompare(b.name))
  return sortedGalleryArray
}