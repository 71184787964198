import * as types from "../actionTypes"

const defaultFilter = {
  by_media_type: [],
  by_design_type: [],
  by_location: "Any",
  by_series: "Any"
}

export const filters = (state = defaultFilter, action) => {
  switch (action.type) {
    case types.UPDATE_FILTER: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}


const defaultOptions = {
  by_media_type: ["photos", "videos", "virtual_tour"],
  by_design_type: [],
  by_location: ["Any"],
  by_series: ["Any"]
}

function generateOptions(responseData) {
  const locationsTmp = []
  const seriesesTmp = []
  Object.values(responseData).forEach(data => {
    const { attributes: { location, series } } = data
    if (location && !locationsTmp.includes(location)) {
      locationsTmp.push(location)
    }
    if (series && !seriesesTmp.includes(series)) {
      seriesesTmp.push(series)
    }
  })
  const locations = locationsTmp.sort((a, b) => a.localeCompare(b))
  const serieses = seriesesTmp.sort((a, b) => a.localeCompare(b))
  return { locations, serieses }
}

export const options = (state = defaultOptions, action) => {
  switch (action.type) {
    case types.FETCH_SUCCESS: {
      const { response } = action.payload
      const { locations, serieses } = generateOptions(response)
      return { ...state, by_location: ["Any", ...locations], by_series: ["Any", ...serieses] }
    }
    default: {
      return state
    }
  }
}