/* eslint-disable no-undef */
import { combineReducers } from "redux"
import * as constants from "../constants"

const api_url = (state = gon.home_lands.api_url, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const options = (state = gon.home_lands.options, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const locationOptions = (state = {}, action) => {
  switch (action.type) {
    case constants.FETCH_SUCCESS: {
      if (action.payload.response.data.homeLandSearch) {
        const locationCount = {}
        Object.values(action.payload.response.data.homeLandSearch).forEach(({ attributes: { locationName, region } }) => {
          if (!Object.keys(locationCount).includes(region)) {
            locationCount[region] = {}
          }
          if (Object.keys(locationCount[region]).includes(locationName)) {
            locationCount[region][locationName] += 1
          } else {
            locationCount[region][locationName] = 1
          }
        })
        return locationCount
      }
      return state
    }
    default: {
      return state
    }
  }
}

function pushStateToLocalStorage(filter) {
  if (!filter.no_push_sotrage) {
    // localStorage.setItem("HOME_LAND_SEARCH_FILTER", JSON.stringify(filter))
    document.cookie = `HOME_LAND_SEARCH_FILTER=${JSON.stringify(filter)}`
  }
}

const filters = (state = gon.home_lands.filters, action) => {
  switch (action.type) {
    case constants.FILTERS_UPDATED_NO_FETCH:
    case constants.FILTERS_UPDATED: {
      const newState = { ...state, ...action.payload }
      pushStateToLocalStorage(newState)
      return newState
    }
    default:
      pushStateToLocalStorage(state)
      return state
  }
}

const homeLandObjs = (state = {}, action) => {
  switch (action.type) {
    case constants.FETCH_SUCCESS: {
      return action.payload.response.data.homeLandSearch || {}
    }
    default: {
      return state
    }
  }
}

function filterIdbyLocation(location_filter, fullObj, ids) {
  if (location_filter.length === 0) {
    return [...ids]
  }
  const filteredList = []
  ids.forEach(homeId => {
    if (location_filter.includes(fullObj[homeId].attributes.locationName)) {
      filteredList.push(homeId)
    }
  })
  return filteredList
}

const homeLandIds = (state = { full: [], filtered: [], obj: {} }, action) => {
  switch (action.type) {
    case constants.FETCH_SUCCESS: {
      const { by_location } = action.payload.filters
      const fullList = action.payload.response.meta.ordering
      const fullObj = action.payload.response.data.homeLandSearch || {}
      const filteredList = filterIdbyLocation(by_location, fullObj, fullList)
      return {
        full: fullList,
        filtered: filteredList,
        obj: fullObj
      }
    }
    case constants.FILTERS_UPDATED_NO_FETCH: {
      const { full, obj } = state
      const { by_location } = action.payload
      const filteredList = filterIdbyLocation(by_location, obj, full)
      return { ...state, filtered: filteredList }
    }
    default: {
      return state
    }
  }
}

const dataFetching = (state = true, action) => {
  switch (action.type) {
    case constants.FETCH_REQUESTED:
    case constants.FILTERS_UPDATED: {
      return true
    }
    case constants.FETCH_SUCCESS:
    case constants.FETCH_FAILED: {
      return false
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  homeLandObjs,
  homeLandIds,
  options,
  locationOptions,
  filters,
  dataFetching,
  api_url
})
