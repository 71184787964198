import React from "react"
import { useSelector } from "react-redux"

import TinySliderWrapper from "shared/TinySliderWrapper"
import HomeSeriesRow from "./homeSeriesRow"

export default function () {
  const tinySliderSetting = {
    items: 1.2,
    loop: false,
    gutter: 10,
    disable: false,
    controls: false,
    navPosition: "bottom",
    mouseDrag: true,
    responsive: {
      575: {
        items: 2.1
      },
      767: {
        disable: true,
        items: 3
      }
    }
  }
  const homeDesignSeries = useSelector(state => state.homeDesignSeries)
  const { attributes: { id: mainHomeDesignId } } = useSelector(state => state.homeDesign)
  if (homeDesignSeries.length) {
    const homeDesign = useSelector(state => state.homeDesign)
    const {
      attributes: { series }
    } = homeDesign
    const facadeGroups = homeDesignSeries.map(homdeDesign => homdeDesign.attributes.facadeGroup)
    const facadeGroupIds = facadeGroups.map(group => group.id)
    const uniqFacadeGroupIds = [...new Set(facadeGroupIds)]
    const uniqFacadeGroups = Array.from(uniqFacadeGroupIds, id => facadeGroups.find(obj => obj.id === id))
    let idx = 0

    return (
      <div className="container mb-md-5 pb-5">
        <h4 className="mb-4">{`The ${series} Series`}</h4>
        <div className="series-table-header d-none d-lg-block">
          <div className="row small-text py-1 mx-1">
            <div className="col-lg-5 pl-0">Floor Plans</div>
            <div className="col-lg-1 px-lg-0">
              Size (m
              <sup>2</sup>
              )
            </div>
            <div className="col-lg-1 px-lg-0">Width (m)</div>
            <div className="col-lg-1 px-lg-0">Depth (m)</div>
            <div className="col-lg-auto">Specs</div>
          </div>
        </div>
        <div className="series-table series-inner mb-4">
          <TinySliderWrapper settings={tinySliderSetting}>
            {homeDesignSeries && homeDesignSeries.map(design => {
              const { attributes: { id } } = design
              if (id !== mainHomeDesignId) idx += 1
              return (
                <HomeSeriesRow key={`home-series-row-${id}`} homeDesign={design} idx={idx - 1} mainId={mainHomeDesignId} />
              )
            })}
          </TinySliderWrapper>
        </div>
        <ul className="list-inline table-legend ml-1">
          {uniqFacadeGroups.map(group => {
            const { hexcolour, groupText } = group
            if (groupText) {
              return (
                <li className="list-inline-item" key={`series_notation_${hexcolour}-${groupText}`}>
                  <div className="square d-inline-block" style={{ backgroundColor: hexcolour }} />
                  {groupText}
                </li>
              )
            }
            return ""
          })}
        </ul>
      </div>
    )
  }
  return ""
}