import { combineReducers }  from "redux"
import { connectRouter } from "connected-react-router"

import createReducer from "components/utils/createReducer"
import favouriteList from "components/common/favouriteIcon/redux/reducers"
import homeLands from "./homeLandSearch"
import * as constants from "../constants"

// building config reducer with gon excluding project has its own.
const config = createReducer(
  function () {
    // eslint-disable-next-line no-undef
    const { ...initialState } = gon

    return initialState
  }()
)({})

const showModalFilter = (state = false, action) => {
  switch (action.type) {
    case constants.CTRL_MODAL_FILTER: {
      return action.payload
    }
    default:
      return state
  }
}

export default history => combineReducers({
  router: connectRouter(history),
  config,
  favouriteList,
  homeLands,
  showModalFilter
})
