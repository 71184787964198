import { combineReducers } from "redux"

export const FETCH_REQUESTED = "display_home/FETCH_REQUESTED"
export const FETCH_SUCCESS = "display_home/FETCH_SUCCESS"
export const FETCH_FAILED = "display_home/FETCH_FAILED"

const objects = (state = [], action) => {
  switch (action.type) {
    case FETCH_SUCCESS: {
      try {
        return action.payload.response.data || []
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error)
        return {}
      }
    }
    default: {
      return state
    }
  }
}

const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_SUCCESS: {
      try {
        return action.payload.response.meta || []
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error)
        return []
      }
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  objects,
  ids
})