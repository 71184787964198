/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import GoogleMapReact from "google-map-react"

import Marker from "./Marker"
import InfoBox from "./InfoBox"

function fitMarkers(map, maps, locations) {
  const bounds = new maps.LatLngBounds()
  locations.forEach(location => {
    const { location: { data: { attributes: { latitude, longitude } } } } = location
    bounds.extend(new maps.LatLng(latitude, longitude))
  })
  map.fitBounds(bounds)
}

export default function HomeDisplayLocation() {
  const { google_key } = useSelector(state => state.config.env_vars)
  const homeDesign = useSelector(state => state.homeDesign)
  const { attributes: { locations } } = homeDesign

  const [selectedIdx, setSelectedIdx] = useState(0)
  const [googleMap, setGoogleMap] = useState(null)
  const [googleMaps, setGoogleMaps] = useState(null)

  useEffect(() => {
    if (googleMap && googleMaps) {
      if (selectedIdx === -1) {
        fitMarkers(googleMap, googleMaps, locations)
      } else {
        const { location: { data: { attributes: { latitude, longitude } } } } = locations[selectedIdx]
        googleMap.setZoom(15)
        googleMap.setCenter({ lat: latitude, lng: longitude })
      }
    }
  }, [googleMap, googleMaps, selectedIdx, locations])


  if (locations.length) {
    const { attributes: { name } } = homeDesign
    return (
      <>
        <div className="container mb-md-5 pb-5" style={{ position: "relative" }}>
          <a
            name="on_display"
            style={{
              display: "block",
              position: "relative",
              top: "-100px",
              visibility: "hidden"
            }}>
          </a>
          <div className="text-center">
            <h2>On Display</h2>
            <p>{`${name} is on display at ${locations.length} location${locations.length > 1 ? "s" : ""}`}</p>
            <div className="map-container-google-displayhome z-depth-1-half map-container" style={{ height: "500px" }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: google_key }}
                defaultCenter={{ lat: 0, lng: 0 }}
                defaultZoom={0}
                onChildClick={(key, childProps) => { setSelectedIdx(parseInt(childProps.idx, 10)) }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                  setGoogleMap(map)
                  setGoogleMaps(maps)
                }}>
                {locations.map((value, idx) => {
                  const { location: { data: { attributes: { id, latitude, longitude } } } } = value
                  return (
                    <Marker
                      key={`pin-${id}`}
                      idx={idx}
                      lat={latitude}
                      lng={longitude}
                      selected={idx === selectedIdx} />
                  )
                })}
              </GoogleMapReact>
              <InfoBox
                locations={locations}
                selected={selectedIdx}
                closeInfo={() => { setSelectedIdx(-1) }} />
            </div>
          </div>
        </div>
      </>
    )
  }
  return ""
}