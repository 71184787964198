import React from "react"
import { Row, Col } from "react-bootstrap"
import classNames from "classnames"
import OptionCard from "./OptionCard"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.GroupCard = false

const GroupCard = ({ group, groupIndex, groupCount }) => {
  // if (window.configurator.debugPoints.GroupCard) {
  //   debugger
  // }

  const defaultOptions = group.getOptions()
    .filter(option => option.isDefaultOption() || option.getType() === "Included" || option.getType() === "Inclusion")
    .sort((a, b) => a.isHidden() ? 1 : (b.isHidden() ? -1 : 0))
  const saverOptions = group.getOptions()
    .filter(option => option.getType() === "Saver")
    .sort((a, b) => a.isHidden() ? 1 : (b.isHidden() ? -1 : 0))
  const premiumOptions = group.getOptions()
    .filter(option => option.getType() === "Premium")
    .sort((a, b) => a.isHidden() ? 1 : (b.isHidden() ? -1 : 0))
  const visibleDefaultOptions = defaultOptions.filter(option => !option.isHidden())
  const visibleSaverOptions = saverOptions.filter(option => !option.isHidden())
  const visiblePremiumOptions = premiumOptions.filter(option => !option.isHidden())

  return (
    <Row
      className={classNames("group-row", {
        "group-row-first": groupIndex === 0,
        "group-row-next": groupIndex !== 0 && groupIndex !== (groupCount - 1),
        "group-row-last": groupIndex === (groupCount - 1)
      })}
      xs={1}
      md={4}>
      <Col
        className="group-name-column"
        xs={12}
        md={3}>
        {group.getName()}
      </Col>
      <Col
        className={classNames("inclusion-column", {
          "d-none": visibleDefaultOptions.length === 0,
          "d-sm-block": visibleDefaultOptions.length === 0
        })}
        xs={12}
        md={3}>
        {
          defaultOptions.map(option => (
            <OptionCard
              key={option.getAssignedID()}
              option={option} />
          ))
        }
      </Col>
      <Col
        className={classNames("saver-column", {
          "d-none": visibleSaverOptions.length === 0,
          "d-sm-block": visibleSaverOptions.length === 0
        })}
        xs={12}
        md={3}>
        {
          saverOptions.map(option => (
            <OptionCard
              key={option.getAssignedID()}
              option={option} />
          ))
        }
      </Col>
      <Col
        className={classNames("premium-column", {
          "d-none": visiblePremiumOptions.length === 0,
          "d-sm-block": visiblePremiumOptions.length === 0
        })}
        xs={12}
        md={3}>
        {
          premiumOptions.map(option => (
            <OptionCard
              key={option.getAssignedID()}
              option={option} />
          ))
        }
      </Col>
    </Row>
  )
}

export default GroupCard
