/* eslint-disable no-undef */
import { combineReducers } from "redux"
import * as constants from "../constants"

const options = (state = gon.land_for_sale.options, action) => {
  switch (action.type) {
    default:
      return state
  }
}


const filters = (state = gon.land_for_sale.filters, action) => {
  switch (action.type) {
    case constants.FILTERS_UPDATED: {
      const newState = { ...state, ...action.payload }
      return newState
    }
    default:
      return state
  }
}

const objects = (state = {}, action) => {
  switch (action.type) {
    case constants.FETCH_SUCCESS: {
      return action.payload.response
    }
    default: {
      return state
    }
  }
}

const ids = (state = [], action) => {
  switch (action.type) {
    case constants.FETCH_SUCCESS: {
      return action.payload.meta.ordering
    }
    default: {
      return state
    }
  }
}

const dataFetching = (state = true, action) => {
  switch (action.type) {
    case constants.FETCH_REQUESTED:
    case constants.FILTERS_UPDATED: {
      return true
    }
    case constants.FETCH_SUCCESS:
    case constants.FETCH_FAILED: {
      return false
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  objects,
  ids,
  options,
  filters,
  dataFetching,
})