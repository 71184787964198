import $ from "jquery"

const ajaxElementForms = function (bindAfterAction) {
  const button = "button#update-page"
  $("#update-page").on("click", e => {
    e.preventDefault()
    e.stopPropagation()
    $("form#pages-form").each(function (index) {
      const form = $(this)
      const data = new FormData(this)
      $.ajax({
        method: form.attr("method"),
        data,
        url: form.attr("action"),
        contentType: false,
        processData: false,
        dataType: "json",
        beforeSend: disableUpdateButton(button),
        success(response) {
          updateFormSuccess(response, form, bindAfterAction)
        },
        error(response) {
          updateFormError(response)
        },
        complete: enableUpdateButton(button),
      })
    })
  })
}

function updateFormSuccess(response, form, bindAfterAction) {
  form.html(response.html)
  $("iframe#page-preview").attr("src", (index, attr) => attr)
  bindAfterAction.call(this)
  if (response.errors) {
    const error_json = response.errors
    const list = $("<div/>")
    for (const key in error_json) {
      if (error_json[key].length) {
        list.append(`<div>${key}: ${error_json[key]}</div>`)
      }
    }
    swal.fire("Error updating form", `${list.prop("outerHTML")}`, "error")
  }
}

function updateFormError(error) {
  console.log(error.responseJSON)
  swal.fire("Error updating form", `${error.responseJSON}`, "error")
}

function disableUpdateButton(button) {
  $(button).attr("disabled", true).text("Updating...")
}

function enableUpdateButton(button) {
  $(button).attr("disabled", false).text("Update")
}

export default ajaxElementForms
