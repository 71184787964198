// eslint-disable-next-line no-undef
const options = (state = _.get(gon, "home_designs.options", {}), action) => {
  switch (action.type) {
    default:
      return state
  }
}

export const getOptions = state => state.options

export default options
