import BaseController from "controllers/base"
import startGalleryPageApp from "apps/Gallery"

class Controller extends BaseController {
  call() {
    super.call()

    startGalleryPageApp(
      document.getElementById("react-body"),
      {}
    )
  }
}

export default Controller