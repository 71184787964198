import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"

import HomeDesignRoot from "./homeDesignRoot"

import configureStore from "./store"

const startHomeDesignDetailApp = rootElem => {
  const store = configureStore()

  ReactDOM.render(
    <Provider store={store}>
      <HomeDesignRoot />
    </Provider>,
    rootElem
  )
}

export default startHomeDesignDetailApp