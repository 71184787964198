/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
import BaseController from "controllers/base"
import configuratorPanel from "apps/Configurator"

class Controller extends BaseController {
  call() {
    super.call()
    bindConfigurator.call(this)
  }
}

const bindConfigurator = () => {
  if (document.getElementById("configurator-panel")) {
    configuratorPanel(
      document.getElementById("configurator-panel"),
      {}
    )
  }
}

export default Controller
