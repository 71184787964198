import BaseController from "controllers/base"

class Controller extends BaseController {
  call() {
    super.call()

    initMap.call(this)
  }
}

const initMap = function () {
  // eslint-disable-next-line no-undef
  const map = new google.maps.Map(
    document.getElementById("map-container-google-where-we-build"), {
      zoom: 8.1,
      center: { lat: -33.866924, lng: 151.207643 },
    }
  )

  map.setOptions({ minZoom: 5, maxZoom: 15 })

  map.data.loadGeoJson(gon.json_file_url)

  // Set style from property
  map.data.setStyle(feature => {
    const color = feature.getProperty("color")
    return {
      fillColor: color,
      fillOpacity: 0.7,
      strokeWeight: 0.5,
      strokeColour: "red"
    }
  })

  // Set name from property
  map.data.addListener('mouseover', function(event) {
    document.getElementById('info-box').textContent =
      event.feature.getProperty('name');
  })
}

export default Controller
