import React from "react"
import { useInclusionTotal } from "../../configurator-context-provider"
import InclusionsList from "./InclusionsList"
import Util from "../../Util/configurator-util"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.InclusionsListView = false

const InclusionsListView = () => {
  if (window.configurator.debugPoints.InclusionsListView) {
    debugger
  }

  const inclusionTotal = useInclusionTotal()

  return (
    <div className="home-deisgns">
      <div className="page-header text-center">
        <h1>Standard Inclusions</h1>
        <h5>{inclusionTotal > 0 ? Util.formatAmount(inclusionTotal) : "Included in Base Price"}</h5>
      </div>
      <InclusionsList />
    </div>
  )
}

export default InclusionsListView
