import Util from "../Util/configurator-util"

/**
 *
 * @param {Object} ctx
 * @param {String} ctx.internalID
 * @param {String} ctx.name
 * @param {String} ctx.taxSchedule
 * @param {Object} ctx.pricing
 */
function Item(ctx) {
  this.internalID = ""
  this.name = ""
  this.taxSchedule = ""
  this.pricing = {}

  if (!ctx) {
    return
  }

  this.setInternalID(ctx.internalID || "")
  this.setName(ctx.name || "")
  this.setTaxSchedule(ctx.taxSchedule || "")

  if (ctx.pricing) {
    Object.keys(ctx.pricing).forEach(p => this.setPrice(p, ctx.pricing[p]))
  }
}

/**
 * Get the Internal ID
 * @returns {String}
 */
Item.prototype.getInternalID = function () { return this.internalID }
/**
 * Set the Internal ID
 * @param {String} internalID The Internal ID
 */
Item.prototype.setInternalID = function (internalID) {
  if (!Util.isString(internalID)) {
    throw new TypeError("Invalid parameter internalID: must be String")
  }

  this.internalID = internalID
}

/**
 * Get the Name
 * @returns {String}
 */
Item.prototype.getName = function () { return this.name }
/**
 * Set the Name
 * @param {String} name The Name
 */
Item.prototype.setName = function (name) {
  if (!Util.isString(name)) {
    throw new TypeError("Invalid parameter name: must be String")
  }

  this.name = name
}

/**
 * Get the Tax Schedule
 * @returns {String}
 */
Item.prototype.getTaxSchedule = function () { return this.taxSchedule }
/**
 * Set the Tax Schedule
 * @param {String} taxSchedule The Tax Schedule
 */
Item.prototype.setTaxSchedule = function (taxSchedule) {
  if (!Util.isString(taxSchedule)) {
    throw new TypeError("Invalid parameter taxSchedule: must be String")
  }

  this.taxSchedule = taxSchedule
}

/**
 * Get the Price of the item (for a specified Price Level)
 * @param {String} level The Price Level to get a Price for
 * @returns {String} String representation of the price for the item (for a specified Price Level)
 */
Item.prototype.getPrice = function (level) {
  if (!Util.isString(level)) {
    throw new TypeError("Invalid parameter level: must be String")
  }

  if (!this.pricing[level]) {
    console.log({
      error: "No price defined for the requested level",
      details: {
        name: this.getName(),
        internalID: this.getInternalID(),
        level: level
      }
    })

    throw new RangeError(`No price defined for the requested level (Name: ${this.getName()}, Internal ID: ${this.getInternalID()}, Level: ${level})`)
  }

  return this.pricing[level]
}

/**
 * Set the Price of the item (for a specified Price Level)
 * @param {String} level The Price Level to set
 * @param {String} price The Price for the Item
 */
Item.prototype.setPrice = function (level, price) {
  if (!level) {
    throw new TypeError("Missing required parameter: level")
  }
  if (!price) {
    throw new TypeError("Missing required parameter: price")
  }

  if (!Util.isString(level)) {
    throw new TypeError("Invalid parameter level: must be String")
  }

  if (!Util.isString(price)) {
    throw new TypeError("Invalid parameter price: must be String")
  }

  if (Number.isNaN(Number(price))) {
    throw new TypeError("Invalid parameter price: must be able to be converted to a Number")
  }

  this.pricing[level] = price
}

/**
 * Get all available Price Levels for the item
 * @returns {String[]}
 */
Item.prototype.getPriceLevels = function () { return Object.keys(this.pricing) }

/**
 * Get the Price, including tax if applicable (for a specified Price Level)
 * @param {String} level The Price Level to get a Price for
 * @returns {String} String representation of the price for the item, including tax if applicable (for a specified Price Level)
 */
Item.prototype.getPriceWithTax = function (level) {
  let toReturn = this.getPrice(level)

  if (this.getTaxSchedule() === "BAS") {
    toReturn = Number(toReturn) * 1.1
  }

  return Util.round(Number(toReturn), 2)
}

Object.defineProperty(Item.prototype, "toString", {
  value: () => "[object Item]"
})

export default Item
