/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import {
  FaClipboardCheck, FaClipboard, FaEnvelope,
  FaFacebook, FaTwitter, FaLinkedin
} from "react-icons/fa"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ReactTooltip from "react-tooltip"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import cx from "classnames"

import "./ShareButton.css"

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      urlCopied: false
    }
  }

  componentDidUpdate(prevState) {
    const { urlCopied } = this.state
    if (urlCopied !== prevState.urlCopied) {
      ReactTooltip.rebuild()
    }
  }

  popUpWindow(link) {
    window.open(link, "popup", "width=600,height=600")
    return false
  }

  toggleMenu() {
    this.setState(prevState => ({
      showMenu: !prevState.showMenu
    }))
  }

  render() {
    const windowHref = window.location.href
    const facebookShareLink = encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${windowHref}`)
    const twitterShareLink = encodeURI(`https://twitter.com/share?hashtags=BeechwoodHomes&url=${windowHref}`)
    const linkedinShareLink = encodeURI(`https://www.linkedin.com/shareArticle?mini=true&url=${windowHref}`)
    const emailShareLink = encodeURI(`mailto:?subject=Share link from Beechwood Homes&body=Check out this post ${windowHref}.`)

    const { greyBtn } = this.props
    const { showMenu, urlCopied } = this.state
    const copyMessage = urlCopied ? "Link Copied" : "Copy Link to Clipboard"

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.location.protocol === "https:") {
      const shareData = {
        title: "Beechwood Homes",
        url: windowHref
      }
      return (
        <div
          className={cx("btn", "share-button", { "btn-outline-grey": greyBtn })}
          onClick={() => navigator.share(shareData)}>
          <i className="material-icons mr-1">share</i>
          Share
        </div>
      )
    }

    return (
      <React.Fragment>
        <div
          className={cx("btn", "share-button", { "btn-outline-grey": greyBtn })}
          onClick={() => this.toggleMenu()}>
          <i className="material-icons mr-1">share</i>
          Share
        </div>
        {showMenu && (
          <TransitionGroup appear className="row design-card-deck">
            <CSSTransition
              timeout={400}
              classNames="tile-transition">
              <div className="share-container">
                <FaFacebook
                  data-tip="Share Link to Facebook"
                  className="facebook-share share-icon"
                  onClick={() => this.popUpWindow(facebookShareLink)} />
                <ReactTooltip />
                <FaTwitter
                  data-tip="Share Link to Twitter"
                  className="twitter-share share-icon"
                  onClick={() => this.popUpWindow(twitterShareLink)} />
                <ReactTooltip />
                <FaLinkedin
                  data-tip="Share Link to LinkedIn"
                  className="linkedin-share share-icon"
                  onClick={() => this.popUpWindow(linkedinShareLink)} />
                <ReactTooltip />
                <a
                  href={emailShareLink}
                  data-tip="Share Link Through Email">
                  <FaEnvelope className="email-share share-icon" />
                </a>
                <ReactTooltip />
                <CopyToClipboard
                  data-tip={copyMessage}
                  text={windowHref}
                  onCopy={() => {
                    this.setState({ urlCopied: true })
                  }}>
                  {urlCopied ? (
                    <FaClipboardCheck
                      className="copy-share share-icon" />
                  ) : (
                    <FaClipboard
                      className="copy-share share-icon" />
                  )}
                </CopyToClipboard>
                <ReactTooltip />
              </div>
            </CSSTransition>
          </TransitionGroup>
        )}

      </React.Fragment>
    )
  }
}