import React from "react"
import { Container, Row } from "react-bootstrap"
import NameFilter from "./NameFilter"
import HomeTypeFilters from "./HomeTypeFilters"
import SpecificationFilters from "./SpecificationFilters"
import PriceFilters from "./PriceFilters"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.DesignCardFilters = false

const DesignCardFilters = () => {
  // if (window.configurator.debugPoints.DesignCardFilters) {
  //   debugger
  // }

  return (
    <div className="filter-wrapper">
      <div className="filter bg-light">
        <Container fluid="sm">
          <Row>
            <NameFilter />
          </Row>
          <Row>
            <HomeTypeFilters />
            <SpecificationFilters />
            <PriceFilters />
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default DesignCardFilters
