import React, { Fragment } from "react"

import Title from "./components/Title"
import HomeDesigns from "./components/HomeDesigns"

export default function HomeDesignRoot() {
  return (
    <Fragment>
      <Title />
      <HomeDesigns />
    </Fragment>
  )
}