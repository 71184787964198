import React from "react"
import ReactDOM from "react-dom"

import Button from "./ShareButton"

export default rootElem => {
  ReactDOM.render(
    <Button />,
    rootElem
  )
}