import React, { PureComponent } from "react"

class IconButtonHorizontal extends PureComponent {
  activeClass() {
    const { active } = this.props
    return active ? "active" : ""
  }

  render() {
    const {
      icon,
      text,
      handleToggled
    } = this.props

    return (
      <div
        role="button"
        className={`btn btn-secondary ${this.activeClass()}`}
        onClick={handleToggled}
        onKeyDown={handleToggled}
        tabIndex={0}>
        <input name="options" type="radio" />
        <div className={`icn-${icon}`}></div>
        <span>{text}</span>
      </div>
    )
  }
}

export default IconButtonHorizontal