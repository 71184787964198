import * as constants from "./constants"

// Data
export const fetchDataStart = payload => ({
  type: constants.FETCH_START,
  payload
})

export const fetchDataSuccess = payload => ({
  type: constants.FETCH_SUCCESS,
  payload
})

export const fetchDataFailed = error => ({
  type: constants.FETCH_FAILED,
  error
})