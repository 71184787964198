const loadCKEditor = function () {
  const CKEditor = window.CKEDITOR

  // to use inbuild attachments
  // filebrowserImageBrowseLinkUrl: "/backend/cms/attachments/ck_browser",
  // filebrowserImageBrowseUrl: "/backend/cms/attachments/ck_browser",

  const editor = CKEditor.replace("html_editor", {
    filebrowserBrowseUrl: "/ckeditor/attachment_files",
    filebrowserFlashBrowseUrl: "/ckeditor/attachment_files",
    filebrowserFlashUploadUrl: "/ckeditor/attachment_files",
    filebrowserImageBrowseLinkUrl: "/ckeditor/pictures",
    filebrowserImageBrowseUrl: "/ckeditor/pictures",
    filebrowserImageUploadUrl: "/ckeditor/pictures?",
    filebrowserUploadUrl: "/ckeditor/attachment_files",
    allowedContent: true,
    filebrowserUploadMethod: "form",
    toolbar: [
      { items: ["Maximize"] },
      { name: "document", groups: ["mode", "document", "doctools"], items: ["Source"] },
      { name: "clipboard", groups: ["clipboard", "undo"], items: ["Cut", "Copy", "Paste", "PasteText", "PasteFromWord", "-", "Undo", "Redo"] },
      { name: "editing", groups: ["find", "selection", "spellchecker"], items: ["Find", "Replace", "-", "SelectAll", "-", "Scayt"] },
      { name: "links", items: ["Link", "Unlink", "Anchor"] },
      { name: "insert", items: ["Image", "Video", "Table", "HorizontalRule", "SpecialChar"] },
      { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi"], items: ["NumberedList", "BulletedList", "-", "Outdent", "Indent", "-", "Blockquote", "CreateDiv", "-", "JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"] },
      { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
      { name: "colors", items: ["TextColor", "BGColor"] },
      { name: "basicstyles", groups: ["basicstyles", "cleanup"], items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript", "-", "RemoveFormat"] }
    ],
    contentsCss: "/ckeditor/fonts/font_faces.css",
    font_names: "Walkway Bold/walkway_boldregular; Walkway Black/walkway_blackregular; Lucky Turns/lucky_turns; Arial/Arial, Helvetica, sans-serif;Comic Sans MS/Comic Sans MS, cursive;Courier New/Courier New, Courier, monospace;Georgia/Georgia, serif;Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;Tahoma/Tahoma, Geneva, sans-serif;Times New Roman/Times New Roman, Times, serif;Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;Verdana/Verdana, Geneva, sans-serif",
  })
  editor.on("change", () => { editor.updateElement() })
  CKEditor.on("instanceReady", ev => {
    ev.editor.dataProcessor.writer.setRules("li", {
      indent: false,
      breakBeforeOpen: false,
      breakAfterOpen: false,
      breakBeforeClose: false,
      breakAfterClose: false
    })
    ev.editor.dataProcessor.writer.setRules("p", {
      indent: false,
      breakBeforeOpen: false,
      breakAfterOpen: false,
      breakBeforeClose: false,
      breakAfterClose: false
    })
    ev.editor.dataProcessor.writer.setRules("ol", {
      indent: false,
      breakBeforeOpen: false,
      breakAfterOpen: false,
      breakBeforeClose: false,
      breakAfterClose: false
    })
    ev.editor.dataProcessor.writer.setRules("ul", {
      indent: false,
      breakBeforeOpen: false,
      breakAfterOpen: false,
      breakBeforeClose: false,
      breakAfterClose: false
    })
    ev.editor.dataProcessor.writer.setRules("h2", {
      indent: false,
      breakBeforeOpen: false,
      breakAfterOpen: false,
      breakBeforeClose: false,
      breakAfterClose: false
    })
    ev.editor.dataProcessor.writer.setRules("h3", {
      indent: false,
      breakBeforeOpen: false,
      breakAfterOpen: false,
      breakBeforeClose: false,
      breakAfterClose: false
    })
  })
}

export default loadCKEditor