/* eslint-disable react/no-array-index-key */
import React from "react"
import { useSelector } from "react-redux"

import LoadingBar from "components/loadingBar"
import GalleryItem from "./GalleryItem"

const Gallery = () => {
  const { data: { filterDataArray }, dataFetching } = useSelector(state => state)
  const dataLength = filterDataArray.length
  return (
    <div className="col">
      <LoadingBar isLoading={dataFetching} />
      <div className="small-text text-secondary mb-3">{`Viewing ${dataLength} items`}</div>
      <div className="row no-gutters">
        {filterDataArray.map((record, idx) => <GalleryItem key={`gallery-item-${record.name}-${idx}`} record={record} />)}
      </div>
    </div>
  )
}

export default Gallery