import BaseController from "controllers/base"
// import { tns } from "tiny-slider/src/tiny-slider"

import startNewsApp from "apps/NewsList"

class Controller extends BaseController {
  call() {
    super.call()

    // bindScrollTop.call(this)
    // bindScrollTopHomeLand.call(this)

    // tinySlider.call(this)
    // tinySliderSeries.call(this)

    startNewsApp(
      document.getElementById("news-list"),
      {}
    )
  }
}

// Sticks the navbar filters at the top
// This is only for the Home-Designs page
//
// let bindScrollTop = function () {
//   if (!window.location.href.includes("/home-designs_old")) return

//   window.onscroll = function (e) {
//     const topHeight = (document.getElementsByClassName("filter")[0].offsetHeight + document.getElementsByClassName("main-header")[0].offsetHeight + document.getElementsByClassName("page-header")[0].offsetHeight)

//     if (this.scrollY >= topHeight) {
//       $(".sticky-filter").addClass("active")
//     } else {
//       $(".sticky-filter").removeClass("active")
//     }
//   }

//   $(document).on("click", ".sticky-filter .dropdown-menu", e => {
//     e.stopPropagation()
//   })
// }

// let bindScrollTopHomeLand = function () {
//   if (!window.location.href.includes("/pages/home-and-land")) return

//   window.onscroll = function (e) {
//     const topHeight = (document.getElementsByClassName("filter")[0].offsetHeight + document.getElementsByClassName("main-header")[0].offsetHeight + document.getElementsByClassName("page-header")[0].offsetHeight)
//     if (this.scrollY >= topHeight) {
//       $(".sticky-filter").addClass("active")
//     } else {
//       $(".sticky-filter").removeClass("active")
//     }
//   }

//   $(document).on("click", ".sticky-filter .dropdown-menu", e => {
//     e.stopPropagation()
//   })
// }

// let tinySlider = function () {
//   if (!window.location.href.includes("/pages/home-designs/compare")) return

//   const slider = tns({
//     container: ".my-slider",
//     items: 1,
//     loop: false,
//     gutter: 20,
//     fixedWidth: 300,
//     mouseDrag: true,
//     responsive: {
//       576: {
//         fixedWidth: 300
//       },
//       992: {
//         items: 3,
//         fixedWidth: false
//       }
//     }
//   })
// }

// let tinySliderSeries = function () {
//   if (!window.location.href.includes("/pages/home-designs")) return

//   const slider = tns({
//     container: ".series-table",
//     items: 1.5,
//     loop: false,
//     fixedWidth: 240,
//     mouseDrag: true,
//     gutter: 20,
//     controls: false,
//     navPosition: "bottom",
//     responsive: {
//       576: {

//       },
//       992: {
//         disable: true
//       }
//     }
//   })
// }

export default Controller
