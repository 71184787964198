import {
  getList, setList,
  FAVOURITE_PLANS, FAVOURITE_LANDS, FAVOURITE_HOMES, FAVOURITE_LAND_FOR_SALE
} from "servos/localStorage/storedList"
import { TOGGLE_FAVOURITE, REMOVE_FAVOURITE } from "./constants"

export default function (state = {
  [FAVOURITE_PLANS]: getList(FAVOURITE_PLANS),
  [FAVOURITE_LANDS]: getList(FAVOURITE_LANDS),
  [FAVOURITE_HOMES]: getList(FAVOURITE_HOMES),
  [FAVOURITE_LAND_FOR_SALE]: getList(FAVOURITE_LAND_FOR_SALE)
}, action) {
  switch (action.type) {
    case TOGGLE_FAVOURITE: {
      const { listName, planId } = action.payload
      const planIdInt = parseInt(planId, 10)
      const favouriteListTemp = Object.assign({}, state)[listName]
      const index = favouriteListTemp.indexOf(planIdInt)
      if (index > -1) {
        favouriteListTemp.splice(index, 1)
      } else {
        favouriteListTemp.push(planIdInt)
      }
      // update cookie
      setList(listName, favouriteListTemp)
      return { ...state, [listName]: favouriteListTemp }
    }
    case REMOVE_FAVOURITE: {
      const { listName, planId } = action.payload
      const planIdInt = parseInt(planId, 10)
      const favouriteListTemp = Object.assign({}, state)[listName]
      const index = favouriteListTemp.indexOf(planIdInt)
      if (index > -1) {
        favouriteListTemp.splice(index, 1)
      }
      setList(listName, favouriteListTemp)
      return { ...state, [listName]: favouriteListTemp }
    }
    default:
      return state
  }
}