/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from "react"
import { connect } from "react-redux"

import HomeTypeFilter from "components/filters/HomeTypeFilter"
import SpecificationsFilter from "components/filters/SpecificationsFilter"
import PriceRangeFilter from "components/filters/PriceRangeFilter"
import DisplayByFilter from "components/filters/DisplayByFilter"

import * as actions from "../../actions"
import * as reducers from "../../reducers"

class StickyBasicFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  static getDerivedStateFromProps(props, state) {
    // If state is empty - First time load
    // Or original filters has changed from the store
    if (_.isEmpty(state) || !_.isEqual(state.originalFilters, props.filters)) {
      return { ...state, originalFilters: props.filters, filters: props.filters }
    }
    return state
  }

  handleClearFilters = e => {
    e.preventDefault()

    const { onClearFilters } = this.props
    const { originalFilters } = this.state

    // Clear main filters
    onClearFilters()

    // Set state from props
    this.setState({ filters: originalFilters })
  }

  handleSaveFilters = () => {
    const { onSaveFilters } = this.props
    const { filters } = this.state

    // These filters are not automatically set in the store
    // so we need to update the local state before sending them back
    //
    onSaveFilters(filters)
  }

  setHomeTypes = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, ...value } })
  }

  handleClearHomeType = () => {
    const { filters, originalFilters } = this.state
    this.setState({ filters: { ...filters, by_home_types: originalFilters.by_home_types } })
  }

  renderHomeTypeFilter = () => {
    const { filters: { by_home_types } } = this.state

    return (
      <div className="dropdown mr-2 mt-2 mt-lg-0">
        <button
          aria-expanded="false"
          aria-haspopup="true"
          className="btn btn-white dropdown-toggle"
          data-toggle="dropdown"
          id="dropdownMenuHomeType"
          type="button">
          Home Type
        </button>

        <div
          aria-labelledby="dropdownMenuHomeType"
          className="dropdown-menu">
          <HomeTypeFilter
            selected_options={by_home_types}
            dataChanged={value => this.setHomeTypes(value)} />
          <div className="dropdown-footer d-flex justify-content-between mt-3">
            <div className="btn btn-cancel" onClick={this.handleClearHomeType}>Cancel</div>
            <div className="btn btn-apply" onClick={this.handleSaveFilters}>Apply</div>
          </div>
        </div>
      </div>
    )
  }

  setSpecifications = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, ...value } })
  }

  handleClearSpecifications = () => {
    const { filters, originalFilters } = this.state

    this.setState({
      filters: {
        ...filters,
        by_bedroom_count: originalFilters.by_bedroom_count,
        by_bathroom_count: originalFilters.by_bathroom_count,
        by_garage_count: originalFilters.by_garage_count
      }
    })
  }

  renderSpecifications = () => {
    const { filters: { by_bedroom_count, by_bathroom_count, by_garage_count } } = this.state

    return (
      <div className="dropdown mr-2 mt-2 mt-lg-0">
        <button
          aria-expanded="false"
          aria-haspopup="true"
          className="btn btn-white dropdown-toggle"
          data-toggle="dropdown"
          id="dropdownMenuPrice"
          type="button">
          Bed, Bath, Garage
        </button>
        <div aria-labelledby="dropdownMenuPrice" className="dropdown-menu">
          <div className="col-lg-12 col-md-12 filter-col filter-basic">
            <SpecificationsFilter
              by_bedroom_count={by_bedroom_count}
              by_bathroom_count={by_bathroom_count}
              by_garage_count={by_garage_count}
              dataChanged={value => this.setSpecifications(value)} />
          </div>
          <div className="dropdown-footer d-flex justify-content-between mt-3">
            <div className="btn btn-cancel" onClick={this.handleClearSpecifications}>Cancel</div>
            <div className="btn btn-apply" onClick={this.handleSaveFilters}>Apply</div>
          </div>
        </div>
      </div>
    )
  }

  setPricerange = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, ...value } })
  }

  handleClearPriceRange = () => {
    const { filters, originalFilters } = this.state
    this.setState({ filters: { ...filters, by_price_range: originalFilters.by_price_range } })
  }

  renderPrice = () => {
    const { filters: { by_price_range } } = this.state

    return (
      <div className="dropdown mr-2 mt-2 mt-lg-0 price-range">
        <button
          aria-expanded="false"
          aria-haspopup="true"
          className="btn btn-white dropdown-toggle"
          data-toggle="dropdown"
          id="dropdownMenuPrice"
          type="button">
          Price Range
        </button>
        <div aria-labelledby="dropdownMenuPrice" className="dropdown-menu">
          <PriceRangeFilter
            price_range={by_price_range}
            dataChanged={value => this.setPricerange(value)} />
          <div className="dash">-</div>
          <div className="dropdown-footer d-flex justify-content-between mt-3">
            <div className="btn btn-cancel" onClick={this.handleClearPriceRange}>Cancel</div>
            <div className="btn btn-apply" onClick={this.handleSaveFilters}>Apply</div>
          </div>
        </div>
      </div>
    )
  }

  renderMoreFilterButton = () => {
    const { openAdvancedFilters } = this.props

    return (
      <a
        href="!#"
        onClick={openAdvancedFilters}
        className="small-text text-link mt-2 mt-lg-0">
        Advanced Filters
      </a>
    )
  }

  renderDisplayType = () => {
    const { filters: { view_by } } = this.state
    const { setDisplayType } = this.props

    return (
      <div className="display-by btn-group btn-group-toggle mt-2 mt-lg-0 pl-0" data-toggle="buttons">
        <DisplayByFilter
          showLabel={false}
          display_type={view_by}
          dataChanged={value => setDisplayType(value)} />
      </div>
    )
  }

  render() {
    return (
      <div className="sticky-filter fixed-top p-1">
        <nav className="navbar navbar-expand-lg">
          <button
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="navbar-toggler p-0"
            data-target="#navbarSupportedContent"
            data-toggle="collapse"
            type="button">
            Filters
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            { this.renderHomeTypeFilter() }
            { this.renderSpecifications() }
            { this.renderPrice() }
            { this.renderMoreFilterButton() }
          </div>
          { this.renderDisplayType() }
        </nav>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  filters: reducers.getFilters(state)
})

export default connect(mapStateToProps, actions)(StickyBasicFilter)