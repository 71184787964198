import { FETCH_REQUESTED, FETCH_SUCCESS, FETCH_FAILED } from "../reducers/displayHomes"

export const getData = () => ({
  type: FETCH_REQUESTED
})

export const fetchDataSuccess = (response, filters) => ({
  type: FETCH_SUCCESS,
  payload: { response, filters }
})

export const fetchDataFailed = error => ({
  type: FETCH_FAILED,
  payload: error
})