import $ from "jquery"

const addDatePicker = function () {
  $("[data-toggle='datepicker-default']").datepicker({
    autoclose: true,
    immediateUpdates: true,
    format: "dd MM yyyy",
    startDate: "01/01/2000",
    todayBtn: true,
    todayHighlight: true
  })

  $("[data-toggle='datepicker-default']").on("changeDate", function () {
    $("#realDate").val(moment($(this).val(), "DD MMMM YYYY").format("YYYY-MM-DD"))
  })
}

export default addDatePicker