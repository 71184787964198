import React from "react"
// import { useDispatch } from "react-redux"

// import { setShowFacadeModal } from "../../actions"

export default function Price({ price }) {
  // const dispatch = useDispatch()
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between">
        <span>
          {`Base Price ${price}*`}
        </span>
        {/* <div className="text-right">
          <button
            type="button"
            className="text-link text-secondary small-text"
            onClick={() => { dispatch(setShowFacadeModal(true)) }}
            style={{
              background: "none",
              border: "none"
            }}>
            See details
          </button>
        </div> */}

      </div>
    </React.Fragment>
  )
}