import startDisplayHomeCard from "apps/DisplayHomeCard"
import BaseController from "controllers/base"

class Controller extends BaseController {
  call() {
    super.call()
    initMaps.call(this)
    startDisplayHomeCard(
      document.getElementById("display-homes"),
      {}
    )
  }
}

const initMaps = function () {
  const { location } = gon

  const map = new google.maps.Map(
    document.getElementById("map-container-google-displayhome"), {
      zoom: 14,
      center: location
    }
  )

  const marker = new google.maps.Marker({
    position: location,
    map,
    icon: ("/fallback/pin-selected.svg")
  })
}

export default Controller