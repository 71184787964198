import {
  all, fork, put, call, takeLatest, select
} from "redux-saga/effects"

import * as requests from "./requesters"
import * as actions from "./actions"
import * as constants from "./constants"

// Request Functions
function* loadPopularDesigns(baseUrl) {
  const { response, error } = yield call(requests.getPopularDesigns, baseUrl)

  if (response) {
    yield put(actions.fetchDataSuccess(response))
  } else {
    yield put(actions.fetchDataFailed(error))
  }
}

// Actions
function* startLoadingData() {
  const apiUrl = yield select(state => state.config.popular_designs_url)
  yield all([
    fork(loadPopularDesigns, apiUrl),
  ])
}

// Watchers
function* watchLoadPopularDesigns() {
  yield takeLatest(constants.FETCH_START, startLoadingData)
}

// Default Export
export default function* RootSaga() {
  yield all([
    fork(watchLoadPopularDesigns)
  ])
}