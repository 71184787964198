import * as constants from "../../constants"

const byIds = (state = {}, action) => {
  switch (action.type) {
    case constants.FETCH_SUCCESS: {
      const { meta: { category, ordering } } = action.payload
      const existingIds = state[category] || []
      const allIds = [...existingIds, ...ordering]
      return { ...state, [category]: allIds }
    }
    default:
      return state
  }
}

export default byIds