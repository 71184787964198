import React from "react"
import {
  Container, Row, Col, Button
} from "react-bootstrap"
import { ACTIONS, useDispatch } from "../configurator-context-provider"
import swirl from "../images/BWH-Swirl-30.png"

const Error = ({ message }) => {
  const dispatch = useDispatch()

  return (
    <Container fluid="sm">
      <Row className="justify-content-center">
        <Col xs="auto">
          <img src={swirl} className="configurator-img bwh-swirl top-offset" alt="Loading Icon" />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="auto">
          <h1>{message}</h1>
          <p>Please click the button below to try again.</p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="auto">
          <Button
            variant="outline-primary"
            onClick={dispatch({
              type: ACTIONS.SUBMIT_CONFIGURATION,
              payload: {
                submission: {
                  loading: false,
                  processed: false,
                  error: ""
                }
              }
            })}>
            Try Again
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default Error
