import * as constants from "./constants"

export const getData = () => ({
  type: constants.FETCH_REQUESTED
})

export const fetchDataSuccess = payload => ({
  type: constants.FETCH_SUCCESS,
  payload
})

export const fetchDataFailed = error => ({
  type: constants.FETCH_FAILED,
  payload: error
})

export const filterUpdateNoFetch = payload => ({
  type: constants.FILTER_UPDATE_NO_FETCH,
  payload
})

export const objectSorted = payload => ({
  type: constants.OBJECT_SORTED,
  payload
})
