import React, {
  Fragment, useState, useEffect, useRef
} from "react"

import MainFilters from "./MainFilters"
import StickyFilters from "./StickyFilters"
import ModalFilters from "./ModalFilters"

export default function Filters() {
  const [stickyFilterShow, setStickyFilterShow] = useState(false)
  const mainFilterRef = useRef(null)

  useEffect(() => {
    const onScroll = () => {
      if (mainFilterRef.current) {
        if (window.scrollY > (mainFilterRef.current.offsetTop + mainFilterRef.current.offsetHeight)) {
          setStickyFilterShow(true)
        } else {
          setStickyFilterShow(false)
        }
      }
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])
  return (
    <Fragment>
      <div ref={mainFilterRef}>
        <MainFilters />
      </div>
      {stickyFilterShow && <StickyFilters />}
      <ModalFilters />
    </Fragment>
  )
}