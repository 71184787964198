import React from "react"
import { Modal } from "react-bootstrap"

import { IconClose } from "images/icons"
import HomeDetails from "../homeDetails"

export default ({ show, onHide, homeDesign }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    centered>
    <Modal.Body>
      <HomeDetails homeDesign={homeDesign} showAsCard />
      <IconClose
        onClick={onHide}
        style={{
          cursor: "pointer",
          position: "absolute",
          top: "20px",
          right: "20px"
        }} />
    </Modal.Body>
  </Modal>
)