import * as constants from "../../constants"

const totalCount = (state = { }, action) => {
  switch (action.type) {
    case constants.FETCH_SUCCESS: {
      const { meta: { category, total_records } } = action.payload
      return { ...state, [category]: total_records }
    }
    default:
      return state
  }
}

export default totalCount