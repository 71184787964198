import React, { Component } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"

import CompareBar from "components/common/CompareBar"
import Title from "components/common/PageTitle"
import TabSwitch from "./components/TabSwitch"
import HomeDesigns from "./components/HomeDesigns"
import HomeLand from "./components/HomeLand"
import LandForSale from "./components/LandForSale"
import DisplayHomes from "./components/DisplayHomes"

class HomeDesignRoot extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Router>
        <div>
          <Title title="Favourites" />
          <TabSwitch />
          <Switch>
            <Route path="/favourites/home-designs">
              <HomeDesigns />
              <CompareBar />
            </Route>
            <Route path="/favourites/home-and-land">
              <HomeLand />
            </Route>
            <Route path="/favourites/display-homes">
              <DisplayHomes />
            </Route>
            <Route path="/favourites/land-for-sale">
              <LandForSale />
            </Route>
            <Route path="/favourites/">
              <Redirect push to="/favourites/home-designs" />
            </Route>
          </Switch>
        </div>
      </Router>
    )
  }
}

export default HomeDesignRoot