/* eslint-disable react/no-danger */
import React, { useState } from "react"

import { IconEye, IconCloseWht } from "images/icons"
import GalleryModal from "components/common/homeDetails/onDisplay/GalleryModal"

const styles = {
  infoBox: {
    display: "block",
    position: "absolute",
    height: "480px",
    maxWidth: "350px",
    top: "10px",
    right: "10px",
    backgroundColor: "rgba(35,35,35,0.85)",
    color: "white",
    textAlign: "start",
    padding: "40px 30px"
  },
  galleryIcon: {
    flexBasis: "48%",
    marginRight: "15px"
  },
  threeDIcon: {
    flex: "1 1 48%",
    textDecoration: "none"
  },
  infoExtraItem: (imageUrl = "/fallback/placeholder-house.svg") => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "26px",
    color: "white",
  }),
  infoExtras: {
    position: "absolute",
    bottom: "0",
    left: "0",
    height: "140px",
    width: "100%",
    padding: "0 30px 40px 30px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    alignContent: "stretch"
  },
  virtualImageWord: {
    fontSize: "27px",
    marginLeft: "3px"
  },
  closeBtn: {
    cursor: "pointer",
    position: "absolute",
    top: "10px",
    right: "10px"
  }
}

export default function InfoBox({ locations, selected, closeInfo }) {
  const [showGalleryModal, setShowGalleryModal] = useState(false)

  if (selected >= 0) {
    const {
      homeName, homeImages,
      homeVirtualLinkPreview,
      homeVirtualLink,
      location: { data: { attributes: locationAttr } }
    } = locations[selected]
    const {
      name, businessHours, phone,
      address, suburb, detailUrl, googleMapDirectionUrl
    } = locationAttr

    return (
      <div style={styles.infoBox}>
        <IconCloseWht
          width="20"
          height="20"
          style={styles.closeBtn}
          onClick={() => closeInfo()} />
        <h2 style={{ fontSize: "1.2em" }}>
          {name}
        </h2>
        <div style={{ marginTop: "25px" }}>{`${address}, ${suburb}`}</div>
        <div className="telphone-link" dangerouslySetInnerHTML={{ __html: phone }} />
        <div style={{ marginTop: "25px" }} dangerouslySetInnerHTML={{ __html: businessHours }} />
        <div style={{ marginTop: "25px" }}>
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href={detailUrl}>
            Learn More
          </a>
          <a
            style={{ marginLeft: "20px", color: "white", textDecoration: "underline" }}
            target="_blank"
            rel="noopener noreferrer"
            href={googleMapDirectionUrl}>
            Get Directions
          </a>
        </div>
        <div style={styles.infoExtras}>
          {(homeImages.length > 0) && (
            <>
              <button
                type="button"
                onClick={() => { setShowGalleryModal(true) }}
                style={{ display: "contents" }}>
                <div style={{ ...styles.infoExtraItem((homeImages[0])), ...styles.galleryIcon }}>
                  Gallery
                </div>
              </button>
              <GalleryModal
                show={showGalleryModal}
                onHide={() => { setShowGalleryModal(false) }}
                homeName={homeName}
                urls={homeImages} />
            </>
          )}
          {homeVirtualLink && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={homeVirtualLink}
              style={styles.threeDIcon}>
              <div style={styles.infoExtraItem(homeVirtualLinkPreview)}>
                <div>
                  <IconEye width="32" height="20" style={{ verticalAlign: "baseline" }} />
                  <span style={styles.virtualImageWord}>
                    3D
                  </span>
                </div>
              </div>
            </a>
          )}
        </div>
      </div>
    )
  }
  return ""
}