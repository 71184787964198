import React from "react"
import BuildLocation from "./BuildLocation"
import DesignCardFilters from "./DesignCardFilters"
import DesignCardList from "./DesignCardList"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.DesignCardListView = false

const DesignCardListView = () => {
  // if (window.configurator.debugPoints.DesignCardListView) {
  //   debugger
  // }

  return (
    <div className="home-deisgns">
      <div className="page-header text-center">
        <h1>Select Build Location and Home Design</h1>
      </div>
      <BuildLocation />
      <DesignCardFilters />
      <DesignCardList />
    </div>
  )
}

export default DesignCardListView
