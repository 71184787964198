import React from "react"
import { useSelector } from "react-redux"

import priceFormatter from "helpers/priceFormatter"
import LandForSaleIntro from "./LandForSaleIntro"

export default function LandForSaleHero() {
  const { landForSale } = useSelector(state => state)
  const {
    attributes: {
      price, suburb, address1, pdf,
      mapimageUrl,
      blockWidth, blockDepth, landarea,
      status, developmentLogo
    }
  } = landForSale

  let devLogoRendered = false
  const priceString = priceFormatter({ price })
  const enoughRoom = () => [blockDepth, blockWidth, landarea].filter(el => ![null, undefined].includes(el)).length <= 2

  const renderLogoInHero = () => {
    const room = enoughRoom()
    if (room) {
      devLogoRendered = true
    }
    return room
  }

  return (
    <>
      <div className="home-design-hero container-fluid mb-md-4">
        <div className="row">
          <div className="col-lg-8">
            <div className="carousel" id="home-design-hero">
              <div className="carousel-inner">
                <div
                  className="carousel-item active w-100"
                  style={{
                    background: `url(${mapimageUrl}) no-repeat center`,
                    backgroundSize: "cover"
                  }}>
                </div>
                <div className="card-badges container-fluid d-flex justify-content-end p-0">
                  <div className="display-home-for-sale-overlay">
                    <span className="for-sale">{status}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 pl-lg-0">
            <div className="bg-light home-land-summary d-flex">
              <div className="text-center align-self-center mx-auto">
                {price && (
                  <div className="price">
                    {`*${priceString}`}
                  </div>
                )}
                <h3 className="mb-4">
                  {address1}
                  <br />
                  {suburb}
                </h3>

                <div className="home-info mt-5 text-left">
                  <table className="table table-sm table-borderless small-text table-list">
                    <tbody>
                      { blockWidth
                        ? (
                          <tr>
                            <td>Land Width</td>
                            <td>{`${blockWidth} m`}</td>
                          </tr>
                        ) : null }
                      { blockDepth
                        ? (
                          <tr>
                            <td>Land Length</td>
                            <td>{`${blockDepth} m`}</td>
                          </tr>
                        ) : null }
                      { landarea
                        ? (
                          <tr>
                            <td>Total Area</td>
                            <td>
                              {`${landarea} m`}
                              <sup>2</sup>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td>Total Area</td>
                            <td>
                              {blockDepth && blockWidth ? `${blockDepth * blockWidth} m` : "m"}
                              <sup>2</sup>
                            </td>
                          </tr>
                        ) }
                    </tbody>
                  </table>
                </div>
                {pdf && pdf.url && (
                  <a
                    className="btn btn-dark mb-4 mb-md-0 mt-5 btn-lg"
                    href={pdf.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    Download Brochure
                  </a>
                )}
                {renderLogoInHero() ? (
                  <div className="row justify-content-center">
                    <div className="col-5 col-md-6">
                      <img className="my-md-4 my-2 img-fluid" src={developmentLogo.url} />
                    </div>
                  </div>
                ) : <></>}

              </div>
            </div>
          </div>
        </div>
      </div>
      <LandForSaleIntro devLogoRendered={devLogoRendered} />
    </>
  )
}