/* eslint-disable no-undef */
import BaseController from "controllers/base"
import $ from "jquery"

class Controller extends BaseController {
  call() {
    super.call()

    this.selectedIcon = "/fallback/pin-selected.svg"
    this.locations = gon.locations.data
    this.markers = []
    this.activeLocation = null
    this.activeRegion = "all"

    this.initMap()
    this.handleSelectRegionTab()
    this.handleSelectLocationTile()
  }

  initMap() {
    this.map = new google.maps.Map(
      document.getElementById("map-container-google-2"), {
        zoom: 20,
        center: { lat: -33.7127957, lng: 150.9545788 }
      }
    )
    this.locations.forEach(location => {
      const {
        attributes: {
          Marker: { lat, lng },
          WebsiteNameParams,
          RegionOrComingSoon,
          MarkerIcon
        }
      } = location
      if (lat && lng) {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(lat, lng),
          map: this.map,
          title: WebsiteNameParams,
          animation: google.maps.Animation.DROP,
          region: RegionOrComingSoon,
          defaultIcon: MarkerIcon
        })
        marker.addListener("click", () => {
          if (WebsiteNameParams !== this.activeLocation) {
            this.activeLocation = WebsiteNameParams
            this.activeRegion = RegionOrComingSoon
            this.updateBounds()
            this.updateMarkerIcon()
            $(".location-tile.active").removeClass("active")
            const $tab = $(`.tab-pane#${RegionOrComingSoon}`)
            const $activeTile = $tab.find(`.location-tile[location="${WebsiteNameParams}"]`)
            $activeTile.addClass("active")
            $(`a.nav-link[href="#${RegionOrComingSoon}"]`).tab("show")
            this.scrollTileToTop($tab, $activeTile, $tab.find(".content"))
          }
        })
        this.markers.push(marker)
      }
    })
    this.updateBounds()
    this.updateMarkerIcon()
  }

  updateMarkerIcon() {
    this.markers.forEach(marker => {
      if (marker.title === this.activeLocation) {
        marker.setIcon(this.selectedIcon)
      } else {
        marker.setIcon(marker.defaultIcon)
      }
    })
  }

  updateBounds() {
    const tmpMarkers = []
    const setBoundsIfValid = (marker, bounds) => {
      if (marker.getPosition().lat() < 0) {
        bounds.extend(marker.getPosition())
      }
    }
    const bounds = new google.maps.LatLngBounds()
    if (this.activeLocation) {
      this.markers.forEach(marker => {
        if (marker.title === this.activeLocation) {
          setBoundsIfValid(marker, bounds)
          tmpMarkers.push(marker)
        }
      })
      this.map.fitBounds(bounds)
      this.map.setZoom(15)
    } else {
      this.markers.forEach(marker => {
        if ((marker.region === this.activeRegion) || (this.activeRegion === "all")) {
          setBoundsIfValid(marker, bounds)
          tmpMarkers.push(marker)
        }
      })
      this.map.fitBounds(bounds)
      // this.drop(tmpMarkers)
    }
  }

  drop(markers) {
    markers.forEach((marker, index) => {
      marker.setMap(null)
      window.setTimeout(() => {
        marker.setMap(this.map)
        marker.setAnimation(4)
      }, index * 200)
    })
  }

  handleSelectRegionTab() {
    const url = window.location.href.split("#")[0]

    $("a.nav-link[data-toggle='tab'").on("click", e => {
      const selectedRegion = $(e.currentTarget).attr("region")
      const newUrl = `${url}#${selectedRegion}`
      history.replaceState(null, null, newUrl)
      this.activeRegion = selectedRegion
      $(".location-tile.active").removeClass("active")
      this.activeLocation = null
      this.updateBounds()
      this.updateMarkerIcon()
    })

    window.onhashchange = function () {
      const location = window.location.hash.split("#")[1]
      $(`.nav-link[region=${location}]`).click()
    }

    if (window.location.hash !== "") {
      const location = window.location.hash.split("#")[1]
      $(`.nav-link[region=${location}]`).click()
    }
  }

  handleSelectLocationTile() {
    $(".location-tile").on("click", e => {
      const $clickedTile = $(e.currentTarget)
      if (!$clickedTile.hasClass("active")) {
        $(".location-tile.active").removeClass("active")
        $clickedTile.addClass("active")
        this.activeLocation = $clickedTile.attr("location")
        this.activeRegion = $clickedTile.attr("region")
        this.updateBounds()
        this.updateMarkerIcon()
      }
    })
  }

  scrollTileToTop($targetTab, $targetTile, $targetScrollEl, offset = 16) {
    const elementTop = $targetTile.offset().top
    const divTop = $targetTab.offset().top + offset
    const relTop = elementTop - divTop
    if ($targetScrollEl) {
      $targetScrollEl[0].scrollTo({
        top: relTop,
        behavior: "smooth"
      })
    } else {
      $targetTab[0].scroll({
        top: relTop,
        behavior: "smooth"
      })
    }
  }
}


export default Controller
