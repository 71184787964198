import React from "react"
import {
  IconNew,
  IconAward,
  IconPromotion,
  IconDisplayPin,
  Icon3DEye,
  IconDisplaySm,
  Icon3DSm,
} from "images/icons"

// NORMAL BADGE
export const NewBadge = ({ display }) => {
  if (display) {
    return <IconNew className="icon-badge" height="36" width="36" />
  }
  return ""
}

export const AwardBadge = ({ display }) => {
  if (display) {
    return <IconAward className="icon-badge" height="36" width="36" />
  }
  return ""
}

export const OnPromotionBadge = ({ display }) => {
  if (display) {
    return <IconPromotion className="icon-badge" height="36" width="36" />
  }
  return ""
}

export const DisplayPin = ({ display }) => {
  if (display) {
    return (
      <li className="list-inline-item">
        <IconDisplayPin className="icon-badge" />
      </li>
    )
  }
  return ""
}

export const ThreeD = ({ display }) => {
  if (display) {
    return (
      <li className="list-inline-item icon-badge">
        <Icon3DEye />
      </li>
    )
  }
  return ""
}

// SMALL BADGES
export const NewSmall = props => {
  const { display } = props
  if (display) {
    return (
      <li {...props} className="list-inline-item icon-badge">
        <IconNew width="26" height="26" />
      </li>
    )
  }
  return ""
}

export const AwardSmall = props => {
  const { display } = props
  if (display) {
    return (
      <li {...props} className="list-inline-item icon-badge">
        <IconAward height="26" width="26" />
      </li>
    )
  }
  return ""
}

export const PromotionSmall = props => {
  const { display } = props
  if (display) {
    return (
      <li {...props} className="list-inline-item icon-badge">
        <IconPromotion height="26" width="26" />
      </li>
    )
  }
  return ""
}


export const DisplaySmall = props => {
  const { display } = props
  if (display) {
    return (
      <li {...props} className="list-inline-item icon-badge">
        <IconDisplaySm height="26" width="26" />
      </li>
    )
  }
  return ""
}

export const ThreeDSmall = props => {
  const { display } = props
  if (display) {
    return (
      <li {...props} className="list-inline-item icon-badge">
        <Icon3DSm height="26" width="26" />
      </li>
    )
  }
  return ""
}

export const TitleBadgesSm = ({ homeDesign }) => {
  const {
    attributes: {
      onDisplay,
      newDesign,
      virtualTour,
      awardWinner,
      onPromotion
    }
  } = homeDesign
  const smBadgeStyle = { marginRight: "0", marginLeft: "0.2rem" }
  return (
    <div>
      <NewSmall display={newDesign} style={{ marginRight: "0", marginLeft: "0" }} />
      <AwardSmall display={awardWinner} style={smBadgeStyle} />
      <DisplaySmall display={onDisplay} style={smBadgeStyle} />
      <ThreeDSmall display={virtualTour} style={smBadgeStyle} />
      <PromotionSmall display={onPromotion} style={smBadgeStyle} />
    </div>

  )
}

export const SeriesTableBadges = ({ homeDesign }) => {
  const {
    attributes: {
      onDisplay,
      newDesign,
      virtualTour,
      awardWinner,
      onPromotion
    }
  } = homeDesign
  return (
    <ul className="list-inline badges">
      <li className="list-inline-item icon-badge">
        {newDesign && <IconNew height="26" width="26" />}
      </li>
      <li className="list-inline-item icon-badge">
        {awardWinner && <IconAward height="26" width="26" />}
      </li>
      <li className="list-inline-item icon-badge">
        {onDisplay && <IconDisplaySm height="26" width="26" />}
      </li>
      <li className="list-inline-item icon-badge">
        {virtualTour && <Icon3DSm height="26" width="26" />}
      </li>
      <li className="list-inline-item icon-badge">
        {onPromotion && <IconPromotion height="26" width="26" />}
      </li>
    </ul>
  )
}
