import Util from "../Util/configurator-util"
import ConfigOption from "./configurator-option"

function OptionGroup(ctx) {
  this.name = ""
  this.sort = 999999999
  this.options = []

  if (!ctx) {
    return
  }

  this.setName(ctx.name || "")
  this.setSort(ctx.sort || 999999999)
  if (ctx.options) {
    Util.each(ctx.options, option => {
      this.addOption(new ConfigOption(option))
    })
  }
}

/**
 * Get the Name
 * @returns {String}
 */
OptionGroup.prototype.getName = function () { return this.name }
/**
 * Set the Name
 * @param {String} name The Name
 */
OptionGroup.prototype.setName = function (name) {
  if (!Util.isString(name)) {
    throw new TypeError("Invalid parameter name: must be String")
  }

  this.name = name
}

/**
 * Get the Sort
 * @returns {Number}
 */
OptionGroup.prototype.getSort = function () { return this.sort }
/**
 * Set the Sort
 * @param {Number} sort The Sort
 */
OptionGroup.prototype.setSort = function (sort) {
  if (!Util.isNumber(sort)) {
    throw new TypeError("Invalid parameter sort: must be Number")
  }

  this.sort = sort
}

/**
 * Get the Options
 * @returns {ConfigOption[]}
 */
OptionGroup.prototype.getOptions = function () { return this.options.slice() }
/**
 * Get a specific ConfigOption by Item Internal ID
 * @param {String} itemInternalID The internal ID of the item the option represents
 * @returns {ConfigOption}
 */
OptionGroup.prototype.getOptionByItemID = function (itemInternalID) {
  let toReturn

  if (!Util.isString(itemInternalID)) {
    throw new TypeError("Invalid parameter itemInternalID: must be String")
  }

  toReturn = null
  Util.each(this.options, option => {
    if (toReturn === null && option.getItemInternalID() === itemInternalID) {
      toReturn = option
    }
  })

  return toReturn
}
/**
 * Get a specific ConfigOption by Assigned ID
 * @returns {ConfigOption}
 * @param assignedID
 */
OptionGroup.prototype.getOptionByAssignedID = function (assignedID) {
  let toReturn

  if (!Util.isString(assignedID)) {
    throw new TypeError("Invalid parameter assignedID: must be String")
  }

  toReturn = null
  Util.each(this.options, option => {
    if (toReturn === null && option.getAssignedID() === assignedID) {
      toReturn = option
    }
  })

  return toReturn
}
/**
 * Add an ConfigOption to the list of options for this group
 * @param {ConfigOption} option The ConfigOption to add
 */
OptionGroup.prototype.addOption = function (option) {
  let found

  if (!option) {
    throw new TypeError("Missing required parameter: option")
  }

  if (option.toString() !== (new ConfigOption()).toString()) {
    throw new TypeError("Invalid parameter option: must be ConfigOption")
  }

  found = false
  Util.each(this.options, existingOption => {
    if (existingOption.getItemInternalID() === option.getItemInternalID()) {
      found = true
    }
  })

  if (!found) {
    this.options.push(option)
  }

  this.options.sort((a, b) => {
    if (a.isDefaultOption()) return -1
    if (b.isDefaultOption()) return 1
    if (a.getPriceWithTax("1") === 0) return -1
    if (b.getPriceWithTax("1") === 0) return 1
    return Number(a.getPriceWithTax("1")) - Number(b.getPriceWithTax("1"))
  })
}
/**
 * Remove an ConfigOption from the list of options in this group
 * @param {String} itemInternalID The Internal ID of the item referenced by the ConfigOption
 */
OptionGroup.prototype.removeOptionByItemID = function (itemInternalID) {
  if (!Util.isString(itemInternalID)) {
    throw new TypeError("Invalid parameter itemInternalID: must be String")
  }

  Object.keys(this.options).forEach(key => {
    if (this.options[key].getItemInternalID() === itemInternalID) {
      this.options.splice(key, 1)
    }
  })
}
OptionGroup.prototype.removeOptionByAssignedID = function (assignedID) {
  if (!Util.isString(assignedID)) {
    throw new TypeError("Invalid parameter assignedID: must be String")
  }

  Object.keys(this.options).forEach(key => {
    if (this.options[key].getAssignedID() === assignedID) {
      this.options.splice(key, 1)
    }
  })
}

/**
 * Get an array containing the internal IDs of all option items
 * @returns {String[]}
 */
OptionGroup.prototype.getAllOptionItemIDs = function () {
  const toReturn = []
  Util.each(this.options, option => {
    toReturn.push(option.getItemInternalID())
  })

  return toReturn
}

/**
 *
 * @returns {String[]}
 */
OptionGroup.prototype.getAllAssignedIDs = function () {
  const toReturn = []
  Util.each(this.options, option => {
    toReturn.push(option.getAssignedID())
  })

  return toReturn
}

Object.defineProperty(OptionGroup.prototype, "toString", {
  value: () => "[object OptionGroup]"
})

export default OptionGroup
