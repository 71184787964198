/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

// import { useWindowSize } from "shared/hooks"

function TableRow({ name, measurement, area }) {
  function AreaUnit() {
    if (area) {
      return (
        <span>
          m
          <sup>2</sup>
        </span>
      )
    }
    return "m"
  }
  return (
    <tr key={`measure-${name}`}>
      <td>{name}</td>
      <td>
        {measurement}
        {" "}
        {AreaUnit()}
      </td>
    </tr>
  )
}

export default function HomeMeasurements({
  homeLand,
  measurements: {
    facadeGroup, blockFrontage, blockDepth,
    designLength, designWidth, sizeM2,
    livingArea,
    terrazzaArea, garageArea, patioArea, balconyArea,
    zeroLotAlignmentCompatible,
    zeroLotWidth,
    designType, grannyFlatType
  }
}) {
  const grannyDesign = (designType === "Granny Flat")
  const [commonRows, setCommonRows] = useState([
    {
      name: "House Width",
      measurement: designWidth,
    },
    {
      name: "House Length",
      measurement: designLength,
    },
  ])
  const [collapseRows, setCollapseRows] = useState([
    {
      name: "Living",
      measurement: livingArea || 0,
      area: true
    },
    {
      name: "Terrazza",
      measurement: terrazzaArea || 0,
      area: true
    },
    {
      name: "Garage",
      measurement: garageArea || 0,
      area: true
    },
    {
      name: "Patio",
      measurement: patioArea || 0,
      area: true
    },
    {
      name: "Balcony",
      measurement: balconyArea || 0,
      area: true
    },
  ])
  const [extraAreaTotal, setExtraAreaTotal] = useState(Object.values(collapseRows).map(obj => obj.measurement).reduce((a, b) => (a + b)))
  const [total, setTotal] = useState({
    name: "Total Area",
    measurement: sizeM2,
    area: true
  })
  if (!homeLand) {
    if (!facadeGroup) {
      console.error("facadeGroup is required for none home land display")
    }
    if (!blockFrontage) {
      console.error("blockFrontage is required for none home land display")
    }
  }

  const specContentRef = useRef(null)

  const bannerText = () => {
    if (grannyDesign) {
      return grannyFlatType === "Attached" ? "ATTACHABLE DESIGN" : "FREESTANDING DESIGN"
    }
    return `SUITS ${blockFrontage}m WIDE LOTS`
  }

  return (
    <>
      <h4>Specifications</h4>
      <div className="home-specs">
        {!homeLand && (
          <>
            {zeroLotAlignmentCompatible && (
              <div className="zero-lot">
                <div className="zero-lot-outline" style={{ borderColor: facadeGroup.hexcolour }}>
                  <div style={{ color: facadeGroup.hexcolour }}>
                    <div>{`${zeroLotWidth}m`}</div>
                    <div>ZERO</div>
                    <div>LOTS</div>
                  </div>
                </div>
              </div>
            )}

            <div className="badge suitable-badge mb-3" style={{ backgroundColor: facadeGroup.hexcolour }}>
              <div className="triangle-clip"></div>
              { bannerText() }
            </div>
            <div className="lot-deep" style={{ width: grannyDesign ? "180px" : "224px" }}>
              {grannyDesign ? <div>&nbsp;</div> : <div>{`SUITS ${blockDepth}m DEEP LOTS`}</div>}
            </div>
          </>
        )}
        <div
          className={cx("spec-content", { "spec-home-design": !homeLand }, { "spec-home-land": homeLand })}
          ref={specContentRef}>
          <table className="table table-sm table-borderless small-text table-list mb-2">
            <tbody>
              {commonRows.map(row => TableRow(row))}
            </tbody>
          </table>
          <hr className="my-2" />
          {extraAreaTotal > 0 && (
            <>
              <table className="table table-sm table-borderless small-text table-list mb-2">
                <tbody>
                  {collapseRows.map(row => (row.measurement > 0 && <TableRow key={`measure-${row.name}`} {...row} />))}
                </tbody>
              </table>
              <hr className="my-2" />
            </>
          )}
          <table className="table table-sm table-borderless small-text table-list">
            <tbody>
              <TableRow key="total-row" {...total} />
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

HomeMeasurements.propTypes = {
  measurements: PropTypes.shape({
    facadeGroup: PropTypes.shape({
      hexcolour: PropTypes.string
    }),
    blockFrontage: PropTypes.number,
    designLength: PropTypes.number.isRequired,
    designWidth: PropTypes.number.isRequired,
    sizeM2: PropTypes.number.isRequired,
    livingArea: PropTypes.number,
    terrazzaArea: PropTypes.number,
    garageArea: PropTypes.number,
    patioArea: PropTypes.number,
    balconyArea: PropTypes.number,
  }).isRequired
}
