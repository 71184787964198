/* eslint-disable no-undef */
import * as constants from "../../constants"

function pushStateToLocalStorage(filter) {
  if (!filter.no_push_sotrage) {
    // localStorage.setItem("HOME_DESIGN_SEARCH_FILTER", JSON.stringify(filter))
    document.cookie = `HOME_DESIGN_SEARCH_FILTER=${JSON.stringify(filter)}`
  }
}

// eslint-disable-next-line no-undef
const filters = (state = _.get(gon, "home_designs.filters", {}), action) => {
  switch (action.type) {
    case constants.FILTERS_UPDATED:
    case constants.FILTERS_UPDATED_NO_REFRESH: {
      const newState = { ...state, ...action.data }
      pushStateToLocalStorage(newState)
      return newState
    }
    case constants.FILTERS_RESET: {
      const newState = { ...gon.home_designs.filters }
      pushStateToLocalStorage(newState)
      return newState
    }
    default:
      pushStateToLocalStorage(state)
      return state
  }
}

export const getFilters = state => state.filters

export default filters
