import BaseController from "controllers/base"
import startHomeDesignsSearchApp from "apps/HomeDesignSearch"

class Controller extends BaseController {
  call() {
    super.call()

    startHomeDesignsSearchApp(
      document.getElementById("home-designs"),
      {}
    )

    this.bindScrollTop.call(this)
  }

  bindScrollTop = () => {
    window.onscroll = () => {
      const topHeight = (document.getElementsByClassName("filter")[0].offsetHeight + document.getElementsByClassName("main-header")[0].offsetHeight + document.getElementsByClassName("page-header")[0].offsetHeight)
      if (window.scrollY >= topHeight) {
        $(".sticky-filter").addClass("active")
      } else {
        $(".sticky-filter").removeClass("active")
      }
    }
  }
}

export default Controller