import React from "react"

export default function Awards({ awards: { data } }) {
  if (data.length) {
    return (
      <React.Fragment>
        <h4 className="mt-4">Awards</h4>
        <div className="row justify-content-start">
          {data.map(award => {
            const {
              attributes: {
                id, awardImageUrl, urlPath
              }
            } = award
            return (
              <div className="col-2 col-md-4" key={`award-image-${id}`}>
                <a href={urlPath}>
                  <img src={awardImageUrl} style={{ width: "70px", height: "auto" }} />
                </a>

              </div>
            )
          })}
        </div>
        <hr />
      </React.Fragment>
    )
  }
  return ""
}