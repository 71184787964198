import React from "react"
import { Container } from "react-bootstrap"
import { useSections } from "../../configurator-context-provider"
import SectionCard from "./SectionCard"
import Loading from "../Loading"
import Error from "../Error"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.ConfigurationList = false

const ConfigurationList = () => {
  // if (window.configurator.debugPoints.ConfigurationList) {
  //   debugger
  // }

  const sections = useSections()

  if (sections.loading) {
    return <Loading />
  }

  if (sections.error) {
    return <Error message={sections.error} />
  }

  return (
    <Container fluid="sm">
      {sections.sections.map((section, idx) => (
        <SectionCard
          key={section.getName()}
          section={section}
          sectionIndex={idx} />
      ))}
    </Container>
  )
}

export default ConfigurationList
