import Util from "../Util/configurator-util"
import Item from "./configurator-item"
import Facade from "./configurator-facade"

function Design(ctx) {
  this.internalID = ""
  this.name = ""
  this.image = ""
  this.defaultFacade = ""
  this.groundFloorPlanRHG = ""
  this.groundFloorPlanLHG = ""
  this.firstFloorPlanRHG = ""
  this.firstFloorPlanLHG = ""
  this.beds = 0
  this.baths = 0
  this.garages = 0
  this.item = null
  this.facadeImages = []
  this.facades = []

  if (!ctx) {
    return
  }

  this.setInternalID(ctx.internalID || "")
  this.setName(ctx.name || "")
  this.setImage(ctx.image || "")
  this.setDefaultFacade(ctx.defaultFacade || "")
  this.setGroundFloorPlanRHG(ctx.groundFloorPlanRHG || "")
  this.setGroundFloorPlanLHG(ctx.groundFloorPlanLHG || "")
  this.setFirstFloorPlanRHG(ctx.firstFloorPlanRHG || "")
  this.setFirstFloorPlanLHG(ctx.firstFloorPlanRHG || "")
  this.setBeds(ctx.beds || 0)
  this.setBaths(ctx.baths || 0)
  this.setGarages(ctx.garages || 0)
  if (ctx.item) {
    this.setItem(new Item(ctx.item))
  }
  if (ctx.facadeImages) {
    Util.each(ctx.facadeImages, facadeData => {
      this.addFacadeImage(facadeData)
    })
  }
  if (ctx.facades) {
    Util.each(ctx.facades, facade => {
      this.addFacade(new Facade(facade))
    })
  }
}

/**
 * Get the Internal ID
 * @returns {String}
 */
Design.prototype.getInternalID = function () { return this.internalID }
/**
 * Set the Internal ID
 * @param {String} internalID The Internal ID
 */
Design.prototype.setInternalID = function (internalID) {
  if (!Util.isString(internalID)) {
    throw new TypeError("Invalid parameter internalID: must be String")
  }

  this.internalID = internalID
}

/**
 * Get the Name
 * @returns {String}
 */
Design.prototype.getName = function () { return this.name }
/**
 * Set the Name
 * @param {String} name The Name
 */
Design.prototype.setName = function (name) {
  if (!Util.isString(name)) {
    throw new TypeError("Invalid parameter name: must be String")
  }

  this.name = name
}

/**
 * Get the Image (URL)
 * @returns {String}
 */
Design.prototype.getImage = function () { return this.image }
/**
 * Set the Image (URL)
 * @param {String} image The Image (URL)
 */
Design.prototype.setImage = function (image) {
  if (!Util.isString(image)) {
    throw new TypeError("Invalid parameter image: must be String")
  }

  this.image = image
}

/**
 * Get the Default Facade (ID)
 * @returns {String}
 */
Design.prototype.getDefaultFacade = function () { return this.defaultFacade }
/**
 * Set the Default Facade (ID)
 * @param {String} defaultFacade The Default Facade (ID)
 */
Design.prototype.setDefaultFacade = function (defaultFacade) {
  if (!Util.isString(defaultFacade)) {
    throw new TypeError("Invalid parameter defaultFacade: must be String")
  }

  this.defaultFacade = defaultFacade
}

/**
 * Get the Ground Floor Plan RHG (URL)
 * @returns {String}
 */
Design.prototype.getGroundFloorPlanRHG = function () { return this.groundFloorPlanRHG }
/**
 * Set the Ground Floor Plan LHG (URL)
 * @param {String} groundFloorPlanRHG The Ground Floor Plan LHG (URL)
 */
Design.prototype.setGroundFloorPlanRHG = function (groundFloorPlanRHG) {
  if (!Util.isString(groundFloorPlanRHG)) {
    throw new TypeError("Invalid parameter groundFloorPlanRHG: must be String")
  }

  this.groundFloorPlanRHG = groundFloorPlanRHG
}

/**
 * Get the Ground Floor Plan LHG (URL)
 * @returns {String}
 */
Design.prototype.getGroundFloorPlanLHG = function () { return this.groundFloorPlanLHG }
/**
 * Set the Ground Floor Plan LHG (URL)
 * @param {String} groundFloorPlanLHG The Ground Floor Plan LHG (URL)
 */
Design.prototype.setGroundFloorPlanLHG = function (groundFloorPlanLHG) {
  if (!Util.isString(groundFloorPlanLHG)) {
    throw new TypeError("Invalid parameter groundFloorPlanLHG: must be String")
  }

  this.groundFloorPlanLHG = groundFloorPlanLHG
}

/**
 * Get the First Floor Plan RHG (URL)
 * @returns {String}
 */
Design.prototype.getFirstFloorPlanRHG = function () { return this.firstFloorPlanRHG }
/**
 * Set the First Floor Plan RHG (URL)
 * @param {String} firstFloorPlanRHG The First Floor Plan RHG (URL)
 */
Design.prototype.setFirstFloorPlanRHG = function (firstFloorPlanRHG) {
  if (!Util.isString(firstFloorPlanRHG)) {
    throw new TypeError("Invalid parameter firstFloorPlanRHG: must be String")
  }

  this.firstFloorPlanRHG = firstFloorPlanRHG
}

/**
 * Get the First Floor Plan LHG (URL)
 * @returns {String}
 */
Design.prototype.getFirstFloorPlanLHG = function () { return this.firstFloorPlanLHG }
/**
 * Set the First Floor Plan LHG (URL)
 * @param {String} firstFloorPlanLHG The First Floor Plan LHG (URL)
 */
Design.prototype.setFirstFloorPlanLHG = function (firstFloorPlanLHG) {
  if (!Util.isString(firstFloorPlanLHG)) {
    throw new TypeError("Invalid parameter firstFloorPlanLHG: must be String")
  }

  this.firstFloorPlanLHG = firstFloorPlanLHG
}

/**
 * Get the Beds
 * @returns {Number}
 */
Design.prototype.getBeds = function () { return this.beds }
/**
 * Set the Beds
 * @param {Number} beds The beds
 */
Design.prototype.setBeds = function (beds) {
  if (!Util.isNumber(beds)) {
    throw new TypeError("Invalid parameter beds: must be Number")
  }

  this.beds = beds
}

/**
 * Get the Baths
 * @returns {Number}
 */
Design.prototype.getBaths = function () { return this.baths }
/**
 * Set the Baths
 * @param {Number} baths The Baths
 */
Design.prototype.setBaths = function (baths) {
  if (!Util.isNumber(baths)) {
    throw new TypeError("Invalid parameter baths: must be Number")
  }

  this.baths = baths
}

/**
 * Get the Garages
 * @returns {Number}
 */
Design.prototype.getGarages = function () { return this.garages }
/**
 * Set the Garages
 * @param {Number} garages The Garages
 */
Design.prototype.setGarages = function (garages) {
  if (!Util.isNumber(garages)) {
    throw new TypeError("Invalid parameter garages: must be Number")
  }

  this.garages = garages
}

/**
 * Get the Item
 * @returns {Item}
 */
Design.prototype.getItem = function () { return this.item }
/**
 * Set the Item
 * @param {Item} item The Item
 */
Design.prototype.setItem = function (item) {
  if (!item) {
    throw new TypeError("Missing required parameter: item")
  }
  if (item.toString() !== (new Item()).toString()) {
    throw new TypeError("Invalid parameter item: must be a Item")
  }

  this.item = item
}

/**
 * Get the list of facade images
 * @returns {Object[]}
 */
Design.prototype.getFacadeImages = function () { return this.facadeImages.slice() }
Design.prototype.addFacadeImage = function (imageData) {
  if (!imageData) {
    throw new TypeError("Missing required parameter: imageData")
  }
  if (!Util.isObject(imageData)) {
    throw new TypeError("Invalid parameter imageData: must be Object")
  }
  if (!Util.isString(imageData.facadeID) || !Util.isString(imageData.facadeName) || !Util.isString(imageData.url)) {
    throw new TypeError("Invalid parameter imageData: must be Object with facadeID, facadeName and url properties of type String")
  }

  this.facadeImages.push(imageData)
}

/**
 * Get a specified facade
 * @param {String} facadeID
 * @returns {Facade}
 */
Design.prototype.getFacade = function (facadeID) {
  let toReturn = null

  Util.each(this.facades, facade => {
    if (facade.getInternalID() === facadeID) toReturn = facade
  })

  return toReturn
}
/**
 * Get the full list of facades
 * @returns {Facade[]}
 */
Design.prototype.getFacadeList = function () {
  return this.facades.slice().sort((a, b) => {
    if (Number(a.getItem().getPriceWithTax("1")) === 0 && Number(b.getItem().getPriceWithTax("1")) === 0) return a.getName().localeCompare(b.getName())
    if (Number(a.getItem().getPriceWithTax("1")) === 0) return -1
    if (Number(b.getItem().getPriceWithTax("1")) === 0) return 1
    return a.getName().localeCompare(b.getName())
  })
}
/**
 * Add a facade
 * @param {Facade} toAdd
 */
Design.prototype.addFacade = function (toAdd) {
  if (!this.getFacade(toAdd.getInternalID())) this.facades.push(toAdd)
}
Design.prototype.getFacadeListForCarousel = function () {
  return this.facades.slice().sort((a, b) => {
    if (this.getDefaultFacade()) {
      if (a.getInternalID() === this.getDefaultFacade()) return -1
      if (b.getInternalID() === this.getDefaultFacade()) return 1
    }
    if (Number(a.getItem().getPriceWithTax("1")) === 0 && Number(b.getItem().getPriceWithTax("1")) === 0) return a.getName().localeCompare(b.getName())
    if (Number(a.getItem().getPriceWithTax("1")) === 0) return -1
    if (Number(b.getItem().getPriceWithTax("1")) === 0) return 1
    return a.getName().localeCompare(b.getName())
  })
}

Object.defineProperty(Design.prototype, "toString", {
  value: () => "[object Design]"
})

export default Design
