/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import { connect } from "react-redux"

import DesignName from "components/common/HomeDesignName"
import HomeRoomsSmall from "components/common/homeRoomsSmall"
import { SeriesTableBadges } from "components/buttons/badges"
import FavouriteIcon from "components/common/favouriteIcon"
import QuickViewModal from "./homeDesignQuickView"

import { setShowQuickView, setQuickViewIdx } from "../../actions"
import "./hoverStyle.css"

const isMobileDeviceOrSafari = () => (
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
)

class HomeSeriesRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showQuickView: false
    }
  }

  setShowQuickView(newState) {
    this.setState({ showQuickView: newState })
  }

  handleQuickView() {
    const {
      mainId, setShow, setIdx, idx, homeDesign: {
        attributes: {
          id
        }
      }
    } = this.props
    if (id !== mainId) {
      if (isMobileDeviceOrSafari()) {
        this.setShowQuickView(true)
      } else {
        setIdx(idx)
        setShow(true)
      }
    }
  }

  curserStyle() {
    const {
      mainId, homeDesign: {
        attributes: {
          id
        }
      }
    } = this.props
    if (id !== mainId) {
      return { cursor: "pointer" }
    }
    return {}
  }

  render() {
    const {
      mainId,
      homeDesign,
      homeDesign: {
        attributes: {
          id,
          name,
          sizeM2,
          designWidth,
          designLength,
          detailUrl,
          facadeGroup: { hexcolour }
        }
      }
    } = this.props
    const { showQuickView } = this.state
    if (id === mainId) {
      return (
        <div className="row-outter mx-1" style={{ borderLeftColor: hexcolour }}>
          <div className="row small-text py-1 mx-0">
            <div className="col-lg-2 row-name" style={{ borderLeftColor: hexcolour }}>
              <DesignName name={name} />
            </div>
            <div className="col-lg-3">
              <SeriesTableBadges homeDesign={homeDesign} />
            </div>
            <div className="col-lg-1 px-lg-0">
              <span className="units">Size:</span>
              {sizeM2}
              <span className="units ml-1">
                m
                <sup>2</sup>
              </span>
            </div>
            <div className="col-lg-1 px-lg-0">
              <span className="units">Width:</span>
              {designWidth}
              <span className="units ml-1">m</span>
            </div>
            <div className="col-lg-1 px-lg-0">
              <span className="units">Depth:</span>
              {designLength}
              <span className="units ml-1">m</span>
            </div>
            <div className="col-lg-auto">
              <HomeRoomsSmall homeDesign={homeDesign} />
            </div>
            <div className="col text-left text-lg-right pr-0">
              <div
                className="btn btn-sm btn-outline-grey mr-1"
                style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                <FavouriteIcon homeDesignId={id} />
              </div>
              <div className="btn btn-sm disabled" style={{ width: "7rem" }}>Viewing</div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="row-outter mx-1" style={{ borderLeftColor: hexcolour }}>
        <div className="row small-text py-1 mx-0">
          <a href={detailUrl} className="col-lg-2 row-name" style={{ borderLeftColor: hexcolour }}>
            <DesignName name={name} />
          </a>
          <a href={detailUrl} className="col-lg-3">
            <SeriesTableBadges homeDesign={homeDesign} />
          </a>
          <a href={detailUrl} className="col-lg-1 px-lg-0">
            <span className="units">Size:</span>
            {sizeM2}
            <span className="units ml-1">
              m
              <sup>2</sup>
            </span>
          </a>
          <a href={detailUrl} className="col-lg-1 px-lg-0">
            <span className="units">Width:</span>
            {designWidth}
            <span className="units ml-1">m</span>
          </a>
          <a href={detailUrl} className="col-lg-1 px-lg-0">
            <span className="units">Depth:</span>
            {designLength}
            <span className="units ml-1">m</span>
          </a>
          <a href={detailUrl} className="col-lg-auto">
            <HomeRoomsSmall homeDesign={homeDesign} />
          </a>
          <div className="col text-left text-lg-right pr-0">
            <div
              className="btn btn-sm btn-outline-grey mr-1"
              style={{ paddingLeft: "8px", paddingRight: "8px" }}>
              <FavouriteIcon homeDesignId={id} />
            </div>
            <div
              className="btn btn-sm btn-outline-grey"
              style={{ width: "7rem" }}
              onClick={() => this.handleQuickView()}>
              Quick View
            </div>
          </div>
          {isMobileDeviceOrSafari() && (
            <QuickViewModal
              show={showQuickView}
              onHide={() => this.setShowQuickView(false)}
              homeDesign={homeDesign} />
          )}
        </div>
      </div>
    )
  }
}

export default connect(null, {
  setShow: setShowQuickView,
  setIdx: setQuickViewIdx
})(HomeSeriesRow)
