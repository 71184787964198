import BaseController from "controllers/base"

import startLandForSaleDetailApp from "apps/DisplayHomeForSaleDetail"

import { cmsFormControl, cmsFormFileDropable } from "servos/cms/ajaxForm"

class Controller extends BaseController {
  call() {
    super.call()

    startLandForSaleDetailApp(
      document.getElementById("display-home-for-sale"),
      {}
    )

    cmsFormControl.call(this)
    cmsFormFileDropable.call(this)
  }
}

export default Controller