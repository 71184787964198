import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools }          from "redux-devtools-extension"
import { routerMiddleware }             from "connected-react-router"

import { createBrowserHistory } from "history"

import createRootReducer from "./reducers"

export const history = createBrowserHistory({ basename: `` })

export default function configureStore(initialState) {
  const RouterMiddleware  = routerMiddleware(history)

  return {
    ...createStore(
      createRootReducer(history),
      initialState,
      composeWithDevTools(applyMiddleware(RouterMiddleware))
    ),
  }
}
