import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"

import createReducer from "components/utils/createReducer"
import favouriteList from "components/common/favouriteIcon/redux/reducers"
import compareIconReducer from "components/common/CompareButton/redux/reducers"
import homeDesignRootReducer, * as homeDesignReducers from "modules/HomeDesign/reducers"
import homeLands from "apps/HomeLandSearch/redux/reducers/homeLandSearch"
import landForSale from "apps/LandForSale/redux/reducers/landForSale"
import displayHomes from "./displayHomes"

// building config reducer with gon excluding project has its own.
const config = createReducer(
  function () {
    // eslint-disable-next-line no-undef
    const { ...initialState } = gon

    return initialState
  }()
)({})

export const getUrls = state => state.config.urls
export const getBaseUrl = state => state.config.urls.api

// HomeDesign
export const getHomeDesigns = state => homeDesignReducers.getHomeDesigns(state)
export const getHomeDesignObj = state => homeDesignReducers.getHomeDesignObj(state)
export const getHomeDesignIds = state => homeDesignReducers.getHomeDesignIds(state)
export const isFetchingHomeDesigns = state => homeDesignReducers.isFetchingHomeDesigns(state)
export const getFilters = state => homeDesignReducers.getFilters(state)
export const getOptions = state => homeDesignReducers.getOptions(state)

export default history => combineReducers({
  router: connectRouter(history),
  config,
  favouriteList,
  comparePlanList: compareIconReducer,
  homeDesigns: homeDesignRootReducer,
  homeLands,
  displayHomes,
  landForSale
})
