import Util from "../Util/configurator-util"
import Item from "./configurator-item"

function Facade(ctx) {
  this.internalID = ""
  this.name = ""
  this.image = ""
  this.item = null

  if (!ctx) {
    return
  }

  this.setInternalID(ctx.internalID || "")
  this.setName(ctx.name || "")
  this.setImage(ctx.image || "")
  if (ctx.item) {
    this.setItem(new Item(ctx.item))
  }
}

/**
 * Get the Internal ID
 * @returns {String}
 */
Facade.prototype.getInternalID = function () { return this.internalID }
/**
 * Set the Internal ID
 * @param {String} internalID The Internal ID
 */
Facade.prototype.setInternalID = function (internalID) {
  if (!Util.isString(internalID)) {
    throw new TypeError("Invalid parameter internalID: must be String")
  }

  this.internalID = internalID
}

/**
 * Get the Name
 * @returns {String}
 */
Facade.prototype.getName = function () { return this.name }
/**
 * Set the Name
 * @param {String} name The Name
 */
Facade.prototype.setName = function (name) {
  if (!Util.isString(name)) {
    throw new TypeError("Invalid parameter name: must be String")
  }

  this.name = name
}

/**
 * Get the Image (URL)
 * @returns {String}
 */
Facade.prototype.getImage = function () { return this.image }
/**
 * Set the Image (URL)
 * @param {String} image The Image (URL)
 */
Facade.prototype.setImage = function (image) {
  if (!Util.isString(image)) {
    throw new TypeError("Invalid paremeter image: must be String")
  }

  this.image = image
}

/**
 * Get the Item
 * @returns {Item}
 */
Facade.prototype.getItem = function () { return this.item }
/**
 * Set the Item
 * @param {Item} item The Item
 */
Facade.prototype.setItem = function (item) {
  if (!item) {
    throw new TypeError("Missing required parameter: item")
  }
  if (item.toString() !== (new Item()).toString()) {
    throw new TypeError("Invalid parameter item: must be a Item")
  }

  this.item = item
}

Object.defineProperty(Facade.prototype, "toString", {
  value: () => "[object Facade]"
})

export default Facade
