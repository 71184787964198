import BaseController from "controllers/base"
import $ from 'jquery';

import ajaxLoadForm from "widgets/cms/LoadEditForm";
import ajaxElementForms from "widgets/cms/ajaxUpdateForm"
import bindFormPreview from "widgets/cms/bindFormPreview"
import loadCKEditor from "widgets/cms/loadCKEditor"
import popUpGallery from "widgets/cms/imageGallery"

class Controller extends BaseController {
  call() {
    super.call();

    loadForm.call(this)
    bindFormPreview.call(this);
  };
};

let loadForm = function () {
  ajaxLoadForm(bindAfterLoadForm);
}

const bindAfterLoadForm = function () {
  bindElementForms.call(this);
  popUpGallery.call(this);
  loadCKEditor.call(this);
}

const bindElementForms = function () {
  ajaxElementForms(loadForm)
}

export default Controller;