import React, { useState } from "react"
import {
  Container, Row, Col, Form
} from "react-bootstrap"
import {
  ACTIONS, FACADE_SORT_OPTIONS, useDispatch, useRoofs, useSelectedPriceLevel, useSelectedRoof
} from "../../configurator-context-provider"
import FacadeCardList from "./FacadeCardList"
import "../../styles/configurator-view.scss"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.FacadeCardListView = false

const FacadeCardListView = () => {
  // if (window.configurator.debugPoints.FacadeCardListView) {
  //   debugger
  // }

  const roofs = useRoofs()
  const selectedPriceLevel = useSelectedPriceLevel()
  const selectedRoof = useSelectedRoof()
  const dispatch = useDispatch()
  const options = Object.values(FACADE_SORT_OPTIONS)
  const [facadeSort, setFacadeSort] = useState(options[0].value)

  const handleSelectedRoofChange = e => {
    if (e.target.value === selectedRoof) return
    dispatch({ type: ACTIONS.SELECT_ROOF, payload: { roofType: e.target.value } })
  }

  const handleSortOptionChange = e => {
    if (e.target.value === facadeSort) return
    setFacadeSort(e.target.value)
    dispatch({ type: ACTIONS.SORT_FACADES, payload: { option: e.target.value } })
  }

  return (
    <div className="home-deisgns">
      <div className="page-header text-center">
        <h1>Select Roof Type and Façade</h1>
      </div>
      <Container fluid="sm">
        <Row>
          <Form.Group as={Col} xs={12} md={3} controlId="roof-type">
            <Form.Label className="configurator-required">Select Roof Type</Form.Label>
            <Form.Control
              as="select"
              name="roof-type"
              value={selectedRoof}
              onChange={e => handleSelectedRoofChange(e)}>
              <option key="select" value="" defaultValue={!!selectedRoof.length}>Select One...</option>
              {roofs.map(roof => (
                <option key={roof.getInternalID()} value={roof.getInternalID()} defaultValue={selectedRoof === roof.getInternalID()}>{roof.getNameAndPrice(selectedPriceLevel || "1")}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} xs={12} md={{ span: 3, offset: 6 }} controlId="sort-facades">
            <Form.Label>Sort By</Form.Label>
            <Form.Control
              as="select"
              name="sort-facades"
              value={facadeSort}
              onChange={e => handleSortOptionChange(e)}>
              {options.map(option => (
                <option key={option.value} value={option.value} defaultValue={option.value === facadeSort}>{option.label}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>
      </Container>
      <FacadeCardList />
    </div>
  )
}

export default FacadeCardListView
