import BaseController from "controllers/base"
import $ from 'jquery';

import ajaxPreviewStatus from 'widgets/cms/loadPreviewStatus'
import bindFormPreview from "widgets/cms/bindFormPreview"

class Controller extends BaseController {
  call() {
    super.call();
    ajaxPreviewStatus.call(this);
    bindFormPreview.call(this);
  };
};


export default Controller;