/* eslint-disable no-undef */
import BaseController from "controllers/base"
import startHomeDesignDetailApp from "apps/HomeDesignDetail"
import { cmsFormControl, cmsFormFileDropable } from "servos/cms/ajaxForm"

class Controller extends BaseController {
  call() {
    super.call()

    startHomeDesignDetailApp(
      document.getElementById("home-design"),
      {}
    )

    cmsFormControl.call(this)
    cmsFormFileDropable.call(this)
  }
}

export default Controller