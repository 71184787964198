/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import Col from "react-bootstrap/Col"
import classNames from "classnames"
import DesignCarousel from "./DesignCarousel"
import DesignSpec from "./DesignSpec"
import Util from "../../Util/configurator-util"
import {
  ACTIONS, useDispatch, useSelectedDesign, useSelectedPriceLevel
} from "../../configurator-context-provider"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.DesignCard = false

const DesignCard = ({ design }) => {
  // if (window.configurator.debugPoints.DesignCard) {
  //   debugger
  // }

  const selectedDesign = useSelectedDesign()
  const selectedPriceLevel = useSelectedPriceLevel()
  const dispatch = useDispatch()

  const designID = design.getInternalID()

  const handleClick = () => {
    if (selectedDesign === designID) return
    dispatch({ type: ACTIONS.SELECT_DESIGN, payload: { selectedDesign: designID } })
  }

  return (
    <Col className="configurator-pointer">
      <div
        className={classNames("configurator-design-card", {
          active: designID === selectedDesign
        })}
        onClick={() => handleClick()}>
        <DesignCarousel
          facades={design.getFacadeListForCarousel()}
          groundFloorPlan={design.getGroundFloorPlanRHG()}
          firstFloorPlan={design.getFirstFloorPlanRHG()} />
        <div
          className="configurator-specifications">
          <div className="spec">
            <span className="configurator-first-word">{Util.firstWord(design.getName())}</span>
            {" "}
            <span>{Util.rest(design.getName())}</span>
          </div>
          <DesignSpec
            bedrooms={design.getBeds()}
            bathrooms={design.getBaths()}
            garages={design.getGarages()} />
          <div className="spec"><span>{Util.formatAmount(design.getItem().getPriceWithTax(selectedPriceLevel || "1"))}</span></div>
        </div>
      </div>
    </Col>
  )
  /* return (
    <Col>
      <div
        className={classNames("design-card", {
          active: designID === selectedDesign
        })}
        onClick={() => handleClick()}
      >
        <DesignCarousel
          facades={design.getFacadeImages()}
          groundFloorPlan={design.getGroundFloorPlanRHG()}
          firstFloorPlan={design.getFirstFloorPlanRHG()}
        />
        <div
          className="specifications"
        >
          <div className="spec"><span className="first-word">{Util.firstWord(design.getName())}</span> <span>{Util.rest(design.getName())}</span></div>
          <DesignSpec
            bedrooms={design.getBeds()}
            bathrooms={design.getBaths()}
            garages={design.getGarages()}
          />
          <div className="spec"><span>{Util.formatAmount(design.getItem().getPriceWithTax(selectedPriceLevel))}</span></div>
        </div>
      </div>
    </Col>
  ) */
}

export default DesignCard
