import BaseController from "controllers/base"
import $ from 'jquery';

import ajaxPreviewStatus from 'widgets/cms/loadPreviewStatus'

class Controller extends BaseController {
  call() {
    super.call();
    ajaxPreviewStatus.call(this);
  };
};


export default Controller;