import React, { Component } from "react"
import { connect } from "react-redux"

import HomeTypeFilter from "components/filters/HomeTypeFilter"
import SpecificationsFilter from "components/filters/SpecificationsFilter"
import PriceRangeFilter from "components/filters/PriceRangeFilter"
import DisplayByFilter from "components/filters/DisplayByFilter"

import AdvancedFiltersModal from "./AdvancedFiltersModal"
import StickyBasicFilter from "./StickyBasicFilter"

import * as actions from "../../actions"
import * as reducers from "../../reducers"

class Filter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_advanced_filters: false,
      advancedFiltersUsed: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    return state
  }

  handleOpenModal = e => {
    e.preventDefault()
    this.setState({ show_advanced_filters: true })
  }

  handleCloseModal = () => {
    this.setState({ show_advanced_filters: false })
  }

  handleClearFilters = () => {
    const { filtersReset } = this.props
    filtersReset()
    this.setState({ advancedFiltersUsed: false })
    this.handleCloseModal()
  }

  filtersApplied = newFilters => {
    const defaultFilters = {
      by_balcony_location: "",
      by_block_width: [0, 35],
      by_home_length: [0, 35],
      by_home_size: [0, 500],
      by_home_width: [0, 35],
      by_master_location: "",
      by_terrazza_location: "",
      has_award_winning: false,
      has_corner_block_design: false,
      has_on_display: false,
      has_on_promotion: false,
      has_split_level_design: false,
      has_zero_lot_compatible: false,
      includes_attic: false,
      includes_butlers_pantry: false,
      includes_corner_stacker_door: false,
      includes_downstairs_bedroom: false,
      includes_guest_bedrooms: false,
      includes_media_study: false,
      includes_staircase_storage: false,
      includes_theatre: false
    }
    let value = false
    Object.entries(defaultFilters).forEach(obj => {
      if (JSON.stringify(newFilters[obj[0]]) !== JSON.stringify(obj[1])) {
        value = true
      }
    })
    return value
  }

  handleSavingAdvancedFilters = newFilters => {
    const { filtersUpdated } = this.props
    const { filters } = this.state

    filtersUpdated(_.extend(filters, newFilters))
    this.setState({ advancedFiltersUsed: this.filtersApplied(newFilters) })
    this.handleCloseModal()
  }

  render() {
    const {
      filters: {
        by_home_types, by_price_range, by_bedroom_count, by_bathroom_count, by_garage_count, view_by
      }, filtersUpdated, filtersUpdatedNoRefresh, filters
    } = this.props

    const { show_advanced_filters, advancedFiltersUsed } = this.state

    return (
      <div className="filter-wrapper">
        <div className="filter bg-light">
          <div className="container-fluid">
            <div className="row">
              <HomeTypeFilter
                selected_options={by_home_types}
                dataChanged={data => { filtersUpdated(data) }} />

              <div className="col-lg-4 col-md-6 filter-col filter-basic">
                <SpecificationsFilter
                  by_bedroom_count={by_bedroom_count}
                  by_bathroom_count={by_bathroom_count}
                  by_garage_count={by_garage_count}
                  dataChanged={data => { filtersUpdated(data) }} />
              </div>

              <div className="col-lg-4 filter-col">
                <div className="row mt-lg-0 mt-md-4 price-display">
                  <PriceRangeFilter
                    price_range={by_price_range}
                    dataChanged={data => { filtersUpdated(data) }} />

                  <DisplayByFilter
                    display_type={view_by}
                    dataChanged={data => { filtersUpdatedNoRefresh(data) }} />
                </div>
              </div>

              <div className="col filter-col">
                <div className="text-center mt-3 btn-advance">
                  <a
                    href="!#"
                    onClick={this.handleOpenModal}
                    className="small-text text-link">
                    Advanced Filters
                  </a>
                  { advancedFiltersUsed ? (
                    <span className="advanced-filters-used">
                    </span>
                  ) : null }
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdvancedFiltersModal
          showModal={show_advanced_filters}
          filters={filters}
          onClearFilters={this.handleClearFilters}
          onSaveFilters={this.handleSavingAdvancedFilters}
          onCloseModal={this.handleCloseModal} />

        <StickyBasicFilter
          onClearFilters={this.handleClearFilters}
          onSaveFilters={this.handleSavingAdvancedFilters}
          setDisplayType={data => { filtersUpdatedNoRefresh(data) }}
          openAdvancedFilters={e => this.handleOpenModal(e)} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  filters: reducers.getFilters(state),
  availableHomeTypes: state.config.home_designs.available_home_types
})

export default connect(mapStateToProps, actions)(Filter)