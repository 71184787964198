/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import classNames from "classnames"
import { ACTIONS, useDispatch } from "../../configurator-context-provider"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.HomeTypeFilters = false

const HomeTypeFilters = () => {
  // if (window.configurator.debugPoints.HomeTypeFilters) {
  //   debugger
  // }

  const dispatch = useDispatch()
  const [filters, setFilters] = useState([])

  const handleClick = seriesID => {
    let newFilters = filters.slice()

    if (newFilters.indexOf(seriesID) < 0) {
      // turn filter on
      newFilters.push(seriesID)
    } else {
      // turn filter off
      newFilters = newFilters.filter(id => id !== seriesID)
    }

    newFilters.sort((a, b) => Number(a) - Number(b))
    setFilters(newFilters)
    dispatch({ type: ACTIONS.SET_SERIES_FILTERS, payload: { filters: newFilters } })
  }

  return (
    <Col xs="12" md="6" lg="4">
      <div className="title">Home Type</div>
      <ul className="list-inline mb-1">
        <li className="list-inline-item home-type-item">
          <div
            className={classNames("btn", "btn-home-type", {
              active: filters.indexOf("18") >= 0
            })}
            role="button"
            tabIndex="0"
            onClick={() => handleClick("18")}>
            <div className="icn icn-single"></div>
            <div id="single-series" className="mb-0">Single</div>
          </div>
        </li>
        <li className="list-inline-item home-type-item">
          <div
            className={classNames("btn", "btn-home-type", {
              active: filters.indexOf("22") >= 0
            })}
            role="button"
            tabIndex="0"
            onClick={() => handleClick("22")}>
            <div className="icn icn-loft"></div>
            <div id="loft-series" className="mb-0">Loft</div>
          </div>
        </li>
        <li className="list-inline-item home-type-item">
          <div
            className={classNames("btn", "btn-home-type", {
              active: filters.indexOf("20") >= 0
            })}
            role="button"
            tabIndex="0"
            onClick={() => handleClick("20")}>
            <div className="icn icn-double"></div>
            <div id="double-series" className="mb-0">Double</div>
          </div>
        </li>
      </ul>
      <ul className="list-inline mb-0">
        <li className="list-inline-item home-type-item">
          <div
            className={classNames("btn", "btn-home-type", {
              active: filters.indexOf("26") >= 0
            })}
            role="button"
            tabIndex="0"
            onClick={() => handleClick("26")}>
            <div className="icn icn-grannyflat"></div>
            <div id="granny-series" className="mb-0">Granny Flat</div>
          </div>
        </li>
        <li className="list-inline-item home-type-item">
          <div
            className={classNames("btn", "btn-home-type", {
              active: filters.indexOf("24") >= 0
            })}
            role="button"
            tabIndex="0"
            onClick={() => handleClick("24")}>
            <div className="icn icn-estate"></div>
            <div id="estate-series" className="mb-0">Estate</div>
          </div>
        </li>
        <li className="list-inline-item home-type-item">
          <div
            className={classNames("btn", "btn-home-type", {
              active: filters.indexOf("28") >= 0
            })}
            role="button"
            tabIndex="0"
            onClick={() => handleClick("28")}>
            <div className="icn icn-dual"></div>
            <div id="dual-series" className="mb-0">Dual Occupancy</div>
          </div>
        </li>
      </ul>
    </Col>
  )
}

export default HomeTypeFilters
