import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Select from "react-select"

import { updateFilter, filterUpdated } from "../../redux/actions/galleryData"
import MediaTypeFilter from "./MediaTypeFilter"
import HomeTypeFilter from "./HomeTypeFilter"

export default function Filter() {
  const dispatch = useDispatch()
  const {
    filters,
    filters: { by_location, by_series },
    options: { by_location: location_options, by_series: series_options }
  } = useSelector(state => state)

  useEffect(() => {
    dispatch(filterUpdated(filters))
  }, [dispatch, filters])

  const locations = location_options.map(v => ({ value: v, label: v }))
  const series = series_options.map(v => ({ value: v, label: v }))

  const selectStyles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      "&:hover": { borderColor: "gray" },
      border: isFocused ? "2px solid #272727" : "1px solid lightgray",
      boxShadow: "none",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: isSelected ? "#272727" : isFocused ? "#f2f2f2" : "white",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#272727" : "#d9d9d9"),
      },
    })
  }
  return (
    <div className="col-xl-3">
      <strong className="d-none d-xl-block">Filters</strong>
      <nav className="navbar navbar-expand-xl navbar-light p-0 photo-video-filter">
        <button aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation" className="navbar-toggler" data-target="#navbarTogglerDemo03" data-toggle="collapse" type="button">
          Filter your search
          <div className="material-icons">filter_list</div>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
          <div className="navbar-nav mr-auto mt-2 mt-lg-0">
            <div className="nav-item filter pt-0 pt-xl-4">
              <div className="row">
                <MediaTypeFilter />
                <HomeTypeFilter />
                <div className="col-xl-12 col-lg-4 col-md-12 filter-col">
                  <div className="row">
                    <div className="col-sm-6 col-lg-12">
                      <div className="title">Display At</div>
                      <Select
                        defaultValue={{ value: by_location, label: by_location }}
                        options={locations}
                        onChange={option => dispatch(updateFilter({ by_location: option.value }))}
                        styles={selectStyles} />
                    </div>
                    <div className="col-sm-6 col-lg-12 mt-3">
                      <div className="title">Series</div>
                      <Select
                        defaultValue={{ value: by_series, label: by_series }}
                        options={series}
                        onChange={option => dispatch(updateFilter({ by_series: option.value }))}
                        styles={selectStyles} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}