// import { delay } from "redux-saga"
import {
  all, fork, put, call, takeLatest, select, debounce
} from "redux-saga/effects"

import { getBaseUrl, getFilters } from "./reducers"
import * as requests from "./requesters"
import * as actions from "./actions"
import * as constants from "./constants"


// Request Functions
function* loadHomeDesigns(baseUrl) {
  const filters = yield select(getFilters)

  const { response, error } = yield call(requests.getHomeDesigns, baseUrl, filters)

  if (response) {
    yield put(actions.fetchDataSuccess(response))
  } else {
    yield put(actions.fetchDataFailed(error))
  }
}

// Actions
function* startLoadingData() {
  const baseUrl = yield select(getBaseUrl)
  yield all([
    fork(loadHomeDesigns, baseUrl),
  ])
}

function* filtersUpdated() {
  yield put(actions.fetchDataStart())
}

// Watchers
function* watchLoadHomeDesigns() {
  yield takeLatest(constants.FETCH_START, startLoadingData)
}

function* watchUpdateFilters() {
  // Need to confirm what has changed here to proceed
  yield debounce(300, constants.FILTERS_UPDATED, filtersUpdated)
}

function* watchResetFilters() {
  yield debounce(300, constants.FILTERS_RESET, filtersUpdated)
}

// Default Export
export default function* RootSaga() {
  yield all([
    fork(watchLoadHomeDesigns),
    fork(watchUpdateFilters),
    fork(watchResetFilters),
  ])
}