import React, { PureComponent } from "react"

class IconButton extends PureComponent {
  activeClass() {
    const { active } = this.props
    return active ? "active" : ""
  }

  disabledClass() {
    const { disabled } = this.props
    return disabled ? "disabled" : ""
  }

  render() {
    const {
      icon,
      text,
      handleToggled,
      disabled
    } = this.props

    return (
      <div
        className={`btn btn-home-type ${this.activeClass()} ${this.disabledClass()}`}
        role="button"
        onClick={disabled ? () => { } : handleToggled}
        onKeyDown={disabled ? () => { } : handleToggled}
        tabIndex={0}>
        <div className={`icn icn-${icon}`}></div>
        <p className="mb-0">{text}</p>
      </div>
    )
  }
}

export default IconButton