import * as constants from "./constants"

// Data
export const fetchDataStart = params => ({
  type: constants.FETCH_START,
  params
})

export const fetchDataSuccess = response => ({
  type: constants.FETCH_SUCCESS,
  response
})

export const fetchDataFailed = error => ({
  type: constants.FETCH_FAILED,
  error
})