import React from "react"
import { useDisplayBy } from "../../configurator-context-provider"
import FacadeCarousel from "./FacadeCarousel"
import FloorplanCarousel from "./FloorplanCarousel"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.DesignCarousel = false

const DesignCarousel = ({ facades, groundFloorPlan, firstFloorPlan }) => {
  // if (window.configurator.debugPoints.DesignCarousel) {
  //   debugger
  // }

  const displayBy = useDisplayBy()

  const floorplans = [groundFloorPlan]
  if (firstFloorPlan) {
    floorplans.push(firstFloorPlan)
  }

  if (displayBy === "facade") {
    return (
      <FacadeCarousel
        facades={facades} />
    )
  }

  // displayBy === floorplan
  return (
    <FloorplanCarousel
      floorplans={floorplans} />
  )
}

export default DesignCarousel
