import * as constants from "../../constants"

const apiRequest = (state = null, action) => {
  switch (action.type) {
    case constants.FETCH_START:
    case constants.FETCH_SUCCESS:
    case constants.FETCH_FAILED:
      return action.type
    default:
      return state
  }
}

export const isFetchingHomeDesigns = state => [null, constants.FETCH_START].includes(state.apiRequest)

export default apiRequest
