import React from "react"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.BedroomSpec = false

const BedroomSpec = ({ bedrooms }) => {
  // if (window.configurator.debugPoints.BedroomSpec) {
  //   debugger
  // }

  return (
    <>
      <svg height="13" viewBox="0 0 24.784 12.805" width="13" xmlns="http://www.w3.org/2000/svg" className="configurator-mr-1 configurator-svg" style={{ marginTop: "-2px" }}>
        <g fill="#fffffe" fillRule="evenodd">
          <path d="M22.436 0v8.958H2.347v-4.5H0V12.8h2.347v-2.1h20.089v2.1h2.348V0z"></path>
          <path d="M3.076 8.094h18.633v-2.66H3.076zM21.7 1.486c-5.007-.3-6.692 3.222-6.692 3.222H21.7z"></path>
        </g>
      </svg>
      {bedrooms}
    </>
  )
}

export default BedroomSpec
