import Util from "../Util/configurator-util"

function ConfigOption(ctx) {
  this.assignedID = ""
  this.itemInternalID = ""
  this.itemName = ""
  this.image = ""
  this.hide = false
  this.invalidSeries = []
  this.invalidDesigns = []
  this.invalidFacades = []
  this.type = ""
  this.incompatibleItems = []
  this.taxSchedule = ""
  this.isDefault = false
  this.pricing = {}
  this.selected = false

  if (!ctx) {
    return
  }

  this.setAssignedID(ctx.assignedID || "")
  this.setItemInternalID(ctx.itemInternalID || "")
  this.setItemName(ctx.itemName || "")
  this.setImage(ctx.image || "")
  this.isHidden(!!ctx.hide)
  if (ctx.invalidSeries) {
    Util.each(ctx.invalidSeries, seriesID => {
      this.addInvalidSeries(seriesID)
    })
  }
  if (ctx.invalidDesigns) {
    Util.each(ctx.invalidDesigns, designID => {
      this.addInvalidDesigns(designID)
    })
  }
  if (ctx.invalidFacades) {
    Util.each(ctx.invalidFacades, facadeID => {
      this.addInvalidFacades(facadeID)
    })
  }
  this.setType(ctx.type || "")
  if (ctx.incompatibleItems) {
    Util.each(ctx.incompatibleItems, itemID => {
      this.addIncompatibleItems(itemID)
    })
  }
  this.setTaxSchedule(ctx.taxSchedule || "")
  this.isDefaultOption(!!ctx.isDefault)
  if (ctx.pricing) {
    Object.keys(ctx.pricing).forEach(p => this.setPrice(p, ctx.pricing[p]))
  }
  this.isSelected(!!ctx.selected)
}

/**
 * Gets the Assigned ID
 * @returns {String}
 */
ConfigOption.prototype.getAssignedID = function () { return this.assignedID }
/**
 * Set the Assigned ID
 * @param {String} assignedID The Assigned ID
 */
ConfigOption.prototype.setAssignedID = function (assignedID) {
  if (!Util.isString(assignedID)) {
    throw new TypeError("Invalid paramater assignedID: must be String")
  }

  this.assignedID = assignedID
}

/**
 * Gets the Item Internal ID
 * @returns {String}
 */
ConfigOption.prototype.getItemInternalID = function () { return this.itemInternalID }
/**
 * Set the Item Internal ID
 * @param {String} itemInternalID The Item Internal ID
 */
ConfigOption.prototype.setItemInternalID = function (itemInternalID) {
  if (!Util.isString(itemInternalID)) {
    throw new TypeError("Invalid parameter itemInternalID: must be String")
  }

  this.itemInternalID = itemInternalID
}

/**
 * Get the Item Name
 * @returns {Strging}
 */
ConfigOption.prototype.getItemName = function () { return this.itemName }
/**
 * Set the Item Name
 * @param {String} itemName The Item Name
 */
ConfigOption.prototype.setItemName = function (itemName) {
  if (!Util.isString(itemName)) {
    throw new TypeError("Invalid Item Name: must be String")
  }

  this.itemName = itemName
}

/**
 * Get the Image (URL)
 * @returns {String}
 */
ConfigOption.prototype.getImage = function () { return this.image }
/**
 * Sets the Image (URL)
 * @param {String} image The Image (URL)
 */
ConfigOption.prototype.setImage = function (image) {
  if (!Util.isString(image)) {
    throw new TypeError("Invalid parameter image: must be String")
  }

  this.image = image
}

/**
 * Gets/sets the value of the isHidden flag
 * @param {Boolean} hide The new value of the isHidden flag
 * @returns {Boolean}
 */
ConfigOption.prototype.isHidden = function (hide) {
  if (Util.isBoolean(hide)) {
    this.hide = hide
  }

  return this.hide
}

/**
 * Gets the Series IDs designated as invlid for this option
 * @returns {String[]}
 */
ConfigOption.prototype.getInvalidSeries = function () { return this.invalidSeries.slice() }
/**
 * Adds a Series ID (or array of series IDs) to the list of Invalid Series
 * @param {String|String[]} seriesIDs The new series ID (or array of series IDs) to add to the list of Invalid series
 */
ConfigOption.prototype.addInvalidSeries = function (seriesIDs) {
  if (Util.isArray(seriesIDs)) {
    Util.each(seriesIDs, internalID => {
      if (!Util.isString(internalID)) {
        throw new TypeError("Invalid Invalid Series: must be String|String[]")
      }
    })

    Util.each(seriesIDs, internalID => {
      if (this.invalidSeries.indexOf(internalID) < 0) {
        this.invalidSeries.push(internalID)
      }
    })
  } else if (Util.isString(seriesIDs)) {
    if (this.invalidSeries.indexOf(seriesIDs) < 0) {
      this.invalidSeries.push(seriesIDs)
    }
  } else {
    throw new TypeError("Invalid Invalid Series: must be String|String[]")
  }
}
/**
 * Removes a Series ID (or array of series IDs) from the list of Invalid Series
 * @param {{String|String[]}} seriesIDs The old series ID (or array of series IDs) to remove from the list of Invalid series
 */
ConfigOption.prototype.removeInvalidSeries = function (seriesIDs) {
  if (Util.isArray(seriesIDs)) {
    Util.each(seriesIDs, internalID => {
      if (!Util.isString(internalID)) {
        throw new TypeError("Invalid Invalid Series: must be String|String[]")
      }
    })

    Util.each(seriesIDs, internalID => {
      if (this.invalidSeries.indexOf(internalID) >= 0) {
        this.invalidSeries.splice(this.invalidSeries.indexOf(internalID), 1)
      }
    })
  } else if (Util.isString(seriesIDs)) {
    if (this.invalidSeries.indexOf(seriesIDs) >= 0) {
      this.invalidSeries.splice(this.invalidSeries.indexOf(seriesIDs), 1)
    }
  } else {
    throw new TypeError("Invalid Invalid Series: must be String|String[]")
  }
}

/**
 * Gets the Design IDs designated as invalid for this option
 * @returns {String[]}
 */
ConfigOption.prototype.getInvalidDesigns = function () { return this.invalidDesigns.slice() }
/**
 * Adds a Design ID (or array of design IDs) to the list of Invalid designs
 * @param {String|String[]} designIDs The new design ID (or array of design IDs) to add to the list of Invalid designs
 */
ConfigOption.prototype.addInvalidDesigns = function (designIDs) {
  if (Util.isArray(designIDs)) {
    Util.each(designIDs, internalID => {
      if (!Util.isString(internalID)) {
        throw new TypeError("Invalid Invalid Design: must be String|String[]")
      }
    })

    Util.each(designIDs, internalID => {
      if (this.invalidDesigns.indexOf(internalID) < 0) {
        this.invalidDesigns.push(internalID)
      }
    })
  } else if (Util.isString(designIDs)) {
    if (this.invalidDesigns.indexOf(designIDs) < 0) {
      this.invalidDesigns.push(designIDs)
    }
  } else {
    throw new TypeError("Invalid Invalid Design: must be String|String[]")
  }
}
/**
 * Removes a Design ID (or array of design IDs) from the list of Invalid designs
 * @param {String|String[]} designIDs The Design ID (or array of Design IDs) to remove from the list of Invalid designs
 */
ConfigOption.prototype.removeInvalidDesigns = function (designIDs) {
  if (Util.isArray(designIDs)) {
    Util.each(designIDs, internalID => {
      if (!Util.isString(internalID)) {
        throw new TypeError("Invalid Invalid Design: must be String|String[]")
      }
    })

    Util.each(designIDs, internalID => {
      if (this.invalidDesigns.indexOf(internalID) >= 0) {
        this.invalidDesigns.splice(this.invalidDesigns.indexOf(internalID), 1)
      }
    })
  } else if (Util.isString(designIDs)) {
    if (this.invalidDesigns.indexOf(designIDs) >= 0) {
      this.invalidDesigns.splice(this.invalidDesigns.indexOf(designIDs), 1)
    }
  } else {
    throw new TypeError("Invalid Invalid Design: must be String|String[]")
  }
}

/**
 * Gets the Facade IDs designated as invalid for this option
 * @returns {String[]}
 */
ConfigOption.prototype.getInvalidFacades = function () { return this.invalidFacades.slice() }
/**
 * Adds a facade ID (or array of facade IDs) to the list of Invalid facades
 * @param {String|String[]} facadeIDs The new facade ID (or array of facade IDs) to add to the list of Invalid facades
 */
ConfigOption.prototype.addInvalidFacades = function (facadeIDs) {
  if (Util.isArray(facadeIDs)) {
    Util.each(facadeIDs, internalID => {
      if (!Util.isString(internalID)) {
        throw new TypeError("Invalid Invalid Facade: must be String|String[]")
      }
    })

    Util.each(facadeIDs, internalID => {
      if (this.invalidFacades.indexOf(internalID) < 0) {
        this.invalidFacades.push(internalID)
      }
    })
  } else if (Util.isString(facadeIDs)) {
    if (this.invalidFacades.indexOf(facadeIDs) < 0) {
      this.invalidFacades.push(facadeIDs)
    }
  } else {
    throw new TypeError("Invalid Invalid Facade: must be String|String[]")
  }
}
/**
 * Removes a facade ID (or array of facade IDs) from the list of Invalid facades
 * @param {String|String[]} facadeIDs The facade ID (or array of facade IDs) to remove from the list of Invalid facades
 */
ConfigOption.prototype.removeInvalidFacades = function (facadeIDs) {
  if (Util.isArray(facadeIDs)) {
    Util.each(facadeIDs, internalID => {
      if (!Util.isString(internalID)) {
        throw new TypeError("Invalid Invalid Facade: must be String|String[]")
      }
    })

    Util.each(facadeIDs, internalID => {
      if (this.invalidFacades.indexOf(internalID) >= 0) {
        this.invalidFacades.splice(this.invalidFacades.indexOf(internalID), 1)
      }
    })
  } else if (Util.isString(facadeIDs)) {
    if (this.invalidFacades.indexOf(facadeIDs) >= 0) {
      this.invalidFacades.splice(this.invalidFacades.indexOf(facadeIDs), 1)
    }
  } else {
    throw new TypeError("Invalid Invalid Facade: must be String|String[]")
  }
}

/**
 * Gets the Type
 * @returns {String}
 */
ConfigOption.prototype.getType = function () { return this.type }
/**
 * Sets the Type
 * @param {String} type The Type
 */
ConfigOption.prototype.setType = function (type) {
  if (!Util.isString(type)) {
    throw new TypeError("Invalid parameter type: must be String")
  }

  this.type = type
}

/**
 * Gets the item IDs designated as incompatible for this option
 * @returns {String[]}
 */
ConfigOption.prototype.getIncompatibleItems = function () { return this.incompatibleItems.slice() }
/**
 * Adds an item ID (or array of item IDs) to the list of incompatible items
 * @param {String|String[]} itemIDs The new item ID (or array of item IDs) to add to the list of incompatible items
 */
ConfigOption.prototype.addIncompatibleItems = function (itemIDs) {
  if (Util.isArray(itemIDs)) {
    Util.each(itemIDs, internalID => {
      if (!Util.isString(internalID)) {
        throw new TypeError("Invalid Incompatible Item: must be String|String[]")
      }
    })

    Util.each(itemIDs, internalID => {
      if (this.incompatibleItems.indexOf(internalID) < 0) {
        this.incompatibleItems.push(internalID)
      }
    })
  } else if (Util.isString(itemIDs)) {
    if (this.incompatibleItems.indexOf(itemIDs) < 0) {
      this.incompatibleItems.push(itemIDs)
    }
  } else {
    throw new TypeError("Invalid Incompatible Item: must be String|String[]")
  }
}
/**
 * Removes an item ID (or array of item IDs) from the list of incompatible items
 * @param {String|String[]} itemIDs The item ID (or array of item IDs) to remove from the list of incompatible items
 */
ConfigOption.prototype.removeIncompatibleItems = function (itemIDs) {
  if (Util.isArray(itemIDs)) {
    Util.each(itemIDs, internalID => {
      if (!Util.isString(internalID)) {
        throw new TypeError("Invalid Incompatible Item: must be String|String[]")
      }
    })

    Util.each(itemIDs, internalID => {
      if (this.incompatibleItems.indexOf(internalID) >= 0) {
        this.incompatibleItems.splice(this.incompatibleItems.indexOf(internalID), 1)
      }
    })
  } else if (Util.isString(itemIDs)) {
    if (this.incompatibleItems.indexOf(itemIDs) >= 0) {
      this.incompatibleItems.splice(this.incompatibleItems.indexOf(itemIDs), 1)
    }
  } else {
    throw new TypeError("Invalid Incompatible Item: must be String|String[]")
  }
}

/**
 * Gets the Tax Schedule
 * @returns {String}
 */
ConfigOption.prototype.getTaxSchedule = function () { return this.taxSchedule }
/**
 * Sets the Tax Schedule
 * @param {String} taxSchedule The Tax Schedule
 */
ConfigOption.prototype.setTaxSchedule = function (taxSchedule) {
  if (!Util.isString(taxSchedule)) {
    throw new TypeError("Invalid parameter taxSchedule: must be String")
  }

  this.taxSchedule = taxSchedule
}

/**
* Gets/sets the value of the isDefault flag
* @param {Boolean} isDefault The new value of the isDefault flag
* @returns {Boolean}
*/
ConfigOption.prototype.isDefaultOption = function (isDefault) {
  if (Util.isBoolean(isDefault)) {
    this.isDefault = isDefault
  }

  return this.isDefault
}

/**
 * Get the Price of the option (for a specified Price Level)
 * @param {String} level The Price Level to get a Price for
 * @returns {String} String representation of the price for the option (for a specified Price Level)
 */
ConfigOption.prototype.getPrice = function (level) {
  if (!level) {
    throw new TypeError("Missing required parameter: level")
  }

  if (!Util.isString(level)) {
    throw new TypeError("Invalid parameter level: must be String")
  }

  if (!this.pricing[level]) {
    console.log({
      error: "No price defined for the requested level",
      details: {
        name: this.getItemName(),
        internalID: this.getItemInternalID(),
        level: level
      }
    })

    throw new RangeError(`No price defined for the requested level (Name: ${this.getItemName()}, Internal ID: ${this.getItemInternalID()}, Level: ${level})`)
  }

  return this.pricing[level]
}

/**
 * Set the Price of the option (for a specified Price Level)
 * @param {String} level The Price Level to set
 * @param {String} price The Price for the option
 */
ConfigOption.prototype.setPrice = function (level, price) {
  if (!level) {
    throw new TypeError("Missing required parameter: level")
  }
  if (!price) {
    throw new TypeError("Missing required parameter: price")
  }

  if (!Util.isString(level)) {
    throw new TypeError("Invalid parameter level: must be String")
  }

  if (!Util.isString(price)) {
    throw new TypeError("Invalid parameter price: must be String")
  }

  if (Number.isNaN(Number(price))) {
    throw new TypeError("Invalid parameter price: must be able to be converted to a Number")
  }

  this.pricing[level] = price
}

/**
 * Get all available Price Levels for the option
 * @returns {String[]}
 */
ConfigOption.prototype.getPriceLevels = function () { return Object.keys(this.pricing) }

/**
 * Get the Price, including tax if applicable (for a specified Price Level)
 * @param {String} level The Price Level to get a Price for
 * @returns {String} String representation of the price for the option, including tax if applicable (for a specified Price Level)
 */
ConfigOption.prototype.getPriceWithTax = function (level) {
  let toReturn = this.getPrice(level)

  if (this.getTaxSchedule() === "BAS") {
    toReturn = Number(toReturn) * 1.1
  }

  return Util.round(Number(toReturn), 2)
}

/**
 * Gets/sets the value of the selected flag
 * @param {Boolean} selected The new value of the selected flag
 * @returns {Boolean}
 */
ConfigOption.prototype.isSelected = function (selected) {
  if (Util.isBoolean(selected)) {
    this.selected = selected
  }

  return this.selected
}

Object.defineProperty(ConfigOption.prototype, "toString", {
  value() { return "[object ConfigOption]" }
})

export default ConfigOption
