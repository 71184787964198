import { useEffect, useRef } from "react"

const useClickOutside = (elementRef, callback) => {
  const callbackRef = useRef()
  callbackRef.current = callback

  useEffect(() => {
    const handleClickOutside = e => {
      if (callbackRef.current && elementRef.current?.contains(e.target) === false) {
        e.stopPropagation()
        callbackRef.current(e)
      }
    }

    document.addEventListener("click", handleClickOutside, true)

    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [callbackRef, elementRef])
}

export default useClickOutside
