import BaseController from "controllers/base"

import popUpGallery from "widgets/cms/imageGallery"

export default class Controller extends BaseController {
  call() {
    super.call()

    popUpGallery.call(this)
  }
}