import React from "react"
import { useSelector, useDispatch } from "react-redux"

import HomeTypeFilter from "components/filters/HomeTypeFilter"
import PriceRangeFilter from "components/filters/PriceRangeFilter"
import SpecificationsFilter from "components/filters/SpecificationsFilter"
import LocationFilter from "components/filters/LocationFilter"
import { updateFilter, updateFilterNoFetchData } from "../../redux/actions"

export default function MainFilters() {
  const dispatch = useDispatch()
  const {
    filters: {
      by_home_types, by_price_range,
      by_bedroom_count, by_bathroom_count, by_garage_count,
      by_location
    },
    locationOptions
  } = useSelector(state => state.homeLands)
  return (
    <div className="filter bg-light">
      <div className="container-fluid">
        <div className="row">

          <div className="col-lg-4">
            <div className="row">
              <HomeTypeFilter
                homeLandType
                extraWrapperCls="col-md-6 col-lg-12"
                selected_options={by_home_types}
                dataChanged={data => { dispatch(updateFilter(data)) }} />
              <PriceRangeFilter
                extraWrapperCls="col-lg-12 col-md-6 mt-4 mt-md-0 mt-lg-4"
                optionArray={[500000, 600000, 650000, 700000, 750000, 800000, 850000, 900000, 1000000, 1100000, 1200000, 1300000, 1400000, 1500000]}
                price_range={by_price_range}
                dataChanged={data => { dispatch(updateFilter(data)) }} />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 filter-col filter-basic">
            <LocationFilter
              filterType="main"
              options={locationOptions}
              selected_options={by_location}
              dataChanged={data => { dispatch(updateFilterNoFetchData(data)) }} />
          </div>

          <div className="col-lg-4 col-md-6 filter-col filter-basic">
            <SpecificationsFilter
              by_bedroom_count={by_bedroom_count}
              by_bathroom_count={by_bathroom_count}
              by_garage_count={by_garage_count}
              dataChanged={data => { dispatch(updateFilter(data)) }} />
          </div>
        </div>
      </div>
    </div>
  )
}
