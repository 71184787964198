import BaseController from "controllers/base"
import $ from 'jquery';
import {displaySuccessFlash, displayErrorFlash} from "servos/ajaxFlash";

class Controller extends BaseController {
  call() {
    super.call();
    hideBackButton.call(this)
    bindElementForms.call(this);
  };
};

let hideBackButton = function () {
  $('#form-template-preview').on('load', function () {
    $('#form-template-preview').contents().find('#form-template-preview-indicator').hide();
  });
}

// We will bind the forms and send the request as JS
let bindElementForms = function () {
  this.flash = { options: { message: 'You hav' } }
  var button = $('button#update-form-template');
  var iframe = $('iframe#form-template-preview');

  $('#update-form-template').on('click', (e) => {
    button.attr("disabled", true);
    button.html('Updating...');
    e.preventDefault()
    e.stopPropagation()
    $('form#form-template-form').each(function (index) {
      console.log($(this));
      var form = $(this);
      let data = new FormData(this);
      console.log(data);
      $.ajax({
        method: form.attr('method'),
        data: data,
        url: form.attr('action'),
        contentType: false,
        processData: false,
        dataType: 'json',
        success: function(response) {
          $('input,textarea').removeClass('is-invalid').addClass('is-valid')
          iframe.attr("src", function (index, attr) {
            return attr;
          });
          displaySuccessFlash.call(this);
        },
        error: function(response) {
          const json = response.responseJSON;
          let list = $('<div/>')
          for(const key in json){
            $(`input#page_${key}`).removeClass('is-valid').addClass('is-invalid')
            list.append(`<div>${key}: ${json[key]}</div>`)
          };
          swal.fire("Error updating form", `${list.prop('outerHTML')}`, "error")
        },
        complete: function () {
          button.attr("disabled", false);
          button.text('Update');
        }
      })
    })

  })
}

export default Controller;