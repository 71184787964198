import React from "react"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.BathroomSpec = false

const BathroomSpec = ({ bathrooms }) => {
  // if (window.configurator.debugPoints.BathroomSpec) {
  //   debugger
  // }

  return (
    <>
      <svg height="13" viewBox="0 0 24.784 12.805" width="13" xmlns="http://www.w3.org/2000/svg" className="configurator-mr-1 configurator-svg" style={{ marginTop: "-2px" }}>
        <g fill="#fffffe" fillRule="evenodd">
          <path d="M8.828 3.491V0H6.537v3.508C-.4 4.294 0 11.437 0 11.437h15.753C15.8 5 10.975 3.737 8.828 3.491M.032 13.58h15.807v-1.654H.032zM3.324 14.324a1.125 1.125 0 101.123 1.125 1.124 1.124 0 00-1.123-1.125M9.772 14.324a1.124 1.124 0 101.128 1.125 1.124 1.124 0 00-1.125-1.125M3.324 17.237a1.013 1.013 0 101.011 1.014 1.014 1.014 0 00-1.011-1.014M9.772 17.237a1.013 1.013 0 101.012 1.014 1.014 1.014 0 00-1.012-1.014M3.324 20.176a.854.854 0 10.854.854.856.856 0 00-.854-.854M9.772 20.176a.854.854 0 10.855.854.855.855 0 00-.855-.854M6.524 14.324a1.124 1.124 0 101.125 1.125 1.125 1.125 0 00-1.125-1.125M12.974 14.324a1.125 1.125 0 101.126 1.125 1.125 1.125 0 00-1.123-1.125M6.524 17.237a1.013 1.013 0 101.012 1.014 1.015 1.015 0 00-1.012-1.014M12.974 17.237a1.013 1.013 0 101.01 1.014 1.016 1.016 0 00-1.01-1.014M6.525 20.176a.854.854 0 10.854.854.857.857 0 00-.854-.854M12.974 20.176a.854.854 0 10.853.854.854.854 0 00-.853-.854"></path>
        </g>
      </svg>
      {bathrooms}
    </>
  )
}

export default BathroomSpec
