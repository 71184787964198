import React from "react"
import { useSelector, useDispatch } from "react-redux"
import cx from "classnames"

import { IcnCheck } from "images/icons"
import { toggleComparePlanList } from "./redux/actions"

const CompareButton = ({ homeDesignId, extra }) => {
  const dispatch = useDispatch()
  const compareList = useSelector(state => state.comparePlanList)
  const buttonActive = compareList.includes(parseInt(homeDesignId, 10))
  return (
    <button
      type="button"
      className={cx(
        "btn", "btn-sm", "btn-white", "btn-compare",
        { disabled: (!buttonActive && compareList.length >= 3) }
      )}
      style={buttonActive ? { background: "#EE1848", color: "white" } : {}}
      onClick={e => {
        e.preventDefault()
        dispatch(toggleComparePlanList({ homeDesignId, extra }))
      }}>
      {buttonActive ? (
        <div>
          <IcnCheck style={{ marginRight: "5px", marginLeft: "-4px" }} />
          Selected
        </div>
      ) : (
        "Compare"
      )}
    </button>
  )
}

export default CompareButton