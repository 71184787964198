import React, { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import Title from "components/common/PageTitle"
import Body from "./components/Body"
import ImageModal from "./components/ImageModal"
import { getGalleryData } from "./redux/actions/galleryData"

export default function GalleryRoot() {
  const dispatch = useDispatch()

  const { api_url: apiUrl } = useSelector(state => state.config.gallery)
  useEffect(() => {
    dispatch(getGalleryData({ apiUrl }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Title title="Photo & Video Gallery" />
      <Body />
      <ImageModal />
    </Fragment>
  )
}