/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import cx from "classnames"

import _ from "lodash"
import { updateFilter } from "../../redux/actions/galleryData"

export default function HomeTypeFilter() {
  const dispatch = useDispatch()
  const { by_design_type: design_filter } = useSelector(state => state.filters)

  function designTypeButton({ type_name, displayAs, icnClass }) {
    return (
      <li className="list-inline-item" style={{ height: "70px" }}>
        <div
          className={cx(
            "btn", "btn-home-type",
            { active: design_filter.includes(type_name) },
          )}
          onClick={() => dispatch(updateFilter({ by_design_type: _.xor(design_filter, [type_name]) }))}>
          <div className={`icn ${icnClass}`}></div>
          <p className="mb-0">{displayAs}</p>
        </div>
      </li>
    )
  }

  return (
    <div className="col-xl-12 col-lg-4 col-md-6 filter-col">
      <div className="title">Home Type</div>
      <ul className="list-inline mb-1">
        {designTypeButton({ type_name: "Single Storey", displayAs: "Single", icnClass: "icn-single" })}
        {designTypeButton({ type_name: "Loft", displayAs: "Loft", icnClass: "icn-loft" })}
        {designTypeButton({ type_name: "Double Storey", displayAs: "Double", icnClass: "icn-double" })}
      </ul>
      <ul className="list-inline mb-0">
        {designTypeButton({ type_name: "Granny Flat", displayAs: "Granny Flat", icnClass: "icn-grannyflat" })}
        {designTypeButton({ type_name: "Estate", displayAs: "Estate", icnClass: "icn-estate" })}
        {designTypeButton({ type_name: "Dual Occupancy", displayAs: "Dual Occupancy", icnClass: "icn-dual" })}
      </ul>
    </div>
  )
}