import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import swirl from "../images/BWH-Swirl-30.png"

const Loading = () => (
  <Container fluid="sm">
    <Row className="justify-content-center">
      <Col xs="auto">
        <img src={swirl} className="configurator-img bwh-swirl bwh-swirl-spin top-offset" alt="Loading Icon" />
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col xs="auto"><h1 className="top-offset">Loading...</h1></Col>
    </Row>
  </Container>
)

export default Loading
