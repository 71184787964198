/* eslint-disable no-use-before-define */
import BaseController from "controllers/base"
import $ from "jquery"
import swal from "sweetalert2"
import "jquery-ui-dist/jquery-ui.js"

import ajaxLoadForm from "widgets/cms/LoadEditForm"
import ajaxElementForms from "widgets/cms/ajaxUpdateForm"
import loadCKEditor from "widgets/cms/loadCKEditor"
import popUpGallery from "widgets/cms/imageGallery"

class Controller extends BaseController {
  call() {
    super.call()
    configBeforeLoadForm.call(this)
    loadForm.call(this)
  }
}

const configBeforeLoadForm = function () {
}

let loadForm = function () {
  ajaxLoadForm(bindAfterLoadForm)
}

const bindAfterLoadForm = function () {
  bindElementForms.call(this)
  popUpGallery.call(this)
  control_logos.call(this)
  sort_logo_list.call(this)
  loadCKEditor.call(this)
}

const bindElementForms = function () {
  ajaxElementForms(loadForm)
}

const sort_logo_list = function () {
  $("#logo-list").sortable({
    handle: ".drag-handle",
    update() {
      $(this).children("li").each((idx, node) => {
        $(node).find("input.logo-sort").first().val(idx)
      })
      // update form
      $("form#pages-form").each(function () {
        const form = $(this)
        const data = new FormData(this)
        $.ajax({
          method: form.attr("method"),
          data,
          url: form.attr("action"),
          contentType: false,
          processData: false,
          dataType: "json",
        })
      })
    },
  })
}

const fire_request = function (element, url) {
  $(element).attr("disabled", true)
  $(element).html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only">Loading...</span>')
  $.ajax({
    method: "get",
    url,
    success() {
      loadForm()
    }
  })
}

const control_logos = function () {
  $(".edit-logo").on("click", function (e) {
    e.preventDefault()
    e.stopPropagation()
    const add_logo_url = $(this).attr("href")
    const logo_id = $(this).attr("logo_id")
    const logo_url = $(`img[logo_id=${logo_id}]`).attr("src")
    let alert_html
    if (logo_url) {
      alert_html = `<img src="${logo_url}" style="width: 250px; height: auto;">`
    } else {
      alert_html = `<div>Empty Image</div>`
    }
    $("form#pages-form").each(function () {
      const form = $(this)
      const data = new FormData(this)
      // update form first then do add or minus
      $.ajax({
        method: form.attr("method"),
        data,
        url: form.attr("action"),
        contentType: false,
        processData: false,
        dataType: "json",
      })
    })
    if ($(this).hasClass("rm-logo")) {
      swal.fire({
        type: "warning",
        title: "Are you sure to remove this Partner Logo?",
        html: alert_html,
        confirmButtonText: "Confirm",
        showCancelButton: true,
      }).then(confirmed => {
        if (confirmed.value) {
          fire_request(this, add_logo_url)
        }
      })
    } else {
      fire_request(this, add_logo_url)
    }
  })
}

export default Controller