import React, {
  useContext, useEffect, useReducer, useRef, useState
} from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import ConfiguratorModel from "./Configurator-Model"
import Log from "./Util/configurator-log"

export const STEPS = {
  DESIGN: 0,
  FACADE: 1,
  SITE_COSTS: 2,
  INCLUSIONS: 3,
  CONFIGURATION: 4,
  CONTACT: 5,
  FINAL: 6
}

export const DESIGN_SORT_OPTIONS = {
  A_Z: { value: "a2z", label: "Name (A-Z)" },
  Z_A: { value: "z2a", label: "Name (Z-A)" },
  PRICE_LOW_HIGH: { value: "priceLowHigh", label: "Price (Lowest-Highest)" },
  PRICE_HIGH_LOW: { value: "priceHighLow", label: "Price (Highest-Lowest)" }
}

export const FACADE_SORT_OPTIONS = {
  DEFAULT: { value: "default", label: "Default" },
  A_Z: { value: "a2z", label: "Name (A-Z)" },
  Z_A: { value: "z2a", label: "Name (Z-A)" },
  PRICE_LOW_HIGH: { value: "priceLowHigh", label: "Price (Lowest-Highest)" },
  PRICE_HIGH_LOW: { value: "priceHighLow", label: "Price (Highest-Lowest)" }
}

export const ACTIONS = {
  SET_STEP_BACK: "setStepBack",
  SET_STEP_NEXT: "setStepNext",
  SET_NAME_FILTER: "setNameFilter",
  SET_SERIES_FILTERS: "setSeriesFilters",
  SET_BEDROOM_FILTERS: "setBedroomFilters",
  SET_BATHROOM_FILTERS: "setBathroomFilters",
  SET_GARAGE_FILTERS: "setGarageFilters",
  SET_PRICE_FILTERS: "setPriceFilters",
  SET_DISPLAY_BY: "setDisplayBy",
  SET_PRICE_LEVEL: "setPriceLevel",
  INITIALISE_APP: "initApp",
  INITIALISE_SECTIONS: "initSections",
  SELECT_DESIGN: "selectDesign",
  SELECT_FACADE: "selectFacade",
  SELECT_ROOF: "selectRoofType",
  SELECT_OPTION: "selectOption",
  SUBMIT_CONFIGURATION: "submitConfiguration",
  SORT_DESIGNS: "sortDesigns",
  SORT_FACADES: "sortFacades"
}

const INITIAL_STATE = {
  step: STEPS.DESIGN,
  nameFilter: "",
  designs: {
    loading: true,
    designs: [],
    error: ""
  },
  roofs: [],
  facades: [],
  sections: {
    loading: false,
    sections: [],
    error: ""
  },
  standardSiteCosts: [],
  standardInclusions: [],
  displayBy: "facade",
  selectedDesign: "",
  selectedFacade: "",
  selectedRoof: "",
  selectedPriceLevel: "",
  total: 0,
  siteCostTotal: 0,
  inclusionTotal: 0,
  submission: {
    loading: false,
    processed: false,
    error: ""
  }
}

const ConfiguratorContext = React.createContext()

export function useStep() { return useContext(ConfiguratorContext).reducerState.step }
export function useNameFilter() { return useContext(ConfiguratorContext).reducerState.nameFilter }
export function useDesigns() { return useContext(ConfiguratorContext).reducerState.designs }
export function useRoofs() { return useContext(ConfiguratorContext).reducerState.roofs }
export function useFacades() { return useContext(ConfiguratorContext).reducerState.facades }
export function useSections() { return useContext(ConfiguratorContext).reducerState.sections }
export function useStandardInclusions() { return useContext(ConfiguratorContext).reducerState.standardInclusions }
export function useStandardSiteCosts() { return useContext(ConfiguratorContext).reducerState.standardSiteCosts }
export function useDisplayBy() { return useContext(ConfiguratorContext).reducerState.displayBy }
export function useSelectedDesign() { return useContext(ConfiguratorContext).reducerState.selectedDesign }
export function useSelectedFacade() { return useContext(ConfiguratorContext).reducerState.selectedFacade }
export function useSelectedRoof() { return useContext(ConfiguratorContext).reducerState.selectedRoof }
export function useSelectedPriceLevel() { return useContext(ConfiguratorContext).reducerState.selectedPriceLevel }
export function useTotal() { return useContext(ConfiguratorContext).reducerState.total }
export function useSiteCostTotal() { return useContext(ConfiguratorContext).reducerState.siteCostTotal }
export function useInclusionTotal() { return useContext(ConfiguratorContext).reducerState.inclusionTotal }
export function useSubmission() { return useContext(ConfiguratorContext).reducerState.submission }

export function useDispatch() { return useContext(ConfiguratorContext).dispatch }

export function useContactFormik() { return useContext(ConfiguratorContext).formik }

export function useContactFormValid() { return useContext(ConfiguratorContext).contactFormValid }

const schema = yup.object({
  firstName: yup.string()
    .max(32, "Must be 32 characters or less")
    .required("Required"),
  surname: yup.string()
    .max(32, "Must be 32 characters or less")
    .required("Required"),
  preferredContactMethod: yup.string()
    .oneOf(
      ["email", "mobile"],
      "Invalid Contact Method"
    )
    .required("Required"),
  email: yup.string()
    .email("Invalid email address")
    .required("Required"),
  mobile: yup.string()
    .required("Required")
    .min(7, "Must be at least 7 digits"),
  currentPostcode: yup.string()
    .max(4, "Must be 4 digits")
    .min(4, "Must be 4 digits")
    .required("Required"),
  intendedBuildLocation: yup.string()
    .required("Required"),
  isFirstHomeBuyer: yup.string()
    .oneOf(["firsthomebuyer", "previoushomeowner"], "Invalid option")
    .required("Required"),
  lookingToBuild: yup.string()
    .oneOf(["newhome", "knockdownrebuild"], "Invalid option")
    .required("Required"),
  liveInvestBoth: yup.string()
    .oneOf(["livein", "investment", "both"], "Invalid option")
    .required("Required")
})

export function ConfiguratorContextProvider({ children }) {
  const newModel = useRef(new ConfiguratorModel())
  const reducer = (state, action) => {
    let newState
    let newDesigns
    let selectedDesignFound
    let newRoofs
    let selectedRoofFound
    let newFacades
    // let newModel

    // if (window.configurator.debugPoints.reducer) {
    //   debugger
    // }

    switch (action.type) {
      case ACTIONS.SET_STEP_BACK:
        switch (state.step) {
          case STEPS.DESIGN:
            return state

          case STEPS.FACADE:
            newState = { ...state, step: STEPS.DESIGN, total: newModel.current?.getTotal(STEPS.DESIGN) }
            break

          case STEPS.SITE_COSTS:
            newState = { ...state, step: STEPS.FACADE, total: newModel.current.getTotal(STEPS.FACADE) }
            break

          case STEPS.INCLUSIONS:
            newState = { ...state, step: STEPS.SITE_COSTS, total: newModel.current.getTotal(STEPS.SITE_COSTS) }
            break

          case STEPS.CONFIGURATION:
            newState = { ...state, step: STEPS.INCLUSIONS, total: newModel.current.getTotal(STEPS.INCLUSIONS) }
            break

          case STEPS.CONTACT:
            newState = { ...state, step: STEPS.CONFIGURATION, total: newModel.current.getTotal(STEPS.CONFIGURATION) }
            break

          case STEPS.FINAL:
            newState = { ...state, step: STEPS.CONTACT, total: newModel.current.getTotal(STEPS.CONTACT) }
            break
          default:
            return state
        }
        break

      case ACTIONS.SET_STEP_NEXT:
        switch (state.step) {
          case STEPS.DESIGN:
            newState = { ...state, step: STEPS.FACADE, total: newModel.current.getTotal(STEPS.FACADE) }
            break

          case STEPS.FACADE:
            newState = { ...state, step: STEPS.SITE_COSTS, total: newModel.current.getTotal(STEPS.SITE_COSTS) }
            break

          case STEPS.SITE_COSTS:
            newState = { ...state, step: STEPS.INCLUSIONS, total: newModel.current.getTotal(STEPS.INCLUSIONS) }
            break

          case STEPS.INCLUSIONS:
            newState = { ...state, step: STEPS.CONFIGURATION, total: newModel.current.getTotal(STEPS.CONFIGURATION) }
            break

          case STEPS.CONFIGURATION:
            newState = { ...state, step: STEPS.CONTACT, total: newModel.current.getTotal(STEPS.CONTACT) }
            break

          case STEPS.CONTACT:
            newState = { ...state, step: STEPS.FINAL, total: newModel.current.getTotal(STEPS.FINAL) }
            break

          case STEPS.FINAL:
            return state

          default:
            return state
        }
        break

      case ACTIONS.SET_NAME_FILTER:
        if (!newModel.current.getDesignList().length) return state

        if (action.payload.nameFilter !== newModel.current.getNameFilter()) {
          newModel.current.setNameFilter(action.payload.nameFilter)
        }

        newDesigns = newModel.current.getFilteredDesigns()
        newState = {
          ...state,
          designs: {
            loading: false,
            designs: newDesigns,
            error: ""
          }
        }

        if (state.selectedDesign || newModel.current.getSelectedDesign()) {
          selectedDesignFound = newDesigns.filter(design => design.getInternalID() === newModel.current.getSelectedDesign()).length

          if (!selectedDesignFound) {
            newModel.current.setSelectedDesign("")
            newModel.current.setSelectedFacade("")

            newState.selectedDesign = ""
            newState.selectedFacade = ""
            newState.facades = []

            newRoofs = newModel.current.getRoofs()
            newState.roofs = newRoofs

            if (state.selectedRoof || newModel.current.getSelectedRoof()) {
              selectedRoofFound = newRoofs.filter(roof => roof.getInternalID() === newModel.current.getSelectedRoof()).length

              if (!selectedRoofFound) {
                newModel.current.setSelectedRoof("")
                newState.selectedRoof = ""
              }
            }

            newState.total = newModel.current.getTotal(state.step)
          }
        }

        break

      case ACTIONS.SET_SERIES_FILTERS:
        if (action.payload.filters.join(":") !== newModel.current.getSeriesFilters().join(":")) {
          newModel.current.setSeriesFilters(action.payload.filters)
        }

        newDesigns = newModel.current.getFilteredDesigns()
        newState = {
          ...state,
          designs: {
            loading: false,
            designs: newDesigns,
            error: ""
          }
        }

        if (state.selectedDesign || newModel.current.getSelectedDesign()) {
          selectedDesignFound = newDesigns.filter(design => design.getInternalID() === newModel.current.getSelectedDesign()).length

          if (!selectedDesignFound) {
            newModel.current.setSelectedDesign("")
            newModel.current.setSelectedFacade("")

            newState.selectedDesign = ""
            newState.selectedFacade = ""
            newState.facades = []

            newRoofs = newModel.current.getRoofs()
            newState.roofs = newRoofs

            if (state.selectedRoof || newModel.current.getSelectedRoof()) {
              selectedRoofFound = newRoofs.filter(roof => roof.getInternalID() === newModel.current.getSelectedRoof()).length

              if (!selectedRoofFound) {
                newModel.current.setSelectedRoof("")
                newState.selectedRoof = ""
              }
            }

            newState.total = newModel.current.getTotal(state.step)
          }
        }
        break

      case ACTIONS.SET_BEDROOM_FILTERS:
        if (action.payload.bedroomFilters.join(":") !== newModel.current.getBedroomFilters().join(":")) {
          newModel.current.setBedroomFilters(action.payload.bedroomFilters)
        }

        newDesigns = newModel.current.getFilteredDesigns()
        newState = {
          ...state,
          designs: {
            loading: false,
            designs: newDesigns,
            error: ""
          }
        }

        if (state.selectedDesign || newModel.current.getSelectedDesign()) {
          selectedDesignFound = newDesigns.filter(design => design.getInternalID() === newModel.current.getSelectedDesign()).length

          if (!selectedDesignFound) {
            newModel.current.setSelectedDesign("")
            newModel.current.setSelectedFacade("")

            newState.selectedDesign = ""
            newState.selectedFacade = ""
            newState.facades = []

            newRoofs = newModel.current.getRoofs()
            newState.roofs = newRoofs

            if (state.selectedRoof || newModel.current.getSelectedRoof()) {
              selectedRoofFound = newRoofs.filter(roof => roof.getInternalID() === newModel.current.getSelectedRoof()).length

              if (!selectedRoofFound) {
                newModel.current.setSelectedRoof("")
                newState.selectedRoof = ""
              }
            }

            newState.total = newModel.current.getTotal(state.step)
          }
        }
        break

      case ACTIONS.SET_BATHROOM_FILTERS:
        if (action.payload.bathroomFilters.join(":") !== newModel.current.getBathroomFilters().join(":")) {
          newModel.current.setBathroomFilters(action.payload.bathroomFilters)
        }

        newDesigns = newModel.current.getFilteredDesigns()
        newState = {
          ...state,
          designs: {
            loading: false,
            designs: newDesigns,
            error: ""
          }
        }

        if (state.selectedDesign || newModel.current.getSelectedDesign()) {
          selectedDesignFound = newDesigns.filter(design => design.getInternalID() === newModel.current.getSelectedDesign()).length

          if (!selectedDesignFound) {
            newModel.current.setSelectedDesign("")
            newModel.current.setSelectedFacade("")

            newState.selectedDesign = ""
            newState.selectedFacade = ""
            newState.facades = []

            newRoofs = newModel.current.getRoofs()
            newState.roofs = newRoofs

            if (state.selectedRoof || newModel.current.getSelectedRoof()) {
              selectedRoofFound = newRoofs.filter(roof => roof.getInternalID() === newModel.current.getSelectedRoof()).length

              if (!selectedRoofFound) {
                newModel.current.setSelectedRoof("")
                newState.selectedRoof = ""
              }
            }

            newState.total = newModel.current.getTotal(state.step)
          }
        }
        break

      case ACTIONS.SET_GARAGE_FILTERS:
        if (action.payload.garageFilters.join(":") !== newModel.current.getGarageFilters().join(":")) {
          newModel.current.setGarageFilters(action.payload.garageFilters)
        }

        newDesigns = newModel.current.getFilteredDesigns()
        newState = {
          ...state,
          designs: {
            loading: false,
            designs: newDesigns,
            error: ""
          }
        }

        if (state.selectedDesign || newModel.current.getSelectedDesign()) {
          selectedDesignFound = newDesigns.filter(design => design.getInternalID() === newModel.current.getSelectedDesign()).length

          if (!selectedDesignFound) {
            newModel.current.setSelectedDesign("")
            newModel.current.setSelectedFacade("")

            newState.selectedDesign = ""
            newState.selectedFacade = ""
            newState.facades = []

            newRoofs = newModel.current.getRoofs()
            newState.roofs = newRoofs

            if (state.selectedRoof || newModel.current.getSelectedRoof()) {
              selectedRoofFound = newRoofs.filter(roof => roof.getInternalID() === newModel.current.getSelectedRoof()).length

              if (!selectedRoofFound) {
                newModel.current.setSelectedRoof("")
                newState.selectedRoof = ""
              }
            }

            newState.total = newModel.current.getTotal(state.step)
          }
        }
        break

      case ACTIONS.SET_PRICE_FILTERS:
        if (action.payload.priceFilters.join(":") !== newModel.current.getPriceFilters().join(":")) {
          newModel.current.setPriceFilters(action.payload.priceFilters)
        }

        newDesigns = newModel.current.getFilteredDesigns()
        newState = {
          ...state,
          designs: {
            loading: false,
            designs: newDesigns,
            error: ""
          }
        }

        if (state.selectedDesign || newModel.current.getSelectedDesign()) {
          selectedDesignFound = newDesigns.filter(design => design.getInternalID() === newModel.current.getSelectedDesign()).length

          if (!selectedDesignFound) {
            newModel.current.setSelectedDesign("")
            newModel.current.setSelectedFacade("")

            newState.selectedDesign = ""
            newState.selectedFacade = ""
            newState.facades = []

            newRoofs = newModel.current.getRoofs()
            newState.roofs = newRoofs

            if (state.selectedRoof || newModel.current.getSelectedRoof()) {
              selectedRoofFound = newRoofs.filter(roof => roof.getInternalID() === newModel.current.getSelectedRoof()).length

              if (!selectedRoofFound) {
                newModel.current.setSelectedRoof("")
                newState.selectedRoof = ""
              }
            }

            newState.total = newModel.current.getTotal(state.step)
          }
        }
        break

      case ACTIONS.SET_DISPLAY_BY:
        newState = {
          ...state,
          displayBy: action.payload.displayBy
        }
        break

      case ACTIONS.SET_PRICE_LEVEL:
        if (action.payload.priceLevel !== newModel.current.getSelectedPriceLevel()) {
          newModel.current.setSelectedPriceLevel(action.payload.priceLevel)
        }

        newState = {
          ...state,
          selectedPriceLevel: newModel.current.getSelectedPriceLevel(),
          total: newModel.current.getTotal(state.step),
          siteCostTotal: newModel.current.getStandardSiteCostsTotal()
        }
        break

      case ACTIONS.INITIALISE_APP:
        newState = {
          ...state,
          designs: action.payload.designs,
          facades: [],
          roofs: action.payload.roofs,
          standardInclusions: action.payload.standardInclusions,
          inclusionTotal: newModel.current.getStandardInclusionsTotal(),
          standardSiteCosts: action.payload.standardSiteCosts,
          siteCostTotal: newModel.current.getStandardSiteCostsTotal(),
          selectedDesign: "",
          selectedFacade: ""
        }
        break

      case ACTIONS.SELECT_DESIGN:
        if (action.payload.selectedDesign !== newModel.current.getSelectedDesign()) {
          newModel.current.setSelectedDesign(action.payload.selectedDesign)
          newModel.current.setSelectedFacade("")
        }

        newRoofs = newModel.current.getRoofs()

        newState = {
          ...state,
          selectedDesign: action.payload.selectedDesign,
          selectedFacade: newModel.current.getSelectedFacade(),
          total: newModel.current.getTotal(state.step),
          facades: newModel.current.getFilteredFacades(),
          standardInclusions: newModel.current.getStandardInclusions(),
          inclusionTotal: newModel.current.getStandardInclusionsTotal(),
          standardSiteCosts: newModel.current.getStandardSiteCosts(),
          siteCostTotal: newModel.current.getStandardSiteCostsTotal(),
          roofs: newRoofs
        }

        if (state.selectedRoof || newModel.current.getSelectedRoof()) {
          selectedRoofFound = newRoofs.filter(roof => roof.getInternalID() === newModel.current.getSelectedRoof()).length

          if (!selectedRoofFound) {
            newModel.current.setSelectedRoof("")
            state.selectedRoof = ""
            state.total = newModel.current.getTotal(state.step)
          }
        }
        break

      case ACTIONS.SELECT_FACADE:
        if (action.payload.selectedFacade !== newModel.current.getSelectedFacade()) {
          newModel.current.setSelectedFacade(action.payload.selectedFacade)
        }

        newRoofs = newModel.current.getRoofs()

        newState = {
          ...state,
          total: newModel.current.getTotal(state.step),
          selectedFacade: action.payload.selectedFacade,
          standardInclusions: newModel.current.getStandardInclusions(),
          inclusionTotal: newModel.current.getStandardInclusionsTotal(),
          standardSiteCosts: newModel.current.getStandardSiteCosts(),
          siteCostTotal: newModel.current.getStandardSiteCostsTotal(),
          roofs: newRoofs
        }

        if (state.selectedRoof || newModel.current.getSelectedRoof()) {
          selectedRoofFound = newRoofs.filter(roof => roof.getInternalID() === newModel.current.getSelectedRoof()).length

          if (!selectedRoofFound) {
            newModel.current.setSelectedRoof("")
            state.selectedRoof = ""
            state.total = newModel.current.getTotal(state.step)
          }
        }
        break

      case ACTIONS.SELECT_ROOF:
        if (action.payload.roofType !== newModel.current.getSelectedRoof()) {
          newModel.current.setSelectedRoof(action.payload.roofType)
        }

        newState = {
          ...state,
          selectedRoof: action.payload.roofType,
          total: newModel.current.getTotal(state.step)
        }
        break

      case ACTIONS.INITIALISE_SECTIONS:
        newState = {
          ...state,
          sections: action.payload.sections,
          total: newModel.current.getTotal(state.step)
        }
        break

      case ACTIONS.SELECT_OPTION:
        newModel.current.calculateSelections(action.payload.assignedID, action.payload.checked)

        newState = {
          ...state,
          sections: {
            loading: false,
            sections: newModel.current.getSectionList(),
            error: ""
          },
          total: newModel.current.getTotal(state.step)
        }
        break

      case ACTIONS.SUBMIT_CONFIGURATION:
        newState = {
          ...state,
          submission: action.payload.submission
        }
        break

      case ACTIONS.SORT_DESIGNS:
        newDesigns = {
          loading: state.designs.loading,
          designs: state.designs.designs.slice(),
          error: state.designs.error
        }

        switch (action.payload.option) {
          case DESIGN_SORT_OPTIONS.A_Z.value:
            newDesigns.designs.sort((a, b) => a.getName().localeCompare(b.getName()))
            break

          case DESIGN_SORT_OPTIONS.Z_A.value:
            newDesigns.designs.sort((a, b) => b.getName().localeCompare(a.getName()))
            break
          case DESIGN_SORT_OPTIONS.PRICE_LOW_HIGH.value:
            newDesigns.designs.sort((a, b) => Number(a.getItem().getPriceWithTax(state.selectedPriceLevel || "1")) - Number(b.getItem().getPriceWithTax(state.selectedPriceLevel || "1")))
            break
          case DESIGN_SORT_OPTIONS.PRICE_HIGH_LOW.value:
            newDesigns.designs.sort((a, b) => Number(b.getItem().getPriceWithTax(state.selectedPriceLevel || "1")) - Number(a.getItem().getPriceWithTax(state.selectedPriceLevel || "1")))
            break

          default:
            return state
        }

        newState = {
          ...state,
          designs: newDesigns
        }
        break

      case ACTIONS.SORT_FACADES:
        newFacades = state.facades.slice()

        switch (action.payload.option) {
          case FACADE_SORT_OPTIONS.DEFAULT.value:
            newFacades = newModel.current.getFilteredFacades()
            break

          case FACADE_SORT_OPTIONS.A_Z.value:
            newFacades.sort((a, b) => a.getName().localeCompare(b.getName()))
            break

          case FACADE_SORT_OPTIONS.Z_A.value:
            newFacades.sort((a, b) => b.getName().localeCompare(a.getName()))
            break

          case FACADE_SORT_OPTIONS.PRICE_LOW_HIGH.value:
            newFacades.sort((a, b) => Number(a.getItem().getPriceWithTax(state.selectedPriceLevel || "1")) - Number(b.getItem().getPriceWithTax(state.selectedPriceLevel || "1")))
            break

          case FACADE_SORT_OPTIONS.PRICE_HIGH_LOW.value:
            newFacades.sort((a, b) => Number(b.getItem().getPriceWithTax(state.selectedPriceLevel || "1")) - Number(a.getItem().getPriceWithTax(state.selectedPriceLevel || "1")))
            break

          default:
            return state
        }

        newState = {
          ...state,
          facades: newFacades
        }
        break

      default:
        return state
    }

    Log.debug({
      title: "New State",
      details: {
        actionType: action.type,
        actionPayload: action.payload,
        newState
      }
    })

    return newState
  }
  const [reducerState, dispatch] = useReducer(reducer, INITIAL_STATE)
  const formik = useFormik({
    initialValues: {
      firstName: "",
      surname: "",
      preferredContactMethod: "email",
      email: "",
      mobile: "",
      currentPostcode: "",
      intendedBuildLocation: "",
      isFirstHomeBuyer: "",
      lookingToBuild: "",
      liveInvestBoth: "",
      message: "",
      subscribe: true
    },
    validationSchema: schema,
    onSubmit: () => {
      // eslint-disable-next-line no-alert
      // alert(JSON.stringify(values, null, 2))
    },
  })
  const [contactFormValid, setContactFormValid] = useState(false)

  /**
   * Effect to keep contactFormValid state variable up to date
   */
  useEffect(() => {
    if (!formik.values.firstName || !formik.values.surname || !formik.values.preferredContactMethod || !formik.values.email
      || !formik.values.mobile || !formik.values.currentPostcode || !formik.values.intendedBuildLocation || !formik.values.isFirstHomeBuyer
      || !formik.values.lookingToBuild || !formik.values.liveInvestBoth) {
      if (contactFormValid) setContactFormValid(false)
    } else if (contactFormValid !== formik.isValid) setContactFormValid(formik.isValid)
    if (JSON.stringify(formik.values) !== JSON.stringify(newModel.current.getFormValues())) {
      newModel.current.setFormValues(formik.values)
    }
  }, [contactFormValid, formik.isValid, formik.values])

  // initialisation on load - gets series, designs, facades, roofs, standard inclusions and standard site costs from server
  useEffect(() => {
    const abortController = new AbortController()

    const getInitData = async () => {
      await newModel.current.initialiseData(abortController, dispatch)
    }
    getInitData()

    return () => abortController?.abort()
  }, [])

  // initialisation on change to selectedFacade - source configuration options
  useEffect(() => {
    const abortController = new AbortController()

    const getConfigData = async () => {
      await newModel.current.initialiseSections(abortController, dispatch)
    }
    getConfigData()

    return () => abortController?.abort()
  }, [reducerState.selectedDesign, reducerState.selectedFacade])

  useEffect(() => {
    if (window.isProcessing) return

    if (reducerState.step !== STEPS.FINAL) return

    window.isProcessing = true

    const abortController = new AbortController()

    const submitConfiguration = async () => {
      await newModel.current.submitToBackend(abortController, dispatch)
    }

    submitConfiguration()

    // return () => abortController?.abort()
  }, [reducerState.step, reducerState.submission.loading, reducerState.submission.processed])

  useEffect(() => {
    window.scrollTo(0, 0)
    const configuratorProgress = document.getElementsByClassName("configurator-progress")[0]

    switch (reducerState.step) {
      case STEPS.DESIGN:
        configuratorProgress.style.setProperty("--configurator-progress", 10)
        break

      case STEPS.FACADE:
        configuratorProgress.style.setProperty("--configurator-progress", 25)
        break

      case STEPS.SITE_COSTS:
        configuratorProgress.style.setProperty("--configurator-progress", 40)
        break

      case STEPS.INCLUSIONS:
        configuratorProgress.style.setProperty("--configurator-progress", 55)
        break

      case STEPS.CONFIGURATION:
        configuratorProgress.style.setProperty("--configurator-progress", 70)
        break

      case STEPS.CONTACT:
        configuratorProgress.style.setProperty("--configurator-progress", 85)
        break

      case STEPS.FINAL:
        configuratorProgress.style.setProperty("--configurator-progress", 100)
        break

      default:
        break
    }
  }, [reducerState.step])

  return (
    <ConfiguratorContext.Provider value={{
      reducerState,
      dispatch,
      formik,
      contactFormValid
    }}>
      {children}
    </ConfiguratorContext.Provider>
  )
}
