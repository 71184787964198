import BaseController from "controllers/base"
import startApp from "apps/LandForSaleDetail"
import { cmsFormControl, cmsFormFileDropable } from "servos/cms/ajaxForm"

class Controller extends BaseController {
  call() {
    super.call()

    startApp(
      document.getElementById("land-for-sale"),
      {}
    )

    cmsFormControl.call(this)
    cmsFormFileDropable.call(this)
  }
}

export default Controller