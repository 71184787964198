/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import { connect, useSelector } from "react-redux"
import { Animated } from "react-animated-css"

import { resetComparePlanList } from "components/common/CompareButton/redux/actions"
import { getHomeDesignIds, getHomeDesignObj } from "modules/HomeDesign/reducers"
import ComparedHomeItem from "./comparedItems"

const CompareBar = ({ resetCompare }) => {
  const compareList = useSelector(state => state.comparePlanList)
  const fetchedHomeDesignIds = useSelector(state => getHomeDesignIds(state))
  const homeDesigns = useSelector(state => getHomeDesignObj(state))
  const compareUrl = `/compare-homes/${compareList.join("/")}`

  return (
    <div className="compare-helper">
      <Animated className="animated" animationIn="fadeInUp" animationOut="fadeOutDown" animationInDuration={500} animationOutDuration={500} isVisible={fetchedHomeDesignIds.length > 0 && compareList.length > 0}>
        <div className="container-fluid d-flex justify-content-between" style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}>
          <ul className="list-inline mb-0 d-none d-lg-block">
            {compareList.map(homeId => <ComparedHomeItem key={`compare-card-${homeId}`} homeDesign={homeDesigns[homeId]} homeDesignId={homeId} />)}
          </ul>
          <ul className="list-inline mb-0 mx-auto mr-lg-0">
            <li className="list-inline-item">
              <a
                className="btn btn-red btn-md mr-2"
                href={compareUrl}>
                {`Compare ${compareList.length} of 3`}
              </a>
            </li>
            <li className="list-inline-item">
              <div
                className="text-link small-text"
                onClick={() => resetCompare()}
                onKeyDown={() => resetCompare()}>
                Clear All
              </div>
            </li>
          </ul>
        </div>
      </Animated>
    </div>
  )
}

export default connect(
  null,
  { resetCompare: resetComparePlanList }
)(CompareBar)