import React from "react"
import { useSiteCostTotal } from "../../configurator-context-provider"
import SiteCostsList from "./SiteCostsList"
import Util from "../../Util/configurator-util"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.SiteCostsListView = false

const SiteCostsListView = () => {
  // if (window.configurator.debugPoints.SiteCostsListView) {
  //   debugger
  // }

  const siteCostTotal = useSiteCostTotal()

  return (
    <div className="home-deisgns">
      <div className="page-header text-center">
        <h1>Standard Site Costs</h1>
        <h5>
          {siteCostTotal > 0 ? Util.formatAmount(siteCostTotal) : "Included in Base Price"}
        </h5>
      </div>
      <SiteCostsList />
    </div>
  )
}

export default SiteCostsListView
