import * as types from "../actionTypes"

export const getGalleryData = payload => ({
  type: types.FETCH_REQUESTED,
  payload
})

export const fetchDataSuccess = payload => ({
  type: types.FETCH_SUCCESS,
  payload
})

export const fetchDataFailed = error => ({
  type: types.FETCH_FAILED,
  payload: error
})

export const updateFilter = payload => ({
  type: types.UPDATE_FILTER,
  payload
})

export const filterUpdated = payload => ({
  type: types.FILTER_UPDATED,
  payload
})

export const dataUpdated = payload => ({
  type: types.DATA_UPDATED,
  payload
})