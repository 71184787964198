export const FAVOURITE_PLANS = "FAVOURITE_PLANS"
export const FAVOURITE_LANDS = "FAVOURITE_LANDS"
export const FAVOURITE_HOMES = "FAVOURITE_HOMES"
export const COMPARE_PLANS = "COMPARE_PLANS"
export const FAVOURITE_LAND_FOR_SALE = "FAVOURITE_LAND_FOR_SALE"

export const resetList = listName => {
  localStorage.setItem(listName, JSON.stringify([]))
}

export const setList = (listName, list) => {
  localStorage.setItem(listName, JSON.stringify(list))
}

export const getListLength = listName => {
  try {
    const storedArray = JSON.parse(localStorage.getItem(listName))
    return storedArray.length
  } catch (err) {
    console.error(err)
    resetList(listName)
    return 0
  }
}

// get array of favourite plan ids as integer
// if localStorage item 'favourite_plans' has error, reset it
export const getList = listName => {
  try {
    const storedArray = JSON.parse(localStorage.getItem(listName))
    if (Array.isArray(storedArray)) {
      const integerArray = []
      let isUpdated = false
      for (let i = 0, len = storedArray.length; i < len; i += 1) {
        if (!Number.isInteger(storedArray[i])) {
          isUpdated = true
        }
        const integer = parseInt(storedArray[i], 10)
        if (!Number.isNaN(integer)) {
          integerArray.push(integer)
        }
      }
      if (isUpdated) {
        setList(listName, integerArray)
      }
      return integerArray
    }
    resetList(listName)
    return []
  } catch (err) {
    console.error(err)
    resetList(listName)
    return []
  }
}

export const addIdToList = (listName, planId) => {
  try {
    setList(listName, [...getList(listName), parseInt(planId, 10)])
    return true
  } catch (err) {
    return false
  }
}
