import Util from "../Util/configurator-util"
import GroupMember from "./configurator-groupmember"

function GroupItem(ctx) {
  this.internalID = ""
  this.name = ""
  this.invalidSeries = []
  this.invalidDesigns = []
  this.invalidFacades = []
  this.memberItems = []

  if (!ctx) return

  this.setInternalID(ctx.internalID || "")
  this.setName(ctx.name || "")
  this.addInvalidSeries(ctx.invalidSeries || [])
  this.addInvalidDesigns(ctx.invalidDesigns || [])
  this.addInvalidFacades(ctx.invalidFacades || [])
  if (ctx.memberItems) {
    Util.each(ctx.memberItems, memberItem => {
      this.addMemberItems(new GroupMember(memberItem))
    })
  }
}

GroupItem.prototype.getInternalID = function () { return this.internalID }
GroupItem.prototype.setInternalID = function (internalID) {
  if (!Util.isString(internalID)) {
    throw new TypeError("Invalid parameter internalID: must be String")
  }

  this.internalID = internalID
}

GroupItem.prototype.getName = function () { return this.name }
GroupItem.prototype.setName = function (name) {
  if (!Util.isString(name)) {
    throw new TypeError("Invalid parameter name: must be String")
  }

  this.name = name
}

GroupItem.prototype.getInvalidSeries = function () { return this.invalidSeries }
GroupItem.prototype.addInvalidSeries = function (seriesIDs) {
  if (Util.isArray(seriesIDs)) {
    Util.each(seriesIDs, internalID => {
      if (!Util.isString(internalID)) {
        throw new TypeError("Invalid Invalid Series: must be String|String[]")
      }
    })

    Util.each(seriesIDs, internalID => {
      if (this.invalidSeries.indexOf(internalID) < 0) {
        this.invalidSeries.push(internalID)
      }
    })
  } else if (Util.isString(seriesIDs)) {
    if (this.invalidSeries.indexOf(seriesIDs) < 0) {
      this.invalidSeries.push(seriesIDs)
    }
  } else {
    throw new TypeError("Invalid Invalid Series: must be String|String[]")
  }
}

GroupItem.prototype.getInvalidDesigns = function () { return this.invalidDesigns }
GroupItem.prototype.addInvalidDesigns = function (designIDs) {
  if (Util.isArray(designIDs)) {
    Util.each(designIDs, internalID => {
      if (!Util.isString(internalID)) {
        throw new TypeError("Invalid Invalid Design: must be String|String[]")
      }
    })

    Util.each(designIDs, internalID => {
      if (this.invalidDesigns.indexOf(internalID) < 0) {
        this.invalidDesigns.push(internalID)
      }
    })
  } else if (Util.isString(designIDs)) {
    if (this.invalidDesigns.indexOf(designIDs) < 0) {
      this.invalidDesigns.push(designIDs)
    }
  } else {
    throw new TypeError("Invalid Invalid Design: must be String|String[]")
  }
}

GroupItem.prototype.getInvalidFacades = function () { return this.invalidFacades }
GroupItem.prototype.addInvalidFacades = function (facadeIDs) {
  if (Util.isArray(facadeIDs)) {
    Util.each(facadeIDs, internalID => {
      if (!Util.isString(internalID)) {
        throw new TypeError("Invalid Invalid Facade: must be String|String[]")
      }
    })

    Util.each(facadeIDs, internalID => {
      if (this.invalidFacades.indexOf(internalID) < 0) {
        this.invalidFacades.push(internalID)
      }
    })
  } else if (Util.isString(facadeIDs)) {
    if (this.invalidFacades.indexOf(facadeIDs) < 0) {
      this.invalidFacades.push(facadeIDs)
    }
  } else {
    throw new TypeError("Invalid Invalid Facade: must be String|String[]")
  }
}

GroupItem.prototype.getMemberItems = function (selectedSeries, selectedDesign, selectedFacade) {
  return this.memberItems.filter(memberItem => {
    if (selectedSeries && memberItem.getInvalidSeries().indexOf(selectedSeries) >= 0) return false
    if (selectedDesign && memberItem.getInvalidDesigns().indexOf(selectedDesign) >= 0) return false
    if (selectedFacade && memberItem.getInvalidFacades().indexOf(selectedFacade) >= 0) return false
    return true
  })
}
GroupItem.prototype.addMemberItems = function (memberItems) {
  if (Util.isArray(memberItems)) {
    Util.each(memberItems, item => {
      if (item.toString() !== (new GroupMember()).toString()) {
        throw new TypeError("Invalid parameter memberItems: must be GroupMember|GroupMember[]")
      }
    })

    Util.each(memberItems, item => {
      this.memberItems.push(item)
    })
  } else if (memberItems.toString() === (new GroupMember()).toString()) {
    this.memberItems.push(memberItems)
  } else {
    throw new TypeError("Invalid Invalid memberItems: must be GroupMember|GroupMember[]")
  }
}

GroupItem.prototype.getTotalPrice = function (level, selectedSeries, selectedDesign, selectedFacade) {
  let candidates
  let toReturn

  if (!Util.isString(level)) {
    throw new TypeError("Invalid parameter level: must be String")
  }

  candidates = this.memberItems.slice()
  if (selectedSeries) candidates = candidates.filter(item => item.getInvalidSeries().indexOf(selectedSeries) < 0)
  if (selectedDesign) candidates = candidates.filter(item => item.getInvalidDesigns().indexOf(selectedDesign) < 0)
  if (selectedFacade) candidates = candidates.filter(item => item.getInvalidFacades().indexOf(selectedFacade) < 0)

  toReturn = 0

  Util.each(candidates, item => {
    toReturn += Number(item.getTotalPrice(level))
  })

  return toReturn
}
GroupItem.prototype.getTotalPriceWithTax = function (level, selectedSeries, selectedDesign, selectedFacade) {
  let candidates
  let toReturn

  if (!Util.isString(level)) {
    throw new TypeError("Invalid parameter level: must be String")
  }

  candidates = this.memberItems.slice()
  if (selectedSeries) candidates = candidates.filter(item => item.getInvalidSeries().indexOf(selectedSeries) < 0)
  if (selectedDesign) candidates = candidates.filter(item => item.getInvalidDesigns().indexOf(selectedDesign) < 0)
  if (selectedFacade) candidates = candidates.filter(item => item.getInvalidFacades().indexOf(selectedFacade) < 0)

  toReturn = 0

  Util.each(candidates, item => {
    toReturn += Number(item.getTotalPriceWithTax(level))
  })

  return toReturn
}

Object.defineProperty(GroupItem.prototype, "toString", {
  value: () => "[object GroupItem]"
})

export default GroupItem
