import * as constants from "../../constants"

const allNews = (state = {}, action) => {
  switch (action.type) {
    case constants.FETCH_SUCCESS: {
      const { records, meta: { category } } = action.payload
      const existingRecords = state[category] || {}
      const allRecords = { ...existingRecords, ...records }
      return { ...state, [category]: allRecords }
    }
    default:
      return state
  }
}

export default allNews
