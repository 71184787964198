/* eslint-disable react/no-array-index-key */
import React from "react"
import Carousel from "react-bootstrap/Carousel"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.FloorplanCarousel = false

const FloorplanCarousel = ({ floorplans }) => {
  // if (window.configurator.debugPoints.FloorplanCarousel) {
  //   debugger
  // }

  const labels = ["Ground Floor", "First Floor"]

  if (floorplans.length > 1) {
    return (
      <Carousel
        controls
        fade
        indicators
        indicatorLabels={labels}
        interval={null}
        variant="dark">
        {floorplans.map((url, idx) => (
          <Carousel.Item key={idx}>
            <img className="configurator-img d-block w-100" src={url} alt={labels[idx]} />
            <Carousel.Caption className="below mb-2">
              <p>{labels[idx]}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    )
  }
  return (
    <Carousel
      controls={false}
      fade
      indicators={false}
      indicatorLabels={null}
      interval={null}
      variant="dark">
      {floorplans.map((url, idx) => (
        <Carousel.Item key={idx}>
          <img className="configurator-img d-block w-100" src={url} alt={labels[idx]} />
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default FloorplanCarousel
