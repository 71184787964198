import React from "react"
import BedroomSpec from "./BedroomSpec"
import BathroomSpec from "./BathroomSpec"
import GarageSpec from "./GarageSpec"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.DesignSpec = false

const DesignSpec = ({ bedrooms, bathrooms, garages }) => {
  // if (window.configurator.debugPoints.DesignSpec) {
  //   debugger
  // }

  return (
    <ul className="list-inline design-spec small">
      <li className="list-inline-item">
        <BedroomSpec bedrooms={bedrooms} />
      </li>
      <li className="list-inline-item">
        <BathroomSpec bathrooms={bathrooms} />
      </li>
      <li className="list-inline-item">
        <GarageSpec garages={garages} />
      </li>
    </ul>
  )
}

export default DesignSpec
