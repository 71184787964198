import React from "react"
import { Row, Col } from "react-bootstrap"
import classNames from "classnames"
import GroupCard from "./GroupCard"
import flexLogo from "../../images/Flex-U-Build-Options.png"
import inclLogo from "../../images/Whats-Included-Logo.png"
import savrLogo from "../../images/Saver-Options-Logo.png"
import premLogo from "../../images/Premium-Options-Logo.png"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.SectionCard = false

const SectionCard = ({ section, sectionIndex }) => {
  // if (window.configurator.debugPoints.SectionCard) {
  //   debugger
  // }

  return (
    <div className="configurator-design-card">
      <Row>
        <Col
          xs={12}
          md={3}
          className="d-none d-md-block">
&nbsp;
        </Col>
        <Col xs={12} md={9} className="section-name-column">{section.getName()}</Col>
      </Row>
      <Row className="section-title-row">
        <Col
          className={classNames("fub-logo", {
            "d-none": sectionIndex !== 0,
            "d-md-table": true
          })}
          xs={12}
          md={3}>
          <img src={flexLogo} alt="Flex-U-Build Options" className="configurator-img" />
        </Col>
        <Col xs={12} md={3} className="inclusion-column d-table">
          <div className="d-table-cell align-middle">
            <Row>
              <Col xs={3} className="d-table"><div className="d-table-cell align-middle"><img src={inclLogo} alt="What's Included" className="configurator-img" /></div></Col>
              <Col xs={9} className="option-header d-table"><div className="d-table-cell align-middle">What&apos;s Included</div></Col>
            </Row>
          </div>
        </Col>
        <Col xs={12} md={3} className="saver-column d-table">
          <div className="d-table-cell align-middle">
            <Row>
              <Col xs={3} className="d-table"><div className="d-table-cell align-middle"><img src={savrLogo} alt="$aver Options" className="configurator-img" /></div></Col>
              <Col xs={9} className="option-header d-table"><div className="d-table-cell align-middle">$aver Options</div></Col>
            </Row>
          </div>
        </Col>
        <Col xs={12} md={3} className="premium-column d-table">
          <div className="d-table-cell align-middle">
            <Row>
              <Col xs={3} className="d-table"><div className="d-table-cell align-middle"><img src={premLogo} alt="Premium Options" className="configurator-img" /></div></Col>
              <Col xs={9} className="option-header d-table"><div className="d-table-cell align-middle">Premium Options</div></Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="section-body-row">
        <Col className="section-body-column" xs={12}>
          {section.getGroups().map((group, index) => (
            <GroupCard
              key={group.getName()}
              group={group}
              groupIndex={index}
              groupCount={section.getGroups().length} />
          ))}
        </Col>
      </Row>
    </div>
  )
}

export default SectionCard
