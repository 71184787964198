/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import Skeleton from "react-loading-skeleton"
import { IconClose } from "images/icons"

export const imageSlide = item => (
  <div className="embed-responsive embed-responsive-16by9">
    {item.loaded ? <img className="embed-responsive-item" src={item.imageSrc} /> : (
      <div className="embed-responsive-item">
        <Skeleton height={500} />
      </div>
    )}
  </div>
)

export default function GalleryModal({
  show, onHide, homeName, urls
}) {
  const items = urls.map(image => ({
    imageSrc: image,
    renderItem: imageSlide,
    renderThumbInner: imageSlide
  }))

  const [renderItems, setRenderItems] = useState([...items])

  useEffect(() => {
    renderItems.forEach((item, index) => {
      const loader = new Image()
      loader.src = item.imageSrc
      loader.onload = () => {
        const tmp = [...renderItems]
        tmp[index].loaded = true
        setRenderItems(tmp)
      }
    })
  }, [])

  if (items.length) {
    const renderLeftNav = (onClick, disabled) => (
      <button
        type="button"
        className="image-gallery-custom-left-nav image-gallery-custom-nav"
        disabled={disabled}
        onClick={onClick} />
    )

    const renderRightNav = (onClick, disabled) => (
      <button
        type="button"
        className="image-gallery-custom-right-nav image-gallery-custom-nav"
        disabled={disabled}
        onClick={onClick} />
    )
    return (
      <Modal
        centered
        scrollable
        show={show}
        onHide={onHide}
        size="xl">
        <Modal.Body>
          <h2 className="gallery-carousel-title">
            {homeName}
          </h2>
          <IconClose
            onClick={onHide}
            className="gallery-carousel-close" />
          <ImageGallery
            additionalClass="gallery-carousel"
            items={renderItems}
            showFullscreenButton={false}
            showPlayButton={false}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav} />
        </Modal.Body>
      </Modal>
    )
  }
  return ""
}