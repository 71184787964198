import React, { Fragment, useEffect } from "react"

import { PinBwh, PinSelected } from "images/icons"
import ReactTooltip from "react-tooltip"

const mapPinWidth = 40
const mapPinHeight = 50
const styles = {
  mapPin: {
    position: "absolute",
    left: -mapPinWidth / 2,
    top: -mapPinHeight
  }
}

export default function Marker({ selected }) {
  useEffect(() => { ReactTooltip.rebuild() }, [selected])

  if (selected) {
    return (
      <Fragment>
        <PinSelected
          width={mapPinWidth}
          height={mapPinHeight}
          style={styles.mapPin}
          data-tip="Click for details" />
        <ReactTooltip place="top" effect="solid" />
      </Fragment>
    )
  }
  return (
    <Fragment>
      <PinBwh
        width={mapPinWidth}
        height={mapPinHeight}
        style={styles.mapPin}
        data-tip="Click for details" />
      <ReactTooltip place="top" effect="solid" />
    </Fragment>
  )
}