import React from "react"

import { css } from "styled-components"
import { CircleLoader } from "react-spinners"

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

const LoadingBar = ({ size = 30, isLoading = true }) => (
  <div className="loading-bar text-center">
    <CircleLoader
      css={override}
      color="#ee1848"
      size={size}
      loading={isLoading} />
  </div>
)

export default LoadingBar
