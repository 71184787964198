import * as constants from "./constants"

export const toggleFavouriteList = ({ listName, planId }) => ({
  type: constants.TOGGLE_FAVOURITE,
  payload: { listName, planId }
})

export const removeFavouriteId = ({ listName, planId }) => ({
  type: constants.REMOVE_FAVOURITE,
  payload: { listName, planId }
})