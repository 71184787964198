import React, { useState, useEffect, Fragment } from "react"
import { useDispatch } from "react-redux"
import Skeleton from "react-loading-skeleton"
import ReactTooltip from "react-tooltip"

import { openModal } from "../../redux/actions/galleryModal"

export default function PhotoItem({
  data: {
    itemIdx, name, url, imageIndex
  }
}) {
  const dispatch = useDispatch()
  const [imageLoaded, setImageLoaded] = useState(false)


  useEffect(() => {
    const imageLoader = new Image()
    imageLoader.src = url

    imageLoader.onload = () => {
      setImageLoaded(true)
    }
    return function () {
      imageLoader.onload = null
      imageLoader.onerror = null
    }
  }, [url])

  return (
    <Fragment>
      <div className="button-wrapper">
        <button
          type="button"
          className="card"
          onClick={() => dispatch(openModal({ name, imageIndex }))}
          style={{ display: "contents" }}
          data-tip={name}
          data-for={`${name}-${itemIdx}`}>
          {imageLoaded
            ? (
              <img
                className="img-fluid rounded"
                src={url}
                alt={name}
                style={{ marginBottom: "12px" }} />
            ) : (
              <div className="img-fluid" style={{ marginBottom: "12px" }}>
                <Skeleton height={260} />
              </div>
            )}
        </button>
        <ReactTooltip id={`${name}-${itemIdx}`} place="bottom" />
      </div>
    </Fragment>
  )
}
