import { combineReducers } from "redux"
import * as types from "../actionTypes"

const modalIndex = (state = 0, action) => {
  switch (action.type) {
    case types.OPEN_MODAL: {
      const { imageIndex } = action.payload
      return imageIndex
    }
    default: {
      return state
    }
  }
}

const modalName = (state = "", action) => {
  switch (action.type) {
    case types.OPEN_MODAL: {
      const { name } = action.payload
      return name
    }
    default: {
      return state
    }
  }
}

const showModal = (state = false, action) => {
  switch (action.type) {
    case types.OPEN_MODAL: {
      return true
    }
    case types.CLOSE_MODAL: {
      return false
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  modalIndex,
  modalName,
  showModal
})