import React from "react"
import PropTypes from "prop-types"

import {
  IconCar, IconShower, IconTheatre, IconBed
} from "images/icons"


export default function HomeRoomIcons({
  bedrooms, bathrooms, garages, homeTheatre,
  size = 22, className
}) {
  const iconStyle = { marginTop: "-2px" }
  return (
    <div className={className}>
      <ul className="list-inline design-spec mb-4">
        { bedrooms > 0 && (
          <li className="list-inline-item ">
            <IconBed className="mr-1" style={iconStyle} height={size} width={size} />
            { bedrooms }
          </li>
        )}
        { bathrooms > 0 && (
          <li className="list-inline-item ">
            <IconShower className="mr-1" style={iconStyle} height={size} width={size} />
            { bathrooms }
          </li>
        )}
        { homeTheatre > 0 && (
          <li className="list-inline-item">
            <IconTheatre className="mr-1" style={iconStyle} height={size} width={size} />
            { homeTheatre }
          </li>
        )}
        { garages > 0 && (
          <li className="list-inline-item">
            <IconCar className="mr-1" style={iconStyle} height={size} width={size} />
            { garages }
          </li>
        )}
      </ul>
    </div>
  )
}

HomeRoomIcons.propTypes = {
  bedrooms: PropTypes.number.isRequired,
  bathrooms: PropTypes.number.isRequired,
  garages: PropTypes.number.isRequired,
  homeTheatre: PropTypes.number.isRequired,
}