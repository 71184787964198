import React from "react"
import { Modal } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"

import { NewBadge, AwardBadge, OnPromotionBadge } from "components/buttons/badges"
import { setShowFacadeModal, setFacadeCarouselIdx  } from "../../actions"

export default function FacadeModal({
  name, facades, newDesign, awardWinner, onPromotion
}) {
  const dispatch = useDispatch()
  const showModal = useSelector(state => state.showFacadeModal)
  return (
    <Modal
      scrollable
      size="xl"
      show={showModal}
      onHide={() => { dispatch(setShowFacadeModal(false)) }}>
      <Modal.Header closeButton>
        <Modal.Title>
          {`${name} Façade Collection`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            {facades.map((facade, idx) => {
              const { attributes: { id: facadeId, imageJson: { md: imageUrl }, name: facadeName } } = facade
              return (
                <button
                  key={`facade-modal-${facadeId}`}
                  type="button"
                  className="col-md-6 col-lg-4 mb-2"
                  onClick={() => {
                    dispatch(setFacadeCarouselIdx(idx))
                    dispatch(setShowFacadeModal(false))
                  }}
                  style={{
                    background: "none",
                    border: "none"
                  }}>
                  <div className="card design-card design-card-facade mb-4 border-0">
                    <div className="card-img">
                      <div
                        title={facadeName}
                        style={{
                          borderRadius: "5px",
                          width: "100%",
                          paddingTop: "56.25%",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundImage: `url(${imageUrl || "/fallback/placeholder-house.svg"})`
                        }}>
                      </div>
                      <div className="card-icons">
                        <div className="row justify-content-between">
                          <div className="col text-right">
                            <ul className="list-inline">
                              <li className="list-inline-item" style={{ borderRadius: "0 0 0.25rem 0" }}>{facadeName}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-badges" style={{ width: "auto" }}>
                      <NewBadge display={newDesign} />
                      <AwardBadge display={awardWinner} />
                      <OnPromotionBadge display={onPromotion} />
                    </div>
                  </div>
                </button>
              )
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}