/* eslint-disable no-undef */
import { combineReducers }  from "redux"
import { connectRouter } from "connected-react-router"
import normalize from "json-api-normalizer"

import createReducer from "components/utils/createReducer"
import favouriteList from "components/common/favouriteIcon/redux/reducers"
import {
  SET_FACADE_MODAL,
  SET_SHOW_QUICKVIEW,
  SET_QUICKVIEW_IDX,
  SET_FACADE_CAROUSEL_IDX
} from "./actions"

const normalizedData = normalize(gon.record).displayHome
const normalizedObj = Object.values(normalizedData)[0]
const displayHome = (state = normalizedObj, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const showFacadeModal = (state = false, action) => {
  switch (action.type) {
    case SET_FACADE_MODAL: {
      const { state: newState } = action.payload
      return newState
    }
    default:
      return state
  }
}

const facadeCarouselIndex = (state = 0, action) => {
  switch (action.type) {
    case SET_FACADE_CAROUSEL_IDX: {
      return action.payload
    }
    default:
      return state
  }
}

const showQuickViewCarousel = (state = false, action) => {
  switch (action.type) {
    case SET_SHOW_QUICKVIEW: {
      const { state: newState } = action.payload
      return newState
    }
    default:
      return state
  }
}

const quickViewCarouselIdx = (state = 0, action) => {
  switch (action.type) {
    case SET_QUICKVIEW_IDX: {
      const { idx } = action.payload
      return idx
    }
    default:
      return state
  }
}

// building config reducer with gon excluding project has its own.
const config = createReducer(
  function () {
    const { ...initialState } = gon
    return initialState
  }()
)({})

export const getUrls    = state => state.config.urls
export const getBaseUrl = state => state.config.urls.api

export default history => combineReducers({
  router: connectRouter(history),
  config,
  displayHome,
  favouriteList,
  showFacadeModal,
  facadeCarouselIndex,
  showQuickViewCarousel,
  quickViewCarouselIdx
})
