import React, { Fragment } from "react"
import HomeDesignTile from "components/common/HomeDesignTile"
import TinySliderWrapper from "shared/TinySliderWrapper"

const setting = {
  items: 1.2,
  loop: false,
  gutter: 10,
  disable: false,
  controls: false,
  navPosition: "bottom",
  mouseDrag: true,
  responsive: {
    575: {
      items: 2.1
    },
    767: {
      disable: true,
      items: 3
    }
  }
}

export default function TabBody({ data }) {
  return (
    <Fragment>
      <Fragment>
        <TinySliderWrapper settings={setting} extraClasses="row">
          {data.map(homeDesign => (
            <div className="col-lg-4 col-md-6" key={`home-design-${homeDesign.id}`}>
              <HomeDesignTile
                homeDesignId={homeDesign.id}
                homeDesign={homeDesign} />
            </div>

          ))}
        </TinySliderWrapper>
      </Fragment>
    </Fragment>
  )
}