import React from "react"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.GarageSpec = false

const GarageSpec = ({ garages }) => {
  // if (window.configurator.debugPoints.GarageSpec) {
  //   debugger
  // }

  return (
    <>
      <svg height="13" viewBox="0 0 24.784 12.805" width="13" xmlns="http://www.w3.org/2000/svg" className="configurator-mr-1 configurator-svg" style={{ marginTop: "-2px" }}>
        <g fill="#fffffe" fillRule="evenodd">
          <path d="M24.656 6.968a9.777 9.777 0 00-5.331-3.012C18.365 1.665 15.658 0 12.428 0 9 0 6.135 1.874 5.359 4.393A9.106 9.106 0 001.35 7.019 7.167 7.167 0 000 11.754l.088.149 2.792-.024-.048-.249a2.412 2.412 0 114.824 0l-.043.21 11.552-.1-.023-.112a2.413 2.413 0 114.826 0l-.016.071 2.232-.017a5.786 5.786 0 00-1.529-4.716zM9.14 5.557H6.783A6.006 6.006 0 019.14 2.606zm6.032 0h-5.1V2.112a5.981 5.981 0 012.388-.506 6.055 6.055 0 012.713.639zm.936 0V2.836a6.032 6.032 0 012.029 2.721z"></path>
          <path d="M5.243 9.934a1.694 1.694 0 00-1.693 1.7l.047.229a1.658 1.658 0 003.3-.026l.041-.2a1.692 1.692 0 00-1.691-1.7M21.554 9.934a1.692 1.692 0 00-1.692 1.7l.019.1a1.678 1.678 0 003.352-.027l.012-.073a1.693 1.693 0 00-1.691-1.7"></path>
        </g>
      </svg>
      {garages}
    </>
  )
}

export default GarageSpec
