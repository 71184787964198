import BaseController from "controllers/base"
import startHomeLandDetailApp from "apps/HomeLandDetail"
import { cmsFormControl, cmsFormFileDropable } from "servos/cms/ajaxForm"

class Controller extends BaseController {
  call() {
    super.call()

    startHomeLandDetailApp(
      document.getElementById("home-land"),
      {}
    )

    cmsFormControl.call(this)
    cmsFormFileDropable.call(this)
  }
}

export default Controller