import BaseController from "controllers/base"

import configTrixEditor from "widgets/cms/configTrix";
import ajaxLoadForm from "widgets/cms/LoadEditForm";
import ajaxElementForms from "widgets/cms/ajaxUpdateForm"
import bindTrixUploader from "widgets/cms/trixAttachmentUploader"
import bindFormPreview from "widgets/cms/bindFormPreview"
import loadCKEditor from "widgets/cms/loadCKEditor"
import popUpGallery from "widgets/cms/imageGallery"

class Controller extends BaseController {
  call() {
    super.call();

    configBeforeLoadForm.call(this)
    loadForm.call(this)
    bindFormPreview.call(this);
  };
};

const configBeforeLoadForm = function () {
}

let loadForm = function () {
  ajaxLoadForm(bindAfterLoadForm);
}

const bindAfterLoadForm = function () {
  bindElementForms.call(this);
  loadCKEditor.call(this);
  popUpGallery.call(this);
}

const bindElementForms = function () {
  ajaxElementForms(loadForm)
}

export default Controller;