import { all, fork } from "redux-saga/effects"

import { homeDesignRootSaga } from "modules/HomeDesign"
import homeLandSagas from "apps/HomeLandSearch/redux/sagas"
import landForSaleSagas from "apps/LandForSale/redux/sagas"

export default function* RootSaga() {
  yield all([
    fork(homeDesignRootSaga),
    fork(homeLandSagas),
    fork(landForSaleSagas)
  ])
}
