import React from "react"
import { Carousel } from "react-bootstrap"
import { connect, useSelector } from "react-redux"

import { IconClose } from "images/icons"
import HomeDetails from "./homeDetails"
import "./QuickViewCarousel.css"

import { setShowQuickView, setQuickViewIdx } from "../actions"

const QuickViewCarousel = ({ setShow, setIdx }) => {
  const homeDesignSeries = useSelector(state => state.homeDesignSeries)
  const showIndex = useSelector(state => state.quickViewCarouselIdx)
  const { attributes: { id: mainHomeDesignId } } = useSelector(state => state.homeDesign)
  if (homeDesignSeries.length) {
    return (
      <Carousel
        interval={null}
        indicators={false}
        activeIndex={showIndex}
        onSelect={selectedIndex => setIdx(selectedIndex)}
        className="overlay-body">
        {homeDesignSeries.map(design => {
          const { attributes: { id } } = design
          if (id !== mainHomeDesignId) {
            return (
              <Carousel.Item key={`series-design-${id}`}>
                <div style={{ width: "100%", height: "100vh", backgroundColor: "rgba(0,0,0,0.7)" }}>
                  <div className="container h-100 card-container" style={{ overflow: "auto" }}>
                    <div className="row align-items-center h-100">
                      <div className="col p-4" style={{ backgroundColor: "rgba(255,255,255,1)" }}>
                        <HomeDetails homeDesign={design} showAsCard />
                        <IconClose
                          onClick={() => setShow(false)}
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "20px",
                            right: "20px",
                            zIndex: 2
                          }} />
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            )
          }
          return ""
        })}
      </Carousel>

    )
  }
  return ""
}

export default connect(null, {
  setShow: setShowQuickView,
  setIdx: setQuickViewIdx
})(QuickViewCarousel)