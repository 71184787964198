/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"

import FavouriteIcon from "components/common/favouriteIcon"
import FacadeCarousel from "components/common/facadeCarousel"
import HomeDesignFloorPlan from "components/common/HomeDesignFloorPlan"
import HomeDesignName from "components/common/HomeDesignName"
import { TitleBadgesSm } from "components/buttons/badges"
import HomeRoomsSmall from "components/common/homeRoomsSmall"

function RemoveButton({ designId, onClickRemove }) {
  return (
    <div className="text-right">
      <div
        className="small-text text-link"
        onClick={() => onClickRemove(designId)}
        onKeyDown={() => onClickRemove(designId)}>
        Remove
      </div>
    </div>
  )
}

const HouseSizeTable = ({ width, length, size }) => (
  <div className="card-body">
    <table className="table table-sm small-text table-list">
      <tbody>
        <tr>
          <td className="border-0">House Width</td>
          <td className="border-0">{`${width} m`}</td>
        </tr>
        <tr>
          <td>House Length</td>
          <td>{`${length} m`}</td>
        </tr>
        <tr>
          <td>Total Area</td>
          <td>
            {`${size} m`}
            <sup>2</sup>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)

const CompareHomeDesignTile = ({ homeDesign, onClickRemove }) => {
  const {
    id,
    attributes: {
      detailUrl,
      name,
      price,
      designWidth,
      designLength,
      sizeM2,
      floorplansLeft,
      floorplansRight,
    }
  } = homeDesign

  return (
    <div className="col-4">
      <RemoveButton
        designId={id}
        onClickRemove={onClickRemove} />

      <div className="card design-card design-card-floorplan mb-4">
        <div className="card-body pt-3 bg-light">
          <TitleBadgesSm homeDesign={homeDesign} />
          <div className="card-title d-flex justify-content-between" style={{ minHeight: "35px" }}>
            <HomeDesignName name={name} />
            <FavouriteIcon homeDesignId={id} />
          </div>
        </div>
        <ul className="list-group list-group-flush bg-light border-0">
          <li className="list-group-item">
            <HomeRoomsSmall homeDesign={homeDesign} />
          </li>
          <li className="list-group-item">
            <div className="row justify-content-around align-items-center">
              {price && (
                <div className="col text-secondary small-text">
                  {`Base Price ${price}`}
                </div>
              )}
            </div>
          </li>
        </ul>

        <HomeDesignFloorPlan floorPlansLeft={floorplansLeft} floorPlansRight={floorplansRight} />

        <HouseSizeTable
          width={designWidth}
          length={designLength}
          size={sizeM2} />

        <div className="card design-card design-card-facade border-0">
          <FacadeCarousel homeDesign={homeDesign} showBedges={false} />
        </div>

        <div className="card-body py-4 px-3 text-center">
          <a className="btn btn-black btn-md" href={detailUrl}>
            View Home Design
          </a>
        </div>
      </div>
    </div>
  )
}

export default CompareHomeDesignTile
