import React, { Component } from "react"
import PropTypes from "prop-types"

import IconButton from "components/buttons/iconButton"

// eslint-disable-next-line react/prefer-stateless-function
class HomeTypeFilter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_options: [],
    }
  }

  static getDerivedStateFromProps(props) {
    return props
  }

  homeTypeSelected = value => {
    const { selected_options } = this.state
    const { dataChanged } = this.props
    const newState = _.xor(selected_options, [value])
    const currentUrl = new URL(document.location)
    currentUrl.searchParams.set("by_home_types", newState)
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, null, currentUrl)
    dataChanged({ by_home_types:  newState })
  }

  isSelected = value => {
    const { selected_options } = this.state
    return _.includes(selected_options, value)
  }

  render() {
    const { extraWrapperCls, homeLandType } = this.props

    let available_home_types = []
    if (homeLandType) {
      available_home_types = gon.home_lands.available_home_types || []
    } else {
      available_home_types = gon.home_designs.available_home_types || []
    }
    return (
      <div className={extraWrapperCls}>
        <div className="title">Home Type</div>
        <ul className="list-inline mb-1">
          <li className="list-inline-item home-type-item">
            <IconButton
              icon="single"
              text="Single"
              active={this.isSelected("single")}
              disabled={!available_home_types.includes("Single Storey")}
              handleToggled={() => this.homeTypeSelected("single")} />
          </li>
          <li className="list-inline-item home-type-item">
            <IconButton
              icon="loft"
              text="Loft"
              active={this.isSelected("loft")}
              disabled={!available_home_types.includes("Loft")}
              handleToggled={() => this.homeTypeSelected("loft")} />
          </li>
          <li className="list-inline-item home-type-item">
            <IconButton
              icon="double"
              text="Double"
              active={this.isSelected("double")}
              disabled={!available_home_types.includes("Double Storey")}
              handleToggled={() => this.homeTypeSelected("double")} />
          </li>
        </ul>
        {!homeLandType && (
          <ul className="list-inline mb-0">
            <li className="list-inline-item home-type-item">
              <IconButton
                icon="grannyflat"
                text="Granny Flat"
                active={this.isSelected("granny_flat")}
                disabled={!available_home_types.includes("Granny Flat")}
                handleToggled={() => this.homeTypeSelected("granny_flat")} />
            </li>
            <li className="list-inline-item home-type-item">
              <IconButton
                icon="estate"
                text="Estate"
                active={this.isSelected("estate")}
                disabled={!available_home_types.includes("Estate")}
                handleToggled={() => this.homeTypeSelected("estate")} />
            </li>
            <li className="list-inline-item home-type-item">
              <IconButton
                icon="dual"
                text="Dual Occupancy"
                active={this.isSelected("dual")}
                disabled={!available_home_types.includes("Dual Occupancy")}
                handleToggled={() => this.homeTypeSelected("dual")} />
            </li>
          </ul>
        )}
      </div>
    )
  }
}

HomeTypeFilter.defaultProps = {
  extraWrapperCls: "col-md-6 col-lg-4",
  homeLandType: false
}

HomeTypeFilter.propTypes = {
  extraWrapperCls: PropTypes.string,
  homeLandType: PropTypes.bool
}

export default HomeTypeFilter