/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { Component } from "react"

class Checkbox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: props.checked
    }
  }

  handleClick = () => {
    const { checked } = this.state
    const { onChange } = this.props
    this.setState({ checked: !checked })

    onChange(!checked)
  }

  render() {
    const { label } = this.props
    const { checked } = this.state

    return (
      <div className="custom-control custom-checkbox" onClick={this.handleClick}>
        <input
          className="custom-control-input"
          type="checkbox"
          checked={checked ? "checked" : ""}
          readOnly />
        <label className="custom-control-label">
          {label}
        </label>
      </div>
    )
  }
}

export default Checkbox