// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// require.context('../images', true)

import "./bootstrap_custom.js"
import "../stylesheets/application"
// import * as Sentry from '@sentry/browser';

import "core-js/stable"
import "regenerator-runtime/runtime"
import axios from "axios"

import Raven          from "raven-js"
import configureRaven from "../lib/raven"
import "@fortawesome/fontawesome-free/js/all"
import boot from "../lib/boot"

require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
require("trix")
// require("@rails/actiontext")
require("channels")

$(() => {
  configureAxios(axios)
  configureRaven()

  Raven.context(() => {
    boot.init()
  })
})

const configureAxios = axios => {
  axios.defaults.headers.common["X-CSRF-Token"] = document.getElementsByName("csrf-token")[0].getAttribute("content")
  axios.defaults.headers.common.Accept = "application/json"
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
