import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"

import createReducer from "components/utils/createReducer"
import comparePlanList from "components/common/CompareButton/redux/reducers"
import favouriteList from "components/common/favouriteIcon/redux/reducers"
import homeDesigns, * as homeDesignReducers from "modules/HomeDesign/reducers"

// building config reducer with gon excluding project has its own.
const config = createReducer(
  function () {
    // eslint-disable-next-line no-undef
    const { ...initialState } = gon

    return initialState
  }()
)({})

export const getUrls = state => state.config.urls
export const getBaseUrl = state => state.config.urls.api

// HomeDesign
export const getHomeDesigns = state => homeDesignReducers.getHomeDesigns(state)
export const getHomeDesignObj = state => homeDesignReducers.getHomeDesignObj(state)
export const getHomeDesignIds = state => homeDesignReducers.getHomeDesignIds(state)
export const isFetchingHomeDesigns = state => homeDesignReducers.isFetchingHomeDesigns(state)

export default history => combineReducers({
  router: connectRouter(history),
  config,
  comparePlanList,
  favouriteList,
  homeDesigns,
})
