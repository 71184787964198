import React from "react"

import Breadcrumb from "./components/breadcrumb"
import LandForSaleHero from "./components/LandForSaleHero"
// import LandForSaleIntro from "./components/LandForSaleIntro"

function HomeLandRoot() {
  return (
    <div className="home-details">
      <Breadcrumb />
      <LandForSaleHero />
    </div>
  )
}

export default HomeLandRoot