import React from "react"

import ShareButton from "apps/ShareButton/ShareButton"

export default function Title() {
  return (
    <div className="page-header text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h1 className="text-center">Compare Homes</h1>
          </div>
          <div className="col-md-2">
            <ShareButton greyBtn />
          </div>
        </div>
      </div>
    </div>
  )
}