import React from "react"
import ReactDOM from "react-dom"
import { ConfiguratorContextProvider } from "./configurator-context-provider"
import ConfiguratorView from "./Configurator-View"
import "./styles/configurator-view.scss"
import "bootstrap/dist/css/bootstrap.css"
import "react-bootstrap"

export default function configuratorPanel(rootElem) {
  ReactDOM.render(
    <ConfiguratorContextProvider>
      <ConfiguratorView />
    </ConfiguratorContextProvider>,
    rootElem,
  )
}
