import axios from "axios"
import normalize from "json-api-normalizer"

export const getNormalizedData = (requestUrl, body = {}) => axios.post(requestUrl, body)
  .then(({ data }) => {
    const normalizedData = normalize(data.records)
    // Can process data with single type
    const defaultData = Object.values(normalizedData)[0] || []
    return {
      response: defaultData,
      meta: data.meta
    }
  })
  .catch(error => (error))