import * as constants from "../../constants"

const curPage = (state = {}, action) => {
  switch (action.type) {
    case constants.FETCH_SUCCESS: {
      const { meta: { category, cur_page } } = action.payload
      return { ...state, [category]: cur_page }
    }
    default:
      return state
  }
}

export default curPage