/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from "react"

import { Modal, Button } from "react-bootstrap"
import { Range } from "rc-slider"

import Checkbox from "components/inputs/Checkbox"
import ToggleSelect from "components/inputs/ToggleSelect"

class AdvancedFiltersModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      originalFilters: props.filters,
      filters: props.filters
    }
  }

  static getDerivedStateFromProps(props, state) {
    // If state is empty - First time load
    // Or original filters has changed from the store
    if (_.isEmpty(state) || !_.isEqual(state.originalFilters, props.filters)) {
      return { ...state, originalFilters: props.filters, filters: props.filters }
    }
    return state
  }


  handleClearFilters = e => {
    e.preventDefault()

    const { onClearFilters } = this.props
    const { originalFilters } = this.state

    // Clear main filters
    onClearFilters()

    // Set state from props
    this.setState({ filters: originalFilters })
  }

  handleSaveFilters = () => {
    const { onSaveFilters } = this.props
    const { filters } = this.state

    // These filters are not automatically set in the store
    // so we need to update the local state before sending them back
    //
    onSaveFilters(filters)
    // advancedFiltersInUse(true)
  }

  // Measurements
  setBlockWidth = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, by_block_width: value } })
  }

  setHomeWidth = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, by_home_width: value } })
  }

  setHomeSize = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, by_home_size: value } })
  }

  setHomeLength = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, by_home_length: value } })
  }

  renderHeading() {
    return (
      <Modal.Header
        closeButton>
        <a
          href="!#"
          onClick={this.handleClearFilters}
          className="text-link small-text">
          Clear All
        </a>
        <strong
          className="modal-title text-center flex-fill"
          id="exampleModalCenterTitle">
          Advanced Filters
        </strong>
      </Modal.Header>
    )
  }

  renderMeasurementsFilters = () => {
    const {
      filters: {
        by_block_width: [bw1, bw2], by_block_width,
        by_home_width: [hw1, hw2], by_home_width,
        by_home_size: [hs1, hs2], by_home_size,
        by_home_length: [hl1, hl2], by_home_length
      }
    } = this.state

    return (
      <div>
        <h3 className="my-3">Home Measurements</h3>
        <div className="row">
          <div className="col-md-6">
            <strong className="small-text">
              <label> Block Width</label>
              <div className="col">
                <div className="slider-label">{`${bw1} - ${bw2} m`}</div>
                <Range
                  className="rc-slider-skinny"
                  min={0}
                  max={35}
                  step={5}
                  value={by_block_width}
                  onChange={value => this.setBlockWidth(value)} />
              </div>
            </strong>
            <strong className="small-text">
              <label> Home Width</label>
              <div className="col">
                <div className="slider-label">{`${hw1} - ${hw2} m`}</div>
                <Range
                  className="rc-slider-skinny"
                  min={0}
                  max={35}
                  step={5}
                  value={by_home_width}
                  onChange={value => this.setHomeWidth(value)} />
              </div>
            </strong>
          </div>
          <div className="col-md-6">
            <strong className="small-text">
              <label> Home Size</label>
              <div className="col">
                <div className="slider-label">
                  {`${hs1} - ${hs2 >= 500 ? `${hs2  }+` : hs2} m`}
                  <sup>2</sup>
                </div>
                <Range
                  className="rc-slider-skinny"
                  min={0}
                  max={500}
                  step={5}
                  value={by_home_size}
                  onChange={value => this.setHomeSize(value)} />
              </div>
            </strong>
            <strong className="small-text">
              <label> Home Length</label>
              <div className="col">
                <div className="slider-label">{`${hl1} - ${hl2} m`}</div>
                <Range
                  className="rc-slider-skinny"
                  min={0}
                  max={35}
                  step={5}
                  value={by_home_length}
                  onChange={value => this.setHomeLength(value)} />
              </div>
            </strong>
          </div>
        </div>
      </div>
    )
  }

  setPromotion = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, has_on_promotion: value } })
  }

  setAwardWinning = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, has_award_winning: value } })
  }

  setOnDisplay = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, has_on_display: value } })
  }

  renderMoreOptions = () => {
    const { filters: { has_on_promotion, has_award_winning, has_on_display } } = this.state

    return (
      <div className="col-sm-6">
        <h3 className="my-3">More Options</h3>
        <Checkbox label="On Promotion" checked={has_on_promotion} onChange={value => this.setPromotion(value)} />
        <Checkbox label="Award Winning" checked={has_award_winning} onChange={value => this.setAwardWinning(value)} />
        <Checkbox label="On Display" checked={has_on_display} onChange={value => this.setOnDisplay(value)} />
      </div>
    )
  }

  setZeroLotCompatible = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, has_zero_lot_compatible: value } })
  }

  setSplitLevelDesign = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, has_split_level_design: value } })
  }

  setCornerBlockDesign = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, has_corner_block_design: value } })
  }

  renderDesignFeatures = () => {
    const { filters: { has_zero_lot_compatible, has_split_level_design, has_corner_block_design } } = this.state

    return (
      <div className="col-sm-6">
        <h3 className="my-3">Design Features</h3>
        <Checkbox label="Zero Lot Compatible" checked={has_zero_lot_compatible} onChange={value => this.setZeroLotCompatible(value)} />
        <Checkbox label="Split Level Design" checked={has_split_level_design} onChange={value => this.setSplitLevelDesign(value)} />
        <Checkbox label="Corner Block Design" checked={has_corner_block_design} onChange={value => this.setCornerBlockDesign(value)} />
      </div>
    )
  }

  setAtticOption = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, includes_attic: value } })
  }

  setButlersPantyOption = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, includes_butlers_pantry: value } })
  }

  setTheatreOption = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, includes_theatre: value } })
  }

  setMediaStudyOption = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, includes_media_study: value } })
  }

  setCornerStackerDoorOption = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, includes_corner_stacker_door: value } })
  }

  setDownstairsBedroomOption = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, includes_downstairs_bedroom: value } })
  }

  setStaircaseStorageOption = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, includes_staircase_storage: value } })
  }

  setGuestBedOption = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, includes_guest_bedrooms: value } })
  }

  renderHomeFeatures = () => {
    const {
      filters: {
        includes_attic,
        includes_butlers_pantry,
        includes_theatre,
        includes_media_study,
        includes_corner_stacker_door,
        includes_downstairs_bedroom,
        includes_staircase_storage,
        includes_guest_bedrooms
      }
    } = this.state

    return (
      <div className="row">
        <div className="col-sm-6">
          <h3 className="my-3">Home Features</h3>
          <Checkbox label="Attic Option" checked={includes_attic} onChange={value => this.setAtticOption(value)} />
          <Checkbox label="Butler's Pantry Option" checked={includes_butlers_pantry} onChange={value => this.setButlersPantyOption(value)} />
          <Checkbox label="Theatre" checked={includes_theatre} onChange={value => this.setTheatreOption(value)} />
          <Checkbox label="Media / Study" checked={includes_media_study} onChange={value => this.setMediaStudyOption(value)} />
        </div>
        <div className="col-sm-6">
          <h3 className="my-3">&nbsp;</h3>
          <Checkbox label="Guest Bedrooms" checked={includes_guest_bedrooms} onChange={value => this.setGuestBedOption(value)} />
          <Checkbox label="Corner Stacker Door" checked={includes_corner_stacker_door} onChange={value => this.setCornerStackerDoorOption(value)} />
          <Checkbox label="*Downstairs Bedroom" checked={includes_downstairs_bedroom} onChange={value => this.setDownstairsBedroomOption(value)} />
          <Checkbox label="*Staircase Storage" checked={includes_staircase_storage} onChange={value => this.setStaircaseStorageOption(value)} />
        </div>
      </div>
    )
  }

  setMasterLocation = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, by_master_location: value } })
  }

  setTerrazzaLocation = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, by_terrazza_location: value } })
  }

  setBalconyLocation = value => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, by_balcony_location: value } })
  }

  renderLocationSelections = () => {
    const {
      filters: {
        by_master_location,
        by_terrazza_location,
        by_balcony_location
      }
    } = this.state

    return (
      <div>
        <div className="row">
          <div className="col-sm-6">
            <strong className="small-text my-2 d-block">Master Location</strong>
            <ToggleSelect
              name="master-location"
              options={[["Front", "front"], ["Middle", "middle"], ["Rear", "rear"]]}
              onChange={value => this.setMasterLocation(value)}
              selected={by_master_location} />
          </div>
          <div className="col-sm-6">
            <strong className="small-text my-2 d-block">Terrazza Location</strong>
            <ToggleSelect
              name="terrazza-location"
              options={[["Side", "side"], ["Rear", "rear"]]}
              onChange={value => this.setTerrazzaLocation(value)}
              selected={by_terrazza_location} />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-6">
            <strong className="small-text my-2 d-block">*Balcony Location</strong>
            <ToggleSelect
              name="balcony-location"
              options={[["Front", "front"], ["Rear", "rear"]]}
              onChange={value => this.setBalconyLocation(value)}
              selected={by_balcony_location} />
          </div>
        </div>
      </div>
    )
  }

  renderFooter() {
    const { onCloseModal } = this.props

    return (
      <Modal.Footer>
        <Button variant="secondary" className="btn btn-secondar" onClick={onCloseModal}>
          Close
        </Button>
        <Button variant="primary" className="btn btn-red" onClick={this.handleSaveFilters}>
          Save Changes
        </Button>
      </Modal.Footer>
    )
  }

  render() {
    const { showModal, onCloseModal } = this.props

    return (
      <Modal
        size="lg"
        show={showModal}
        scrollable
        dialogClassName="modal-600w"
        onHide={onCloseModal}
        aria-labelledby="example-modal-sizes-title-lg">
        { this.renderHeading() }
        <Modal.Body className="py-3 px-5 modal-body">
          { this.renderMeasurementsFilters() }
          <hr className="my-3"></hr>

          <div className="row">
            { this.renderMoreOptions() }
            { this.renderDesignFeatures() }
          </div>
          { this.renderHomeFeatures() }
          { this.renderLocationSelections() }
          <p className="small-text mt-4">*For double storey designs only.</p>
        </Modal.Body>
        { this.renderFooter() }
      </Modal>
    )
  }
}


export default AdvancedFiltersModal