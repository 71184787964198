/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { tns } from "ventura-slider"
import "ventura-slider/dist/tiny-slider.css"
import { ObjectsEqual, ChildrenEqual } from "./utils"

/**
 * Wrapper component to show carousel, controlling the lifecycle
 * methods and settings update.
 * @param {object} settings - setting to configure tiny-slider plugin
 * @param {function} onClick - callback to return slide clicked
 * @param {number} startIndex - index of carousel initiation
 *
 * @param {function} onIndexChanged - event bind
 * @param {function} onTransitionStart - event bind
 * @param {function} onTransitionEnd - event bind
 * @param {function} onTouchStart - event bind
 * @param {function} onTouchMove - event bind
 * @param {function} onTouchEnd - event bind
 *
 * @returns {ReactElement}
 */
export default class Carousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultSettings: {
        responsive: {},
        controlsText: ["◀", "▶"]
      }
    }

    this.slider = null
    this.build = this.build.bind(this)
    this.onClick = this.onClick.bind(this)
    this.dragging = false
    this.mergedSettings = 0
    this.count = 0
  }


  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.build(this.props.settings)
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot) {
      // rebuild in case to doesn exists
      this.build(this.props.settings)
    }
  }

  componentWillUnmount() {
    if (this.slider) this.slider.destroy()
  }

  /**
   * Fire click on carousel when no draggin and call the onClick callback on prop
   */
  onClick(event) {
    const { onClick } = this.props
    if (this.dragging || !onClick) return

    // when only one element the slider doesnt init
    if (!this.slider) return onClick(null, null, event)

    const info = this.slider.getInfo()
    const slideClicked = info.slideItems[info.index]

    // call click callback wiht info and slide clicked
    onClick(slideClicked, info, event)
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (
      !ObjectsEqual(prevProps.settings, this.props.settings)
      || !ChildrenEqual(prevProps.children, this.props.children)
    ) {
      // prepare to reinitializationgit
      if (this.slider) {
        this.slider.destroy()
        this.slider = null
        return true
      }
    }
    return false
  }


  /** * Initialize the carousel plugin with new settings */
  build(settings = {}) {
    if (this.slider) this.slider.destroy()

    const { defaultSettings } = this.state

    /* INIT CAROUSEL */
    this.mergedSettings = {
      ...defaultSettings,
      ...settings,
      container: this.ref,
      onInit: () => {
        this.postInit()
      }
    }

    this.slider = tns(this.mergedSettings)

    // call events binding
    if (this.slider) return

    if (this.ref) this.ref.className += " tns-item"

    // so if there is lazy load active load src manually
    if (this.mergedSettings.lazyload) {
      [].forEach.call(document.getElementsByClassName("tns-lazy-img"), img => {
        if (img.dataset.src) {
          img.src = img.dataset.src
          img.className += " loaded"
        }
      })
    }
  }


  /**
   * Once the slider plugins has been initialized
   */
  postInit() {
    if (!this.slider) {
      if (this.count >= 4) {
        return this.props.onInit(false) // call initMetod anyway
      }
      this.count += 1
      return setTimeout(this.postInit.bind(this), 100)
    }
    this.count = 0

    const { events, goTo } = this.slider
    const {
      onIndexChanged,
      onTransitionStart,
      onTransitionEnd,
      onTouchStart,
      onTouchMove,
      onTouchEnd,
      startIndex,

      onInit
    } = this.props

    /* BIND EVENTS */

    // change draggin value to purge onClick event.
    events.on("transitionStart", info => {
      this.dragging = true
      if (onTransitionStart) onTransitionStart(info)
    })

    events.on("transitionEnd", info => {
      this.dragging = false
      if (onTransitionEnd) onTransitionEnd(info)
    })

    if (onIndexChanged) events.on("indexChanged", onIndexChanged)
    if (onTouchStart) events.on("touchStart", onTouchStart)
    if (onTouchMove) events.on("touchMove", onTouchMove)
    if (onTouchEnd) events.on("touchEnd", onTouchEnd)

    /* GO TO START SLIDE */
    if (startIndex) {
      goTo(startIndex)
    }

    // call on Innit
    onInit(true)
  }


  render() {
    const { children, extraClasses } = this.props
    return (
      <div
        role="button"
        ref={ele => { this.ref = ele }}
        className={`slider tiny-slider-react ${extraClasses}`}
        onClick={this.onClick}>
        {children}
      </div>
    )
  }
}

Carousel.propTypes = {
  settings: PropTypes.object,
  onClick: PropTypes.func,
  startIndex: PropTypes.number,
  // custom tiny events events
  onIndexChanged: PropTypes.func,
  onTransitionStart: PropTypes.func,
  onTransitionEnd: PropTypes.func,
  onTouchStart: PropTypes.func,
  onTouchMove: PropTypes.func,
  onTouchEnd: PropTypes.func,
  // events
  onInit: PropTypes.func
}

Carousel.defaultProps = {
  // custom tiny events events
  onIndexChanged: () => { },
  onTransitionStart: () => { },
  onTransitionEnd: () => { },
  onTouchStart: () => { },
  onTouchMove: () => { },
  onTouchEnd: () => { },
  // events
  onInit: () => { }
}
