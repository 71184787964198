/* eslint-disable no-use-before-define */
import BaseController from "controllers/base"
import $ from "jquery"
import Pickr from "@simonwep/pickr"
import "@simonwep/pickr/dist/themes/monolith.min.css"

class Controller extends BaseController {
  call() {
    super.call()

    bindColorPicker.call(this)
    initMap.call(this)
  }
}

const bindColorPicker = function () {
  const colourCols = this.root.find(".col-colour")
  let colourColCount = colourCols.length
  const existingColourArray = []
  colourCols.each((index, element) => {
    const $colourInput = $(element).find("input")
    const colorHEXA = $colourInput.val()
    if (!existingColourArray.includes(colorHEXA)) existingColourArray.push(colorHEXA)
  })
  colourCols.each((index, element) => {
    const $colourCol = $(element)
    const $colourPickerBox = $colourCol.find("span")
    const $colourInput = $colourCol.find("input")
    const defaultColor = $colourInput.val()
    const colourColClass = $colourPickerBox.attr("class")
    const pickr = Pickr.create({
      el: `.${colourColClass}`,
      default: defaultColor,
      theme: "monolith",
      swatches: existingColourArray,
      components: {
        // Main components
        preview: true,
        // opacity: true,
        hue: true,
        // Input / output Options
        interaction: {
          input: true,
          // clear: true,
          save: true
        }
      }
    })
    pickr.on("save", (color, instance) => {
      const pickedColourHEXA = color.toHEXA().toString()
      $colourInput.val(pickedColourHEXA)
      pickr.hide()
    })
    pickr.on("init", instance => {
      colourColCount -= 1
      if (colourColCount === 0) {
        $(".loading-overlay").hide()
      }
    })
  })
}

const initMap = function () {
  // eslint-disable-next-line no-undef
  const map = new google.maps.Map(
    document.getElementById("map-container-google-where-we-build"), {
      zoom: 6.9,
      center: { lat: -33.866924, lng: 151.207643 },
    }
  )

  map.setOptions({ minZoom: 5, maxZoom: 15 })

  map.data.loadGeoJson(gon.json_file_url)

  // Set style from property
  map.data.setStyle(feature => {
    const color = feature.getProperty("color")
    return {
      fillColor: color,
      fillOpacity: 0.7,
      strokeWeight: 0.5,
      strokeColour: "red"
    }
  })
}

export default Controller