// import { delay } from "redux-saga"
import {
  all, fork, put, call, select, takeEvery
} from "redux-saga/effects"

import * as requests from "./requesters"
import * as actions from "./actions"
import * as constants from "./constants"

// Request Functions
function* loadNewsList(params) {
  const { api_url: apiUrl } = yield select(state => state.config)
  const { curPage } = yield select(state => state.newsList)
  const { payload: { category } } = params
  const tabCutPage = curPage[category] || 0
  const body = {
    category,
    page: tabCutPage + 1
  }
  const { response, error } = yield call(requests.getNewsList, { apiUrl, body })
  if (response) {
    yield put(actions.fetchDataSuccess(response))
  } else {
    yield put(actions.fetchDataFailed(error))
  }
}

// Watchers
function* watchLoadNewsList() {
  yield takeEvery(constants.FETCH_START, loadNewsList)
}

// Default Export
export default function* RootSaga() {
  yield all([
    fork(watchLoadNewsList),
  ])
}