/* eslint-disable no-use-before-define */
import $ from "jquery"
import swal from "sweetalert2"

export default function popUpGallery() {
  let $input_element

  // load model first time
  $.ajax({
    method: "get",
    url: "/backend/cms/attachments/load_popup_model",
    success(response) {
      $("#image-gallery").html(response.html)
      bindPopupTrigger()
      bindPopupAction()
    },
    error(error) {
      $("#image-gallery").text(error)
    }
  })

  function bindPopupTrigger() {
    $(".show_image_gallery").on("click", e => {
      $input_element = $(e.currentTarget)
      $("#image-gallery-popup").modal("show")
    })
  }

  function bindPopupAction() {
    removeType()
    rememberTab()
    newTypeForm()
    uploadFiles()
    closeManageModal()
    deleteAttachment()
    updateAttachmentType()
    if ($(".ck-browser").length) {
      returnUrl()
    } else {
      selectImage()
    }
  }

  function selectImage() {
    $(".select-img").on("click", e => {
      const img_url = $(e.currentTarget).attr("image_url")
      $input_element.val(img_url)
      $("#image-gallery-popup").modal("hide")
    })
  }

  // ckeditor select

  const returnUrl = function () {
    $(".select-img").on("click", e => {
      const url = $(e.currentTarget).attr("image_url")
      returnFileUrl(url)
    })
  }

  function getUrlParam(paramName) {
    const reParam = new RegExp(`(?:[\?&]|&)${paramName}=([^&]+)`, "i")
    const match = window.location.search.match(reParam)

    return (match && match.length > 1) ? match[1] : null
  }
  $.QueryString = (function (a) {
    if (a == "") return {}
    const b = {}
    for (let i = 0; i < a.length; ++i) {
      const p = a[i].split("=")
      if (p.length != 2) continue
      b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "))
    }
    return b
  }(window.location.search.substr(1).split("&")))
  // Simulate user action of selecting a file to be returned to CKEditor.
  function returnFileUrl(fileUrl) {
    const funcNum = getUrlParam("CKEditorFuncNum")
    window.opener.CKEDITOR.tools.callFunction($.QueryString.CKEditorFuncNum, fileUrl)
    window.close()
  }
  // ======

  function loadElementHtml($element, htmlContent) {
    $element.html(htmlContent)
    bindPopupAction()
  }

  function updateButton($button, message, disable = true) {
    $button.attr("disabled", disable)
    $button.html(message)
  }

  function removeType() {
    $("a.type-remove").on("click", e => {
      const $element = $(e.currentTarget)
      const buttonContent = $element.html()
      $.ajax({
        method: $element.attr("data-method"),
        url: $element.attr("href"),
        dataType: "json",
        beforeSend() {
          updateButton($element, "Deleting...")
        },
        success(response) {
          loadElementHtml($("#image-gallery"), response.html)
          swal.fire({
            type: "success",
            title: "Type Removed",
            confirmButtonText: "Ok",
          })
        },
        error(response) {
          updateButton($element, buttonContent, false)
          swal.fire({
            type: "error",
            title: "Type Can't Removed!",
            text: response.responseJSON.error,
            confirmButtonText: "Ok",
          })
        },
      })
      return false
    })
  }

  function rememberTab() {
    $('a.type-link[data-toggle="pill"]').on("shown.bs.tab", e => {
      const hash = $(e.target).attr("href")
      localStorage.setItem("GALLERY_TYPE", hash)
    })
    const hash = localStorage.getItem("GALLERY_TYPE")
    if (hash) {
      if ($(`a.type-link[href="${hash}"]`).length) {
        $(`a.type-link[href="${hash}"]`).tab("show")
      } else {
        $(`a.type-link[href]`).first().tab("show")
      }
    } else {
      $(`a.type-link[href]`).first().tab("show")
    }
  }

  function closeManageModal() {
    $(".att-manage-close").on("click", function () {
      $(this).closest(".att-manage-modal").modal("hide")
      return false
    })
  }

  function deleteAttachment() {
    $(".delete-attachment").on("click", e => {
      const $element = $(e.currentTarget)
      const buttonContent = $element.html()
      $.ajax({
        method: "delete",
        url: $element.attr("href"),
        dataType: "json",
        beforeSend() {
          updateButton($element, "Deleting...")
        },
        success(response) {
          $element.closest(".att-manage-modal").modal("hide")
          $element.closest(".att-manage-modal").on("hidden.bs.modal", () => {
            loadElementHtml($(`#attachement-tab-type-${$element.attr("type_id")}`), response.html)
          })
          swal.fire({
            type: "success",
            title: "Deleted",
            confirmButtonText: "Ok",
          })
        },
        error(response) {
          updateButton($element, buttonContent, false)
          swal.fire({
            type: "error",
            title: "Delete Attachment Failed!",
            text: response.responseJSON.error,
            confirmButtonText: "Ok",
          })
        },
      })
      return false
    })
  }

  function updateAttachmentType() {
    $(".att-manage-update").on("click", e => {
      const $element = $(e.currentTarget)
      const $form = $element.closest(".att-manage-modal").find("form.attachment-type-form")
      const buttonContent = $element.html()
      $.ajax({
        method: $form.attr("method"),
        data: new FormData($form[0]),
        url: $form.attr("action"),
        contentType: false,
        processData: false,
        dataType: "json",
        beforeSend() {
          updateButton($element, "Saving...")
        },
        success(response) {
          $element.closest(".att-manage-modal").modal("hide")
          $element.closest(".att-manage-modal").on("hidden.bs.modal", () => {
            loadElementHtml($("#image-gallery"), response.html)
          })
          swal.fire({
            type: "success",
            title: "Saved",
            confirmButtonText: "Ok",
          })
        },
        error(response) {
          updateButton($element, buttonContent, false)
          swal.fire({
            type: "error",
            title: "Save Failed!",
            text: response.responseJSON.error,
            confirmButtonText: "Ok",
          })
        },
      })
      return false
    })
  }


  function newTypeForm() {
    $("form#new-type-form").on("submit", event => {
      event.preventDefault()
      event.stopImmediatePropagation()
      const $form = $(event.currentTarget)
      const $submitBtn = $form.find("[type='submit']")
      const submitBtnContent = $submitBtn.html()
      $.ajax({
        method: $form.attr("method"),
        data: new FormData(event.currentTarget),
        url: $form.attr("action"),
        contentType: false,
        processData: false,
        dataType: "json",
        beforeSend() {
          updateButton($submitBtn, "Updating...")
        },
        success(response) {
          loadElementHtml($("#image-gallery"), response.html)
          $form.find("input[type='text']").val("")
        },
        error(response) {
          swal.fire({
            type: "error",
            title: "New Type Create Failed!",
            text: response.responseJSON.error,
            confirmButtonText: "Ok",
            showCancelButton: true,
          })
        },
        complete() {
          updateButton($submitBtn, submitBtnContent, false)
        },
      })
    })
  }

  function allUploaded(objArray) {
    objArray.forEach(obj => {
      if (!obj.update_status) {
        return false
      }
    })
    return true
  }

  function uploadFiles() {
    $(".upload-btn").on("click", event => {
      const clicked_id = $(event.currentTarget).attr("type_id")
      $(`#file_upload-${clicked_id}`).trigger("click")
    })
    $(".file_upload").on("change", event => {
      const origional_field_key = "backend_cms_attachment[attachment][]"
      const single_field_key = "backend_cms_attachment[attachment]"
      const file_objects = []
      const file_input = event.target
      const $form = $(file_input).closest("form")
      const clicked_id = $(file_input).attr("type_id")
      const upload_queue = [...file_input.files]
      $(file_input).val("")
      upload_queue.forEach((file, idx) => {
        const data = new FormData($form[0])
        const $wrapperEl = $(".img-wrapper").first().clone().empty()
          .append($('<div class="img-container p-0"></div>'))
        file_objects.push({
          file,
          element: $wrapperEl,
          update_status: false,
        })
        const reader = new FileReader()
        reader.onload = function (e) {
          const $newEl = $("<img/>").attr("src", e.target.result).addClass("img-fluid")
          const $statusOverlay = $('<div class="spinner-overlay"><div class="spinner-border text-light" role="status"><span class="sr-only">Loading...</span></div><span class="ml-2">Uploading...</span></div>')
          $wrapperEl.children(".img-container").append($newEl).append($statusOverlay)
          $wrapperEl.attr("idex", idx).attr("type_id", clicked_id)
          $wrapperEl.insertAfter(`#new-add-btn-${clicked_id}`)
          const $element = $(`.img-wrapper[idex=${idx}][type_id=${clicked_id}]`)
          data.delete(origional_field_key)
          data.set(single_field_key, file)
          $.ajax({
            method: $form.attr("method"),
            data,
            url: $form.attr("action"),
            contentType: false,
            processData: false,
            dataType: "json",
            success(response) {
              $element.find("spinner-overlay").hide()
              file_objects[idx].update_status = true
              if (allUploaded(file_objects)) {
                loadElementHtml($(`#attachement-tab-type-${clicked_id}`), response.html)
              }
            },
            error(response) {
              swal.fire({
                type: "error",
                title: "New Type Create Failed!",
                text: response.responseJSON.error,
                confirmButtonText: "Ok",
                showCancelButton: true,
              })
            },
          })
        }
        reader.readAsDataURL(file)
      })
    })
  }
}