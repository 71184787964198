/* eslint-disable react/no-array-index-key */
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import TinySliderWrapper from "shared/TinySliderWrapper"

import LoadingBar from "components/loadingBar"
import { homeDesignActions } from "modules/HomeDesign"
import { setComparePlanList } from "components/common/CompareButton/redux/actions"
import CompareHomeDesignTile from "./CompareHomeDesignTile"

const AddDesignButton = () => (
  <div className="col">
    <a href="/home-designs">
      <div className="add-design text-center p-5 bg-light border rounded mt-4">
        Add a design
      </div>
    </a>
  </div>
)

const tinnySliderSetting = {
  items: 1,
  loop: false,
  gutter: 10,
  disable: false,
  controls: false,
  navPosition: "bottom",
  mouseDrag: true,
  responsive: {
    767: {
      disable: true
    }
  }
}

const compareColumns = 3

export default function HomeDesigns() {
  const dispatch = useDispatch()
  const { compare_ids: urlCompateIds, page_path: pagePath } = useSelector(state => state.config.home_designs)
  const {
    homeDesigns: { apiRequest, byId, allIds },
    comparePlanList: localCompareIds
  } = useSelector(state => state)
  useEffect(() => {
    if (urlCompateIds && Array.isArray(urlCompateIds) && urlCompateIds.length > 0) {
      // overwirte compare_ids in store if compare_ids present in url
      dispatch(setComparePlanList(urlCompateIds))
    } else {
      // add local compare_ids to url
      const newPagePath = `${pagePath}/${localCompareIds.join("/")}`
      // eslint-disable-next-line no-restricted-globals
      history.pushState({}, "", newPagePath)
    }
    // fetch data
    if (localCompareIds.length > 0) {
      dispatch(homeDesignActions.filtersUpdated({ by_ids: urlCompateIds }))
    } else {
      dispatch(homeDesignActions.fetchDataSuccess({ data: {}, ordering: [], count: 0 }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlCompateIds])

  function removeCompareId(id) {
    const planListTemp = [...localCompareIds].filter(planId => `${planId}` !== id)
    const newPagePath = `${pagePath}/${planListTemp.join("/")}`
    // eslint-disable-next-line no-restricted-globals
    history.pushState({}, "", newPagePath)
    dispatch(setComparePlanList(planListTemp))
  }

  const isFetchingHomeDesigns = [null, "HomeDesigns/FETCH_START"].includes(apiRequest)

  useEffect(() => {
    if (!isFetchingHomeDesigns && localCompareIds.length > 0) {
      const fetchedValidIds = localCompareIds.filter(id => allIds.includes(id))
      const newPagePath = `${pagePath}/${fetchedValidIds.join("/")}`
      // eslint-disable-next-line no-restricted-globals
      history.pushState({}, "", newPagePath)
      if (JSON.stringify(localCompareIds) !== JSON.stringify(fetchedValidIds)) dispatch(setComparePlanList(fetchedValidIds))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(allIds)])

  let emptyColumn = compareColumns

  return (
    <div>
      <div className="container pb-5 compare-slider">
        <LoadingBar isLoading={isFetchingHomeDesigns} />
        <TinySliderWrapper settings={tinnySliderSetting} extraClasses="row">
          {localCompareIds.map(planId => {
            if (planId in byId) {
              emptyColumn -= 1
              return (
                <CompareHomeDesignTile
                  key={`compare-tile-${planId}`}
                  homeDesign={byId[planId]}
                  onClickRemove={removeId => removeCompareId(removeId)} />
              )
            }
            return ""
          })}
          {[...Array(emptyColumn)].map((e, idx) => <AddDesignButton key={`add-compre-${idx}`} />)}
        </TinySliderWrapper>
      </div>
    </div>
  )
}
