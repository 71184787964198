/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import PriceRangeFilter from "components/filters/PriceRangeFilter"
import HomeTypeFilter from "components/filters/HomeTypeFilter"
import SpecificationsFilter from "components/filters/SpecificationsFilter"
import LocationFilter from "components/filters/LocationFilter"
import { updateFilter, updateFilterNoFetchData, controlModalFilter } from "../../redux/actions"

export default function StickyFilters() {
  const dispatch = useDispatch()
  const {
    filters: {
      by_home_types, by_price_range,
      by_bedroom_count, by_bathroom_count, by_garage_count,
      by_location
    },
    locationOptions
  } = useSelector(state => state.homeLands)

  const [homeTypeFilterState, setHomeTypeFilterState] = useState(by_home_types)
  const [priceRangeFilterState, setPriceRangeFilterState] = useState(by_price_range)
  const [locationFilterState, setLocationFilterState] = useState(by_location)
  const [bedroomFilterState, setBedroomFilterState] = useState(by_bedroom_count)
  const [bathroomFilterState, setBathroomFilterState] = useState(by_bathroom_count)
  const [garageFilterState, setGarageFilterState] = useState(by_garage_count)

  useEffect(() => { setHomeTypeFilterState(by_home_types) }, [by_home_types])
  useEffect(() => { setPriceRangeFilterState(by_price_range) }, [by_price_range])
  useEffect(() => { setLocationFilterState(by_location) }, [by_location])
  useEffect(() => { setBedroomFilterState(by_bedroom_count) }, [by_bedroom_count])
  useEffect(() => { setBathroomFilterState(by_bathroom_count) }, [by_bathroom_count])
  useEffect(() => { setGarageFilterState(by_garage_count) }, [by_garage_count])

  function DropDownFooter({ cancelAction, applyAction }) {
    return (
      <div className="dropdown-footer d-flex justify-content-between mt-3">
        <button
          className="btn btn-cancel"
          type="button"
          onClick={cancelAction}>
          Cancel
        </button>
        <button
          className="btn btn-apply"
          type="button"
          onClick={applyAction}>
          Apply
        </button>
      </div>
    )
  }

  return (
    <div className="sticky-filter fixed-top p-1 active">
      <nav className="navbar navbar-expand-lg">
        <button
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          className="navbar-toggler p-0"
          data-target="#navbarSupportedContent"
          data-toggle="collapse"
          type="button">
          <div
            className="btn btn-outline"
            style={{ boxShadow: "none" }}
            onClick={() => { dispatch(controlModalFilter(true)) }}>
            Filters
          </div>
        </button>
        <div className="collapse navbar-collapse">
          <div className="dropdown mr-2">
            <button
              aria-expanded="false"
              aria-haspopup="true"
              className="btn btn-white dropdown-toggle"
              data-toggle="dropdown"
              id="dropdownMenuHomeType"
              type="button">
              Home Type
            </button>
            <div aria-labelledby="dropdownMenuHomeType" className="dropdown-menu">
              <HomeTypeFilter
                homeLandType
                selected_options={homeTypeFilterState}
                dataChanged={data => { setHomeTypeFilterState(data.by_home_types) }} />
              <DropDownFooter
                cancelAction={() => { setHomeTypeFilterState(by_home_types) }}
                applyAction={() => { dispatch(updateFilter({ by_home_types: homeTypeFilterState })) }} />
            </div>
          </div>
          <div className="dropdown mr-2 price-range">
            <button
              aria-expanded="false"
              aria-haspopup="true"
              className="btn btn-white dropdown-toggle"
              data-toggle="dropdown"
              id="dropdownMenuPrice"
              type="button">
              Price Range
            </button>
            <div aria-labelledby="dropdownMenuPrice" className="dropdown-menu">
              <PriceRangeFilter
                optionArray={[500000, 600000, 650000, 700000, 750000, 800000, 850000, 900000, 1000000, 1100000, 1200000, 1300000, 1400000, 1500000]}
                price_range={priceRangeFilterState}
                dataChanged={data => { setPriceRangeFilterState(data.by_price_range) }} />
              <DropDownFooter
                cancelAction={() => { setPriceRangeFilterState(by_price_range) }}
                applyAction={() => { dispatch(updateFilter({ by_price_range: priceRangeFilterState })) }} />
            </div>
          </div>
          <div className="dropdown mr-2">
            <button
              aria-expanded="false"
              aria-haspopup="true"
              className="btn btn-white dropdown-toggle"
              data-toggle="dropdown"
              id="dropdownMenuLocation"
              type="button">
              Location
            </button>
            <div aria-labelledby="dropdownMenuLocation" className="dropdown-menu">
              <div className="col-lg-12 col-md-12 filter-col filter-basic">
                <LocationFilter
                  filterType="sticky"
                  options={locationOptions}
                  selected_options={locationFilterState}
                  dataChanged={data => { setLocationFilterState(data.by_location) }} />
              </div>
              <DropDownFooter
                cancelAction={() => { setLocationFilterState(by_location) }}
                applyAction={() => { dispatch(updateFilterNoFetchData({ by_location: locationFilterState })) }} />
            </div>
          </div>
          <div className="dropdown">
            <button
              aria-expanded="false"
              aria-haspopup="true"
              className="btn btn-white dropdown-toggle"
              data-toggle="dropdown"
              id="dropdownMenuRooms"
              type="button">
              Bed, Bath, Garage
            </button>
            <div aria-labelledby="dropdownMenuRooms" className="dropdown-menu">
              <div className="col p-2">
                <SpecificationsFilter
                  by_bedroom_count={bedroomFilterState}
                  by_bathroom_count={bathroomFilterState}
                  by_garage_count={garageFilterState}
                  dataChanged={data => {
                    setBedroomFilterState(data.by_bedroom_count)
                    setBathroomFilterState(data.by_bathroom_count)
                    setGarageFilterState(data.by_garage_count)
                  }} />
              </div>
              <DropDownFooter
                cancelAction={() => {
                  setBedroomFilterState(by_bedroom_count)
                  setBathroomFilterState(by_bathroom_count)
                  setGarageFilterState(by_garage_count)
                }}
                applyAction={() => {
                  dispatch(updateFilter({
                    by_bedroom_count: bedroomFilterState,
                    by_bathroom_count: bathroomFilterState,
                    by_garage_count: garageFilterState,
                  }))
                }} />
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}