import $ from 'jquery';
import Rails from "@rails/ujs";

function uploadAttachment(attachment) {
  var file = attachment.file;
  var form = new FormData;
  var isImage = file.type.match(/image/);
  form.append("Content-Type", file.type);
  form.append("html_attachment[attachment]", file);

  var xhr = new XMLHttpRequest;
  xhr.open("POST", "/backend/cms/html_attachments.json", true);
  xhr.setRequestHeader("X-CSRF-Token", Rails.csrfToken());

  xhr.upload.onprogress = function (event) {
    var progress = event.loaded / event.total * 100;
    attachment.setUploadProgress(progress);
  }

  xhr.onload = function () {
    if (xhr.status === 201) {
      var data = JSON.parse(xhr.responseText);
      if (isImage) {
        return attachment.setAttributes({
          url: data.attach_url,
        })
      } else {
        return attachment.setAttributes({
          url: data.attach_url,
          href: data.attach_url
        })
      }
    } else {
      console.log("Error during upload image in Trix");
      console.log(xhr.responseText);
    }
  }
  return xhr.send(form);
}

const bindTrixUploader = function () {
  $(document).on('trix-attachment-add', function (event) {
    console.log("trix got a new attachment");
    var attachment = event.originalEvent.attachment;
    if (attachment.file) {
      return uploadAttachment(attachment);
    }
  })
}

export default bindTrixUploader;