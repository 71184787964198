import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Tabs, Tab } from "react-bootstrap"

import LoadingBar from "components/loadingBar"
import { fetchDataStart } from "../actions"
import * as constants from "../constants"
import TabBody from "./TabBody"

function resizeWindow() {
  window.dispatchEvent(new Event("resize"))
  window.dispatchEvent(new Event("scroll"))
}

function humanizeName(str) {
  return str.split("_").map(w => (w[0].toUpperCase() + w.slice(1))).join(" ")
}

export default function PopularDesignsRoot() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchDataStart({}))
  }, [dispatch])

  const {
    apiRequest, designs
  } = useSelector(state => state.popularDesigns)

  // trigger https://pixelcog.github.io/parallax.js/
  // on homepage to reload
  useEffect(() => {
    window.dispatchEvent(new Event("resize"))
    window.dispatchEvent(new Event("scroll"))
  }, [apiRequest, designs])

  const isFetchingData = [null, constants.FETCH_START].includes(apiRequest)

  const tabs = ["most_popular", "single_storey", "double_storey", "loft", "granny_flat", "dual_occupancy", "estate"]
  return (
    <div>
      <div className="container-fluid">
        <h1 className="text-center mt-5 mb-3">Popular Home Designs</h1>
        {isFetchingData ? <LoadingBar isLoading /> : (
          <Tabs className="bh-nav justify-content-center mb-3" defaultActiveKey="most_popular" id="popular-designs" onClick={resizeWindow}>
            {tabs.map(tab => {
              const data = Object.values(_.get(designs, `${tab}.plan`, {}))
              if (data.length) {
                return (
                  <Tab key={tab} className="nav-item" eventKey={tab} title={humanizeName(tab)}>
                    <TabBody isFetching={isFetchingData} data={data} />
                  </Tab>
                )
              }
              return ""
            })}
          </Tabs>
        )}
      </div>
    </div>
  )
}