import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"

import Root from "./Root"
import configureStore from "./redux/store"
import RootSaga from "./redux/sagas"

export default function startHomeLandSearchApp(rootElem) {
  const store = configureStore()
  store.runSaga(RootSaga)

  ReactDOM.render(
    <Provider store={store}>
      <Root />
    </Provider>,
    rootElem
  )
}