import React from "react"

export default function SortByFilter({ selected, options, onChange }) {
  const [label] = _.find(options, o => o[1] === selected)

  return (
    <div className="col order-2 text-right sort-by text-nowrap">
      Sort by:
      <div className="d-inline-block">
        <div className="dropdown show">
          <button
            type="button"
            aria-expanded="true"
            aria-haspopup="true"
            className="dropdown-toggle"
            data-toggle="dropdown"
            id="dropdownMenuLink">
            {` ${label}`}
          </button>
          <div aria-labelledby="dropdownMenuLink" className="dropdown-menu dropdown-menu-right">
            {options.map(object => (
              <button
                type="button"
                key={`name-${object[1]}`}
                className="dropdown-item"
                onClick={() => { onChange(object[1]) }}>
                {object[0]}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
