/* eslint-disable react/no-danger */
import React from "react"

import { useSelector } from "react-redux"

export default function LandForSaleIntro({ devLogoRendered }) {
  const { attributes: { description, developmentLogo } } = useSelector(state => state.landForSale)
  return (
    <>
      <div className="container">
        <div className="land-for-sale-intro mt-4">
          <div className="row justify-content-between">
            <div className="col-md-8" dangerouslySetInnerHTML={{ __html: description }} />
            {!devLogoRendered
            && (
              <div className="col-md-3 text-center text-md-right">
                <img className="img-fluid" src={developmentLogo.url} />
              </div>
            )}
          </div>
        </div>
      </div>
      <br />
    </>
  )
}