export const SET_FACADE_MODAL = "SET_FACADE_MODAL"
export const SET_SHOW_QUICKVIEW = "SET_SHOW_QUICKVIEW"
export const SET_QUICKVIEW_IDX = "SET_QUICKVIEW_IDX"
export const SET_FACADE_CAROUSEL_IDX = "SET_FACADE_CAROUSEL_IDX"

export const setShowFacadeModal = state => ({
  type: SET_FACADE_MODAL,
  payload: { state }
})

export const setShowQuickView = state => ({
  type: SET_SHOW_QUICKVIEW,
  payload: { state }
})

export const setQuickViewIdx = idx => ({
  type: SET_QUICKVIEW_IDX,
  payload: { idx }
})

export const setFacadeCarouselIdx = idx => ({
  type: SET_FACADE_CAROUSEL_IDX,
  payload: idx
})
