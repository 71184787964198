/* eslint-disable no-console */
const Log = (function () {
  const LOG_LEVELS = {
    DEBUG: "debug",
    AUDIT: "audit",
    ERROR: "error",
    EMERGENCY: "emergency"
  }

  function logExecution(level, title, details) {
    const toLog = { title: title || "", details: details || "" }
    switch (level) {
      case LOG_LEVELS.DEBUG:
        console.debug(toLog)
        break
      case LOG_LEVELS.AUDIT:
        console.info(toLog)
        break
      case LOG_LEVELS.ERROR:
        console.warn(toLog)
        break
      case LOG_LEVELS.EMERGENCY:
        console.error(toLog)
        break
      default:
        break
    }
  }

  function getFlatArgs(title, details) {
    let theTitle; let
      theDetails

    // eslint-disable-next-line no-prototype-builtins
    if (title !== undefined && title !== null && typeof title.hasOwnProperty === "function" && title.hasOwnProperty("title")) {
      theTitle = title.title
      theDetails = title.details
    } else {
      theTitle = title
      theDetails = details
    }

    return { title: theTitle, details: theDetails }
  }

  /**
   * Log a debug statement into the console
   *
   * @memberof Log
   * @name Log.debug
   * @param {String} title
   * @param {String} details
   */
  function debug(title, details) {
    const args = getFlatArgs(title, details)
    logExecution(LOG_LEVELS.DEBUG, args.title, args.details)
  }

  /**
   * Log an audit statement into the console
   *
   * @memberof Log
   * @name Log.audit
   * @param {String} title
   * @param {String} details
   */
  function audit(title, details) {
    const args = getFlatArgs(title, details)
    logExecution(LOG_LEVELS.AUDIT, args.title, args.details)
  }

  /**
   * Log an error statement into the console
   * @memberof Log
   * @name Log.error
   * @param {String} title
   * @param {String} details
   */
  function error(title, details) {
    const args = getFlatArgs(title, details)
    logExecution(LOG_LEVELS.ERROR, args.title, args.details)
  }

  /**
   * Log an emergency statement into the console
   * @memberof Log
   * @name Log.emergency
   * @param {String} title
   * @param {String} details
   */
  function emergency(title, details) {
    const args = getFlatArgs(title, details)
    logExecution(LOG_LEVELS.EMERGENCY, args.title, args.details)
  }

  return {
    debug,
    audit,
    error,
    emergency,
    LOG_LEVELS
  }
}())

export default Log
