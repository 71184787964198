import React from "react"
import {
  Container, Row, Col, Form, InputGroup
} from "react-bootstrap"
import { useContactFormik } from "../../configurator-context-provider"
import "../../styles/configurator-view.scss"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.ContactForm = false

const ContactForm = () => {
  // if (window.configurator.debugPoints.ContactForm) {
  //   debugger
  // }

  const formik = useContactFormik()
  const conditionsLabel = [
    "By completing this form, I acknowledge that this is not a formal Tender, I agree to receiving future communications from Beechwood Homes and that Beechwood",
    "Homes may contact me by phone/email about this document and to discuss my home building needs. I understand that I can choose to unsubscribe at any time."
  ].join(" ")

  return (
    <div className="home-deisgns">
      <div className="page-header text-center">
        <h1>Contact Information</h1>
      </div>
      <Container fluid="sm">
        <Form id="contact-form" noValidate onSubmit={formik.handleSubmit}>
          <Row>
            <Form.Group as={Col} controlId="first-name">
              <Form.Label>First Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.firstName && formik.errors.firstName} />
                <Form.Control.Feedback type="invalid">{formik.errors.firstName}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="surname">
              <Form.Label>Surname</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Surname"
                  name="surname"
                  value={formik.values.surname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.surname && formik.errors.surname} />
                <Form.Control.Feedback type="invalid">{formik.errors.surname}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="email">
              <Form.Label>Email</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.email && formik.errors.email} />
                <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="mobile">
              <Form.Label>Mobile</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="tel"
                  placeholder="Mobile"
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.mobile && formik.errors.mobile} />
                <Form.Control.Feedback type="invalid">{formik.errors.mobile}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="preferred-contact-method" style={{ float: "right" }}>
              <Form.Label>Preferred Contact Method</Form.Label>
              <InputGroup hasValidation>
                <Form.Check
                  inline
                  type="radio"
                  name="preferredContactMethod"
                  label="Email"
                  value="email"
                  checked={formik.values.preferredContactMethod === "email"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.preferredContactMethod && formik.errors.preferredContactMethod} />
                <Form.Check
                  inline
                  type="radio"
                  name="preferredContactMethod"
                  label="Mobile"
                  value="mobile"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  checked={formik.values.preferredContactMethod === "mobile"}
                  isInvalid={formik.touched.preferredContactMethod && formik.errors.preferredContactMethod} />
                <Form.Control.Feedback type="invalid">{formik.errors.preferredContactMethod}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="current-postcode">
              <Form.Label>Current Postcode</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Current Postcode"
                  name="currentPostcode"
                  value={formik.values.currentPostcode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.currentPostcode && formik.errors.currentPostcode} />
                <Form.Control.Feedback type="invalid">{formik.errors.currentPostcode}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="intended-build-location">
              <Form.Label>Intended Build Location</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Where are you thinking of building?"
                  name="intendedBuildLocation"
                  value={formik.values.intendedBuildLocation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.intendedBuildLocation && formik.errors.intendedBuildLocation} />
                <Form.Control.Feedback type="invalid">{formik.errors.intendedBuildLocation}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="is-first-home-buyer">
              <Form.Label>I am a</Form.Label>
              <InputGroup hasValidation>
                <Form.Select
                  name="isFirstHomeBuyer"
                  value={formik.values.isFirstHomeBuyer}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.isFirstHomeBuyer && formik.errors.isFirstHomeBuyer}>
                  <option value="">Select One...</option>
                  <option value="firsthomebuyer">first home buyer</option>
                  <option value="previoushomeowner">previous home owner</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">{formik.errors.isFirstHomeBuyer}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="looking-to-build">
              <Form.Label>I&apos;m looking to</Form.Label>
              <InputGroup hasValidation>
                <Form.Select
                  name="lookingToBuild"
                  value={formik.values.lookingToBuild}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.lookingToBuild && formik.errors.lookingToBuild}>
                  <option value="">Select One...</option>
                  <option value="newhome">build a new home on vacant land</option>
                  <option value="knockdownrebuild">knock down rebuild</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">{formik.errors.lookingToBuild}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="live-invest-both">
              <Form.Label>I am building</Form.Label>
              <InputGroup hasValidation>
                <Form.Select
                  name="liveInvestBoth"
                  value={formik.values.liveInvestBoth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.liveInvestBoth && formik.errors.liveInvestBoth}>
                  <option value="">Select One...</option>
                  <option value="livein">to live in</option>
                  <option value="investment">as an investment</option>
                  <option value="both">to live in and as an investment</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">{formik.errors.liveInvestBoth}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="configurator-message">
                Please add any additional requests or details in the message box below so that our team will have some further information for you before contacting you to discuss your
                preliminary estimate document. These questions will help our building and design team have a better understanding of your home requirements. Some examples include:
              </p>
              <ul>
                <li>Your block of land and its characteristics (i.e. where, size, dimensions, registered, slope, nearby features).</li>
                <li>Any changes you would like to make to your home design. Unlike some other builders, we allow you to make changes to your home design. After all, it is your family home.</li>
                <li>Any particular items you would like included in your new Beechwood home to make your home a true reflection of your personality.</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="message">
              <Form.Label>Message</Form.Label>
              <InputGroup>
                <Form.Control
                  as="textarea"
                  placeholder="Brief summary of any desired Custom Variations, or anything else we need to know."
                  name="message"
                  value={formik.values.message}
                  style={{ height: "100px" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="justify-content-md-center">
            <Form.Group as={Col} controlId="subscribe">
              <InputGroup>
                <Form.Check
                  type="checkbox"
                  name="subscribe"
                  value={formik.values.subscribe}
                  checked={formik.values.subscribe}
                  label={conditionsLabel}
                  onChange={formik.handleChange} />
              </InputGroup>
            </Form.Group>
          </Row>
        </Form>
      </Container>
    </div>
  )
}

export default ContactForm
