/* eslint-disable no-undef */
import { combineReducers } from "redux"
import * as types from "../actionTypes"

// response data as object
const fullObjects = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_SUCCESS: {
      const { response } = action.payload
      return response
    }
    default: {
      return state
    }
  }
}

// data as array
const fullArray = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_SUCCESS: {
      const { response } = action.payload
      return Object.values(response)
    }
    case types.UPDATE_FILTER: {
      return state
    }
    default: {
      return state
    }
  }
}

// filtered Data for render
const filterDataArray = (state = [], action) => {
  switch (action.type) {
    case types.DATA_UPDATED: {
      return action.payload
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  fullObjects,
  fullArray,
  filterDataArray
})