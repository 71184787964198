import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { FAVOURITE_LAND_FOR_SALE } from "servos/localStorage/storedList"
import { updateFilter } from "apps/LandForSale/redux/actions"
import SortByFilter from "components/filters/SortByFilter"
import HomeCount from "components/common/HomeCount"
import LoadingBar from "components/loadingBar"
import HomeDesignList from "components/common/HomeDesignList"
import { removeFavouriteId } from "components/common/favouriteIcon/redux/actions"

export default function LandForSale() {
  const dispatch = useDispatch()
  const favouriteList = useSelector(state => state.favouriteList)
  const favouriteLandList = favouriteList[FAVOURITE_LAND_FOR_SALE]

  useEffect(() => {
    if (favouriteLandList.length > 0) {
      dispatch(updateFilter({ by_ids: favouriteLandList }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    dataFetching, objects, ids, filters, options
  } = useSelector(state => state.landForSale)
  const { sort_by } = options

  useEffect(() => {
    if (!dataFetching) {
      favouriteLandList.forEach(favouriteLandId => {
        if (!ids.includes(favouriteLandId)) {
          dispatch(removeFavouriteId({ listName: FAVOURITE_LAND_FOR_SALE, planId: favouriteLandId }))
        }
      })
    }
  }, [dataFetching, favouriteLandList, ids, dispatch])

  const renderList = ids.filter(id => favouriteLandList.includes(id))
  return (
    <div>
      <div className="container-fluid filter-content">
        <div className="row justify-content-around my-2 small-text align-items-center">
          <HomeCount number={renderList.length} />
          <SortByFilter
            options={sort_by}
            onChange={value => { dispatch(updateFilter({ sort_by: value })) }}
            selected={filters.sort_by} />
        </div>
        {favouriteLandList.length > 0 && <LoadingBar isLoading={dataFetching} />}
        {((!dataFetching && renderList.length === 0) || favouriteLandList.length === 0)
          ? <div className="no-results">List is empty</div>
          : <HomeDesignList renderList={renderList} dataObject={objects} renderType="landForSale" />}
      </div>
    </div>
  )
}