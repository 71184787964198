import React from "react"
import { Container } from "react-bootstrap"
import { useStandardSiteCosts } from "../../configurator-context-provider"
import StandardSiteCost from "./StandardSiteCost"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.SiteCostsList = false

const SiteCostsList = () => {
  // if (window.configurator.debugPoints.SiteCostsList) {
  //   debugger
  // }

  const siteCosts = useStandardSiteCosts()

  return (
    <Container fluid="sm">
      {siteCosts.map((groupMember, idx) => (
        <StandardSiteCost
          key={groupMember.getInternalID()}
          groupMember={groupMember}
          index={idx} />
      ))}
    </Container>
  )
}

export default SiteCostsList
