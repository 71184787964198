import React from "react"
import { NavLink } from "react-router-dom"

const TabSwitch = () => (
  <div>
    <div className="d-flex justify-content-center">
      <ul className="nav bh-nav">
        <li>
          <NavLink
            to="/favourites/home-designs"
            className="nav-link"
            activeClassName="active">
            Home Designs
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/favourites/home-and-land"
            className="nav-link"
            activeClassName="active">
            Home & Land
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/favourites/display-homes"
            className="nav-link"
            activeClassName="active">
            Display Homes
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/favourites/land-for-sale"
            className="nav-link"
            activeClassName="active">
            Land for Sale
          </NavLink>
        </li>
      </ul>
    </div>
    <hr className="mt-0" />
  </div>

)

export default TabSwitch