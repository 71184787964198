import BaseController from "controllers/base"

import startLandForSaleApp from "apps/DisplayHomeForSale"

class Controller extends BaseController {
  call() {
    super.call()

    startLandForSaleApp(
      document.getElementById("display-home-for-sale"),
      { dataSrc: document.getElementById("display-home-for-sale").getAttribute("data-src") }
    )
  }
}

export default Controller