import React, { Component } from "react"
import { connect } from "react-redux"
import LoadingBar from "components/loadingBar"
import HomeDesigns from "components/common/HomeDesignList"

import SortByFilter from "components/filters/SortByFilter"

import * as actions from "../../actions"
import * as reducers from "../../reducers"

class HomeDesignsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    const { fetchDataStart } = this.props
    fetchDataStart({})
  }

  static getDerivedStateFromProps(props, state) {
    return state
  }

  renderSortBy() {
    const { filters, options: { sort_by }, filtersUpdated } = this.props

    return (
      <SortByFilter
        options={sort_by}
        onChange={value => filtersUpdated({ ...filters, sort_by: value })}
        selected={filters.sort_by} />
    )
  }

  renderSpinner() {
    const { isFetchingHomeDesigns } = this.props

    return (
      <div className="col">
        <LoadingBar
          isLoading={isFetchingHomeDesigns} />
      </div>
    )
  }

  render() {
    const tileType = _.get(this.props, "filters.view_by", "facade")
    const {
      homeDesignObj, homeDesignIds, isFetchingHomeDesigns, count
    } = this.props

    return (
      <div className="container-fluid filter-content pb-5">

        <div className="row justify-content-around mt-4 mb-2 small-text search-status-bar">
          <div className="col text-secondary">
            {`Viewing ${count} Homes`}
          </div>
          {this.renderSpinner()}
          {this.renderSortBy()}
        </div>
        {
          (!isFetchingHomeDesigns && homeDesignIds.length === 0)
            ? <div className="no-results">No Results Found</div>
            : (
              <HomeDesigns
                renderList={homeDesignIds}
                dataObject={homeDesignObj}
                renderType={tileType} />
            )
        }

      </div>
    )
  }
}

const mapStateToProps = state => ({
  isFetchingHomeDesigns: reducers.isFetchingHomeDesigns(state),
  homeDesignIds: reducers.getHomeDesignIds(state),
  homeDesignObj: reducers.getHomeDesignObj(state),
  homeDesigns: reducers.getHomeDesigns(state),
  filters: reducers.getFilters(state),
  options: reducers.getOptions(state),
  count: state.homeDesigns.count
})

export default connect(mapStateToProps, actions)(HomeDesignsList)