import BaseController from "controllers/base"

import startLandForSaleApp from "apps/LandForSale"

class Controller extends BaseController {
  call() {
    super.call()

    startLandForSaleApp(
      document.getElementById("land-for-sale"),
      {}
    )
  }
}

export default Controller