import React from "react"
import ConfigurationList from "./ConfigurationList"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.ConfigurationListView = false

const ConfigurationListView = () => {
  // if (window.configurator.debugPoints.ConfigurationListView) {
  //   debugger
  // }

  return (
    <div className="home-deisgns">
      <div className="page-header text-center">
        <h1>Flex-U-Build Options</h1>
        <h6>If you&apos;re happy with our standard FUB Options, just click &quot;Next&quot;</h6>
      </div>
      <ConfigurationList />
    </div>
  )
}

export default ConfigurationListView
