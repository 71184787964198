/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import { imageSlide } from "components/common/homeDetails/onDisplay/GalleryModal"

const videoItem = item => (
  <div className="embed-responsive embed-responsive-16by9">
    <iframe className="embed-responsive-item" title={`video-${item.original}`} src={item.original} allowFullScreen />
  </div>
)

export default function PlanGallery() {
  const { attributes: { imageUrls, videoUrls, allLocations } } = useSelector(state => state.homeDesign)

  const items = imageUrls.map(image => ({
    imageSrc: image,
    renderItem: imageSlide,
    renderThumbInner: imageSlide
  }))
  videoUrls.forEach(video => {
    items.push({
      original: video.video,
      thumbnail: video.thumbnail,
      imageSrc: video.thumbnail,
      renderItem: videoItem,
      renderThumbInner: imageSlide
    })
  })
  Object.values(allLocations).forEach(location => {
    const { homeImages } = location
    homeImages.forEach(image => {
      items.push({
        imageSrc: image,
        thumbnail: imageSlide,
        renderItem: imageSlide,
        renderThumbInner: imageSlide
      })
    })
  })
  items.forEach((item, idx) => {
    item.index = idx
    item.loaded = false
  })

  const [renderItems, setRenderItems] = useState([...items])

  useEffect(() => {
    renderItems.forEach((item, index) => {
      const loader = new Image()
      loader.src = item.imageSrc
      loader.onload = () => {
        const tmp = [...renderItems]
        tmp[index].loaded = true
        setRenderItems(tmp)
      }
    })
  }, [])

  if (renderItems.length) {
    const renderLeftNav = (onClick, disabled) => (
      <button
        type="button"
        className="image-gallery-custom-left-nav image-gallery-custom-nav"
        disabled={disabled}
        onClick={onClick} />
    )

    const renderRightNav = (onClick, disabled) => (
      <button
        type="button"
        className="image-gallery-custom-right-nav image-gallery-custom-nav"
        disabled={disabled}
        onClick={onClick} />
    )
    return (
      <div className="container mb-md-5 pb-5">
        <ImageGallery
          additionalClass={`gallery-carousel ${renderItems.length === 1 ? "single-slide" : ""}`}
          items={renderItems}
          showFullscreenButton={false}
          showPlayButton={false}
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav} />
      </div>
    )
  }
  return ""
}
