import React, { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import Title from "components/common/PageTitle"
import SortByFilter from "components/filters/SortByFilter"
import HomeDesignList from "components/common/HomeDesignList"
import LoadingBar from "components/loadingBar"
import HomeCount from "components/common/HomeCount"
import { getData, updateFilter } from "./redux/actions"

export default function Root() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getData())
  }, [dispatch])

  const {
    filters: { sort_by },
    options: { sort_by: sort_by_options },
    objects, ids,
    dataFetching
  } = useSelector(state => state.landForSale)
  return (
    <Fragment>
      <Title title="Land for Sale" />
      <div className="container-fluid filter-content pb-5">
        <div className="row justify-content-around mt-4 mb-2 small-text">
          <HomeCount number={ids.length} />
          <SortByFilter
            options={sort_by_options}
            onChange={value => { dispatch(updateFilter({ sort_by: value })) }}
            selected={sort_by} />
        </div>
        <LoadingBar isLoading={dataFetching} />
        {(!dataFetching && ids.length === 0)
          ? <div className="no-results">No Results Found</div>
          : <HomeDesignList renderList={ids} dataObject={objects} renderType="landForSale" />}
      </div>

    </Fragment>
  )
}
