import Util from "../Util/configurator-util"

function GroupMember(ctx) {
  this.internalID = ""
  this.name = ""
  this.quantity = 0
  this.invalidSeries = []
  this.invalidDesigns = []
  this.invalidFacades = []
  this.taxSchedule = ""
  this.pricing = {}

  if (!ctx) return

  this.setInternalID(ctx.internalID || "")
  this.setName(ctx.name || "")
  this.setQuantity(ctx.quantity || 0)
  this.addInvalidSeries(ctx.invalidSeries || [])
  this.addInvalidDesigns(ctx.invalidDesigns || [])
  this.addInvalidFacades(ctx.invalidFacades || [])
  this.setTaxSchedule(ctx.taxSchedule || "")
  if (ctx.pricing) {
    Util.each(ctx.pricing, (price, level) => {
      this.setPrice(level, price)
    })
  }
}

GroupMember.prototype.getInternalID = function () { return this.internalID }
GroupMember.prototype.setInternalID = function (internalID) {
  if (!Util.isString(internalID)) {
    throw new TypeError("Invalid parameter internalID: must be String")
  }

  this.internalID = internalID
}

GroupMember.prototype.getName = function () { return this.name }
GroupMember.prototype.setName = function (name) {
  if (!Util.isString(name)) {
    throw new TypeError("Invalid parameter name: must be String")
  }

  this.name = name
}

GroupMember.prototype.getQuantity = function () { return this.quantity }
GroupMember.prototype.setQuantity = function (quantity) {
  if (!Util.isNumber(quantity)) {
    throw new TypeError("Invalid parameter quantity: must be String")
  }

  this.quantity = quantity
}

GroupMember.prototype.getInvalidSeries = function () { return this.invalidSeries }
GroupMember.prototype.addInvalidSeries = function (seriesIDs) {
  if (Util.isArray(seriesIDs)) {
    Util.each(seriesIDs, internalID => {
      if (!Util.isString(internalID)) {
        throw new TypeError("Invalid Invalid Series: must be String|String[]")
      }
    })

    Util.each(seriesIDs, internalID => {
      if (this.invalidSeries.indexOf(internalID) < 0) {
        this.invalidSeries.push(internalID)
      }
    })
  } else if (Util.isString(seriesIDs)) {
    if (this.invalidSeries.indexOf(seriesIDs) < 0) {
      this.invalidSeries.push(seriesIDs)
    }
  } else {
    throw new TypeError("Invalid Invalid Series: must be String|String[]")
  }
}

GroupMember.prototype.getInvalidDesigns = function () { return this.invalidDesigns }
GroupMember.prototype.addInvalidDesigns = function (designIDs) {
  if (Util.isArray(designIDs)) {
    Util.each(designIDs, internalID => {
      if (!Util.isString(internalID)) {
        throw new TypeError("Invalid Invalid Design: must be String|String[]")
      }
    })

    Util.each(designIDs, internalID => {
      if (this.invalidDesigns.indexOf(internalID) < 0) {
        this.invalidDesigns.push(internalID)
      }
    })
  } else if (Util.isString(designIDs)) {
    if (this.invalidDesigns.indexOf(designIDs) < 0) {
      this.invalidDesigns.push(designIDs)
    }
  } else {
    throw new TypeError("Invalid Invalid Design: must be String|String[]")
  }
}

GroupMember.prototype.getInvalidFacades = function () { return this.invalidFacades }
GroupMember.prototype.addInvalidFacades = function (facadeIDs) {
  if (Util.isArray(facadeIDs)) {
    Util.each(facadeIDs, internalID => {
      if (!Util.isString(internalID)) {
        throw new TypeError("Invalid Invalid Facade: must be String|String[]")
      }
    })

    Util.each(facadeIDs, internalID => {
      if (this.invalidFacades.indexOf(internalID) < 0) {
        this.invalidFacades.push(internalID)
      }
    })
  } else if (Util.isString(facadeIDs)) {
    if (this.invalidFacades.indexOf(facadeIDs) < 0) {
      this.invalidFacades.push(facadeIDs)
    }
  } else {
    throw new TypeError("Invalid Invalid Facade: must be String|String[]")
  }
}

GroupMember.prototype.getTaxSchedule = function () { return this.taxSchedule }
GroupMember.prototype.setTaxSchedule = function (taxSchedule) {
  if (!Util.isString(taxSchedule)) {
    throw new TypeError("Invalid parameter taxSchedule: must be String")
  }

  this.taxSchedule = taxSchedule
}

GroupMember.prototype.getPrice = function (level) {
  if (!this.pricing[level]) {
    console.log({
      error: "No price defined for the requested level",
      details: {
        name: this.getName(),
        internalID: this.getInternalID(),
        level: level
      }
    })

    throw new RangeError(`No price defined for the requested level (Name: ${this.getName()}, Internal ID: ${this.getInternalID()}, Level: ${level})`)
  }

  return this.pricing[level]
}
GroupMember.prototype.getPriceLevels = function () { return Object.keys(this.pricing) }
GroupMember.prototype.setPrice = function (level, price) {
  if (!level) {
    throw new TypeError("Missing required parameter: level")
  }
  if (!price) {
    throw new TypeError("Missing required parameter: price")
  }

  if (!Util.isString(level)) {
    throw new TypeError("Invalid parameter level: must be String")
  }

  if (!Util.isString(price)) {
    throw new TypeError("Invalid parameter price: must be String")
  }

  if (Number.isNaN(Number(price))) {
    throw new TypeError("Invalid parameter price: must be able to be converted to a Number")
  }

  this.pricing[level] = price
}
GroupMember.prototype.getTotalPrice = function (level) {
  return Util.round(Number(this.getPrice(level)) * this.quantity, 2)
}
GroupMember.prototype.getPriceWithTax = function (level) {
  let toReturn = this.getPrice(level)

  if (this.getTaxSchedule() === "BAS") {
    toReturn = Number(toReturn) * 1.1
  }

  return Util.round(Number(toReturn), 2)
}
GroupMember.prototype.getTotalPriceWithTax = function (level) {
  let toReturn = this.getTotalPrice(level)

  if (this.getTaxSchedule() === "BAS") {
    toReturn = Number(toReturn) * 1.1
  }

  return Util.round(Number(toReturn), 2)
}

Object.defineProperty(GroupMember.prototype, "toString", {
  value: () => "[object GroupMember]"
})

export default GroupMember
