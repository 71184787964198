/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import { Modal } from "react-bootstrap"

import { IconClose } from "images/icons"
import { closeModal, openModal } from "../redux/actions/galleryModal"

function ImageModal() {
  const dispatch = useDispatch()
  const { data: { filterDataArray } } = useSelector(state => state)
  const { showModal, modalIndex, modalName } = useSelector(state => state.imageModal)

  if (filterDataArray.length > 0) {
    const imageModals = {}
    filterDataArray.forEach(item => {
      const {
        type, name, url, imageIndex
      } = item
      if (type === "photo") {
        if (!Object.keys(imageModals).includes(name)) {
          imageModals[name] = {
            objs: [],
            galleryImages: [],
            element: null
          }
        }
        imageModals[name].objs.push({
          type,
          name,
          url,
          imageIndex
        })
        imageModals[name].galleryImages.push({
          original: url, thumbnail: url
        })
      }
    })

    const renderLeftNav = (onClick, disabled) => (
      <button
        className="image-gallery-custom-left-nav image-gallery-custom-nav"
        disabled={disabled}
        onClick={onClick} />
    )

    const renderRightNav = (onClick, disabled) => (
      <button
        className="image-gallery-custom-right-nav image-gallery-custom-nav"
        disabled={disabled}
        onClick={onClick} />
    )
    if (modalName !== "") {
      const imageModal = imageModals[modalName]
      let modalImages = []
      if (imageModal) {
        modalImages = imageModal.galleryImages || []
      }
      return (
        <Modal
          centered
          show={showModal}
          onHide={() => dispatch(closeModal())}
          size="xl">
          <Modal.Body>
            <h2 className="gallery-carousel-title">
              {modalName}
            </h2>
            <IconClose
              onClick={() => dispatch(closeModal())}
              className="gallery-carousel-close" />
            <ImageGallery
              additionalClass="gallery-carousel"
              items={modalImages}
              showFullscreenButton={false}
              showPlayButton={false}
              renderLeftNav={renderLeftNav}
              renderRightNav={renderRightNav}
              onSlide={idx => {
                dispatch(openModal({ name: modalName, imageIndex: idx }))
              }}
              startIndex={modalIndex} />
          </Modal.Body>
        </Modal>
      )
    }
  }
  return ""
}

export default ImageModal