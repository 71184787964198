import React, { useState, useEffect, Fragment } from "react"
import Skeleton from "react-loading-skeleton"
import { Modal } from "react-bootstrap"
import { AiFillPlayCircle } from "react-icons/ai"
import ReactTooltip from "react-tooltip"

import "./VideoItem.css"

const styles = {
  virtualWrapperStyle: {
    position: "absolute",
    top: 0,
    background: "rgba(0, 0, 0, 0)",
    width: "100%",
    height: "100%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "rgba(255,255,255,0.5)",
  },
}

export default function VideoItem({
  data: {
    itemIdx, name, url, preview
  }
}) {
  const [imageLoaded, setImageLoaded] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    const imageLoader = new Image()
    imageLoader.src = preview

    imageLoader.onload = () => {
      setImageLoaded(true)
    }
    return function () {
      imageLoader.onload = null
      imageLoader.onerror = null
    }
  }, [preview])
  return (
    <Fragment>
      <div className="button-wrapper">
        <button type="button" className="card" onClick={() => { setOpenModal(true) }} style={{ display: "contents" }}>
          <div style={{ position: "relative" }} className="play-button-wrapper">
            {imageLoaded
              ? <img className="img-fluid rounded" src={preview} alt={name} style={{ width: "100%", objectFit: "cover", maxHeight: "260px" }} /> : (
                <div className="img-fluid">
                  <Skeleton style={{ width: "100%", objectFit: "cover", maxHeight: "260px" }} />
                </div>
              )}
            <div
              style={styles.virtualWrapperStyle}
              data-tip={name}
              data-for={`${name}-${itemIdx}`}>
              <AiFillPlayCircle size="5em" className="play-button" />
            </div>
            <ReactTooltip id={`${name}-${itemIdx}`} place="bottom" />
          </div>
        </button>
      </div>
      <Modal
        centered
        scrollable
        show={openModal}
        onHide={() => { setOpenModal(false) }}
        size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: "100%", paddingBottom: "56.25%", position: "relative" }}>
            <iframe
              src={url}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title={name}
              style={{ width: "100%", height: "100%", position: "absolute" }} />
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}