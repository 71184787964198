import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import normalize from "json-api-normalizer"

import DisplayHomeTile from "components/common/DisplayHomeTile"
import SortByFilter from "components/filters/SortByFilter"
import HomeCount from "components/common/HomeCount"
import LoadingBar from "components/loadingBar"
import { FAVOURITE_HOMES } from "servos/localStorage/storedList"
import { getData, fetchDataSuccess, fetchDataFailed } from "../actions/displayHomes"

export default function DisplayHomes() {
  const dispatch = useDispatch()
  const [dataFetching, setDataFetching] = useState(true)
  const { display_homes: { api_url: apiUrl } } = useSelector(state => state.config)
  const { favouriteList } = useSelector(state => state)
  const favouriteDisplayHomeIds = favouriteList[FAVOURITE_HOMES]
  const filters = { by_ids: favouriteDisplayHomeIds }
  useEffect(() => {
    dispatch(getData())
    axios.post(apiUrl, filters)
      .then(({ data }) => {
        const normalizedData = normalize(data.records)
        const displayHomes = []
        const objects = normalizedData.displayHome
        const ids = data.meta.ordering
        ids.forEach(id => { displayHomes.push(objects[id]) })
        dispatch(fetchDataSuccess({ data: displayHomes, meta: ids }, filters))
        setDataFetching(false)
      })
      .catch(error => {
        dispatch(fetchDataFailed(error.message))
        setDataFetching(false)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favouriteDisplayHomeIds])

  const sort_by = [
    ["Default", "default"],
    ["Name A-Z", "name__asc"],
    ["Name Z-A", "name__desc"],
    ["Bedroom High", "bedrooms__desc"],
    ["Bedroom Low", "bedrooms__asc"],
    ["Bathroom High", "bathrooms__desc"],
    ["Bathroom Low", "bathrooms__asc"],
    ["Garage High", "garages__desc"],
    ["Garage Low", "garages__asc"]]
  const [sortBySelected, setSortBySelected] = useState("default")
  const { objects } = useSelector(state => state.displayHomes)
  const [sortedDisplayHomes, setSortedDisplayHomes] = useState([])
  useEffect(() => {
    if (sortBySelected === "default") {
      setSortedDisplayHomes(objects)
    } else {
      const sortable = [...objects]
      const [key, order] = sortBySelected.split("__")
      if (key === "name") {
        sortable.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
      } else {
        sortable.sort((a, b) => a.attributes[key] - b.attributes[key])
      }
      if (order === "desc") sortable.reverse()
      setSortedDisplayHomes(sortable)
    }
  }, [sortBySelected, objects])


  return (
    <div>
      <div className="container-fluid filter-content">
        <div className="row justify-content-around my-2 small-text align-items-center">
          <HomeCount number={sortedDisplayHomes.length} />
          <SortByFilter
            options={sort_by}
            onChange={value => {
              setSortBySelected(value)
            }}
            selected={sortBySelected} />
        </div>
        <LoadingBar isLoading={dataFetching} />
        {!dataFetching && (
          <div className="row">
            {sortedDisplayHomes.map(home => {
              const { attributes: { id } } = home
              if (favouriteDisplayHomeIds.includes(id)) {
                return (
                  <DisplayHomeTile key={`display-home-${id}`} displayHome={home} />
                )
              }
              return ""
            })}
          </div>
        )}
        { !dataFetching && sortedDisplayHomes.length === 0 && <div className="no-results">List is empty</div> }
      </div>
    </div>
  )
}