import React from "react"
import Select from "react-select"
import { Container, Row, Col } from "react-bootstrap"
import { ACTIONS, useDispatch, useSelectedPriceLevel } from "../../configurator-context-provider"
import Util from "../../Util/configurator-util"

const BuildLocation = () => {
  // if (window.configurator.debugPoints.BuildLocation) {
  //   debugger
  // }

  const dispatch = useDispatch()
  const selectedPriceLevel = useSelectedPriceLevel()

  const priceLevelOptions = [
    { label: "Select One...", value: "" },
    { label: "Sydney", value: "1" },
    { label: "North Coast", value: "2" },
    { label: "South Coast", value: "3" }
  ]

  let selectedPriceLevelOption = priceLevelOptions[0]
  Util.each(priceLevelOptions, option => {
    if (option.value === selectedPriceLevel) selectedPriceLevelOption = option
  })

  return (
    <Container fluid="sm">
      <Row className="configurator-build-location">
        <Col xs={12} md={3}>
          <div className="form-group">
            <div className="title configurator-required">Build Location</div>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              value={selectedPriceLevelOption}
              isClearable={false}
              isSearchable={false}
              name="buildLocation"
              options={priceLevelOptions}
              onChange={newValue => dispatch({ type: ACTIONS.SET_PRICE_LEVEL, payload: { priceLevel: newValue.value } })} />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default BuildLocation
