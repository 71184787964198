/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react"

import FavouriteIcon from "components/common/favouriteIcon"
import CompareButton from "components/common/CompareButton"
import FacadeCarousel from "components/common/facadeCarousel"
import HomeDesignName from "components/common/HomeDesignName"
import HomeRoomsSmall from "components/common/homeRoomsSmall"

export default function HomeDesignTile({
  homeDesign,
  homeDesign: {
    id,
    attributes: {
      name,
      price,
      detailUrl
    }
  }
}) {
  return (
    <div className="card design-card design-card-facade mb-4 border-0 rounded-0">
      <FacadeCarousel
        homeDesign={homeDesign}
        showBedges />
      <a
        href={detailUrl}
        className="no-url-decoration">
        <div className="card-body bg-light">
          <div className="card-title d-flex justify-content-between">
            <HomeDesignName name={name} />
            <FavouriteIcon homeDesignId={id} />
          </div>
        </div>
        <ul className="list-group list-group-flush bg-light">
          <li className="list-group-item">
            <HomeRoomsSmall homeDesign={homeDesign} />
          </li>
          <li className="list-group-item">
            <div className="row justify-content-around align-items-center">
              {price && (
                <div className="col text-secondary small-text">
                  {`Base Price ${price}`}
                </div>
              )}
              <div className="col text-right">
                <CompareButton homeDesignId={id} extra={{ name }} />
              </div>
            </div>
          </li>
        </ul>
      </a>
    </div>
  )
}
