import React, { Component } from "react"
import PropTypes from "prop-types"

import SimpleSelect from "components/inputs/SimpleSelect"

// eslint-disable-next-line react/prefer-stateless-function
class PriceRangeFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  static getDerivedStateFromProps(props) {
    return props
  }

  setMinRange = () => {
    const { optionArray } = this.props
    const { price_range: [, max] } = this.state
    const options = optionArray.map(x => (
      { label: `$ ${x.toLocaleString("en-AU")}`, value: `${x}`, isDisabled: (x >= max) }))

    return _.union([{ label: "Any", value: "0" }], options)
  }

  setMaxRange = () => {
    const { optionArray } = this.props
    const { price_range: [min] } = this.state
    const options = optionArray.map(x => (
      { label: `$ ${x.toLocaleString("en-AU")}`, value: `${x}`, isDisabled: (x <= min) }))

    return _.union([{ label: "Any", value: "9999999999" }], options)
  }

  setMinValue = option => {
    const { price_range: [, max] } = this.state
    const { dataChanged } = this.props

    dataChanged({ by_price_range: [parseInt(option.value, 10), parseInt(max, 10)] })
  }

  setMaxValue = option => {
    const { price_range: [min] } = this.state
    const { dataChanged } = this.props

    dataChanged({ by_price_range: [parseInt(min, 10), parseInt(option.value, 10)] })
  }

  render() {
    const { extraWrapperCls } = this.props
    const { price_range: [min, max] } = this.state

    const minOptions = this.setMinRange()
    const maxOptions = this.setMaxRange()

    const defaultMinOption = _.find(minOptions, e => e.value === `${min}`) || _.head(minOptions)
    const defaultMaxOption = _.find(maxOptions, e => e.value === `${max}`) || _.head(maxOptions)

    return (
      <div className={`price-range ${extraWrapperCls}`}>
        <div className="title">Price Range</div>
        <div className="row">
          <div className="col">
            <SimpleSelect
              label="Min"
              value={defaultMinOption}
              options={minOptions}
              handleSelect={option => this.setMinValue(option)} />
          </div>
          <div className="col">
            <SimpleSelect
              label="Max"
              value={defaultMaxOption}
              options={maxOptions}
              handleSelect={option => this.setMaxValue(option)} />
          </div>
        </div>
        <div className="dash">-</div>
      </div>
    )
  }
}

PriceRangeFilter.defaultProps = {
  optionArray: [90000, 175000, 200000, 225000, 250000, 275000, 300000, 325000, 350000, 375000, 400000, 450000, 500000, 550000, 600000],
  extraWrapperCls: "col-lg-12 col-md-6"
}

PriceRangeFilter.propTypes = {
  optionArray: PropTypes.arrayOf(PropTypes.number),
  extraWrapperCls: PropTypes.string
}

export default PriceRangeFilter
