import React from "react"

export default function NewsItem({
  news: {
    id,
    attributes: {
      thumbnailImage,
      selectedPublishDateFormated,
      publishedContentShort,
      headerTitle,
      url
    }
  }
}) {
  const newUrl = `/${url}`
  return (
    <div className="col-md-4 col-sm-6 news-item" key={id}>
      <div className="py-3">
        <a href={newUrl}>
          <img
            className="rounded news-image"
            src={thumbnailImage}
            alt={headerTitle} />
        </a>
        <div className="date smaller-text">
          {selectedPublishDateFormated}
        </div>
        <a href={newUrl}>
          <h3>{headerTitle}</h3>
        </a>
        <a href={newUrl}>
          <p>{publishedContentShort}</p>
        </a>
      </div>
    </div>
  )
}