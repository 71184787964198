/* eslint-disable no-use-before-define */
import BaseController from "controllers/base"

import popUpGallery from "widgets/cms/imageGallery"

class Controller extends BaseController {
  call() {
    super.call()

    popUpGallery.call(this)
    homePageScrollToSection.call(this)
  }
}

const homePageScrollToSection = function () {
  const editAnchors = this.root.find("a[iframs-a]")
  const iframePreview = this.root.find("#page-preview")
  editAnchors.on("click", function () {
    iframePreview[0].contentWindow.location.hash = $(this).attr("iframs-a")
  })
}


export default Controller