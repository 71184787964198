import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Modal } from "react-bootstrap"

import PriceRangeFilter from "components/filters/PriceRangeFilter"
import HomeTypeFilter from "components/filters/HomeTypeFilter"
import SpecificationsFilter from "components/filters/SpecificationsFilter"
import LocationFilter from "components/filters/LocationFilter"
import { updateFilter, controlModalFilter } from "../../redux/actions"


export default function ModalFilters() {
  const dispatch = useDispatch()
  const { showModalFilter } = useSelector(state => state)
  const {
    filters: {
      by_home_types, by_price_range,
      by_bedroom_count, by_bathroom_count, by_garage_count,
      by_location
    },
    locationOptions
  } = useSelector(state => state.homeLands)

  const [homeTypeFilterState, setHomeTypeFilterState] = useState(by_home_types)
  const [priceRangeFilterState, setPriceRangeFilterState] = useState(by_home_types)
  const [locationFilterState, setLocationFilterState] = useState(by_location)
  const [bedroomFilterState, setBedroomFilterState] = useState(by_bedroom_count)
  const [bathroomFilterState, setBathroomFilterState] = useState(by_bathroom_count)
  const [garageFilterState, setGarageFilterState] = useState(by_garage_count)

  useEffect(() => { setHomeTypeFilterState(by_home_types) }, [by_home_types])
  useEffect(() => { setPriceRangeFilterState(by_price_range) }, [by_price_range])
  useEffect(() => { setLocationFilterState(by_location) }, [by_location])
  useEffect(() => { setBedroomFilterState(by_bedroom_count) }, [by_bedroom_count])
  useEffect(() => { setBathroomFilterState(by_bathroom_count) }, [by_bathroom_count])
  useEffect(() => { setGarageFilterState(by_garage_count) }, [by_garage_count])

  return (
    <Modal scrollable show={showModalFilter} onHide={() => { dispatch(controlModalFilter(false)) }}>
      <Modal.Header closeButton>
        <strong
          className="modal-title text-center flex-fill"
          id="exampleModalCenterTitle">
          Filters
        </strong>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-filter home-designs">
          <HomeTypeFilter
            extraWrapperCls="col"
            homeLandType
            selected_options={homeTypeFilterState}
            dataChanged={data => { setHomeTypeFilterState(data.by_home_types) }} />
          <hr />
          <PriceRangeFilter
            extraWrapperCls="col"
            optionArray={[500000, 600000, 650000, 700000, 750000, 800000, 850000, 900000, 1000000, 1100000, 1200000, 1300000, 1400000, 1500000]}
            price_range={priceRangeFilterState}
            dataChanged={data => { setPriceRangeFilterState(data.by_price_range) }} />
          <hr />
          <div className="col-lg-12 col-md-12 filter-col filter-basic">
            <LocationFilter
              filterType="modal"
              options={locationOptions}
              selected_options={locationFilterState}
              dataChanged={data => { setLocationFilterState(data.by_location) }} />
          </div>
          <hr />
          <div className="col-lg-12 col-md-12 filter-col filter-basic">
            <SpecificationsFilter
              by_bedroom_count={bedroomFilterState}
              by_bathroom_count={bathroomFilterState}
              by_garage_count={garageFilterState}
              dataChanged={data => {
                setBedroomFilterState(data.by_bedroom_count)
                setBathroomFilterState(data.by_bathroom_count)
                setGarageFilterState(data.by_garage_count)
              }} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          style={{ boxShadow: "none" }}
          onClick={() => {
            setHomeTypeFilterState(by_home_types)
            setPriceRangeFilterState(by_price_range)
            setLocationFilterState(by_location)
            setBedroomFilterState(by_bedroom_count)
            setBathroomFilterState(by_bathroom_count)
            setGarageFilterState(by_garage_count)
            dispatch(controlModalFilter(false))
          }}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-red"
          style={{ boxShadow: "none" }}
          onClick={() => {
            dispatch(updateFilter({
              by_home_types: homeTypeFilterState,
              by_price_range: priceRangeFilterState,
              by_location: locationFilterState,
              by_bedroom_count: bedroomFilterState,
              by_bathroom_count: bathroomFilterState,
              by_garage_count: garageFilterState,
            }))
            dispatch(controlModalFilter(false))
          }}>
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  )
}