import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"

import HomeLandRoot from "./HomeLandRoot"
import configureStore from "./store"

const startHomeLandDetailApp = rootElem => {
  const store = configureStore()

  ReactDOM.render(
    <Provider store={store}>
      <HomeLandRoot />
    </Provider>,
    rootElem
  )
}

export default startHomeLandDetailApp