import React from "react"
import PropTypes from "prop-types"

export default function FloorPlanFeatures({ features = [] }) {
  if (features.length) {
    return (
      <div className="design-option">
        <div className="d-flex align-items-center mb-2">
          <div className="lightbulb mr-2"></div>
          <h4 className="mb-0">Design Features</h4>
        </div>
        <ul>
          {features.map(feature => <li key={feature}>{feature}</li>)}
        </ul>
      </div>
    )
  }
  return ""
}

FloorPlanFeatures.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string).isRequired
}
