import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useSubmission } from "../../configurator-context-provider"
import Loading from "../Loading"
import Error from "../Error"

window.configurator = window.configurator || {}
window.configurator.debugPoints = window.configurator.debugPoints || {}
window.configurator.debugPoints.Final = false

const Final = () => {
  // if (window.configurator.debugPoints.Final) {
  //   debugger
  // }

  const submission = useSubmission()

  if (submission.loading) {
    return <Loading />
  }

  if (submission.error) {
    return <Error message={submission.error} />
  }

  return (
    <div className="home-deisgns">
      <div className="page-header text-center">
        <h1>Thank You!</h1>
      </div>
      <Container fluid="sm">
        <Row>
          <Col>
            Thank you for your interest in purchasing a new Beechwood home. Your custom build preliminary estimate has been recieved and a summary will be sent to you shortly.
            A member of our building and design team will contact you soon to discuss making your dream home a reality!
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Final
