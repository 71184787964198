import React from "react"
// import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import priceFormatter from "helpers/priceFormatter"
import HomeDesignName from "components/common/HomeDesignName"
import { IconFacade, IconDisplayPin } from "images/icons"
import { AwardBadge } from "components/buttons/badges"
import HomeRooms from "components/common/homeRooms"


const OnDisplayBadge = ({ onDisplay }) => {
  if (onDisplay) {
    return (
      <li className="list-inline-item mr-2">
        <a href="#on_display">
          <div className="btn btn-black-opac btn-md">
            <IconDisplayPin style={{ marginTop: "-2px" }} />
          </div>
        </a>
      </li>
    )
  }
  return ""
}

// OnDisplayBadge.propTypes = {
//   virtualTour: PropTypes.bool
// }

function VirtualTourBadge({ virtualTour }) {
  if (virtualTour) {
    return (
      <li className="list-inline-item">
        <a
          className="btn btn-white btn-md mr-2"
          href={virtualTour}
          target="_blank"
          rel="noopener noreferrer">
          <i className="material-icons">remove_red_eye</i>
          <span className="ml-1 hide-1">
            3D Virtual Tour
          </span>
        </a>
      </li>
    )
  }
  return ""
}

// VirtualTourBadge.propTypes = {
//   virtualTour: PropTypes.string
// }

export default function Hero() {
  const {
    attributes: {
      salePrice, returnValue, saleBrochureUrl, suburb, address1,
      facadeUrl, name, facadeType,
      bedrooms, bathrooms, homeTheatre, garages,
      virtualTour360, awardWinner, saleStatus
    }
  } = useSelector(state => state.displayHome)

  const priceString = priceFormatter({ price: salePrice })

  return (
    <div className="home-design-hero container-fluid mb-md-4">
      <div className="row">
        <div className="col-lg-8">
          <div className="carousel" id="home-design-hero">
            <div className="carousel-inner">
              <div
                className="carousel-item active home-land"
                style={{
                  background: `url(${facadeUrl}) no-repeat center`,
                  backgroundSize: "cover"
                }}>
              </div>
            </div>
            <div className="card-badges container-fluid d-flex justify-content-between p-0">
              <div>
                <AwardBadge display={awardWinner} />
              </div>
              <div className="display-home-for-sale-overlay">
                {returnValue > 0 && (
                  <span className="return-value">{`${returnValue} % RETURN`}</span>
                )}
                <span className="for-sale">{saleStatus}</span>
              </div>
            </div>
            <div className="summary">
              <div className="row justify-content-around">
                <div className="col-md-6 order-2 order-md-1">
                  <h1 className="h2 text-left home-name">
                    <HomeDesignName name={name} />
                  </h1>
                </div>
                <div className="col-md-6 text-right align-self-end order-1 order-md-2">
                  {facadeType && (
                    <ul className="list-inline mr-2">
                      <li className="list-inline-item">
                        <div className="btn btn-black-opac">
                          <IconFacade className="mr-2" style={{ marginTop: "-2px" }} />
                          {facadeType}
                        </div>
                      </li>
                      <OnDisplayBadge onDisplay />
                      <VirtualTourBadge virtualTour={virtualTour360} />
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 pl-lg-0">
          <div className="bg-light home-land-summary d-flex">
            <div className="text-center align-self-center mx-auto">
              {salePrice && (
                <div className="price">
                  {`${priceString}*`}
                </div>
              )}
              <h3 className="mb-4">
                {address1}
                <br />
                {suburb}
              </h3>
              <HomeRooms
                bedrooms={bedrooms}
                bathrooms={bathrooms}
                garages={garages}
                homeTheatre={homeTheatre} />

              {saleBrochureUrl && (
                <a
                  className="btn btn-dark mb-4 mb-md-0 mt-5 btn-lg"
                  href={saleBrochureUrl}
                  target="_blank"
                  rel="noopener noreferrer">
                  Download Brochure
                </a>
              )}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
