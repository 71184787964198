import Util from "../Util/configurator-util"
import Design from "./configurator-design"

/**
 * @param {Object} ctx
 * @param {String} ctx.internalID
 * @param {String} ctx.name
 * @param {String} ctx.image
 * @param {Design[]} ctx.designs
 */
function Series(ctx) {
  this.internalID = ""
  this.name = ""
  this.image = ""
  this.designs = []

  if (!ctx) {
    return
  }

  this.setInternalID(ctx.internalID || "")
  this.setName(ctx.name || "")
  this.setImage(ctx.image || "")

  if (ctx.designs) {
    Util.each(ctx.designs, design => {
      this.designs.push(new Design(design))
    })
  }
}

/**
 * Get the Internal ID
 * @returns {String}
 */
Series.prototype.getInternalID = function () { return this.internalID }
/**
 * Set the Internal ID
 * @param {String} internalID The Internal ID
 */
Series.prototype.setInternalID = function (internalID) {
  if (!Util.isString(internalID)) {
    throw new TypeError("Invalid parameter internalID: must be String")
  }

  this.internalID = internalID
}

/**
 * Get the Name
 * @returns {String}
 */
Series.prototype.getName = function () { return this.name }
/**
 * Set the Name
 * @param {String} name The Name
 */
Series.prototype.setName = function (name) {
  if (!Util.isString(name)) {
    throw new TypeError("Invalid parameter name: must be String")
  }
  this.name = name
}

/**
 * Get the Image (URL)
 * @returns {String}
 */
Series.prototype.getImage = function () { return this.image }
/**
 * Set the Image (URL)
 * @param {String} image The Image (URL)
 */
Series.prototype.setImage = function (image) {
  if (!Util.isString(image)) {
    throw new TypeError("Invalid parameter image: must be String")
  }

  this.image = image
}

/**
 *
 * @param {String} designID
 * @returns {Design}
 */
Series.prototype.getDesign = function (designID) {
  let toReturn = null

  Util.each(this.designs, design => {
    if (design.getInternalID() === designID) toReturn = design
  })

  return toReturn
}

/**
 * Add a design to this series
 * @param {Design} toAdd
 */
Series.prototype.addDesign = function (toAdd) {
  if (!this.getDesign(toAdd.getInternalID())) this.designs.push(toAdd)
}
/**
 * @returns {Design[]}
 */
Series.prototype.getDesignList = function () {
  return this.designs
}

Object.defineProperty(Series.prototype, "toString", {
  value: () => "[object Series]"
})

export default Series
