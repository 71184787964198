import React from "react"

import { PinSelected } from "images/icons"

const mapPinWidth = 40
const mapPinHeight = 50
const styles = {
  mapPin: {
    position: "absolute",
    left: -mapPinWidth / 2,
    top: -mapPinHeight
  }
}

export default function Marker() {
  return (
    <PinSelected
      width={mapPinWidth}
      height={mapPinHeight}
      style={styles.mapPin} />
  )
}