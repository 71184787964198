import React, { useState, useEffect } from "react"
import Skeleton from "react-loading-skeleton"
import ReactTooltip from "react-tooltip"

import { IconEye } from "images/icons"

const styles = {
  virtualWrapperStyle: {
    position: "absolute",
    top: 0,
    background: "rgba(0, 0, 0, 0)",
    width: "100%",
    height: "100%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
  },
  threeDWordStyle: {
    fontSize: "52px",
    marginLeft: "10px"
  }
}

export default function VirtualTourItem({
  data: {
    itemIdx, name, url, preview
  }
}) {
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const imageLoader = new Image()
    imageLoader.src = preview
    imageLoader.onload = () => {
      setImageLoaded(true)
    }
    return function () {
      imageLoader.onload = null
      imageLoader.onerror = null
    }
  }, [preview])

  return (
    <div className="button-wrapper">
      <a
        className="card"
        href={url}
        target="_blank"
        rel="noopener noreferrer">
        {imageLoaded ? <img className="img-fluid rounded" src={preview} alt={name} />
          : (
            <div className="img-fluid">
              <Skeleton height={260} />
            </div>
          )}
        <div
          style={styles.virtualWrapperStyle}
          data-tip={name}
          data-for={`${name}-${itemIdx}`}>
          <div>
            <IconEye style={{ verticalAlign: "baseline" }} width="61.552" height="39.231" />
            <span style={styles.threeDWordStyle}>
              3D
            </span>
          </div>
        </div>
        <ReactTooltip id={`${name}-${itemIdx}`} place="bottom" />
      </a>
    </div>
  )
}